import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";

export default function LeavingPageDialog(props) {
  const { open, onPrimaryClick, onSecondaryClick } = props;

  return (
    <AlertDialog
      open={open}
      dialogType="WARNING"
      title="Discard Changes?"
      contentText="Leaving before saving the changes of this line item will discard any unsaved changes. How would you like to continue?"
      primaryLabel="Discard"
      onPrimaryClick={onPrimaryClick}
      onSecondaryClick={onSecondaryClick}
    />
  );
}

LeavingPageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};
