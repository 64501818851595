import { useSnackbar } from "notistack";

//for hooks
export function useCheckSnackbar() {
  const { enqueueSnackbar } = useSnackbar();
  return (message, options) => {
    const canViewSnackbar = JSON.parse(localStorage.getItem("viewSnackbar"));

    return canViewSnackbar
      ? enqueueSnackbar(message, options)
      : () => {
          /* This is Intentional */
        };
  };
}

//for classes
export function checkSnackbar(message, options, enqueueSnackbar) {
  const canViewSnackbar = JSON.parse(localStorage.getItem("viewSnackbar"));
  canViewSnackbar && enqueueSnackbar(message, options);
}
