import React from "react";
import loadable from "@loadable/component";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { Authority, Type } from "../../AuthProvider";

const LazyReorderDetailPage = loadable(() => import("./ReorderDetailPage"));
const LazyReorderListPage = loadable(() => import("./ReorderListPage"));
const LazyPaymentPage = loadable(() => import("../Payment/PaymentPage"));

export default class Reorders {
  static routes(context) {
    const type = context.whoAmI().type;
    let reorderRoutes = [];

    // Feature flag should work once it's added
    if (context.hasAuthority(Authority.FEATURE_REORDER)) {
      reorderRoutes.push({
        groupName: type === Type.CSR ? "Search" : "My Items",
        items: [
          {
            label: "Reorders",
            icon: <PlaylistAddIcon />,
            nestedListItems: [
              {
                label: "In Progress",
                icon: <EditIcon />,
                path: "/reorders/in-progress",
                component: <LazyReorderListPage />,
                alternativeRoutes: [
                  {
                    path: "/reorders/in-progress/:reorderResourceId",
                    component: <LazyReorderDetailPage />,
                  },
                  {
                    hide: !context.hasAnyAuthority([
                      Authority.FEATURE_REORDER,
                      Authority.REORDER_WARRANTY_PURCHASE,
                    ]),
                    path: "/reorders/:resourceId/purchase",
                    component: <LazyPaymentPage />,
                  },
                ],
              },
              {
                label: "Completed",
                icon: <CheckCircleIcon />,
                path: "/reorders/completed",
                dataTest: "completed-reorders-drawer-btn",
                component: <LazyReorderListPage />,
                alternativeRoutes: [
                  {
                    path: "/reorders/completed/:reorderResourceId",
                    component: <LazyReorderDetailPage />,
                  },
                ],
              },
            ],
          },
        ],
      });
    }

    return reorderRoutes;
  }
}
