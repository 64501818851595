import React, { Fragment } from "react";
import groupBy from "lodash/groupBy";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";

export default function ReorderComponentList(props) {
  const { reorder } = props;

  /// Group line items by reorder item id
  const reorderItems = Object.entries(groupBy(reorder?.items, "reorderItemId"));

  return (
    <Grid container spacing={2}>
      {reorderItems.map(([key, value], index) => (
        <Fragment key={index}>
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              Line Item #{key}
            </Typography>
          </Grid>
          {value.map((item, innerIndex) => {
            const componentData = item.product.components.componentData[0];
            const isPeepSite = componentData.partType === "PeepSite";
            return (
              <Grid
                sx={{
                  ml: 2,
                }}
                key={innerIndex}
                data-test={`line-item-${key}-component-${innerIndex + 1}`}
                item
                xs={12}
                container
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography data-test="component-type" variant="body2">
                    {componentData.display}
                  </Typography>
                  <Typography
                    data-test="component-description"
                    variant="caption"
                    color="textSecondary"
                    component="div"
                  >
                    {`${componentData.description} ${
                      (!isPeepSite && componentData.finish?.description) || ""
                    }`}
                  </Typography>
                  <Typography
                    data-test="reorder-reason"
                    variant="caption"
                    color="textSecondary"
                    component="div"
                  >
                    {item.reorderReason}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography data-test="component-quantity" variant="caption">
                    QTY: {item.quantity}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Fragment>
      ))}
    </Grid>
  );
}

ReorderComponentList.propTypes = {
  reorder: PropTypes.object,
};
