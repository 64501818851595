import React from "react";
import loadable from "@loadable/component";
import DollarIcon from "@mui/icons-material/AttachMoney";
import { Authority, Type } from "../../AuthProvider";

const LazySalesPage = loadable(() => import("./SalesPage"));

export default class Sales {
  static routes(context) {
    const type = context.whoAmI().type;

    return [
      {
        groupName: "None",
        items: [
          {
            label: "Sales",
            hide:
              type === Type.CSR ||
              type === Type.SALES_REP ||
              !context.hasAnyAuthority([
                Authority.SERVICEAREA_READ,
                Authority.COMPANY_MANAGE_MULTIPLIER,
              ]),
            icon: <DollarIcon />,
            path: "/sales",
            component: <LazySalesPage />,
          },
        ],
      },
    ];
  }
}
