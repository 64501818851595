import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import PlusSign from "@mui/icons-material/Add";
import MinusSign from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

async function getUpdatedUnitHeight(
  selections,
  selectedCutDownHeight,
  getRoughOpenings,
  setUpdatedUnitHeight
) {
  let options = [
    selections.slabLayout,
    selections.slabWidth,
    selections.slabHeight,
    selections.handing,
    selections.isDoorCovered,
    selections.hurricaneRated,
    selectedCutDownHeight,
  ];

  if (get(selections, "slabLayout.hasSidelite")) {
    options.push(selections.sideLiteWidth);
  }

  const resp = await getRoughOpenings(options);
  setUpdatedUnitHeight(resp.unitHeight);
}

export default function CutDownDoor({
  cutDownIndex,
  setCutDownIndex,
  cutDownDoorHeights,
  originalUnitHeight,
  getRoughOpenings,
  selections,
}) {
  const [updatedUnitHeight, setUpdatedUnitHeight] = React.useState();

  React.useEffect(() => {
    getUpdatedUnitHeight(
      selections,
      cutDownDoorHeights[cutDownIndex],
      getRoughOpenings,
      setUpdatedUnitHeight
    );
  }, [
    getRoughOpenings,
    cutDownDoorHeights,
    setUpdatedUnitHeight,
    selections,
    cutDownIndex,
  ]);

  const maximumValue = Object.keys(cutDownDoorHeights).length - 1;

  async function updateCutDownValue(method) {
    let newCutDownIndex;
    if (method === "add" && cutDownIndex < 23) {
      newCutDownIndex = cutDownIndex + 1;
      setCutDownIndex(newCutDownIndex);
    } else if (method === "subtract" && cutDownIndex > 0) {
      newCutDownIndex = cutDownIndex - 1;
      setCutDownIndex(newCutDownIndex);
    }

    await getUpdatedUnitHeight(
      selections,
      cutDownDoorHeights[newCutDownIndex],
      getRoughOpenings,
      setUpdatedUnitHeight
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={12} sm={3} container direction="column">
          <Typography variant="overline">Before</Typography>
          <Typography variant="h6" data-test="door-height-value">
            {originalUnitHeight}"
          </Typography>
          <Typography variant="caption"> Unit Height </Typography>
        </Grid>
        <Grid item xs={12} sm={3} container direction="column">
          <Typography variant="overline">After</Typography>
          <Typography
            color="secondary"
            variant="h6"
            data-test="updated-height-value"
          >
            {updatedUnitHeight}"
          </Typography>
          <Typography variant="caption"> Unit Height </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            onClick={() => updateCutDownValue("subtract")}
            disabled={cutDownIndex <= 0}
            variant="outlined"
            data-test="subtract-cut-value-btn"
          >
            <MinusSign />
          </Button>
          <Typography
            data-test="cut-down-value"
            sx={{
              textAlign: "center",
              p: 1,
              m: 1,
              fontSize: "16px",
              borderRadius: "8px",
              minWidth: "70px",
              backgroundColor: "primary.200",
            }}
          >
            -
            {cutDownDoorHeights[cutDownIndex] &&
              cutDownDoorHeights[cutDownIndex].description}
            "
          </Typography>
          <Button
            onClick={() => updateCutDownValue("add")}
            disabled={cutDownIndex >= maximumValue}
            variant="outlined"
            data-test="add-cut-value-btn"
          >
            <PlusSign />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

CutDownDoor.propTypes = {
  doorHeight: PropTypes.number.isRequired,
  cutDownIndex: PropTypes.number.isRequired,
  setCutDownIndex: PropTypes.func.isRequired,
  cutDownDoorHeights: PropTypes.array.isRequired,
  originalUnitHeight: PropTypes.string,
  getRoughOpenings: PropTypes.func.isRequired,
  selections: PropTypes.object.isRequired,
};
