import { customPartDefaultState } from "../defaultStates";
import {
  CLEAR_FORM,
  RESET_STATE,
  SET_CUSTOM_PART_ACTIVE_STEP,
  SET_DEFAULT_SPECS,
  SET_FORM_VALUES,
  SET_ITEM_CODE,
  SET_ITEM_TYPE,
  SET_ITEM_IS_IMPACT_RATED,
  SET_ITEM_PRICE,
  SET_STORE_STATE,
  SET_CUSTOM_PART_RESPONSE,
  SET_ITEM_CODE_ERROR_MESSAGE,
  SET_ITEM_TYPE_ERROR_MESSAGE,
  SET_ITEM_PRICE_ERROR_MESSAGE,
  SET_CUSTOM_PART_ERROR_MESSAGE,
} from "../actions/customPartActionCreators";

const customPartReducer = (state = customPartDefaultState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return customPartDefaultState;
    case SET_STORE_STATE:
      return action.payload;
    case SET_DEFAULT_SPECS:
      return {
        ...state,
        defaultSpecs: action.payload,
      };
    case SET_CUSTOM_PART_ACTIVE_STEP:
      return {
        ...state,
        formViewOptions: {
          ...state.formViewOptions,
          activeStep: action.payload,
        },
      };
    case SET_ITEM_CODE:
      return {
        ...state,
        selections: {
          ...state.selections,
          itemCode: action.payload,
        },
      };
    case SET_ITEM_CODE_ERROR_MESSAGE:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          itemCodeErrorMessage: action.payload,
        },
      };
    case SET_ITEM_TYPE:
      return {
        ...state,
        selections: {
          ...state.selections,
          itemType: action.payload,
        },
      };
    case SET_ITEM_TYPE_ERROR_MESSAGE:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          itemTypeErrorMessage: action.payload,
        },
      };
    case SET_ITEM_IS_IMPACT_RATED:
      return {
        ...state,
        selections: {
          ...state.selections,
          itemIsImpactRated: action.payload,
        },
      };
    case SET_ITEM_PRICE:
      return {
        ...state,
        selections: {
          ...state.selections,
          itemPrice: action.payload,
        },
      };
    case SET_ITEM_PRICE_ERROR_MESSAGE:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          itemPriceErrorMessage: action.payload,
        },
      };
    case SET_FORM_VALUES:
      return {
        ...state,
        selections: {
          ...state.selections.partNumber,
          selections: action.payload,
        },
      };
    case SET_CUSTOM_PART_RESPONSE:
      return {
        ...state,
        customPartResponse: action.payload,
      };
    case SET_CUSTOM_PART_ERROR_MESSAGE:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          customPartErrorMessage: action.payload,
        },
      };
    case CLEAR_FORM:
      return {
        ...state,
        selections: {
          itemCode: "",
          description: "",
          itemType: "",
          itemIsImpactRated: false,
          itemPrice: "",
        },
        customPartResponse: null,
      };
    default:
      return state;
  }
};

export default customPartReducer;
