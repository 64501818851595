import React from "react";
import loadable from "@loadable/component";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import InboxIcon from "@mui/icons-material/Inbox";
import { Authority, Type } from "../../AuthProvider";

const LazyInquiryFilterPage = loadable(() =>
  import("./Contractor/InquiryFilterPage")
);

const LazyCsrWorkingInquiryFilterPage = loadable(() =>
  import("./Csr/WorkingInquiryFilterPage")
);

const LazyCsrCompletedInquiryFilterPage = loadable(() =>
  import("./Csr/CompletedInquiryFilterPage")
);

const LazyInquiryDetailPage = loadable(() => import("./InquiryDetailPage"));

export default class Inquiries {
  static routes(context) {
    let inquiryRoutes = [];
    const type = context.whoAmI().type;
    if (
      type === Type.CSR &&
      context.hasAnyAuthority([
        Authority.INQUIRY_READ,
        Authority.INQUIRY_ADMIN,
        Authority.INQUIRY_ADMIN_READ,
      ])
    ) {
      inquiryRoutes.push({
        groupName: "Search",
        items: [
          {
            label: "Inquiries",
            icon: <InboxIcon />,
            nestedListItems: [
              {
                label: "Working",
                icon: <EditIcon />,
                path: "/inquiries/working",
                component: <LazyCsrWorkingInquiryFilterPage />,
                alternativeRoutes: [
                  {
                    path: "/inquiries/:inquiryId/orders/:orderResourceId/items/:orderItemResourceId/working",
                    component: <LazyInquiryDetailPage />,
                  },
                ],
              },
              {
                label: "Completed",
                icon: <CheckCircleIcon />,
                path: "/inquiries/completed",
                component: <LazyCsrCompletedInquiryFilterPage />,
                alternativeRoutes: [
                  {
                    path: "/inquiries/:inquiryId/orders/:orderResourceId/items/:orderItemResourceId/completed",
                    component: <LazyInquiryDetailPage />,
                  },
                ],
              },
            ],
          },
        ],
      });
    } else {
      inquiryRoutes.push({
        groupName: "My Items",
        items: [
          {
            label: "Inquiries",
            icon: <InboxIcon />,
            path: "/inquiries",
            component: <LazyInquiryFilterPage />,
            alternativeRoutes: [
              {
                path: "/inquiries/:inquiryId/orders/:orderResourceId/items/:orderItemResourceId",
                component: <LazyInquiryDetailPage />,
              },
            ],
          },
        ],
      });
    }

    return inquiryRoutes;
  }
}
