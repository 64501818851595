import React from "react";
import kebabCase from "lodash/kebabCase";
import TextField from "@mui/material/TextField";
import { PercentMaskCustom, SizeLimitMaskCustom } from "./textMasks";

export function PercentTextField({
  InputLabelProps,
  InputProps,
  label = "",
  labelErrorMessage,
  error,
  dataTest,
  ...otherProps
}) {
  return (
    <TextField
      {...otherProps}
      variant="standard"
      style={{ width: "80px" }}
      label={error ? labelErrorMessage || `Invalid ${label}` : label}
      error={error}
      margin="none"
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
        "data-test": error
          ? `${kebabCase(label)}-error-label`
          : `${kebabCase(label)}-label`,
      }}
      InputProps={{
        ...InputProps,
        inputComponent: PercentMaskCustom,
        inputProps: {
          min: 0,
          step: "1",
          "data-test": dataTest ? dataTest : `${kebabCase(label)}-input`,
          style: { textAlign: "center" },
        },
      }}
    />
  );
}

export function GenericNumberTextField({
  InputLabelProps,
  InputProps,
  fullWidth = true,
  label = "",
  labelErrorMessage,
  error,
  dataTest,
  limit,
  ...otherProps
}) {
  return (
    <TextField
      {...otherProps}
      variant="standard"
      type="tel"
      fullWidth={fullWidth}
      label={error ? labelErrorMessage || `Invalid ${label}` : label}
      error={error}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
        "data-test": error
          ? `${kebabCase(label)}-error-label`
          : `${kebabCase(label)}-label`,
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          "data-test": dataTest ? dataTest : `${kebabCase(label)}-input`,
          limit: limit,
        },
        inputComponent: SizeLimitMaskCustom,
      }}
    />
  );
}

export function SelectTextField({
  InputLabelProps,
  SelectProps,
  defaultOption = "Select",
  fullWidth = true,
  range,
  label,
  labelErrorMessage,
  dataTest,
  error,
  margin = "normal",
  variant = "standard",
  ...otherProps
}) {
  return (
    <TextField
      {...otherProps}
      variant={variant}
      select
      fullWidth={fullWidth}
      error={error}
      label={error ? labelErrorMessage || label : label}
      margin={margin}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
        "data-test": error
          ? `${kebabCase(label)}-error-label`
          : `${kebabCase(label)}-label`,
      }}
      SelectProps={{
        ...SelectProps,
        inputProps: {
          "data-test": dataTest ? dataTest : `${kebabCase(label)}-select`,
        },
        native: true,
        autoWidth: true,
      }}
    >
      <option disabled value="">
        {defaultOption}
      </option>
      {range.map((option, i) => (
        <option key={i} value={option.value || option}>
          {option.label || option}
        </option>
      ))}
    </TextField>
  );
}

export function NotesTextField({
  InputLabelProps,
  InputProps = {},
  fullWidth = true,
  label = "",
  labelErrorMessage,
  error,
  margin = "normal",
  dataTest,
  rows = 5,
  ...otherProps
}) {
  return (
    <TextField
      {...otherProps}
      variant="standard"
      fullWidth={fullWidth}
      label={error ? labelErrorMessage || `Invalid ${label}` : label}
      error={error}
      margin={margin}
      multiline
      minRows={rows}
      maxRows={rows}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
        "data-test": error
          ? `${kebabCase(label)}-error-label`
          : `${kebabCase(label)}-label`,
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...InputProps.inputProps,
          "data-test": dataTest ? dataTest : `${kebabCase(label)}-input`,
        },
      }}
    />
  );
}
