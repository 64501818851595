import React from "react";
import PropTypes from "prop-types";
import {
  AlertDialog,
  EmailTextField,
  PasswordTextField,
} from "@masonite-digital/common-components";
import { login } from "../../api";

class LoginDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      userName: "",
      password: "",
      userNameError: false,
      passwordError: false,
      errorMessage: null,
    };
  }

  login = async () => {
    const { userName, password } = this.state;

    if (!userName && !password) {
      this.setState({ userNameError: true, passwordError: true });
      return;
    } else if (!userName) {
      this.setState({ userNameError: true });
      return;
    } else if (!password) {
      this.setState({ passwordError: true });
      return;
    }

    try {
      await login({
        username: userName,
        password: password,
      });
      await this.props.onLogin();
    } catch (e) {
      this.setState({ errorMessage: e.message });
    }
  };

  handleUsernameChange = (e) => {
    this.setState({
      userName: e.target.value,
      errorMessage: null,
      userNameError: false,
    });
  };

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
      errorMessage: null,
      passwordError: false,
    });
  };

  onEnter = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await this.login();
    }
  };

  render() {
    const { open, onCancelClick } = this.props;

    return (
      <AlertDialog
        open={open}
        primaryLabel="Sign in"
        onPrimaryClick={this.login}
        onSecondaryClick={onCancelClick}
        dialogType={this.state.errorMessage ? "ERROR" : ""}
        title="Sign in to your account"
        contentText="Your login details have expired. Please re-enter your information."
        helperText={this.state.errorMessage}
        customContent={
          <>
            <EmailTextField
              autoFocus
              error={this.state.userNameError}
              onKeyPress={this.onEnter}
              onChange={this.handleUsernameChange}
              id="username"
              placeholder="contractor@advisar.com"
              autoComplete="username"
              label="Username"
              tabIndex={0}
            />
            <PasswordTextField
              error={this.state.passwordError}
              autoComplete="current-password"
              onKeyPress={this.onEnter}
              onChange={this.handlePasswordChange}
              id="password"
              label="Password"
              placeholder="password"
              tabIndex={1}
            />
          </>
        }
      />
    );
  }
}

LoginDialog.defaultProps = {
  onLogin: () => {
    /*Empty function is intentional*/
  },
};

LoginDialog.propTypes = {
  open: PropTypes.bool,
  onLogin: PropTypes.func,
  onCancelClick: PropTypes.func.isRequired,
};

export default LoginDialog;
