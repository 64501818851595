import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import FilterIcon from "@mui/icons-material/FilterList";
import WarningIcon from "@mui/icons-material/Warning";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Type } from "../../../AuthProvider";
import {
  googleNavClickEvent,
  googleProductClickEvent,
} from "../../../analytics/googleAnalyticsUtility";
import {
  CustomColorDialog,
  DeleteActiveCustomColorDialog,
  SideliteSelectionDialog,
} from "../../../components/Dialogs";
import OptionList from "../step1/OptionList";
import OptionListMobile from "../step1/OptionListMobile";
import DoorDetailOptions from "../step2/DoorDetailOptions";
import DoorSummary from "../summary/DoorSummary";

export default function SelectionSection({
  isLessThanSmall,
  hasCustomColor,
  doorSummary,
  roughOpening,
  doorViewOptions,
  options,
  loadingStates,
  errorStates,
  selections,
  user,
  apiCalls,
  retryCalls,
  reloadHardware,
  reloadDimension,
  onOptionClick,
  onDetailsChange,
  onCasingColorChange,
  onFinishingChange,
  onPeepSiteChange,
  onCasingChange,
  onBrickMoldChange,
  onInactivePrepChange,
  onHardwareChange,
  onHardwareFinishChange,
  onAdaChange,
  changeTab,
  setAccordionStep,
  setSettingsDrawer,
  goToStep,
  goToQuote,
  getProduct,
  addColors,
  deleteColor,
  resetState,
  addDoorToNewQuote,
}) {
  const [openSideliteDialog, setOpenSideliteDialog] = React.useState(false);
  const [finishSection, setFinishSection] = React.useState("finishStep1");
  const [openCustomColorDialog, setOpenCustomColorDialog] =
    React.useState(false);
  const [openDeleteCustomColorDialog, setOpenDeleteCustomColorDialog] =
    React.useState(false);
  const [newFinishKey, setNewFinishKey] = React.useState("exteriorDoor");
  const [finishKeysToUpdate, setFinishKeysToUpdate] = React.useState(null);

  const theme = useTheme();
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down("lg"), {
    noSsr: true,
  });
  const { slabDesigns, glassDesigns, finishes, customColors } = options;
  const TABS = [
    {
      dataTest: "design-tab",
      label: "Design",
      hasError: errorStates.slabDesignError,
    },
    {
      dataTest: "glass-tab",
      label: "Glass",
      hasError: errorStates.glassDesignError,
    },
    {
      dataTest: "finish-tab",
      label: "Finish",
      hasError: errorStates.finishError,
    },
  ];

  React.useEffect(() => {
    if (doorViewOptions.activeStep === 0) {
      setFinishSection("finishStep1");
    }
  }, [doorViewOptions.activeStep]);

  const handleStep1TabChange = (event, value) => {
    googleNavClickEvent({
      action: `tab changed to ${value}`,
      activeStep: doorViewOptions.activeStep,
      tabStep:
        doorViewOptions.activeStep === 0
          ? doorViewOptions.tabIndex
          : doorViewOptions.accordionStep,
    });
    changeTab(value);
  };

  const handleSideliteGlassSelection = (option) => {
    googleProductClickEvent({
      type: "sidelite-glass",
      option: option,
      activeStep: doorViewOptions.activeStep,
      tabStep: doorViewOptions.tabIndex,
    });

    onOptionClick("SIDE_LITE_GLASS_DESIGN")(option);
    setOpenSideliteDialog(false);
  };

  const handleCustomPaintDeleteCheck = (option) => {
    let keysToUpdate = [];
    let newKey;

    Object.keys(selections.finish)
      .filter((key) => key !== "weatherStripping")
      .forEach((key) => {
        if (selections.finish[key].resourceId === option.resourceId) {
          keysToUpdate.push(key);
        } else if (!newKey) {
          newKey = key;
        }
      });

    if (keysToUpdate.length > 0 && newKey) {
      setFinishKeysToUpdate(keysToUpdate);
      setNewFinishKey(newKey);
      setOpenDeleteCustomColorDialog(true);
    } else {
      deleteColor(option);
    }
  };

  const handleAddCustomColorSection = (option) => {
    if (finishSection === "finishStep1") {
      onOptionClick("FINISH")(option);
    } else if (finishSection === "casing") {
      onCasingColorChange(option);
    } else {
      onFinishingChange(finishSection, option);
    }
  };

  const renderTabbedSections = () => {
    switch (doorViewOptions.tabIndex) {
      case 0:
        return (
          <OptionList
            title="Design"
            isLoading={
              loadingStates.loadingSlabDesigns ||
              (loadingStates.loadingExistingStateCheck &&
                isEmpty(slabDesigns)) ||
              loadingStates.loadingRecoveryOptions ||
              loadingStates.loadingEditableDoor
            }
            selections={selections}
            slabLayout={selections.slabLayout}
            optionError={errorStates.slabDesignError}
            optionsList={slabDesigns}
            onOptionClick={onOptionClick("SLAB_DESIGN")}
            defaultSelected={selections.slabDesign}
            retryCall={retryCalls.handleRetryClick(
              errorStates.slabDesignError,
              retryCalls.slabDesignCall,
            )}
            hasSubsections
          />
        );
      case 1:
        return (
          <OptionList
            title="Glass"
            selections={selections}
            isLoading={
              loadingStates.loadingGlassDesigns ||
              (loadingStates.loadingExistingStateCheck &&
                isEmpty(glassDesigns)) ||
              loadingStates.loadingRecoveryOptions ||
              loadingStates.loadingEditableDoor
            }
            optionError={errorStates.glassDesignError}
            optionsList={glassDesigns}
            onOptionClick={onOptionClick("GLASS_DESIGN")}
            defaultSelected={selections.glassDesign}
            slabLayout={selections.slabLayout}
            sideliteGlassDesign={
              get(selections, "slabLayout.hasSidelite") &&
              selections.sideliteGlassDesign
            }
            openSideliteModal={() => setOpenSideliteDialog(true)}
            zoomEnabled
            retryCall={retryCalls.handleRetryClick(
              errorStates.glassDesignError,
              retryCalls.glassDesignCall,
            )}
          />
        );
      default:
        return (
          <OptionList
            title="Finish"
            hasCustomColor={hasCustomColor}
            hasSubsections
            selections={selections}
            isLoading={
              loadingStates.loadingFinishes ||
              (loadingStates.loadingExistingStateCheck &&
                // Commenting the code (for removing Stains) instead of removing it, in case it is needed in the future
                // (isEmpty(finishes.PAINT) || isEmpty(finishes.STAIN))) ||
                isEmpty(finishes.PAINT)) ||
              loadingStates.loadingEditableDoor
            }
            optionError={errorStates.finishError}
            optionsList={
              // Commenting the code (for removing Stains) instead of removing it, in case it is needed in the future
              // isEmpty(finishes.PAINT) || isEmpty(finishes.STAIN)
              isEmpty(finishes.PAINT)
                ? []
                // Commenting the code (for removing Stains) instead of removing it, in case it is needed in the future
                // : [...finishes.STAIN, ...finishes.PAINT, ...customColors]
                : [...finishes.PAINT, ...customColors]
            }
            customColors={customColors}
            defaultSelected={selections.finish.doorExterior}
            onOptionClick={onOptionClick("FINISH")}
            retryCall={retryCalls.finishesCall}
            onCustomClick={() => setOpenCustomColorDialog(true)}
            onCustomDelete={handleCustomPaintDeleteCheck}
          />
        );
    }
  };

  const renderMobileSections = () => {
    return (
      <>
        <OptionListMobile
          title="Design"
          isLoading={
            loadingStates.loadingSlabDesigns ||
            (loadingStates.loadingExistingStateCheck && isEmpty(slabDesigns)) ||
            loadingStates.loadingEditableDoor
          }
          selections={selections}
          slabLayout={selections.slabLayout}
          optionError={errorStates.slabDesignError}
          optionsList={slabDesigns}
          onOptionClick={onOptionClick("SLAB_DESIGN")}
          defaultSelected={selections.slabDesign}
          retryCall={retryCalls.handleRetryClick(
            errorStates.slabDesignError,
            retryCalls.slabDesignCall,
          )}
          hasSubsections
        />
        <Divider />
        <OptionListMobile
          title="Glass"
          selections={selections}
          isLoading={
            loadingStates.loadingGlassDesigns ||
            (loadingStates.loadingExistingStateCheck &&
              isEmpty(glassDesigns)) ||
            loadingStates.loadingEditableDoor
          }
          optionError={errorStates.glassDesignError}
          optionsList={glassDesigns}
          onOptionClick={onOptionClick("GLASS_DESIGN")}
          defaultSelected={selections.glassDesign}
          sideliteGlassDesign={
            get(selections, "slabLayout.hasSidelite") &&
            selections.sideliteGlassDesign
          }
          openSideliteModal={() => setOpenSideliteDialog(true)}
          zoomEnabled
          retryCall={retryCalls.handleRetryClick(
            errorStates.glassDesignError,
            retryCalls.glassDesignCall,
          )}
        />
        <Divider />
        <OptionListMobile
          title="Finish"
          hasCustomColor={hasCustomColor}
          hasSubsections
          isLoading={
            loadingStates.loadingFinishes ||
            (loadingStates.loadingExistingStateCheck &&
              // Commenting the code (for removing Stains) instead of removing it, in case it is needed in the future
              // (isEmpty(finishes.PAINT) || isEmpty(finishes.STAIN))) ||
              isEmpty(finishes.PAINT)) ||
            loadingStates.loadingRecoveryOptions ||
            loadingStates.loadingEditableDoor
          }
          selections={selections}
          optionError={errorStates.finishError}
          optionsList={
            // Commenting the code (for removing Stains) instead of removing it, in case it is needed in the future
            // isEmpty(finishes.PAINT) || isEmpty(finishes.STAIN)
            isEmpty(finishes.PAINT)
              ? []
              // Commenting the code (for removing Stains) instead of removing it, in case it is needed in the future
              // : [...finishes.STAIN, ...finishes.PAINT, ...customColors]
              : [...finishes.PAINT, ...customColors]
          }
          defaultSelected={selections.finish.doorExterior}
          onOptionClick={onOptionClick("FINISH")}
          retryCall={retryCalls.finishesCall}
          onCustomClick={() => setOpenCustomColorDialog(true)}
          onCustomDelete={handleCustomPaintDeleteCheck}
        />
      </>
    );
  };

  return (
    <>
      <SideliteSelectionDialog
        open={openSideliteDialog}
        selections={selections}
        onPrimaryClick={handleSideliteGlassSelection}
        onSecondaryClick={() => setOpenSideliteDialog(false)}
      />
      <CustomColorDialog
        open={openCustomColorDialog}
        onSecondaryClick={() => setOpenCustomColorDialog(false)}
        getColors={apiCalls.getColors}
        searchColor={apiCalls.searchColor}
        addColors={addColors}
        onOptionClick={(option) => handleAddCustomColorSection(option)}
      />
      <DeleteActiveCustomColorDialog
        open={openDeleteCustomColorDialog}
        closeModal={() => setOpenDeleteCustomColorDialog(false)}
        onCustomDelete={deleteColor}
        finishKeysToUpdate={finishKeysToUpdate}
        finishSelections={selections.finish}
        setFinishSelection={onFinishingChange}
        newFinishKey={newFinishKey}
      />
      {doorViewOptions.activeStep === 0 && (
        <>
          <AppBar
            position="sticky"
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            })}
          >
            <Grid container>
              <Grid item xs={11}>
                <Tabs
                  className="tabBar"
                  variant={isLessThanMedium ? "scrollable" : "fullWidth"}
                  value={doorViewOptions.tabIndex}
                  onChange={handleStep1TabChange}
                  indicatorColor="secondary"
                >
                  {TABS.map((tab, i) => (
                    <Tab
                      key={i}
                      data-test={tab.dataTest}
                      label={
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography style={{ paddingRight: "16px" }}>
                            {tab.label}
                          </Typography>
                          {tab.hasError && (
                            <WarningIcon
                              data-test={tab.dataTest + "-error"}
                              color="error"
                            />
                          )}
                        </Box>
                      }
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid
                bgcolor="primary.300"
                item
                xs={1}
                container
                justifyContent="center"
              >
                <IconButton
                  data-test="filter-btn"
                  onClick={() => setSettingsDrawer(true)}
                >
                  <FilterIcon color="action" />
                </IconButton>
              </Grid>
            </Grid>
          </AppBar>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                display: "flex",
                height: "100%",
                alignItems: "stretch",
                flexDirection: "column",
              },
            })}
          >
            {isLessThanSmall ? renderMobileSections() : renderTabbedSections()}
          </Box>
        </>
      )}

      {doorViewOptions.activeStep === 1 && (
        <DoorDetailOptions
          isMobile={isLessThanSmall}
          hasCustomColor={hasCustomColor}
          onAccordionChange={setAccordionStep}
          onDetailsChange={onDetailsChange}
          retryDimensionsCall={retryCalls.dimensionsCall}
          retryHandingCall={retryCalls.handingCall}
          onCasingColorChange={onCasingColorChange}
          retryCasingCall={retryCalls.casingCall}
          onFinishingChange={onFinishingChange}
          retryFinishCall={retryCalls.finishesCall}
          onPeepSiteChange={onPeepSiteChange}
          retryPeepSiteCall={retryCalls.peepSiteCall}
          onCasingChange={onCasingChange}
          onBrickMoldChange={onBrickMoldChange}
          retryBrickMoldCall={retryCalls.brickMoldCall}
          retrySillCall={retryCalls.sillCall}
          retryBoreDetailsCall={retryCalls.boreDetailsCall}
          onInactivePrepChange={onInactivePrepChange}
          onAdaChange={onAdaChange}
          reloadHardware={reloadHardware}
          reloadDimension={reloadDimension}
          onHardwareChange={onHardwareChange}
          onHardwareFinishChange={onHardwareFinishChange}
          options={options}
          doorViewOptions={doorViewOptions}
          accordionStep={doorViewOptions.accordionStep}
          selections={selections}
          loadingStates={loadingStates}
          errorStates={errorStates}
          roughOpening={roughOpening}
          getRoughOpenings={apiCalls.getRoughOpenings}
          customPaintDeleteCheck={handleCustomPaintDeleteCheck}
          openCustomColorDialogClick={(section) => {
            setFinishSection(section);
            setOpenCustomColorDialog(true);
          }}
        />
      )}

      {doorViewOptions.activeStep === 2 && (
        <DoorSummary
          isCsr={user.type === Type.CSR || user.type === Type.SALES_REP}
          isLoading={
            loadingStates.loadingGetProduct || loadingStates.loadingEditableDoor
          }
          isLoadingEditableDoor={loadingStates.loadingEditableDoor}
          isMobile={isLessThanSmall}
          companyId={user.companyId}
          payload={doorSummary}
          doorViewOptions={doorViewOptions}
          roughOpening={roughOpening}
          selections={selections}
          retryGetProduct={getProduct}
          getProductError={errorStates.getProductError}
          getQuotes={apiCalls.getQuotes}
          addToExistingQuote={apiCalls.addToExistingQuote}
          addToNewQuote={addDoorToNewQuote}
          updateExistingQuote={apiCalls.updateExistingQuote}
          goToStep={goToStep}
          goToQuote={goToQuote}
          resetState={resetState}
        />
      )}
    </>
  );
}

SelectionSection.propTypes = {
  isLessThanSmall: PropTypes.bool.isRequired,
  hasCustomColor: PropTypes.bool.isRequired,
  doorSummary: PropTypes.object,
  roughOpening: PropTypes.object,
  doorViewOptions: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  loadingStates: PropTypes.object.isRequired,
  errorStates: PropTypes.object.isRequired,
  selections: PropTypes.object.isRequired,
  user: PropTypes.object,
  apiCalls: PropTypes.shape({
    getQuotes: PropTypes.func,
    addToExistingQuote: PropTypes.func,
    addToNewQuote: PropTypes.func,
    getColors: PropTypes.func.isRequired,
    searchColor: PropTypes.func.isRequired,
  }).isRequired,
  retryCalls: PropTypes.shape({
    finishesCall: PropTypes.func.isRequired,
    dimensionsCall: PropTypes.func,
    handingCall: PropTypes.func,
    casingCall: PropTypes.func,
    peepSiteCall: PropTypes.func,
    brickMoldCall: PropTypes.func,
    handleRetryClick: PropTypes.func.isRequired,
  }).isRequired,
  reloadHardware: PropTypes.func,
  reloadDimension: PropTypes.func,
  onOptionClick: PropTypes.func.isRequired,
  onDetailsChange: PropTypes.func,
  onCasingColorChange: PropTypes.func,
  onFinishingChange: PropTypes.func,
  onPeepSiteChange: PropTypes.func,
  onCasingChange: PropTypes.func,
  onBrickMoldChange: PropTypes.func,
  onInactivePrepChange: PropTypes.func,
  onHardwareChange: PropTypes.func,
  onHardwareFinishChange: PropTypes.func,
  onAdaChange: PropTypes.func,
  changeTab: PropTypes.func.isRequired,
  setAccordionStep: PropTypes.func,
  setSettingsDrawer: PropTypes.func.isRequired,
  goToStep: PropTypes.func,
  goToQuote: PropTypes.func,
  getProduct: PropTypes.func,
  addColors: PropTypes.func.isRequired,
  deleteColor: PropTypes.func.isRequired,
};
