import React from "react";
import loadable from "@loadable/component";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartIconOutline from "@mui/icons-material/ShoppingCartOutlined";
import { Authority, Type } from "../../AuthProvider";

const LazyQuoteDetailPage = loadable(() => import("./common/QuoteDetailPage"));

const LazyOrderDetailPage = loadable(() => import("./common/OrderDetailPage"));

const LazyQuoteFilterPage = loadable(() =>
  import("./Contractor/Quote/QuoteFilterPage")
);

const LazyOrderFilterPage = loadable(() =>
  import("./Contractor/Order/OrderFilterPage")
);

const LazySearchPage = loadable(() => import("./Csr/Quote/QuoteSearchPage"));
const LazyFilterPage = loadable(() => import("./Csr/Quote/QuoteFilterPage"));
const LazyOrderSearchPage = loadable(() =>
  import("./Csr/Order/OrderSearchPage")
);
const LazyOrderItemInquiryPage = loadable(() =>
  import("./common/OrderItemInquiryPage")
);

const LazyReorderDetailPage = loadable(() =>
  import("../Reorders/ReorderDetailPage")
);

const LazyPaymentPage = loadable(() => import("../Payment/PaymentPage"));

export default class Quote {
  static routes(context) {
    const type = context.whoAmI().type;
    if (type === Type.CSR || type === Type.SALES_REP) {
      return [
        {
          groupName: "Search",
          items: [
            {
              label: "Quotes",
              icon: <ShoppingCartIcon />,
              path: "/quotes",
              component: <LazySearchPage />,
              alternativeRoutes: [
                {
                  path: "/quotes/:resourceId",
                  component: <LazyQuoteDetailPage />,
                },
              ],
            },
            {
              label: "Orders",
              icon: <ReceiptIcon />,
              path: "/orders",
              component: <LazyOrderSearchPage />,
              alternativeRoutes: [
                {
                  path: "/orders/:resourceId",
                  component: <LazyOrderDetailPage />,
                },
                {
                  hide: !context.hasAuthority(Authority.FEATURE_REORDER),
                  path: "/orders/:resourceId/new-reorder",
                  component: <LazyReorderDetailPage />,
                },
                {
                  hide: !context.hasAnyAuthority([
                    Authority.ORDER_PURCHASE,
                    Authority.ORDER_ADMIN,
                  ]),
                  path: "/inquiries/orders/:resourceId/items/:itemResourceId/inquiry",
                  component: <LazyOrderItemInquiryPage />,
                },
              ],
            },
          ],
        },
        {
          groupName: "My Items",
          items: [
            {
              label: "My Quotes",
              icon: <ShoppingCartIconOutline />,
              path: "/my-quotes",
              component: <LazyFilterPage />,
              alternativeRoutes: [
                {
                  path: "/my-quotes/:resourceId",
                  component: <LazyQuoteDetailPage />,
                },
              ],
            },
          ],
        },
      ];
    }

    return [
      {
        groupName: "My Items",
        items: [
          {
            label: "Quotes",
            icon: <ShoppingCartIcon />,
            path: "/quotes",
            component: <LazyQuoteFilterPage />,
            alternativeRoutes: [
              {
                path: "/quotes/:resourceId",
                component: <LazyQuoteDetailPage />,
              },
              {
                hide: !context.hasAuthority(Authority.ORDER_PURCHASE),
                path: "/payment/:resourceId",
                component: <LazyPaymentPage />,
              },
            ],
          },
          {
            label: "Orders",
            icon: <ReceiptIcon />,
            path: "/orders",
            component: <LazyOrderFilterPage />,
            alternativeRoutes: [
              {
                path: "/orders/:resourceId",
                component: <LazyOrderDetailPage />,
              },
              {
                hide: !context.hasAnyAuthority([
                  Authority.ORDER_PURCHASE,
                  Authority.ORDER_ADMIN,
                ]),
                path: "/inquiries/orders/:resourceId/items/:itemResourceId/inquiry",
                component: <LazyOrderItemInquiryPage />,
              },
            ],
          },
        ],
      },
    ];
  }
}
