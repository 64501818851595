import React from "react";
import camelCase from "lodash/camelCase";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import {
  AlertDialog,
  ResultMessage,
} from "@masonite-digital/common-components";
import PlusSign from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { AuthContext } from "../../../AuthContext";
import { Authority } from "../../../AuthProvider";
import OptionSkeleton, {
  OptionSkeletonType,
} from "../../../components/Common/OptionSkeleton";
import { ConflictDialog, CutDownDialog } from "../../../components/Dialogs";
import { ImageSelector, RadioSelector } from "../../../components/Selection";
import { fetchRoughOpenings } from "../../../redux/actions/exteriorPrehungFetchActions";
import Box from "@mui/material/Box";

const LabelTypography = styled(Typography)(() => ({
  fontSize: "0.75rem",
  color: "primary.main",
  fontWeight: 500,
  textTransform: "uppercase",
}));

export default function DoorDetailSpecs({
  isDoorDetailsLoading,
  isDimensionsLoading,
  isRoughOpeningLoading,
  isHandingLoading,
  doorSpecOptions,
  classifierRules,
  dimensionOptions,
  selections,
  onChange,
  reloadHardware,
  reloadDimension,
  retryDimensionsCall,
  retryHandingCall,
  dimensionError,
  handingError,
  roughOpening,
  getRoughOpenings,
}) {
  const [notCompatibleDialog, setNotCompatibleDialog] = React.useState(false);
  const [isCutDownDialogOpen, setIsCutDownDialogOpen] = React.useState(false);
  const [selectedIncompatibleDimension, setSelectedIncompatibleDimension] =
    React.useState(false);
  const [cutDownValue, setCutDownValue] = React.useState(
    selections.slabHeight.value,
  );
  const [openCustomHeightWarningDialog, setOpenCustomHeightWarningDialog] =
    React.useState(false);
  const [dimensionValue, setDimensionValue] = React.useState(null);
  const indexOfCutDown = isEmpty(selections.cutDownDoorHeight)
    ? 0
    : doorSpecOptions.cutDownDoorHeights.findIndex(
        (height) =>
          height.resourceId === selections.cutDownDoorHeight.resourceId,
      );
  const [cutDownIndex, setCutDownIndex] = React.useState(
    indexOfCutDown === -1 ? 0 : indexOfCutDown,
  );
  // saved inches are used to hold the inches value when editing.
  const [savedInches, setSavedInches] = React.useState(null);

  const context = React.useContext(AuthContext);

  const hasCutDownsFeature = context.hasAuthority(Authority.FEATURE_CUT_DOWNS);

  const handleRadioChange = (key, value) => onChange({ [key]: value });

  function changeImpactRatedCheck() {
    // After dimension selection is updated, impactRatedOnly may become true
    if (
      !selections.impactRated.value &&
      dimensionOptions.matrix.glassImpactRatedOnly
    ) {
      onChange({
        impactRated: doorSpecOptions.impactRatings.find(
          (item) => item.value === true,
        ),
      });
    }
  }

  function handleDimensionChange(key, value) {
    const compatible = Boolean(
      key === "slabHeight"
        ? value.validWidths.find(
            (valid) => valid === selections.slabWidth.resourceId,
          )
        : value.validHeights.find(
            (valid) => valid === selections.slabHeight.resourceId,
          ),
    );

    if (compatible) {
      if (
        !isEmpty(selections.cutDownDoorHeight) &&
        !dimensionValue &&
        key === "slabHeight"
      ) {
        setDimensionValue(value);
        setOpenCustomHeightWarningDialog(true);
        return;
      } else if (dimensionValue) {
        setDimensionValue(null);
      }
    }

    if (compatible) {
      onChange({ [key]: value });
      changeImpactRatedCheck();
    } else {
      setNotCompatibleDialog(true);
      setSelectedIncompatibleDimension(value);
    }
  }

  function handleDimensionReselection() {
    if (selectedIncompatibleDimension.type === "SLAB_HEIGHT") {
      const compatibleWidth = dimensionOptions.slabWidths.find(
        (width) =>
          width.resourceId === selectedIncompatibleDimension.validWidths[0],
      );
      onChange({
        slabHeight: selectedIncompatibleDimension,
        slabWidth: compatibleWidth,
      });
    } else if (selectedIncompatibleDimension.type === "SLAB_WIDTH") {
      const compatibleHeight = dimensionOptions.slabHeights.find(
        (height) =>
          height.resourceId === selectedIncompatibleDimension.validHeights[0],
      );
      onChange({
        slabWidth: selectedIncompatibleDimension,
        slabHeight: compatibleHeight,
      });
    }

    if (selections.cutDownDoorHeight) {
      onChange({
        cutDownDoorHeight: null,
      });
      setCutDownIndex(0);
    }

    changeImpactRatedCheck();
    setNotCompatibleDialog(false);
  }

  function handleRatingChange(e, type) {
    const isTypeHvhz = type === "hurricaneRated";
    let isGlassImpactRatedOnly = selections.glassDesign.glassImpactRatedOnly;

    if (isTypeHvhz) {
      onChange({
        [type]: doorSpecOptions.hurricaneRatings.find(
          (item) => item.value === e.target.checked,
        ),
      });
      if (!isGlassImpactRatedOnly) {
        onChange({
          impactRated: doorSpecOptions.impactRatings.find(
            (item) => item.value === e.target.checked,
          ),
        });
      }

      reloadDimension();
      reloadHardware();
    } else if (type === "isDoorCovered") {
      onChange({
        isDoorCovered: doorSpecOptions.doorCovered.find(
          (item) => item.value === e.target.checked,
        ),
      });
      reloadDimension();
    } else {
      //Reset 'Is your door covered?' question when HVHZ is deselected
      if (isTypeHvhz) {
        onChange({
          isDoorCovered: doorSpecOptions.doorCovered.find(
            (item) => item.value === false,
          ),
        });
      }
      onChange({
        [type]: doorSpecOptions[
          isTypeHvhz ? "hurricaneRatings" : "impactRatings"
        ].find((item) => item.value === e.target.checked),
      });
      reloadDimension();
      reloadHardware();
    }
  }

  function handleHandingChange(option) {
    //Reset 'Is your door covered?' question when handing changes to Inswing
    if (
      selections.isDoorCovered.value &&
      option.description.includes("Inswing")
    ) {
      onChange({
        isDoorCovered: doorSpecOptions.doorCovered.find(
          (item) => item.value === false,
        ),
      });
    }
    onChange({ handing: option });
  }

  function cutDownPrimaryClick() {
    onChange({
      cutDownDoorHeight: doorSpecOptions.cutDownDoorHeights[cutDownIndex],
    });
    setIsCutDownDialogOpen(false);
  }

  let dimensionCanHvhz = false;
  let dimensionCanImpactRated = false;
  let dimensionImpactRatedOnly = false;
  let dimensionIsTrimmable = false;
  if (dimensionOptions.matrix) {
    let dimensionMatrix = dimensionOptions.matrix.find(
      (item) =>
        item.heightResourceId === selections.slabHeight.resourceId &&
        item.widthResourceId === selections.slabWidth.resourceId,
    );

    if (dimensionMatrix) {
      dimensionCanHvhz = dimensionMatrix.slabCanHvhz;
      dimensionCanImpactRated = dimensionMatrix.glassDesignCanImpactRated;
      dimensionImpactRatedOnly = dimensionMatrix.glassImpactRatedOnly;
      dimensionIsTrimmable = dimensionMatrix.trimmable;
    }
  }

  const hasDoorGlassAndRateable = selections.slabDesign.hasDoorGlass
    ? selections.slabDesign.rateable
    : true;
  const showHVHZ = dimensionCanHvhz ? hasDoorGlassAndRateable : false;

  const showImpactRating = dimensionCanImpactRated || dimensionImpactRatedOnly;
  const showDoorCoveredQuestion = get(
    selections,
    "handing.description",
    "",
  ).includes("Outswing");

  const unmodifiedDoorHeight =
    selections.slabHeight && selections.slabHeight.value;

  const isDoorTrimmable =
    selections.slabHeight.value !== 78 && dimensionIsTrimmable;

  return (
    <>
      <CutDownDialog
        open={isCutDownDialogOpen}
        roughOpening={roughOpening}
        getRoughOpenings={getRoughOpenings}
        onPrimaryClick={cutDownPrimaryClick}
        onSecondaryClick={() => {
          if (savedInches) {
            setCutDownIndex(savedInches);
            setIsCutDownDialogOpen(false);
          } else {
            setCutDownIndex(0);
            setIsCutDownDialogOpen(false);
          }
        }}
        doorHeight={unmodifiedDoorHeight}
        cutDownValue={cutDownValue}
        cutDownDoorHeights={doorSpecOptions.cutDownDoorHeights}
        cutDownIndex={cutDownIndex}
        setCutDownIndex={setCutDownIndex}
        selections={selections}
      />
      <Grid container>
        {isDoorDetailsLoading && (
          <Grid item data-test="door-detail-specs">
            <Skeleton
              style={{ marginBottom: "8px" }}
              variant="rectangular"
              width={100}
              height={18}
            />
            <OptionSkeleton
              type={OptionSkeletonType.CHECK_BOX}
              optionWidth={200}
            />
            <OptionSkeleton
              type={OptionSkeletonType.CHECK_BOX}
              optionWidth={200}
            />
          </Grid>
        )}
        {!isDoorDetailsLoading && (showHVHZ || showImpactRating) && (
          <Grid item container>
            <Grid item xs={12}>
              <LabelTypography gutterBottom>Construction</LabelTypography>
            </Grid>
            {showHVHZ && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-test="hvhz-check-box"
                      color="secondary"
                      checked={selections.hurricaneRated.value}
                    />
                  }
                  label="Hurricane-rated Door (HVHZ)"
                  onChange={(e) => handleRatingChange(e, "hurricaneRated")}
                />
                {showImpactRating && (
                  <Grid pl={4} mb={1}>
                    <Typography
                      data-test="hvhz-caption"
                      color="textSecondary"
                      variant="caption"
                    >
                      *All door configurations that are hurricane-rated must
                      have impact-rated glass.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            {showImpactRating && (
              <Grid item xs={6} ml={4}>
                <FormControlLabel
                  disabled={
                    selections.hurricaneRated.value || dimensionImpactRatedOnly
                  }
                  control={
                    <Checkbox
                      data-test="impact-rated-check-box"
                      color="secondary"
                      checked={
                        selections.impactRated.value || dimensionImpactRatedOnly
                      }
                    />
                  }
                  label="Impact-rated Glass"
                  onChange={(e) => handleRatingChange(e, "impactRated")}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12} mt={1} container>
          <Grid item xs={12}>
            <LabelTypography gutterBottom>Dimensions</LabelTypography>
          </Grid>
          {!isDimensionsLoading &&
          (isEmpty(dimensionOptions) || dimensionError) ? (
            <ResultMessage
              type="WARNING"
              title="Something went wrong"
              message={dimensionError !== null ? dimensionError.message : " "}
              onClick={retryDimensionsCall}
            />
          ) : (
            <>
              <Grid item xs={12}>
                <RadioSelector
                  isLoading={isDimensionsLoading}
                  label="Door Height"
                  options={dimensionOptions.slabHeights}
                  selections={selections}
                  onChange={handleDimensionChange}
                  validOptions={get(selections.slabWidth, "validHeights", [])}
                />
              </Grid>
              {hasCutDownsFeature && (
                <Grid>
                  {isDimensionsLoading && (
                    <Grid item mt={1}>
                      <Skeleton
                        style={{ marginBottom: "8px" }}
                        variant="rectangular"
                        width={100}
                        height={18}
                      />
                      <OptionSkeleton
                        type={OptionSkeletonType.CHECK_BOX}
                        optionWidth={200}
                        optionHeight={40}
                      />
                      <OptionSkeleton
                        type={OptionSkeletonType.CHECK_BOX}
                        optionWidth={200}
                      />
                    </Grid>
                  )}
                  {!isDimensionsLoading && (
                    <>
                      <LabelTypography
                        sx={{
                          p: "8px 0",
                        }}
                        component="div"
                      >
                        CUSTOM HEIGHT
                      </LabelTypography>
                      <Grid
                        container
                        sx={(theme) => ({
                          flexWrap: "nowrap",
                          mb: 2,
                          [theme.breakpoints.down("md")]: {
                            flexDirection: "column",
                            alignItems: "start",
                          },
                        })}
                      >
                        {isDoorTrimmable && (
                          <Grid item xs={10} md={8}>
                            <Typography variant="caption" component="div">
                              Fine tune the height of your door by removing up
                              to 3" from your door's current height.
                            </Typography>
                            {!isEmpty(selections.cutDownDoorHeight) &&
                              !isRoughOpeningLoading && (
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  backgroundColor="rgba(0, 0, 0, 0.04)"
                                  minHeight="80px"
                                  p={1}
                                  mt={1}
                                  mb={1}
                                >
                                  <Grid
                                    container
                                    item
                                    direction="column"
                                    xs={6}
                                  >
                                    <Typography
                                      variant="h6"
                                      color="secondary"
                                      data-test="cut-down-door-height"
                                    >
                                      {roughOpening.unitHeight}"
                                    </Typography>
                                    <Typography variant="caption">
                                      Custom Door Height
                                    </Typography>
                                    <Typography variant="caption">
                                      Unit Height (-
                                      {selections.cutDownDoorHeight.description}
                                      ")
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={6}
                                    justifyContent="flex-end"
                                  >
                                    <IconButton
                                      onClick={() => {
                                        setSavedInches(cutDownIndex);
                                        setIsCutDownDialogOpen(true);
                                      }}
                                      data-test="edit-cut-down-value-btn"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        setCutDownIndex(0);
                                        setCutDownValue(unmodifiedDoorHeight);
                                        setSavedInches(null);
                                        onChange({
                                          cutDownDoorHeight: null,
                                        });
                                        fetchRoughOpenings();
                                      }}
                                      data-test="delete-cut-down-value-btn"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              )}
                            <Typography variant="caption" color="error">
                              *Will be an additional cost
                            </Typography>
                          </Grid>
                        )}
                        {!isDoorTrimmable && (
                          <Grid item xs={8} md={6}>
                            <Typography
                              variant="caption"
                              data-test="custom-unavailable-msg"
                            >
                              Unfortunately, custom height is not available for
                              a door with the set dimension.
                            </Typography>
                          </Grid>
                        )}
                        {isEmpty(selections.cutDownDoorHeight) &&
                          isDoorTrimmable && (
                            <Grid item xs={8} md={6}>
                              <Button
                                sx={(theme) => ({
                                  my: 1,
                                  [theme.breakpoints.up("lg")]: {
                                    ml: 2,
                                  },
                                })}
                                variant="outlined"
                                color="secondary"
                                onClick={() => setIsCutDownDialogOpen(true)}
                                data-test="customize-cut-down-btn"
                                startIcon={<PlusSign />}
                              >
                                CUSTOMIZE HEIGHT
                              </Button>
                            </Grid>
                          )}
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <RadioSelector
                  isLoading={isDimensionsLoading}
                  label="Door Width"
                  options={dimensionOptions.slabWidths}
                  selections={selections}
                  onChange={handleDimensionChange}
                  validOptions={get(selections.slabHeight, "validWidths", [])}
                />
              </Grid>
              {selections.slabLayout.hasSidelite && (
                <Grid item xs={12}>
                  <RadioSelector
                    isLoading={isDimensionsLoading}
                    label="Sidelite Width"
                    options={dimensionOptions.sideLiteWidths}
                    selections={selections}
                    onChange={handleRadioChange}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <LabelTypography>Other</LabelTypography>
          </Grid>
          <Grid item xs={12}>
            <RadioSelector
              isLoading={isDimensionsLoading}
              label="Frame/Jamb Depth"
              options={doorSpecOptions.jambDepths}
              selections={selections}
              onChange={handleRadioChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Handing:</Typography>
          </Grid>
          <Grid item xs={12}>
            {!isHandingLoading &&
            (isEmpty(doorSpecOptions.handings) || handingError) ? (
              <ResultMessage
                type="WARNING"
                title="Something went wrong"
                message={handingError !== null ? handingError.message : " "}
                onClick={retryHandingCall}
              />
            ) : (
              <ImageSelector
                isLoading={isHandingLoading}
                imgSizeSx={{ width: 116, height: "auto" }}
                options={doorSpecOptions.handings}
                optionType="Handing"
                selected={selections.handing}
                onSelect={handleHandingChange}
                gridContainerProps={{ spacing: 0 }}
                showDescription
              />
            )}
          </Grid>
          {showDoorCoveredQuestion && (
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    data-test={"door-covered"}
                    color="secondary"
                    checked={selections.isDoorCovered.value}
                  />
                }
                label={
                  <Box>
                    <Typography>Is your door covered?</Typography>
                    {selections.slabDesign.hasSideliteGlass && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ mb: 1 }}
                      >
                        If your door is covered, you will receive back to back
                        construction sidelites rather than a unit with a
                        continuous sill.
                      </Typography>
                    )}
                  </Box>
                }
                onChange={(e) => handleRatingChange(e, "isDoorCovered")}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <ConflictDialog
        open={notCompatibleDialog}
        handleContinue={handleDimensionReselection}
        handleCancel={() => setNotCompatibleDialog(false)}
      />
      <AlertDialog
        open={openCustomHeightWarningDialog}
        dialogType="WARNING"
        title="Change Height?"
        contentText="Changing your base slab dimension will remove your trimmed custom height that you currently have set. Are you sure you want to change the base slab dimension?"
        primaryLabel="Change"
        onPrimaryClick={() => {
          onChange({
            cutDownDoorHeight: null,
          });
          handleDimensionChange(camelCase(dimensionValue.type), dimensionValue);
          setCutDownIndex(0);
          setOpenCustomHeightWarningDialog(false);
        }}
        onSecondaryClick={() => {
          setDimensionValue(null);
          setOpenCustomHeightWarningDialog(false);
        }}
      />
    </>
  );
}

DoorDetailSpecs.propTypes = {
  isDoorDetailsLoading: PropTypes.bool.isRequired,
  isRoughOpeningLoading: PropTypes.bool.isRequired,
  isDimensionsLoading: PropTypes.bool.isRequired,
  isHandingLoading: PropTypes.bool.isRequired,
  doorSpecOptions: PropTypes.object.isRequired,
  classifierRules: PropTypes.object,
  dimensionOptions: PropTypes.object.isRequired,
  selections: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  reloadHardware: PropTypes.func.isRequired,
  reloadDimension: PropTypes.func.isRequired,
  retryDimensionsCall: PropTypes.func.isRequired,
  retryHandingCall: PropTypes.func.isRequired,
  dimensionError: PropTypes.string,
  handingError: PropTypes.string,
  roughOpening: PropTypes.object,
};
