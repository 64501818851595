import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === "light" ? green["700"] : green["500"],
}));

export default function RetiredComponentsDialog(props) {
  const {
    open,
    retiredComponents,
    returnToQuotePage,
    onPrimaryClick,
    onSecondaryClick,
  } = props;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [shouldReturnToQuotePage, setShouldReturnToQuotePage] =
    React.useState(false);

  const handlePrimaryClick = async () => {
    try {
      await onPrimaryClick();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const handleSecondaryClick = async () => {
    await onSecondaryClick();
    setShouldReturnToQuotePage(true);
  };

  const handleOnExited = () => {
    if (shouldReturnToQuotePage) {
      returnToQuotePage();
    }
  };

  const aresElectricDeadboltResourceId = "2f9c297e-f28b-476d-9558-7b99414e9cfd";

  return (
    <AlertDialog
      open={open}
      dialogType="WARNING"
      title="Components no longer available"
      onPrimaryClick={handlePrimaryClick}
      primaryLabel="Continue"
      onSecondaryClick={handleSecondaryClick}
      secondaryLabel="Cancel"
      contentText="The following components are no longer offered and were changed:"
      helperText={errorMessage}
      TransitionProps={{
        onExited: handleOnExited,
      }}
      customContent={
        <Grid
          container
          direction="column"
          spacing={2}
          data-test="retired-components-body"
        >
          {retiredComponents.hurricaneRated && (
            <Grid container item direction="column">
              <Typography
                style={{ fontWeight: "bold" }}
                data-test="retired-components-hurricane-rated-label"
              >
                Hurricane-rated
              </Typography>
              <Typography data-test="retired-hurricane-rated">
                - The hurricane-rated door is no longer available.
              </Typography>
              <StyledTypography data-test="retired-components-non-hurricane-rated-label">
                + Changed to the non-hurricane-rated (width and height may
                change if the current dimension is unavailable).
              </StyledTypography>
            </Grid>
          )}

          {retiredComponents.slabHeight && (
            <Grid container item direction="column">
              <Typography
                style={{ fontWeight: "bold" }}
                data-test="retired-components-door-height-label"
              >
                Door Height
              </Typography>
              <Typography data-test="retired-slab-height">
                - {retiredComponents.slabHeight.description} height doors are no
                longer available.
              </Typography>
              <StyledTypography data-test="retired-components-default-height-label">
                {`+ Changed to ${
                  retiredComponents.slabHeight.value === 80 ? 96 : 80
                }"`}
              </StyledTypography>
            </Grid>
          )}

          {retiredComponents.slabWidth && (
            <Grid container item direction="column">
              <Typography
                style={{ fontWeight: "bold" }}
                data-test="retired-components-door-width-label"
              >
                Door Width
              </Typography>
              <Typography data-test="retired-slab-width">
                - {retiredComponents.slabWidth.description} width doors are no
                longer available.
              </Typography>
              <StyledTypography data-test="retired-components-default-width-label">
                + Changed to an available width
              </StyledTypography>
            </Grid>
          )}

          {retiredComponents.cutDownDoorHeight && (
            <Grid container item direction="column">
              <Typography
                style={{ fontWeight: "bold" }}
                data-test="retired-components-cut-down-label"
              >
                Door Height
              </Typography>
              <Typography data-test="retired-cut-down">
                - {retiredComponents.cutDownDoorHeight.description}" cut downs
                are no longer available. Please select a new door height.
              </Typography>
            </Grid>
          )}

          {retiredComponents.hardware &&
            retiredComponents.hardware?.resourceId !==
              aresElectricDeadboltResourceId && (
              <Grid container item direction="column">
                <Typography
                  style={{ fontWeight: "bold" }}
                  data-test="retired-components-hardware-label"
                >
                  Hardware
                </Typography>
                <Typography data-test="retired-hardware">
                  - {retiredComponents.hardware.description} is no longer
                  available.
                </Typography>
                <StyledTypography data-test="retired-components-default-hardware-label">
                  + Changed to Hamden No Plate, Cortina Lever
                </StyledTypography>
              </Grid>
            )}

          {retiredComponents.hardware?.resourceId ===
            aresElectricDeadboltResourceId && (
            <Grid container item direction="column">
              <Typography
                style={{ fontWeight: "bold" }}
                data-test="retired-components-hardware-label"
              >
                Hardware
              </Typography>
              <Typography data-test="retired-hardware">
                - Ares w/ Electric Deadbolt (Schlage Sense), Poseidon Lever is
                no longer available.
              </Typography>
              <StyledTypography data-test="retired-components-default-hardware-label">
                + Changed to Ares w/ Electric Deadbolt (Schlage Encode),
                Poseidon Lever
              </StyledTypography>
            </Grid>
          )}

          {retiredComponents.weatherStripping && (
            <Grid container item direction="column">
              <Typography
                style={{ fontWeight: "bold" }}
                data-test="retired-components-weather-stripping-label"
              >
                Weather Stripping
              </Typography>
              <Typography data-test="retired-weather-stripping">
                - {retiredComponents.weatherStripping.description} finish is no
                longer available.
              </Typography>
              <StyledTypography data-test="retired-components-default-weather-stripping-label">
                + Changed to Bronze
              </StyledTypography>
            </Grid>
          )}

          {retiredComponents.sideliteGlassDesign && (
            <Grid container item direction="column">
              <Typography
                style={{ fontWeight: "bold" }}
                data-test="retired-components-sidelite-glass-design-label"
              >
                Sidelite Glass
              </Typography>
              <Typography data-test="retired-sidelite-glass-design">
                - Impact Rated{" "}
                {retiredComponents.sideliteGlassDesign.description} Glass is no
                longer available.
              </Typography>
              <StyledTypography data-test="retired-components-default-sidelite-glass-design-label">
                + Changed to Impact Rated Clear Glass
              </StyledTypography>
            </Grid>
          )}

          {retiredComponents.peepSite && (
            <Grid container item direction="column">
              <Typography
                style={{ fontWeight: "bold" }}
                data-test="retired-components-peepsight-label"
              >
                Peep Sight
              </Typography>
              <Typography data-test="retired-peepsight">
                - A Peep Sight is incompatible with this unit.
              </Typography>
              <StyledTypography data-test="retired-components-default-peepsight-label">
                + Removed Peep Sight from quote.
              </StyledTypography>
            </Grid>
          )}

          {retiredComponents.hinge && (
            <Grid container item direction="column">
              <Typography
                style={{ fontWeight: "bold" }}
                data-test="retired-components-hinge-label"
              >
                Hinges
              </Typography>
              <Typography data-test="retired-hinge">
                - {retiredComponents.hinge.description} hinges have been
                discontinued
              </Typography>
              <StyledTypography data-test="retired-components-default-hinge-label">
                + Updated hinge option to Stainless Steel finish
              </StyledTypography>
            </Grid>
          )}

          <Grid container item direction="column">
            <Typography>
              <span>
                Any questions or need assistance please contact Customer
                Service.
              </span>
            </Typography>
            <Typography>
              <Link
                data-test="email-link"
                href="mailto:CustomerCare@Advisar.com"
              >
                CustomerCare@Advisar.com
              </Link>
              <span>, or </span>
              <Link data-test="phone-number-link" href="tel:813-261-3580">
                813-261-3580
              </Link>
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
}

RetiredComponentsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  retiredComponents: PropTypes.object,
  returnToQuotePage: PropTypes.func.isRequired,
};
