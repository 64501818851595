import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import addImage from "./assets/add.gif";
import designImg from "./assets/design.gif";
import filterImg from "./assets/filter.gif";
import finishImg from "./assets/finish.gif";
import glassImg from "./assets/glass.gif";
import layoutImg from "./assets/layout.gif";

const StyledImg = styled("img")(({ theme }) => ({
  height: 280,
  width: "auto",
  [theme.breakpoints.down("sm")]: {
    height: 150,
  },
}));

const StyledIFrame = styled("iframe")(({ theme }) => ({
  borderRadius: "10px",
  height: 280,
  width: 430,
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    width: 225,
  },
}));

export default function ConsumerHelpDialog(props) {
  const { open, onClose } = props;

  const [step, setStep] = React.useState(0);

  const STEPS = [
    {
      text: "You've been invited to build and customize using the door configurator - take a look at the explainer video, explore the features in the tour, or skip:",
      buttonLabel: "Take the tour",
      content: (
        <StyledIFrame
          src="https://www.youtube.com/embed/bihbo6b-apc"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      ),
    },
    {
      text: "Choose your door layout:",
      content: <StyledImg src={layoutImg} alt="layout-gif" />,
    },
    {
      text: "Choose your door design:",
      content: <StyledImg src={designImg} alt="design-gif" />,
    },
    {
      text: "Choose your glass:",
      content: <StyledImg src={glassImg} alt="glass-gif" />,
    },
    {
      text: "Choose your paint or finish:",
      content: <StyledImg src={finishImg} alt="finish-gif" />,
    },
    {
      text: "Filter your options:",
      content: <StyledImg src={filterImg} alt="filter-gif" />,
    },
    {
      text: "Finish and send:",
      buttonLabel: "Build a door",
      content: <StyledImg src={addImage} alt="add-gif" />,
    },
  ];

  const handlePrimaryClick = () => {
    if (step < STEPS.length - 1) {
      setStep(step + 1);
    } else {
      onClose();
    }
  };

  const handleSecondaryClick = () => {
    if (step === STEPS.length - 1) {
      setStep(0);
    } else {
      onClose();
    }
  };

  const currentStep = STEPS[step];

  return (
    <AlertDialog
      open={open}
      title="Build Your Door"
      contentText={currentStep.text}
      customContent={
        <Grid container item justifyContent="center">
          {currentStep.content}
        </Grid>
      }
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={handleSecondaryClick}
      primaryLabel={currentStep.buttonLabel ? currentStep.buttonLabel : "Next"}
      secondaryLabel={step === STEPS.length - 1 ? "Start Over" : "Skip"}
    />
  );
}

ConsumerHelpDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
