import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
  RequestPaginatedTable,
  ResultMessage,
  StatusChip,
} from "@masonite-digital/common-components";
import Paper from "@mui/material/Paper";
import DeclinedByCustomerDialog from "./DeclinedByCustomerDialog";
import ResendLinkDialog from "./ResendLinkDialog";
import { CreateConsumerLeadAction } from "./index";
import { AuthContext } from "../../AuthContext";
import { Authority } from "../../AuthProvider";
import consumerLeadFunctions from "../../activities/Consumer/consumerLeadFunctions";

export default function ConsumerLeadsTable(props) {
  const { isCsr, type, constraint, apiFunction } = props;

  const [redirect, setRedirect] = React.useState(null);
  const context = React.useContext(AuthContext);
  const isReadOnly = context.hasAuthority(Authority.LEAD_ADMIN_READ);

  let messageType, title, message;
  if (type === "filter") {
    messageType = "EMPTY_CART";
    title = "No Consumer Leads";
    message =
      "Try searching for a different or less specific criteria, and check your spelling.";
  } else {
    messageType = "SEARCHED";
    title = "Search for Consumer Leads";
    message =
      "Search for a consumer lead by First Name, Last Name, Email Address, Phone Number, Expiration, and Status.";
  }

  const columns = [
    {
      id: "firstName",
      label: "First Name",
    },
    {
      id: "lastName",
      label: "Last Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "phoneNumber",
      label: "Phone Number",
    },
    {
      id: "expires",
      label: "Link Expiration",
      customContent: (row) =>
        Date.now() >= Date.parse(row.expiration.timestamp) ? (
          <div style={{ color: "#f44336" }}>Link is expired</div>
        ) : (
          `Expires in ${row.expiration.displayTime}`
        ),
    },
    {
      id: "state.status",
      align: "right",
      label: "Status",
      customContent: (row) =>
        row.state && (
          <StatusChip
            label={row.state.displayName}
            colorHex={row.state.color}
          />
        ),
    },
  ];

  if (isCsr) {
    columns.splice(3, 0, {
      id: "company.name",
      label: "Company",
      customContent: (row) => row.company && row.company.name,
      sortable: false,
    });
  }

  const handleRowClick = ({ resourceId }) => {
    setRedirect(`/consumer-leads/${resourceId}`);
  };

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  const handleResendLinkClick = async (resourceId) => {
    await consumerLeadFunctions.resendLink(resourceId);
  };

  const handleDeclinedClick = async (resourceId) => {
    await consumerLeadFunctions.updateLead(resourceId, {
      state: "CLOSED_CANCELLED",
    });
  };

  function rowActionOptionFunction(item) {
    return [
      {
        label: "Resend Link",
        hide: isReadOnly,
        DialogComponent: ({ open, onClose }) => (
          <ResendLinkDialog
            open={open}
            onPrimaryClick={() => handleResendLinkClick(item.resourceId)}
            onSecondaryClick={onClose}
            email={item.email}
            expirationTime={item.expiration.displayTime}
          />
        ),
      },
      {
        label: "Declined by customer",
        hide: isReadOnly,
        DialogComponent: ({ open, onClose, reloadTable }) => (
          <DeclinedByCustomerDialog
            open={open}
            onPrimaryClick={() => handleDeclinedClick(item.resourceId)}
            onSecondaryClick={onClose}
            refresh={reloadTable}
          />
        ),
      },
    ];
  }

  return (
    <Paper>
      <RequestPaginatedTable
        type={type}
        initialSort="audit.modifiedDate-"
        constraint={constraint}
        columns={columns}
        fetchDataCall={apiFunction}
        onRowClick={handleRowClick}
        rowActionOptions={(item) => rowActionOptionFunction(item)}
        endSearchBarActions={(reloadTable) =>
          !isReadOnly && (
            <CreateConsumerLeadAction
              createConsumerLead={consumerLeadFunctions.createLead}
              refresh={reloadTable}
              {...props}
            />
          )
        }
        emptyStateComponent={
          <ResultMessage type={messageType} title={title} message={message} />
        }
      />
    </Paper>
  );
}

ConsumerLeadsTable.propTypes = {
  isCsr: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  apiFunction: PropTypes.func.isRequired,
  objectSearchName: PropTypes.string,
  constraint: PropTypes.string,
};
