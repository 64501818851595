import React from "react";
import PropTypes from "prop-types";
import DialogContentText from "@mui/material/DialogContentText";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";

const AddToNewQuote = (props) => {
  const { addToExistingQuote, onChange, quoteName, error, isCustomPart } =
    props;

  return (
    <div>
      <DialogContentText style={{ marginTop: "16px", marginBottom: "16px" }}>
        <strong>
          Before adding your {isCustomPart ? "item" : "door"}, give your quote a
          name:
        </strong>
      </DialogContentText>
      <TextField
        autoFocus
        variant="standard"
        error={!!error}
        helperText={error && error.message}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          tabIndex: -1,
          inputProps: {
            className: "autoFocus",
            "data-test": "new-quote-name-text",
          },
          value: quoteName,
        }}
        label="Quote Name"
        value={quoteName}
        fullWidth
        onChange={(e) => onChange(e.target.value)}
      />
      <Link
        sx={{
          marginTop: "8px",
          "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
          },
        }}
        underline="hover"
        component="button"
        data-test="add-to-existing-quote-link"
        color="secondary"
        variant="subtitle2"
        onClick={() => addToExistingQuote(quoteName)}
      >
        Already have an existing quote?
      </Link>
    </div>
  );
};

AddToNewQuote.propTypes = {
  addToExistingQuote: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  quoteName: PropTypes.string.isRequired,
  isCustomPart: PropTypes.bool,
};

export default AddToNewQuote;
