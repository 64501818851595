import React, { Fragment } from "react";
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import PencilIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/FormatListBulleted";
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../../../AuthContext";
import { Authority } from "../../../AuthProvider";
import { googleContextClickEvent } from "../../../analytics/googleAnalyticsUtility";
import { FullScreenDialog } from "../../../components/Dialogs";
import {
  ADA_PEEP_SITE_LOCATION,
  PEEP_SITE_LOCATION,
} from "../../../components/Doors/payload/getPayload";

const StyledSkeletonBox = styled(Box)(() => ({
  marginBottom: "4px",
  width: "100%",
}));

const StyledReviewButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: `${theme.palette.text.primary} !important`,
}));

const StyledWrapper = styled(Box)((theme) => ({
  display: "inline-flex",
  alignItems: "center",
}));

const StyledColorCircleImg = styled("img")(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "50%",
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
  minWidth: theme.spacing(1.5),
  minHeight: theme.spacing(1.5),
  marginLeft: theme.spacing(1.5),
}));

/**
 * ReviewDoorSpec uses the specifications to display the data.
 */
export default function ReviewDoorSpec(props) {
  const { isMobile, isLoading, doorConfig, roughOpening, goToStep } = props;
  const context = React.useContext(AuthContext);
  const [openFullScreenDialog, setOpenFullScreenDialog] = React.useState(null);
  const hasCutDownsFeature = context.hasAuthority(Authority.FEATURE_CUT_DOWNS);

  const FinishOption = ({ finish }) => {
    const description = (finish && finish.description) || "N/A";
    return (
      <StyledWrapper>
        {description}
        {finish && finish.hexCode ? (
          <Box
            sx={(theme) => ({
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: "50%",
              width: theme.spacing(1.5),
              height: theme.spacing(1.5),
              minWidth: theme.spacing(1.5),
              minHeight: theme.spacing(1.5),
              marginLeft: theme.spacing(1.5),
              background: `#${finish && finish.hexCode}`,
            })}
          />
        ) : (
          <StyledColorCircleImg src={finish.links[0].href} alt={description} />
        )}
      </StyledWrapper>
    );
  };

  function goToStepHandler(step) {
    const { doorViewOptions } = props;

    googleContextClickEvent({
      activeStep: doorViewOptions.activeStep,
      tabStep: doorViewOptions.tabIndex,
      action: `Click Edit ${step}`,
      label: "",
    });

    goToStep(step);
  }

  function getItemByPartTypeSpecifications(type) {
    return get(doorConfig, "specifications", []).find(
      (item) => item.type === type
    );
  }

  function getItemByPartType(type) {
    return get(doorConfig, "components.componentData", []).find(
      (item) => item.partType === type
    );
  }

  const hasDoorGlass =
    get(getItemByPartTypeSpecifications("GLASS_DESIGN"), "description") !==
    "No Glass";
  const hasSideLiteGlass =
    get(
      getItemByPartTypeSpecifications("SIDE_LITE_GLASS_DESIGN"),
      "description"
    ) !== "No Glass";
  const isDoubleDoor = get(
    getItemByPartTypeSpecifications("SLAB_LAYOUT"),
    "description",
    ""
  ).includes("Double Door");
  const isHurricaneRated = get(
    getItemByPartTypeSpecifications("HURRICANE_RATED"),
    "value"
  );
  const isImpactRated = get(
    getItemByPartTypeSpecifications("IMPACT_RATED"),
    "value"
  );
  const isDoorCovered = get(
    getItemByPartTypeSpecifications("DOOR_COVERED"),
    "value"
  );
  const finishes = getItemByPartTypeSpecifications("SLAB_FINISH");
  const hingeFinishImage = get(
    getItemByPartTypeSpecifications("FINISH"),
    "links[0].href",
    "None"
  );
  const hardware = getItemByPartTypeSpecifications("HARDWARE_PART");
  const peepSite = getItemByPartTypeSpecifications("PEEP_SITE");
  const customHeight = get(
    getItemByPartTypeSpecifications("CUT_DOWN_HEIGHT"),
    "description",
    "None"
  );
  const approvalCode = doorConfig.components?.approvalCode;
  const hingeDescription = get(
    getItemByPartType("Hinge"),
    "description",
    "N/A"
  );
  const sillDescription = get(
    getItemByPartType("Frame"),
    "sill.description",
    "N/A"
  );
  const hardwareDescription = get(hardware, "description", "N/A");
  const hasAnyGlass = hasDoorGlass || hasSideLiteGlass;
  let brickMoldDescription = get(
    getItemByPartTypeSpecifications("BRICK_MOLD"),
    "description",
    "No Brickmold"
  );

  //This is to show brickmold as WM180 in case the selected brickmold is 'Shipped Loose' (while editing old quotes)
  if (brickMoldDescription.includes("Shipped Loose")) {
    brickMoldDescription = "WM180";
  }

  let payloadList = [
    {
      group: "OPENINGS",
      onClick: () => goToStepHandler("DETAILS"),
      singleColumn: true,
      firstColumnLabels: ["Unit Size:", "Rough Opening:", "Outside Brickmold:"],
      secondColumnLabels: [
        hasCutDownsFeature ? "Custom Height:" : "EMPTY",
        hasCutDownsFeature ? "Height Reduced:" : "EMPTY",
      ],
      firstColumnValues: [
        `W: ${roughOpening.unitWidth}" | H: ${roughOpening.unitHeight}"`,
        `W: ${roughOpening.roughOpeningWidth}" | H: ${roughOpening.roughOpeningHeight}"`,
        `W: ${roughOpening.outsideBrickmoldWidth}" | H: ${roughOpening.outsideBrickmoldHeight}"`,
      ],
      secondColumnValues: [
        customHeight !== "None" ? "Yes" : "No",
        customHeight !== "None" ? `${customHeight}"` : customHeight,
      ],
    },
    {
      group: "GENERAL",
      onClick: () => goToStepHandler("SLAB_DESIGN"),
      labels: [
        "Layout:",
        "Glazing Type:",
        "Hurricane-rated (HVHZ):",
        "Impact-rated:",
      ],
      hideLines: [false, false, false, isLoading ? false : !hasAnyGlass],
      onClickItems: [
        null,
        null,
        () => goToStepHandler("DETAILS"),
        () => goToStepHandler("DETAILS"),
      ],
      values: [
        get(
          getItemByPartTypeSpecifications("SLAB_LAYOUT"),
          "description",
          "N/A"
        ),
        get(
          getItemByPartTypeSpecifications("GLAZING_TYPE"),
          "description",
          "N/A"
        ),
        isHurricaneRated ? "Yes" : "No",
        isImpactRated ? "Yes" : "No",
      ],
    },
    {
      group: "HARDWARE",
      onClick: () => goToStepHandler("HARDWARE"),
      labels: ["Model:", "Finish:", "Inactive Prep:", "Sill:", "Hinge:"],
      hideLines: [
        false,
        hardwareDescription === "None",
        isLoading ? false : !isDoubleDoor,
        false,
        false,
      ],
      values: [
        hardwareDescription,
        <StyledWrapper>
          {get(hardware, "finish.description", "N/A")}
          <StyledColorCircleImg
            src={get(hardware, "finish.links[1].href")}
            alt="Hardware Finish"
          />
        </StyledWrapper>,
        get(
          getItemByPartTypeSpecifications("INACTIVE_PREP"),
          "description",
          "None"
        ),
        <div>
          {sillDescription}
          {hingeFinishImage !== "None" && (
            <StyledColorCircleImg src={hingeFinishImage} alt="Sill Finish" />
          )}
        </div>,
        <StyledWrapper>
          {hingeDescription}
          {hingeFinishImage !== "None" && (
            <StyledColorCircleImg src={hingeFinishImage} alt="Hinge Finish" />
          )}
        </StyledWrapper>,
      ],
    },
    {
      group: "BORE DETAILS",
      onClick: () => goToStepHandler("HARDWARE"),
      hideGroup: hardwareDescription !== "None",
      labels: ["Diameter:", "Backset:", "Center Line:"],
      values: [
        get(getItemByPartType("Door"), "bore.diameter", "N/A"),
        get(getItemByPartType("Door"), "bore.backSet", "N/A"),
        get(getItemByPartType("Door"), "bore.centerLine", "N/A"),
      ],
    },
    {
      group: "DOOR",
      onClick: () => goToStepHandler("SLAB_DESIGN"),
      labels: ["Design:", "Glass:", "Dimensions:"],
      onClickItems: [
        null,
        () => goToStepHandler("GLASS_DESIGN"),
        () => goToStepHandler("DETAILS"),
      ],
      values: [
        get(
          getItemByPartTypeSpecifications("SLAB_DESIGN"),
          "description",
          "N/A"
        ),
        get(
          getItemByPartTypeSpecifications("GLASS_DESIGN"),
          "description",
          "N/A"
        ),
        `W ${get(
          getItemByPartTypeSpecifications("SLAB_WIDTH"),
          "description",
          "N/A"
        )} x H ${get(
          getItemByPartTypeSpecifications("SLAB_HEIGHT"),
          "description",
          "N/A"
        )}`,
      ],
    },
    {
      group: "SIDELITE",
      onClick: () => goToStepHandler("GLASS_DESIGN"),
      hideGroup: isLoading
        ? false
        : !get(getItemByPartTypeSpecifications("SLAB_LAYOUT"), "hasSidelite"),
      labels: ["EMPTY", "Glass:", "Dimensions:"],
      onClickItems: [null, null, () => goToStepHandler("DETAILS")],
      values: [
        get(
          getItemByPartTypeSpecifications("SLAB_DESIGN"),
          "description",
          "N/A"
        ),
        get(
          getItemByPartTypeSpecifications("SIDE_LITE_GLASS_DESIGN"),
          "description",
          "N/A"
        ),
        `W ${get(
          getItemByPartTypeSpecifications("SIDE_LITE_WIDTH"),
          "value",
          "N/A"
        )}" x H ${get(
          getItemByPartTypeSpecifications("SLAB_HEIGHT"),
          "description",
          "N/A"
        )}`,
      ],
    },
    {
      group: "FINISHES",
      onClick: () => goToStepHandler("FINISH"),
      labels: [
        "Door Exterior:",
        "Door Interior:",
        "Frame Exterior:",
        "Frame Interior:",
        "Weather Stripping:",
      ],
      values: [
        <FinishOption finish={finishes && finishes.exterior} />,
        <FinishOption finish={finishes && finishes.interior} />,
        <FinishOption finish={finishes && finishes.exteriorFrame} />,
        <FinishOption finish={finishes && finishes.interiorFrame} />,
        <FinishOption finish={finishes && finishes.weatherstrip} />,
      ],
    },
    {
      group: "DETAILS",
      onClick: () => goToStepHandler("DETAILS"),
      labels: [
        "Handing:",
        "Door Covered:",
        "Jamb Depth:",
        "Casing Profile:",
        "Casing Finish:",
      ],
      onClickItems: [
        null,
        null,
        null,
        () => goToStepHandler("ADD_ONS"),
        () => goToStepHandler("ADD_ONS"),
      ],
      hideLines: [
        false,
        isLoading
          ? false
          : !(
              get(
                getItemByPartTypeSpecifications("HANDING"),
                "description",
                ""
              ).includes("Outswing") && isHurricaneRated
            ),
        false,
        false,
        isLoading
          ? false
          : get(
              getItemByPartTypeSpecifications("CASING"),
              "description",
              "None"
            ) === "None",
      ],
      values: [
        get(getItemByPartTypeSpecifications("HANDING"), "description", "N/A"),
        isDoorCovered ? "Yes" : "No",
        get(
          getItemByPartTypeSpecifications("JAMB_DEPTH"),
          "description",
          "N/A"
        ),
        get(getItemByPartTypeSpecifications("CASING"), "description", "None"),
        <FinishOption
          finish={get(getItemByPartTypeSpecifications("CASING"), "finish")}
        />,
      ],
    },
    {
      group: "ADD-ONS",
      onClick: () => goToStepHandler("ADD_ONS"),
      singleColumn: true,
      firstColumnLabels: ["Brickmold:", "Peep Sight:", "ADA Peep Sight:"],
      hideFirstColumnLines: [
        false,
        isLoading ? false : !Boolean(peepSite),
        isLoading ? false : !Boolean(peepSite && peepSite.ada),
      ],
      secondColumnLabels: [
        "EMPTY",
        "Peep Sight Location:",
        "ADA Peep Sight Location:",
      ],
      firstColumnValues: [
        brickMoldDescription === "No Brickmold"
          ? "None"
          : `${brickMoldDescription} | Shipped Loose`,
        get(peepSite, "description"),
        get(peepSite, "description"),
      ],
      secondColumnValues: [
        get(
          getItemByPartTypeSpecifications("BRICK_MOLD"),
          "description",
          "None"
        ),
        PEEP_SITE_LOCATION,
        ADA_PEEP_SITE_LOCATION,
      ],
    },
    {
      group: "CERTIFICATIONS",
      hideGroup: approvalCode == null,
      singleColumn: true,
      firstColumnLabels: ["Approval Code:", "DP Rating:"],
      firstColumnValues: [
        get(approvalCode, "flApprovalCode", "N/A"),
        get(approvalCode, "dpRating", "N/A"),
      ],
      secondColumnLabels: [],
    },
  ];

  const renderDoorSpecs = () => {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          Review Door Specifications
        </Typography>
        <Grid container spacing={2} data-test={"door-summary-value-container"}>
          {payloadList.map((item, i) => {
            if (item.hideGroup) {
              return null;
            }
            return (
              <Fragment key={i}>
                {item.singleColumn ? (
                  <Grid
                    item
                    xs={12}
                    data-test={`${kebabCase(item.group)}-group`}
                    container
                  >
                    <Grid item xs={12}>
                      <Divider />
                      <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="overline">{item.group}</Typography>
                        {item.onClick && (
                          <IconButton
                            data-test={kebabCase(item.group + "-edit-btn")}
                            onClick={item.onClick}
                          >
                            <PencilIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={6} container style={{ paddingRight: "8px" }}>
                      {item.firstColumnLabels.map((label, j) => {
                        if (
                          item.hideFirstColumnLines &&
                          item.hideFirstColumnLines[j]
                        ) {
                          return null;
                        }
                        return (
                          <Grid
                            key={j}
                            item
                            xs={12}
                            style={{
                              visibility: label === "EMPTY" ? "hidden" : "none",
                            }}
                          >
                            <StyledReviewButton
                              data-test={kebabCase(label + "-btn")}
                              fullWidth
                              onClick={item.onClick}
                              disabled={!item.onClick}
                            >
                              {isLoading ? (
                                <StyledSkeletonBox>
                                  <Skeleton
                                    variant="rectangular"
                                    data-test={kebabCase(label + "-skeleton")}
                                  />
                                </StyledSkeletonBox>
                              ) : (
                                <Grid container>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ textAlign: "left" }}
                                  >
                                    <Typography variant="body2" color="primary">
                                      {label}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ textAlign: "right" }}
                                  >
                                    <Typography
                                      component="div"
                                      variant="body2"
                                      data-test={kebabCase(label + "-value")}
                                    >
                                      {item.firstColumnValues[j]}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                            </StyledReviewButton>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Grid item xs={6} container style={{ paddingLeft: "8px" }}>
                      {item.secondColumnLabels.map((label, j) => {
                        if (
                          item.hideFirstColumnLines &&
                          item.hideFirstColumnLines[j]
                        ) {
                          return null;
                        }
                        return (
                          <Grid
                            key={j}
                            item
                            xs={12}
                            style={{
                              visibility: label === "EMPTY" ? "hidden" : "none",
                            }}
                          >
                            <StyledReviewButton
                              data-test={kebabCase(label + "-btn")}
                              fullWidth
                              onClick={item.onClick}
                            >
                              {isLoading ? (
                                <StyledSkeletonBox>
                                  <Skeleton
                                    variant="rectangular"
                                    data-test={kebabCase(label + "-skeleton")}
                                  />
                                </StyledSkeletonBox>
                              ) : (
                                <Grid container>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ textAlign: "left" }}
                                  >
                                    <Typography variant="body2" color="primary">
                                      {label}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ textAlign: "right" }}
                                  >
                                    <Typography
                                      variant="body2"
                                      data-test={kebabCase(label + "-value")}
                                    >
                                      {item.secondColumnValues[j]}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                            </StyledReviewButton>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={6}
                    data-test={`${kebabCase(item.group)}-group`}
                    container
                  >
                    <Grid item xs={12}>
                      <Divider />
                      <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="overline">{item.group}</Typography>
                        <IconButton
                          data-test={kebabCase(item.group + "-edit-btn")}
                          onClick={item.onClick}
                        >
                          <PencilIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {item.labels.map((label, j) => {
                      if (item.hideLines && item.hideLines[j]) {
                        return null;
                      }
                      return (
                        <Grid
                          key={j}
                          item
                          xs={12}
                          style={{
                            visibility: label === "EMPTY" ? "hidden" : "none",
                          }}
                        >
                          <StyledReviewButton
                            data-test={kebabCase(label + "-btn")}
                            fullWidth
                            onClick={
                              (item.onClickItems && item.onClickItems[j]) ||
                              item.onClick
                            }
                          >
                            {isLoading ? (
                              <StyledSkeletonBox>
                                <Skeleton
                                  variant="rectangular"
                                  data-test={kebabCase(label + "-skeleton")}
                                />
                              </StyledSkeletonBox>
                            ) : (
                              <Grid container>
                                <Grid item xs={5} style={{ textAlign: "left" }}>
                                  <Typography variant="body2" color="primary">
                                    {label}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={7}
                                  style={{ textAlign: "right" }}
                                >
                                  <Typography
                                    component="div"
                                    variant="body2"
                                    data-test={kebabCase(label + "-value")}
                                  >
                                    {item.values[j]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </StyledReviewButton>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Fragment>
            );
          })}
        </Grid>
      </>
    );
  };

  const renderMobile = () => {
    const items = [
      {
        label: "View Door Specs",
        onClick: () => setOpenFullScreenDialog(0),
        icon: ListIcon,
        content: renderDoorSpecs(),
      },
    ];
    return (
      <>
        <Grid container spacing={2} justifyContent={"space-between"}>
          {items.map((item, index) => {
            const Icon = item.icon;
            return (
              <Grid
                key={index}
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ButtonBase
                  data-test={`${kebabCase(item.label)}-btn`}
                  onClick={item.onClick}
                  style={{ width: "90%" }}
                >
                  <Paper
                    style={{
                      width: "100%",
                      padding: "10px 12px",
                    }}
                  >
                    <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <Typography style={{ color: "#6e6e6e" }}>
                        {item.label}
                      </Typography>
                      <Icon color="secondary" />
                    </div>
                  </Paper>
                </ButtonBase>
              </Grid>
            );
          })}
        </Grid>
        {items.map((item, index) => (
          <FullScreenDialog
            key={index}
            open={openFullScreenDialog === index}
            onClose={() => setOpenFullScreenDialog(null)}
            label={item.label}
            content={item.content}
          />
        ))}
      </>
    );
  };

  return isMobile ? renderMobile() : renderDoorSpecs();
}

ReviewDoorSpec.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  doorConfig: PropTypes.object,
  roughOpening: PropTypes.object,
  handing: PropTypes.object,
  goToStep: PropTypes.func.isRequired,
};
