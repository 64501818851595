import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import TextField from "@mui/material/TextField";

export default function LabelUpdateDialog(props) {
  const { open, value, onPrimaryClick, onSecondaryClick, onExited, onRefresh } =
    props;
  const [label, setLabel] = React.useState(value || "");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [shouldRefresh, setShouldRefresh] = React.useState(false);

  const handlePrimaryClick = async () => {
    if (!value && !label) {
      setErrorMessage("Text is required to add a label.");
      return;
    }
    try {
      await onPrimaryClick(label);
      setShouldRefresh(true);
      onSecondaryClick();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const handleTextFieldChange = (event) => {
    if (event.target.value.length <= 80) {
      setLabel(event.target.value);
      setErrorMessage("");
    }
  };

  const handleExit = async () => {
    setLabel(value);
    onExited();

    if (shouldRefresh) {
      await onRefresh();
    }
  };

  const onEnter = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await handlePrimaryClick();
    }
  };

  return (
    <AlertDialog
      open={open}
      primaryLabel="Save"
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      dialogType={errorMessage ? "ERROR" : ""}
      title={`${value ? "Change" : "Add"} Label`}
      contentText="The maximum character length is 80."
      TransitionProps={{
        onExited: handleExit,
      }}
      customContent={
        <TextField
          autoFocus
          variant="standard"
          fullWidth
          error={Boolean(errorMessage)}
          helperText={errorMessage ? errorMessage : " "}
          value={label}
          onKeyPress={onEnter}
          onChange={handleTextFieldChange}
          margin="normal"
          label="Label"
          type="text"
          inputProps={{ maxLength: 80 }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: { "data-test": "label-input" },
          }}
        />
      }
    />
  );
}

LabelUpdateDialog.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.string,
  onExited: PropTypes.func,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};
