import React from "react";
import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import DefaultIcon from "@mui/icons-material/CircleOutlined";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Skeleton from "@mui/material/Skeleton";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

const IncompatibleIcon = (props) => {
  return (
    <SvgIcon>
      <path
        fill="crimson"
        d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z"
      />
    </SvgIcon>
  );
};

const RadioSelector = (props) => {
  const {
    isLoading,
    selections,
    options,
    onChange,
    disabled,
    label,
    validOptions,
    description,
  } = props;

  const renderOptions = isLoading ? [{}, {}, {}] : options || [];

  return (
    <Grid container>
      {label &&
        (isLoading ? (
          <Skeleton
            style={{ margin: "8px 0" }}
            variant="rectangular"
            height={24}
            width={90}
          />
        ) : (
          <Grid item xs={12}>
            <Typography
              sx={(theme) => ({
                ...(disabled && {
                  color: theme.palette.action.disabled,
                }),
              })}
            >
              {label}:
            </Typography>
          </Grid>
        ))}

      {description && (
        <Grid item xs={12}>
          <Typography variant="caption">{description}</Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <FormControl component="fieldset" disabled={disabled}>
          <RadioGroup data-test={kebabCase(`${label}-list`)} row>
            {renderOptions.map((option, i) => {
              if (isLoading) {
                return (
                  <Skeleton
                    key={i}
                    style={{ marginRight: "16px" }}
                    variant="rectangular"
                    height={24}
                    width={55}
                  />
                );
              }

              const key = camelCase(option.type);
              const isSelected =
                option.resourceId === selections[key].resourceId;
              const compatible = !validOptions
                ? true
                : Boolean(
                    validOptions.find((valid) => valid === option.resourceId)
                  );

              return (
                <FormControlLabel
                  data-test={
                    option &&
                    option.description &&
                    kebabCase(`${label}-${option.description}`)
                  }
                  key={option.resourceId}
                  value={option.description}
                  label={
                    <span data-test={kebabCase(`${label}-text`)}>
                      {option.description}
                    </span>
                  }
                  onChange={() => {
                    onChange(key, option);
                  }}
                  control={
                    <Radio
                      color="secondary"
                      checked={isSelected}
                      icon={compatible ? <DefaultIcon /> : <IncompatibleIcon />}
                    />
                  }
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

RadioSelector.propTypes = {
  isLoading: PropTypes.bool,
  validOptions: PropTypes.array,
  label: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selections: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      description: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ),
};

export default RadioSelector;
