import React, { useContext, useRef } from "react";
import SockJSClient from "react-stomp";
import { enqueueSnackbar } from "notistack";
import { AuthContext } from "./AuthContext";
import { Authority } from "./AuthProvider";
import { checkSnackbar } from "./utils/snackbars";

export function WebSocket({ endpoint = "http://localhost:9002/ws" }) {
  const ref = useRef(null);
  const context = useContext(AuthContext);
  let subscribedTopics = [];

  subscribedTopics.push(
    `/topic/${context.whoAmI().contactId}/order/transferred`
  );

  if (context.hasAuthority(Authority.ORDER_RESERVE)) {
    subscribedTopics.push(
      `/topic/${context.whoAmI().companyId}/reservation/requested`
    );
  }

  return (
    <SockJSClient
      url={endpoint}
      topics={subscribedTopics}
      ref={ref}
      debug={true}
      onMessage={(msg) => {
        checkSnackbar(msg, { variant: "success" }, enqueueSnackbar);
      }}
    />
  );
}

export default WebSocket;
