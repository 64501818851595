import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { googleProductClickEvent } from "../../../analytics/googleAnalyticsUtility";
import { LabeledCheckboxGroup } from "../../../components/Selection";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? "rgba(0, 0, 0, 0.54)"
      : theme.palette.primary["600"],
}));

export default function DoorFilterSettings({
  isLoading,
  onChange,
  selections,
  options,
  doorViewOptions,
  selectedSlabLayout,
}) {
  const handleCheckboxChange = (key, filterValue) => {
    const isChecked = !filterValue ? "Checked" : "Unchecked";
    const currentFilter = key && key.replace(/[\d]/g, "");
    const currentFilterValue = key && key.replace(/[a-zA-Z]/g, "");
    googleProductClickEvent(
      "Exterior Door | Design Your Door",
      "Filter",
      `Click: ${isChecked} ${currentFilter}: ${currentFilterValue}`
    );
    return onChange(key, !filterValue);
  };

  function handleRatingChange(e, type) {
    const isTypeHvhz = type === "hurricaneRated";

    if (isTypeHvhz) {
      onChange("hurricaneRated", e.target.checked);
      onChange("impactRated", e.target.checked);
    } else {
      onChange(type, e.target.checked);
    }
    googleProductClickEvent(
      "Exterior Door | Design Your Door",
      "Filter",
      `Click: ${type} ${e.target.checked ? "Checked" : "Unchecked"}`
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={(theme) => ({
              fontSize: "12px",
              color: theme.palette.primary.main,
              letterSpacing: "0.6px",
              textTransform: "uppercase",
            })}
            gutterBottom
          >
            Construction
          </Typography>
          {isLoading ? (
            <Skeleton height={24} width={200} />
          ) : (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    data-test="filter-hvhz-check-box"
                    color="secondary"
                    checked={selections.hurricaneRated}
                  />
                }
                label="Hurricane-rateable (HVHZ)"
                onChange={(e) => handleRatingChange(e, "hurricaneRated")}
              />
              <div style={{ paddingLeft: "32px" }}>
                <StyledTypography data-test="filter-hvhz-note" variant="body2">
                  Only display configurations that are able to be rated for High
                  Velocity Hurricane Zones as specified in the Florida building
                  code.
                </StyledTypography>
                <StyledTypography
                  data-test="filter-hvhz-caption"
                  variant="caption"
                >
                  *All door configurations that are hurricane-rated must have
                  impact-rated glass.
                </StyledTypography>
              </div>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <Skeleton height={24} width={200} />
          ) : (
            <>
              <FormControlLabel
                disabled={selections.hurricaneRated}
                control={
                  <Checkbox
                    data-test="filter-impact-rated-check-box"
                    color="secondary"
                    checked={selections.impactRated}
                  />
                }
                label="Impact-rateable Glass"
                onChange={(e) => handleRatingChange(e, "impactRated")}
              />
              <div style={{ paddingLeft: "32px" }}>
                <Typography
                  sx={
                    selections.hurricaneRated
                      ? { color: "action.disabled" }
                      : (theme) => ({
                          color:
                            theme.palette.mode === "light"
                              ? "rgba(0, 0, 0, 0.54)"
                              : theme.palette.primary["600"],
                        })
                  }
                  data-test="filter-impact-rated-note"
                  variant="body2"
                >
                  Only display configurations that are able to include glass
                  that can be impact-rated.
                </Typography>
              </div>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12} style={{ marginBottom: "16px" }}>
          <Typography
            sx={(theme) => ({
              fontSize: "12px",
              color: theme.palette.primary.main,
              letterSpacing: "0.6px",
              textTransform: "uppercase",
            })}
          >
            Dimensions
          </Typography>
          <StyledTypography variant="body2">
            Only display configurations that match the selected dimensions.
          </StyledTypography>
          <StyledTypography variant="caption">
            *All dimensions will be shown if nothing is selected.
          </StyledTypography>
        </Grid>
        <Grid item xs={12}>
          <LabeledCheckboxGroup
            isLoading={isLoading}
            label="Door Height"
            options={options.dimensions.slabHeights}
            selections={selections}
            onChange={handleCheckboxChange}
          />
        </Grid>
        <Grid item xs={12}>
          <LabeledCheckboxGroup
            isLoading={isLoading}
            label="Door Width"
            options={options.dimensions.slabWidths}
            selections={selections}
            onChange={handleCheckboxChange}
          />
        </Grid>
        <Grid item xs={12}>
          <LabeledCheckboxGroup
            isLoading={isLoading}
            label="Sidelite Width"
            disabled={!selectedSlabLayout.hasSidelite}
            options={options.dimensions.sideLiteWidths}
            selections={selections}
            onChange={handleCheckboxChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

DoorFilterSettings.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedSlabLayout: PropTypes.object,
  options: PropTypes.object.isRequired,
  selections: PropTypes.object.isRequired,
};
