import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ConflictDialog } from "../../../components/Dialogs";
import NoImage from "../../../components/Icons/no-image-available-sm.svg";
import { SelectionBox } from "../../../components/Selection";

const StyledSpeedDial = styled(SpeedDial, {
  shouldForwardProp: (prop) => prop !== "hasError",
})(({ theme, hasError }) => ({
  position: "relative",
  margin: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(1),
  },
  ".MuiSpeedDial-fab": {
    backgroundColor: hasError
      ? theme.palette.error.main
      : theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: hasError
        ? theme.palette.error.main
        : theme.palette.secondary.main,
    },
  },
}));

export default function SlabLayoutSpeedDial({
  isLoading,
  hasError,
  setOpenWarning,
  selected,
  slabLayouts = [],
  onChange,
}) {
  const [open, setOpen] = useState(false);
  const [openConflict, setOpenConflict] = useState(false);
  const [invalidSelection, setInvalidSelection] = useState({});

  const handleSlabTypeSelectionSpeedDial = (option) => {
    if (option.compatible == null ? false : !option.compatible) {
      setOpenConflict(true);
      setInvalidSelection(option);
    } else {
      if (option.resourceId !== selected.resourceId) {
        onChange(option);
      }
    }
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  if (isLoading) {
    return (
      <Skeleton
        sx={(theme) => ({
          position: "relative",
          m: 2,
          [theme.breakpoints.down("md")]: {
            m: 1,
          },
        })}
        variant="circular"
        width={56}
        height={56}
      />
    );
  }

  return (
    <>
      <StyledSpeedDial
        hasError={hasError}
        data-test="slab-layout-speed-dial"
        ariaLabel="SpeedDial"
        open={open}
        direction="down"
        onClick={hasError ? () => setOpenWarning(true) : handleToggle}
        icon={
          <SpeedDialIcon
            sx={{
              display: "flex",
              justifyContent: "center",
              ".MuiSpeedDialIcon-iconOpen": {
                transform: "rotate(0deg) !important",
              },
            }}
            icon={
              hasError ? (
                <WarningIcon style={{ color: "white" }} />
              ) : (
                <img
                  alt={selected.description}
                  src={
                    !isEmpty(selected.links) &&
                    (selected.links.find(
                      (link) => link.rel === "selection-image"
                    ).href ||
                      "")
                  }
                  onError={(e) => {
                    e.target.src = NoImage;
                  }}
                />
              )
            }
          />
        }
      >
        {!hasError &&
          slabLayouts.map((slab, i) => (
            <SpeedDialAction
              sx={{ m: { xs: "4px 8px", sm: 1 } }}
              key={i}
              title={slab.description}
              onClick={() => handleSlabTypeSelectionSpeedDial(slab)}
              icon={
                <SelectionBox
                  dataTest={`slab-layout-${kebabCase(slab.description)}`}
                  circleShape
                  component="div"
                  compatible={slab.compatible}
                  selected={selected.resourceId === slab.resourceId}
                >
                  <img
                    alt={slab.description}
                    src={
                      slab.links.find((link) => link.rel === "option-image")
                        .href
                    }
                    width={27}
                    height={27}
                    onError={(e) => {
                      e.target.src = NoImage;
                    }}
                  />
                </SelectionBox>
              }
            />
          ))}
      </StyledSpeedDial>
      <ConflictDialog
        open={openConflict}
        handleContinue={() => {
          setOpenConflict(false);
          onChange(invalidSelection);
        }}
        handleCancel={() => {
          setOpenConflict(false);
        }}
      />
    </>
  );
}

SlabLayoutSpeedDial.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  setOpenWarning: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    compatible: PropTypes.bool,
    description: PropTypes.string,
    hasSidelite: PropTypes.bool,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        rel: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      })
    ),
    resourceId: PropTypes.string,
    slabType: PropTypes.string,
    type: PropTypes.string,
  }),
  slabLayouts: PropTypes.arrayOf(
    PropTypes.shape({
      compatible: PropTypes.bool.isRequired,
      description: PropTypes.string.isRequired,
      hasSidelite: PropTypes.bool.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          rel: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
        })
      ),
      resourceId: PropTypes.string.isRequired,
      slabType: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
};
