import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import InfoBlock from "../Blocks/InfoBlock";

export default function RemoveCutDownDialog(props) {
  const { open, onPrimaryClick, onSecondaryClick } = props;

  return (
    <AlertDialog
      open={open}
      data-test="remove-custom-height-dialog"
      dialogType="WARNING"
      title="Remove Custom Height?"
      primaryLabel="Remove"
      onPrimaryClick={onPrimaryClick}
      onSecondaryClick={onSecondaryClick}
      customContent={
        <div style={{ marginTop: "10px" }}>
          <InfoBlock type={"error"}>
            Changing to this configuration will remove your custom height option
          </InfoBlock>
          <div style={{ marginTop: "15px" }}>
            If necessary, please check your updated dimensions and reapply your
            custom height option. How would you like to proceed?
          </div>
        </div>
      }
    />
  );
}

RemoveCutDownDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};
