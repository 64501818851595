import React from "react";
import PropTypes from "prop-types";
import { ResultMessage } from "@masonite-digital/common-components";
import SendIcon from "@mui/icons-material/Send";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomPopper from "../../components/Icons/CustomPopper";
import SimpleDataTable from "../../components/Tables/SimpleDataTable";
import TwoLineDataCell from "../../components/Tables/TwoLineDataCell";
import { formatDate } from "../../utils";

const loadData = async (apiFunction, setRows) => {
  try {
    const jsonData = await apiFunction();
    setRows(jsonData.content);
  } catch (e) {}
};

export default function LeadActivityCard(props) {
  const { name, info, title, onRowClick, apiFunction } = props;

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    loadData(apiFunction, setRows);
  }, [apiFunction]);

  return (
    <>
      <Grid container item alignItems="center">
        <SendIcon
          sx={{
            color: "secondary.main",
            ml: 2,
          }}
          data-test="list-icon"
        />
        <Grid
          container
          item
          alignItems="center"
          sx={{ width: "fit-content", my: 0, mx: 1 }}
        >
          <Typography data-test={`${name}-title`}>{title}</Typography>
          <Typography
            sx={{
              color: "primary.600",
              ml: 1,
            }}
          >
            (Last 10)
          </Typography>
        </Grid>
        <CustomPopper name={`${name}-popper`} info={info} />
      </Grid>
      <SimpleDataTable
        rows={rows}
        onRowClick={onRowClick}
        CustomTableResults={() => (
          <ResultMessage
            type="SEARCHED"
            title="No Recent Activity"
            message="There was no activity for this item"
          />
        )}
        columns={[
          {
            id: "email",
            label: "Homeowner",
            sortable: false,
            formatContent: (row) => {
              return (
                <TwoLineDataCell
                  line1={`${row.firstName} ${row.lastName}`}
                  line2={row.email}
                />
              );
            },
          },
          {
            id: "ownedBy.company.name",
            label: "Company",
            sortable: false,
            formatContent: (row) => {
              return (
                <TwoLineDataCell
                  line1={row.ownedBy.fullName}
                  line2={row.ownedBy.company.name}
                />
              );
            },
          },
          {
            id: "audit.modifiedDate",
            label: "Date Sent",
            sortable: false,
            formatContent: (row) => {
              return (
                <TwoLineDataCell
                  line1={`Sent: ${formatDate(row.audit.createdDate)}`}
                  line2={`Modified: ${formatDate(row.audit.modifiedDate)}`}
                />
              );
            },
          },
        ]}
      />
    </>
  );
}

LeadActivityCard.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onRowClick: PropTypes.func.isRequired,
  apiFunction: PropTypes.func.isRequired,
};
