import React, { useEffect, useState } from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import { googleProductClickEvent } from "../../analytics/googleAnalyticsUtility";
import ImageSelector from "../Selection/ImageSelector";

export default function SideliteSelectionDialog(props) {
  const { open, selections, onPrimaryClick, onSecondaryClick } = props;
  const [sidelite, setSidelite] = useState(selections.sideliteGlassDesign);
  const text =
    get(selections.glassDesign, "sideLiteGlassDesign.length") === 1
      ? "The selected glass design only has one available sidelite glass option."
      : "Select one of the following to change the glass of your sidelite(s):";

  useEffect(() => {
    setSidelite(selections.sideliteGlassDesign);
  }, [selections]);

  return (
    <AlertDialog
      open={open}
      onPrimaryClick={() => {
        onPrimaryClick(sidelite);

        googleProductClickEvent({
          activeStep: 0,
          tabStep: 1,
          url: "sidelite-modal",
          ...sidelite,
        });
      }}
      onSecondaryClick={onSecondaryClick}
      title="Sidelite Glass"
      contentText={text}
      TransitionProps={{
        onExited: () => setSidelite(selections.sideliteGlassDesign),
      }}
      customContent={
        <ImageSelector // TODO Will be completed in 36389.
          options={selections.glassDesign.sideLiteGlassDesign}
          selected={sidelite}
          onSelect={setSidelite}
          imgSizeSx={(theme) => ({
            width: "56px",
            height: "auto",
            [theme.breakpoints.down("sm")]: {
              width: "40px",
            },
          })}
        />
      }
    />
  );
}

SideliteSelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  selections: PropTypes.object.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};
