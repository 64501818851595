import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useCheckSnackbar } from "../../utils";

const CopyButton = ({ value, style }) => {
  const enqueueSnackbar = useCheckSnackbar();

  const handleClick = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value).then(
      function () {
        enqueueSnackbar(`Item code copied: ${value}`, {
          variant: "success",
          autoHideDuration: 1000,
        });
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  return (
    <Button
      style={style}
      onClick={handleClick}
      variant="outlined"
      size="small"
      data-test={`copy-button`}
    >
      {value}
    </Button>
  );
};

CopyButton.propTypes = {
  value: PropTypes.string.isRequired,
};

export default CopyButton;
