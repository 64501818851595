import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import DeclinedByCustomerDialog from "./DeclinedByCustomerDialog";
import ResendLinkDialog from "./ResendLinkDialog";
import OverflowMenu from "../../components/Menus/OverflowMenu";

export default function ConsumerRowAction(props) {
  const { row, refresh, onResendLinkClick, onDeclinedClick } = props;

  const menu = React.useRef(null);
  const [openResendLinkDialog, setOpenResendLinkDialog] = React.useState(false);
  const [openDeclinedDialog, setOpenDeclinedDialog] = React.useState(false);

  const menuList = [
    {
      dataTest: "resend-link",
      label: "Resend Link",
      onClick: () => setOpenResendLinkDialog(true),
    },
    {
      dataTest: "declined-by-customer-link",
      label: "Declined by customer",
      onClick: () => setOpenDeclinedDialog(true),
    },
  ];

  const handleResendLinkClick = async () => {
    await onResendLinkClick(row.resourceId);
  };

  const handleDeclinedClick = async () => {
    await onDeclinedClick(row.resourceId, { state: "CLOSED_CANCELLED" });
  };

  if (row.state.status !== "OPEN") {
    return null;
  }

  return (
    <>
      <ResendLinkDialog
        open={openResendLinkDialog}
        onPrimaryClick={handleResendLinkClick}
        onSecondaryClick={() => setOpenResendLinkDialog(false)}
        email={row.email}
        expirationTime={row.expiration.displayTime}
      />
      <DeclinedByCustomerDialog
        open={openDeclinedDialog}
        onPrimaryClick={handleDeclinedClick}
        onSecondaryClick={() => setOpenDeclinedDialog(false)}
        refresh={refresh}
      />
      <OverflowMenu ref={menu} dataTest="consumer-lead-overflow-btn">
        {menuList.map((item, i) => {
          return (
            <MenuItem
              key={i}
              data-test={item.dataTest}
              onClick={() => {
                menu.current.close();
                item.onClick();
              }}
              {...item.props}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </OverflowMenu>
    </>
  );
}

ConsumerRowAction.propTypes = {
  row: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
  onResendLinkClick: PropTypes.func.isRequired,
  onDeclinedClick: PropTypes.func.isRequired,
};
