import { createAction } from "@reduxjs/toolkit";

export const SET_FORM_VALUES = "SET_FORM_VALUES";
export const CLEAR_FORM = "CLEAR_FORM";
export const SET_STORE_STATE = "SET_STORE_STATE";

export const SET_CUSTOM_PART_ACTIVE_STEP = "SET_CUSTOM_PART_ACTIVE_STEP";

export const SET_ITEM_CODE = "SET_ITEM_CODE";
export const SET_ITEM_CODE_ERROR_MESSAGE = "SET_ITEM_CODE_ERROR_MESSAGE";

export const SET_ITEM_TYPE = "SET_ITEM_TYPE";
export const SET_ITEM_TYPE_ERROR_MESSAGE = "SET_ITEM_TYPE_ERROR_MESSAGE";

export const SET_ITEM_IS_IMPACT_RATED = "SET_ITEM_IS_IMPACT_RATED";

export const SET_ITEM_PRICE = "SET_ITEM_PRICE";
export const SET_ITEM_PRICE_ERROR_MESSAGE = "SET_ITEM_PRICE_ERROR_MESSAGE";

export const SET_CUSTOM_PART_ERROR_MESSAGE = "SET_CUSTOM_PART_ERROR_MESSAGE";
export const RESET_STATE = "RESET_STATE";
export const SET_DEFAULT_SPECS = "SET_DEFAULT_SPECS";
export const SET_CUSTOM_PART_RESPONSE = "SET_CUSTOM_PART_RESPONSE";
export const SET_CUSTOM_PART_SUMMARY = "SET_CUSTOM_PART_SUMMARY";

export const resetState = createAction(RESET_STATE);
export const defaultSpecs = createAction(SET_DEFAULT_SPECS);
export const setCustomPartActiveStep = createAction(
  SET_CUSTOM_PART_ACTIVE_STEP,
);

export const clearForm = createAction(CLEAR_FORM);

export const setItemCode = createAction(SET_ITEM_CODE);
export const setItemCodeErrorMessage = createAction(
  SET_ITEM_CODE_ERROR_MESSAGE,
);

export const setItemType = createAction(SET_ITEM_TYPE);
export const setItemTypeErrorMessage = createAction(
  SET_ITEM_TYPE_ERROR_MESSAGE,
);

export const setItemIsImpactRated = createAction(SET_ITEM_IS_IMPACT_RATED);

export const setItemPrice = createAction(SET_ITEM_PRICE);
export const setItemPriceErrorMessage = createAction(
  SET_ITEM_PRICE_ERROR_MESSAGE,
);

export const setCustomPartResponse = createAction(SET_CUSTOM_PART_RESPONSE);
export const setCustomPartErrorMessage = createAction(
  SET_CUSTOM_PART_ERROR_MESSAGE,
);
