import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../../AuthContext";

export default function ExpiredReservationDialog(props) {
  const {
    open,
    onPrimaryRefreshClick,
    onPrimaryReleaseClick,
    onSecondaryClick,
    onExited,
  } = props;
  const context = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [value, setValue] = React.useState("reserve");

  const theme = useTheme();

  const getStyle = (selected) => ({
    padding: "16px",
    marginLeft: 0,
    width: "100%",
    backgroundColor: selected ? theme.palette.secondary["100"] : "inherit",
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handlePrimaryClick = async () => {
    try {
      if (value === "reserve") {
        await onPrimaryRefreshClick();
      } else if (value === "release") {
        await onPrimaryReleaseClick();
      }
      onSecondaryClick();
    } catch (e) {
      if (e.status === 503) {
        context.openErrorSnackbar(e);
      } else {
        setErrorMessage(e.message);
      }
    }
  };

  return (
    <AlertDialog
      open={open}
      title="Quote Expired"
      TransitionProps={{
        onExited: onExited,
      }}
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      helperText={errorMessage}
      primaryLabel={value}
      secondaryLabel="Cancel"
      contentText="One or more reservations in this quote have expired and must be refreshed to receive an updated price. To refresh the quote, select one of the following options:"
      customContent={
        <>
          <RadioGroup value={value} onChange={handleChange}>
            <FormControlLabel
              style={getStyle(value === "reserve")}
              value="reserve"
              control={<Radio color="secondary" data-test="reserve-btn" />}
              labelPlacement="start"
              label={
                <ListItemText
                  primary="Reserve Expired Reservations"
                  secondary="Refresh the quote and attempt to reserve items that were reserved before expiration"
                />
              }
            />
            <Divider />
            <FormControlLabel
              style={getStyle(value === "release")}
              value="release"
              labelPlacement="start"
              control={<Radio color="secondary" data-test="release-btn" />}
              label={
                <ListItemText
                  primary="Release All Reservations"
                  secondary="Release the inventory and remove all current reservations on all line items in quote"
                />
              }
            />
          </RadioGroup>
        </>
      }
    />
  );
}

ExpiredReservationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  hasReservationAuthority: PropTypes.bool.isRequired,
  onPrimaryRefreshClick: PropTypes.func.isRequired,
  onPrimaryReleaseClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};
