import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import {
  MobilePortraitOnly,
  ResultMessage,
  Spinner,
} from "@masonite-digital/common-components";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AuthContext } from "../../../AuthContext";
import {
  FilterDrawer,
  PreviewSection,
  SelectionSection,
} from "../../../actions/ProductBuilder/step1";
import { ProgressLinear } from "../../../components/Common";
import {
  changeTab,
  resetState,
  setDoorPreview,
  setMultipleLoadingStates,
  setSettingsDrawer,
} from "../../../redux/actions/exteriorPrehungActionCreators";
import {
  addColors,
  clearState,
  deleteColor,
  existingStateCheck,
  filterOptions,
  loadClassifierRules,
  reloadOptionsOnSelect,
  retryGlassDesignCall,
  retrySlabDesignCall,
  retrySlabLayoutCall,
} from "../../../redux/actions/exteriorPrehungActions";
import {
  addDoorToCart,
  fetchDefaultDoor,
  fetchFinishes,
} from "../../../redux/actions/exteriorPrehungFetchActions";
import { exteriorPrehungDefaultState } from "../../../redux/defaultStates";
import builderFunctions from "../common/builderFunctions";

const StyledGrid = styled(Grid)(() => ({
  height: "100%",
  overflow: "auto",
}));

export default function ConsumerDoorBuilder({ match }) {
  const didMountRef = React.useRef(false);
  const [redirect, setRedirect] = React.useState(null);
  const context = React.useContext(AuthContext);
  const {
    loadingStates,
    errorStates,
    options,
    selections,
    filterSelections,
    doorViewOptions,
  } = useSelector(
    (state) => ({
      loadingStates: state.exteriorPrehung.loadingStates,
      errorStates: state.exteriorPrehung.errorStates,
      options: state.exteriorPrehung.selectionOptions,
      selections: state.exteriorPrehung.selections,
      filterSelections: state.exteriorPrehung.filterSettings,
      doorViewOptions: state.exteriorPrehung.doorViewOptions,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const dispatchCallBack = React.useCallback(
    (value) => dispatch(value),
    [dispatch]
  );
  const theme = useTheme();
  const isLessThanExtraSmall = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const user = context.whoAmI();
  const classifierRules = loadClassifierRules(user.classifiers);

  // Did Mount
  React.useEffect(() => {
    if (didMountRef.current) {
      return;
    }

    if (isEmpty(sessionStorage.getItem("accessToken"))) {
      setRedirect("/not-authorized");
      return;
    }

    if (
      Object.keys(loadingStates).some((state) => loadingStates[state] === true)
    ) {
      dispatchCallBack(
        setMultipleLoadingStates(exteriorPrehungDefaultState.loadingStates)
      );
    }

    dispatchCallBack(existingStateCheck(classifierRules));

    didMountRef.current = true;
  }, [
    dispatchCallBack,
    loadingStates,
    doorViewOptions.activeStep,
    classifierRules,
  ]);

  // Will Unmount
  React.useEffect(() => {
    return async () => {
      await dispatchCallBack(clearState());
    };
  }, [dispatchCallBack]);

  const handleAddDoorClick = async () => {
    await dispatchCallBack(addDoorToCart(match.params.resourceId));
    localStorage.setItem("emailAlreadySent", JSON.stringify(false));
    setRedirect(`/consumer/${match.params.resourceId}`);
  };

  const handleCancelClick = () => {
    setRedirect(`/consumer/${match.params.resourceId}`);
  };

  const handleOnClick = (type) => (option) => {
    dispatchCallBack(reloadOptionsOnSelect(type, option));
  };

  const handlePreviewOpenChange = (value) => {
    dispatchCallBack(setDoorPreview(value));
  };

  const handleDrawerChange = (value) => {
    dispatchCallBack(setSettingsDrawer(value));
  };

  const handleRetryClick = (error, retryCall) => {
    return () => {
      const filterSettings = filterSelections;

      if (error && error.isFilterError) {
        dispatchCallBack(filterOptions(filterSettings));
      } else if (error && error.type === "SEARCHED") {
        const filterKeys = Object.assign({}, filterSettings);
        Object.keys(filterKeys).forEach((key) => (filterKeys[key] = false));
        dispatchCallBack(filterOptions(filterKeys));
      } else {
        retryCall();
      }
    };
  };

  const retryCalls = {
    slabDesignCall: () => dispatchCallBack(retrySlabDesignCall()),
    glassDesignCall: () => dispatchCallBack(retryGlassDesignCall()),
    finishesCall: () => dispatchCallBack(fetchFinishes()),
    handleRetryClick: handleRetryClick,
  };

  const apiCalls = {
    getColors: builderFunctions.getColors,
    searchColor: builderFunctions.getColor,
  };

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  if (
    Object.values(errorStates).some((error) => error && error.status === 401)
  ) {
    setRedirect("/expired");
  }

  if (errorStates.defaultDoorError) {
    return (
      <Box sx={{ height: "calc(100vh - 64px)" }}>
        <ResultMessage
          type="WARNING"
          title="Something went wrong"
          message="Unable to get default specifications. Please either select retry or contact customer support for help."
          onClick={async () => dispatchCallBack(fetchDefaultDoor())}
        />
      </Box>
    );
  }

  if (doorViewOptions.activeStep > 0) {
    dispatchCallBack(clearState());
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        // 64px is the height of the top AppBar
        height: `calc(${theme.palette.innerHeight}px - 64px)`,
        [theme.breakpoints.down("sm")]: {
          // 56px is the height of the top AppBar on xs
          height: `calc(${theme.palette.innerHeight}px - 56px)`,
        },
      })}
    >
      <MobilePortraitOnly>
        <ProgressLinear
          open={loadingStates.loadingFilteredOptions}
          title="Loading Configurations"
        />
        {loadingStates.loadingGetProduct && <Spinner fullScreen />}
        <FilterDrawer
          isLoading={
            loadingStates.loadingDoorSpecs ||
            loadingStates.loadingExistingStateCheck
          }
          doorViewOptions={doorViewOptions}
          open={doorViewOptions.drawerOpen}
          handleDrawerState={(isOpen) => handleDrawerChange(isOpen)}
          settings={filterSelections}
          options={options.doorSpecs}
          onSave={(option) => dispatchCallBack(filterOptions(option))}
          slabLayout={selections.slabLayout}
        />
        <Grid
          container
          sx={(theme) => ({
            // 64px is the height of the bottom stepper
            height: "calc(100% - 64px)",
            [theme.breakpoints.down("sm")]: {
              // 54px is the bottom stepper on xs
              height: "calc(100% - 54px)",
            },
          })}
        >
          <StyledGrid
            item
            xs={12}
            sm={6}
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                // 56px is the height of the top AppBar on xs
                height: `calc(${theme.palette.innerHeight * 0.6}px - 56px)`,
              },
            })}
          >
            <StyledGrid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <PreviewSection
                hasCustomColor
                doorViewOptions={doorViewOptions}
                loadingStates={loadingStates}
                errorStates={errorStates}
                selections={selections}
                slabLayouts={options.slabLayouts}
                getSlabLayouts={() => dispatchCallBack(retrySlabLayoutCall())}
                setDoorPreview={handlePreviewOpenChange}
                setSettingsDrawer={(value) =>
                  dispatchCallBack(setSettingsDrawer(value))
                }
                onSlabLayoutClick={handleOnClick("SLAB_LAYOUT")}
                resetClick={() => dispatchCallBack(clearState())}
              />
            </StyledGrid>
          </StyledGrid>
          <StyledGrid
            item
            xs={12}
            sm={6}
            sx={(theme) => ({
              backgroundColor: theme.palette.primary["200"],
              [theme.breakpoints.down("sm")]: {
                // 54px is the bottom stepper on xs
                height: `calc(${theme.palette.innerHeight * 0.4}px - 54px)`,
              },
            })}
          >
            <StyledGrid container item xs={12}>
              <Grid item xs={12}>
                <SelectionSection
                  isLessThanSmall={isLessThanExtraSmall}
                  hasCustomColor
                  doorViewOptions={doorViewOptions}
                  options={options}
                  loadingStates={loadingStates}
                  errorStates={errorStates}
                  selections={selections}
                  apiCalls={apiCalls}
                  retryCalls={retryCalls}
                  onOptionClick={handleOnClick}
                  changeTab={(value) => dispatchCallBack(changeTab(value))}
                  setSettingsDrawer={(value) =>
                    dispatchCallBack(setSettingsDrawer(value))
                  }
                  addColors={(colors) => dispatchCallBack(addColors(colors))}
                  deleteColor={(color) => dispatchCallBack(deleteColor(color))}
                  resetState={() => dispatchCallBack(resetState())}
                />
              </Grid>
            </StyledGrid>
          </StyledGrid>
        </Grid>
        <AppBar position="relative">
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              data-test="cancel-btn"
              variant="outlined"
              color="secondary"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              data-test="add-to-cart-btn"
              disabled={
                loadingStates.loadingDefaultDoor ||
                loadingStates.loadingExistingStateCheck ||
                loadingStates.loadingSlabDesigns ||
                loadingStates.loadingGlassDesigns ||
                loadingStates.loadingFinishes
              }
              variant="contained"
              color="secondary"
              onClick={handleAddDoorClick}
            >
              Add to cart
            </Button>
          </Toolbar>
        </AppBar>
      </MobilePortraitOnly>
    </Box>
  );
}
