import React from "react";
import PropTypes from "prop-types";
import {
  NameTextField,
  SelectTextField,
  ZipCodeTextField,
} from "@masonite-digital/common-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { states } from "../../utils";

export default function BillingAddressDetail({ data, onChange }) {
  const { errors } = data;

  function createChangeHandler(type) {
    return function handleChange(e) {
      onChange({ [type]: e.target.value });
    };
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" component="div">
          Billing Address
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NameTextField
          id="address-line1"
          label="Address Line 1"
          onChange={createChangeHandler("line1")}
          value={data.line1}
          error={errors.line1}
        />
      </Grid>
      <Grid item xs={12}>
        <NameTextField
          id="address-line2"
          label="Address Line 2 (Optional)"
          onChange={createChangeHandler("line2")}
          value={data.line2}
        />
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} sm={6}>
          <NameTextField
            label="City"
            onChange={createChangeHandler("city")}
            value={data.city}
            error={errors.city}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectTextField
            label="State"
            id="state"
            defaultOption=""
            range={states}
            onChange={createChangeHandler("state")}
            value={data.state || ""}
            error={errors.state}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ZipCodeTextField
            onChange={createChangeHandler("zipCode")}
            value={data.zipCode}
            error={errors.zipCode}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

BillingAddressDetail.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    errors: PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      line1: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      line2: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      city: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      state: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};
