import React from "react";
import { Link, withRouter } from "react-router-dom";
import kebabCase from "lodash/kebabCase";
import LightOnIcon from "mdi-material-ui/LightbulbOn";
import LightOffIcon from "mdi-material-ui/LightbulbOutline";
import SidebarLeftIcon from "mdi-material-ui/PageLayoutSidebarLeft";
import SidebarRightIcon from "mdi-material-ui/PageLayoutSidebarRight";
import PropTypes from "prop-types";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import HelpCircleOutline from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { googleGenericEvent } from "../../../analytics/googleAnalyticsUtility";

class ProfileMenu extends React.Component {
  state = {
    anchorEl: null,
    contactId: sessionStorage.getItem("contactId"),
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogout = () => {
    this.setState({ anchorEl: null });
    this.props.history.push("/logout");
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isInverted !== this.props.isInverted) {
      let curAnchor = this.state.anchorEl;
      this.setState({ anchorEl: null }, () =>
        this.setState({ anchorEl: curAnchor })
      );
    }
  }

  render() {
    const { anchorEl, contactId } = this.state;
    const { handleTheming, user, isInverted, handleInverting } = this.props;

    const isLightTheme = window.localStorage.getItem("isLightTheme") === "true";
    const environmentConfig = window.__RUNTIME_CONFIG__
        ? window.__RUNTIME_CONFIG__
        : process.env;

    return (
      <>
        <IconButton
          data-test="help-link"
          style={{ color: "white", ...(isInverted && { marginRight: 32 }) }}
          component="a"
          edge={isInverted ? "start" : "end"}
          href={environmentConfig.REACT_APP_HELP_LINK}
          target="_blank"
        >
          <HelpCircleOutline />
        </IconButton>
        <IconButton
          data-test="profile-menu"
          style={{
            color: "white",
            ...(isInverted ? { marginRight: 16 } : { marginLeft: 16 }),
          }}
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          edge={isInverted ? "start" : "end"}
          onClick={this.handleClick}
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem
            data-test="profile-link"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
            component={Link}
            onClick={this.handleClose}
            to={`/employees/${kebabCase(user.type)}/${contactId}`}
          >
            <AccountCircleIcon
              fontSize="large"
              style={{ color: "#adb5bd", marginBottom: "8px" }}
            />
            <Typography
              data-test="fullname-value"
              variant="subtitle2"
              gutterBottom
            >
              {user.fullName}
            </Typography>
            <Typography
              data-test="username-value"
              variant="caption"
              style={{
                color: isLightTheme
                  ? "rgba(0, 0, 0, 0.54)"
                  : "rgba(255, 255, 255, 0.54)",
              }}
              gutterBottom
            >
              {user.userName}
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleTheming}>
            <ListItemIcon>
              {isLightTheme ? <LightOnIcon /> : <LightOffIcon />}
            </ListItemIcon>
            <ListItemText primary="Night Mode" />
            <div style={{ marginLeft: "16px" }}>
              <Switch
                data-test="toggle-light"
                color="secondary"
                checked={!isLightTheme}
                value={""}
                onClick={() =>
                  googleGenericEvent(
                    `Profile Menu | Theme Toggle`,
                    "click theme toggle"
                  )
                }
              />
            </div>
          </MenuItem>
          <MenuItem onClick={handleInverting}>
            <ListItemIcon>
              {isInverted ? <SidebarRightIcon /> : <SidebarLeftIcon />}
            </ListItemIcon>
            <ListItemText primary="Invert Layout" />
            <div style={{ marginLeft: "16px" }}>
              <Switch
                data-test="toggle-layout"
                checked={isInverted}
                color="secondary"
                value={""}
                onClick={() =>
                  googleGenericEvent(
                    `profile menu | layout toggle`,
                    "click layout toggle"
                  )
                }
              />
            </div>
          </MenuItem>
          <MenuItem
            data-test="logout-link"
            onClick={() => {
              this.handleLogout();
              googleGenericEvent(`Profile Menu | Logout`, "click logout", "");
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </>
    );
  }
}

ProfileMenu.propTypes = {
  handleTheming: PropTypes.func.isRequired,
  handleInverting: PropTypes.func.isRequired,
  isInverted: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    type: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }).isRequired,
};
export default withRouter(ProfileMenu);
