import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import { AuthContext } from "../../AuthContext";

export default function DeleteDialog(props) {
  const {
    open,
    isLastItem,
    isQuote,
    isReorder,
    onPrimaryClick,
    onSecondaryClick,
    onRefresh,
    onExited,
  } = props;

  const [errorMessage, setErrorMessage] = React.useState("");
  const [shouldRefresh, setShouldRefresh] = React.useState(false);
  const context = React.useContext(AuthContext);

  let title;
  if (isQuote) {
    title = "Delete Quote";
  } else if (isReorder) {
    title = "Delete Reorder";
  } else if (isLastItem) {
    title = "Delete Last Line Item";
  } else {
    title = "Delete Line Item";
  }

  let contentText;
  if (isQuote) {
    contentText = "Deleting this quote will permanently remove it.";
  } else if (isReorder) {
    contentText = "Deleting this reorder will permanently remove it.";
  } else if (isLastItem) {
    contentText =
      "Deleting the last line item will also permanently remove this quote.";
  } else {
    contentText =
      "Deleting this line item will permanently remove it from the quote.";
  }

  async function handlePrimaryClick(event) {
    try {
      await onPrimaryClick(event);
      setShouldRefresh(true);
      onSecondaryClick();
    } catch (e) {
      if (e.status === 503) {
        context.openErrorSnackbar(e);
      } else {
        setErrorMessage(e.message);
      }
    }
  }

  async function handleOnExit() {
    if (onExited != null) {
      onExited();
    }

    if (shouldRefresh) {
      await onRefresh();
    }
  }

  return (
    <AlertDialog
      open={open}
      primaryLabel="Delete"
      dialogType={errorMessage ? "ERROR" : ""}
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      title={title}
      contentText={contentText}
      TransitionProps={{
        onExited: handleOnExit,
      }}
    />
  );
}

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isQuote: PropTypes.bool,
  isReorder: PropTypes.bool,
  isLastItem: PropTypes.bool,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onExited: PropTypes.func,
};
