import React from "react";
import SVG from "react-inlinesvg";
import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Box, Grid, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ImageNotAvailable from "../Icons/image-not-available.svg";

const PaintOverlay = styled(SVG, {
  shouldForwardProp: (prop) => prop !== "hexCode" && prop !== "sx",
})(({ hexCode }) => ({
  position: "absolute",
  zIndex: 2,
  display: "flex",
  justifyContent: "center",
  backfaceVisibility: "initial",
  color: hexCode ? "#" + hexCode : "transparent",
  mixBlendMode: "multiply",
  filter: "contract(1.16)",
  width: "100%",
  height: "100%",
  fillOpacity: 0.85,
}));

const skeletonWidthSingleDoorMixins = (theme) => ({
  minWidth: "25vw",
  [theme.breakpoints.down("lg")]: {
    minWidth: "28vw",
  },
  [theme.breakpoints.down("md")]: {
    minWidth: "30vw",
  },
  [theme.breakpoints.down("sm")]: {
    minWidth: "40vw",
  },
});

const skeletonWidthDoubleDoorMixins = (theme) => ({
  minWidth: "30vw",
  [theme.breakpoints.down("lg")]: {
    minWidth: "38vw",
  },
  [theme.breakpoints.down("md")]: {
    minWidth: "35vw",
  },
  [theme.breakpoints.down("sm")]: {
    minWidth: "60vw",
  },
});

async function getOverlays(
  isBackImage,
  slabDesign,
  selectedFinish,
  sideliteGlassDesign,
  setIsOverlayLoading,
  setImageUrl,
  setPaintOverlay,
) {
  setIsOverlayLoading(true);

  const selectionImage =
    !isEmpty(slabDesign) &&
    get(
      slabDesign.links.find((link) => link.rel === "selection-image"),
      "href",
    );

  // Find the unfinished image to apply a SVG overlay or find the image with a stain.
  const targetUrl =
    slabDesign?.links?.find(
      (link) =>
        link.rel ===
          (isBackImage ? "back-preview-image" : "front-preview-image") &&
        (selectedFinish && selectedFinish.finishType !== "STAIN"
          ? link.type.includes("unfinished")
          : link.type.includes(selectedFinish?.resourceId)) &&
        (!slabDesign.hasSideliteGlass ||
          (sideliteGlassDesign &&
            link.type.includes(sideliteGlassDesign.resourceId))),
    )?.href || "";
  setImageUrl(targetUrl);

  // Gets the paint overlay svg equivalent of the slab design selection image link.
  if (selectionImage) {
    const slabDesignImageName = selectionImage.split("/").slice(-1).pop();
    try {
      const paintOverlaySvg = await import(
        `../../actions/ProductBuilder/images/svg/${slabDesignImageName.replace(
          "png",
          "svg",
        )}`
      );

      setPaintOverlay(paintOverlaySvg.default);
    } catch (e) {
      console.warn(e);
      setPaintOverlay(null);
    }
    setIsOverlayLoading(false);
  }
}

export default function DoorSVG(props) {
  const {
    sx,
    selectedFinish,
    slabDesign,
    isBackImage,
    isDoubleDoor,
    isLoading,
    isPrintView,
    sideliteGlassDesign,
    isCustomPart,
  } = props;

  const [isOverlayLoading, setIsOverlayLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(null);
  const [paintOverlay, setPaintOverlay] = React.useState(null);
  const [invalidUrl, setInvalidUrl] = React.useState(false);
  const hasHexCode = !isEmpty(selectedFinish?.hexCode);
  const imgReverse =
    isBackImage && !invalidUrl ? { transform: "rotateY(180deg)" } : {};
  const imgSource = paintOverlay ? imageUrl : "";

  React.useEffect(() => {
    if (!isCustomPart) {
      // The invalidUrl handles the instance where the imageUrl is invalid but the paint image is valid.
      setInvalidUrl(false);

      getOverlays(
        isBackImage,
        slabDesign,
        selectedFinish,
        sideliteGlassDesign,
        setIsOverlayLoading,
        setImageUrl,
        setPaintOverlay,
      );
    }
  }, [
    isBackImage,
    slabDesign,
    selectedFinish,
    sideliteGlassDesign,
    isCustomPart,
  ]);

  if (isLoading || (isOverlayLoading && slabDesign.description !== "N/A")) {
    return (
      <Skeleton
        variant="rectangular"
        sx={(theme) => ({
          ...(typeof sx === "function" ? sx(theme) : sx),
          ...(isDoubleDoor
            ? skeletonWidthDoubleDoorMixins(theme)
            : skeletonWidthSingleDoorMixins(theme)),
        })}
      />
    );
  }

  if (!isEmpty(imageUrl) && hasHexCode) {
    return (
      <Grid
        data-test="svg-img-container"
        container
        justifyContent="center"
        sx={(theme) => ({
          border: theme.palette.border,
          position: "relative",
        })}
      >
        {paintOverlay && !invalidUrl && (
          <PaintOverlay
            data-test="door-svg-img"
            hexCode={selectedFinish.hexCode}
            src={paintOverlay}
            sx={sx}
          />
        )}
        <Box
          component="img"
          // If the paint or camera overlay is not available then show image not available.
          src={imgSource}
          alt="Door"
          onError={(e) => {
            if (paintOverlay) {
              console.warn("Failed to load preview image " + imageUrl);
            }
            e.target.src = ImageNotAvailable;
            e.target.style.border = "none";
            if (!isPrintView) {
              e.target.style.width = "20vw";
            }
            if (!invalidUrl) {
              setInvalidUrl(true);
            }
          }}
          sx={(theme) => ({
            ...(typeof sx === "function" ? sx(theme) : sx),
          })}
        />
      </Grid>
    );
  }

  return (
    <div>
      <>
        <Box
          component="object"
          hidden
          aria-label="Image Spacing"
          type="image/svg+xml"
          sx={sx}
        />
        <Box
          component="img"
          src={isCustomPart ? ImageNotAvailable : imageUrl}
          alt="Door"
          onError={(e) => {
            e.target.src = ImageNotAvailable;
            e.target.style.border = "none";
            if (!isPrintView) {
              e.target.style.width = "20vw";
            }
          }}
          sx={(theme) => ({
            border: theme.palette.border,
            position: "relative",
            ...(typeof sx === "function" ? sx(theme) : sx),
            ...imgReverse,
          })}
        />
      </>
    </div>
  );
}

DoorSVG.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  selectedFinish: PropTypes.shape({
    resourceId: PropTypes.string,
    finishType: PropTypes.string,
    hexCode: PropTypes.string,
  }),
  slabDesign: PropTypes.shape({
    hasSideliteGlass: PropTypes.bool,
    links: PropTypes.array,
  }),
  isBackImage: PropTypes.bool,
  isDoubleDoor: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPrintView: PropTypes.bool,
  sideliteGlassDesign: PropTypes.shape({
    resourceId: PropTypes.string,
  }),
  isCustomPart: PropTypes.bool,
};
