import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import {
  Button,
  Divider,
  FormControlLabel,
  ListItemText,
  Radio,
  RadioGroup,
} from "@mui/material";

export default function PrintOrderDialog(props) {
  const { open, pathname, onPrimaryClick, onSecondaryClick, isReorderPath } =
    props;
  const [value, setValue] = React.useState("accounting");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const accountingInvoiceText = isReorderPath
    ? "View items, billing details, shipping details, and price"
    : "View customer price, company cost, billing details, shipping details";
  const shippingReceiptText = isReorderPath
    ? "View a breakdown of items and price"
    : "View customer price, company cost, billing details, shipping details";

  return (
    <AlertDialog
      open={open}
      title={`Print ${isReorderPath ? "Reorder" : "Order"}?`}
      maxWidth="xs"
      contentText="You may need invoices for specialized purposes. Select the type of invoice you would like to open in a new window."
      customContent={
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel
            sx={{
              padding: "16px",
              marginLeft: 0,
              width: "100%",
              justifyContent: "space-between",
              ...(value === "accounting" && {
                bgcolor: "secondary.100",
              }),
            }}
            value="accounting"
            control={<Radio color="secondary" data-test="accounting-btn" />}
            labelPlacement="start"
            label={
              <ListItemText
                primary="Accounting Invoice"
                secondary={accountingInvoiceText}
              />
            }
          />
          <Divider />
          <FormControlLabel
            sx={(theme) => ({
              padding: "16px",
              marginLeft: 0,
              width: "100%",
              justifyContent: "space-between",
              backgroundColor:
                value === "shipping" && theme.palette.secondary["100"],
            })}
            value="shipping"
            labelPlacement="start"
            control={<Radio color="secondary" data-test="shipping-btn" />}
            label={
              <ListItemText
                primary="Shipping Receipt"
                secondary={shippingReceiptText}
              />
            }
          />
        </RadioGroup>
      }
      customDialogAction={
        <>
          <Button
            data-test="secondary-btn"
            variant="outlined"
            onClick={onSecondaryClick}
          >
            Cancel
          </Button>
          <Button
            data-test="primary-btn"
            color="secondary"
            variant="contained"
            style={{ marginLeft: "8px" }}
            component={Link}
            rel="opener"
            target="_blank"
            to={
              value === "accounting"
                ? `${pathname}/print/invoice`
                : `${pathname}/print`
            }
            onClick={onPrimaryClick}
          >
            Preview
          </Button>
        </>
      }
    />
  );
}

PrintOrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  isReorderPath: PropTypes.bool,
};
