export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

/**
 * We first serialized with JSON.stringify().
 * If parts of your state object are not mappable to JSON objects,
 * the serialization process may transform these parts of your state in unexpected ways.
 * For example, the javascript Set type does not exist in JSON.
 * When you try to serialize a Set via JSON.stringify(), it gets converted to an empty object.
 *
 * NOTE: Please do NOT save JSX objects to the Redux state!
 * It will cause React to throw an "Objects are not valid as a React child" error.
 */
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};
