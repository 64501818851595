import React, { Fragment } from "react";
import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import { ResultMessage } from "@masonite-digital/common-components";
import AddIcon from "@mui/icons-material/ControlPoint";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { ImageSelector } from "../../../components/Selection";

const LabelTypography = styled(Typography)({
  fontSize: "0.75rem",
  color: "primary.main",
  fontWeight: 500,
  textTransform: "uppercase",
});

export default function FinishDetails(props) {
  const {
    isLoading,
    finishError,
    finishOptions,
    customColors,
    selections,
    onClick,
    retryFinishCall,
    hasCustomColor,
    customPaintDeleteCheck,
    openCustomColorDialogClick,
  } = props;

  const handleOnClick = (type) => {
    return (option) => {
      onClick(type, option);
    };
  };

  if (isEmpty(finishOptions)) {
    return (
      <ResultMessage
        type="WARNING"
        title="Something went wrong"
        message={finishError.message}
        onClick={retryFinishCall}
      />
    );
  }

  const finishColorsArray = [
    {
      label: "Door Exterior",
      section: "doorExterior",
      textDataTest: "selected-exterior-finish-description",
      listDataTest: "door-exterior-finish-list",
      onClick: handleOnClick("doorExterior"),
      description: selections.doorExterior.description,
      selected: selections.doorExterior,
    },
    {
      label: "Door Interior",
      section: "doorInterior",
      textDataTest: "selected-interior-finish-description",
      listDataTest: "door-interior-finish-list",
      onClick: handleOnClick("doorInterior"),
      description: selections.doorInterior.description,
      selected: selections.doorInterior,
    },
    {
      label: "Frame Exterior",
      section: "frameExterior",
      textDataTest: "selected-exterior-frame-finish-description",
      listDataTest: "frame-exterior-finish-list",
      onClick: handleOnClick("frameExterior"),
      description: selections.frameExterior.description,
      selected: selections.frameExterior,
    },
    {
      label: "Frame Interior",
      section: "frameInterior",
      textDataTest: "selected-interior-frame-finish-description",
      listDataTest: "frame-interior-finish-list",
      onClick: handleOnClick("frameInterior"),
      description: selections.frameInterior.description,
      selected: selections.frameInterior,
    },
  ];

  const isPrimedOnly = selections.doorExterior.description === "Primed Only";

  return (
    <Grid container spacing={2} data-test="finish-list">
      {isPrimedOnly && (
        <Grid item xs={12}>
          <Typography variant="body2" color="error">
            Other finish options not available when Primed Only option is
            selected
          </Typography>
        </Grid>
      )}
      <Grid container item spacing={2}>
        {finishColorsArray.map((item, index) => {
          return (
            <Fragment key={index}>
              {isLoading ? (
                <Grid item container sm={6} xs={12}>
                  <Skeleton
                    style={{ marginBottom: "8px" }}
                    variant="rectangular"
                    width="100%"
                    height={25}
                  />
                  <Skeleton variant="rectangular" width="100%" height={262} />
                </Grid>
              ) : (
                <Grid item container sm={6} xs={12}>
                  <Grid item container>
                    <Grid item xs={6}>
                      <LabelTypography>{item.label}</LabelTypography>
                      <Typography
                        data-test={item.textDataTest}
                        variant="body2"
                        gutterBottom
                      >
                        {item.description}
                      </Typography>
                    </Grid>
                    {hasCustomColor && !isPrimedOnly && (
                      <Grid item xs={6} container justifyContent="flex-end">
                        <Grid>
                          <IconButton
                            data-test={kebabCase(
                              "add-custom-color-to-" + item.label + "-btn",
                            )}
                            onClick={() =>
                              openCustomColorDialogClick(item.section)
                            }
                          >
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} data-test={item.listDataTest}>
                    {/* Commenting the code (for removing Stains) instead of removing it, in case it is needed in the future */}
                    {/*{!isPrimedOnly && (*/}
                    {/*  <>*/}
                    {/*    <ImageSelector*/}
                    {/*      imgSizeSx={{ width: 32, height: 32 }}*/}
                    {/*      onSelect={item.onClick}*/}
                    {/*      selected={item.selected}*/}
                    {/*      options={finishOptions.STAIN}*/}
                    {/*      optionType="Stain"*/}
                    {/*      gridContainerProps={{ spacing: 0 }}*/}
                    {/*      forceExtraSmallIcons*/}
                    {/*      showDescription={false}*/}
                    {/*      addMargin={true}*/}
                    {/*    />*/}
                    {/*    <Divider style={{ margin: "5px 0" }} />*/}
                    {/*  </>*/}
                    {/*)}*/}
                    <ImageSelector
                      imgSizeSx={{ width: 32, height: 32 }}
                      onSelect={item.onClick}
                      selected={item.selected}
                      options={finishOptions.PAINT.filter((finish) => {
                        // If selected Prime Only then filter for Prime Only
                        if (isPrimedOnly) {
                          return finish.description === "Primed Only";
                        }
                        return finish.description !== "Primed Only";
                      })}
                      optionType="Paint"
                      gridContainerProps={{ spacing: 0 }}
                      forceExtraSmallIcons
                      showDescription={false}
                      addMargin={true}
                    />
                    {!isPrimedOnly && (
                      <>
                        <Divider style={{ margin: "5px 0" }} />
                        <ImageSelector
                          imgSizeSx={{ width: 32, height: 32 }}
                          onSelect={item.onClick}
                          selected={item.selected}
                          options={customColors}
                          optionType="Custom Paint"
                          gridContainerProps={{ spacing: 0 }}
                          forceExtraSmallIcons
                          showDescription={false}
                          showDelete
                          onDelete={customPaintDeleteCheck}
                          addMargin={true}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </Fragment>
          );
        })}
        {isLoading ? (
          <Grid item container sm={6} xs={12}>
            <Skeleton
              style={{ marginBottom: "8px" }}
              variant="rectangular"
              width="100%"
              height={25}
            />
            <Skeleton
              style={{ marginBottom: "8px" }}
              variant="rectangular"
              width="100%"
              height={100}
            />
          </Grid>
        ) : (
          <Grid item container sm={6} xs={12}>
            <Grid item xs={12}>
              <LabelTypography>Weather Stripping</LabelTypography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                data-test="selected-weather-stripping-description"
                variant="body2"
                gutterBottom
              >
                {selections.weatherStripping.description}
              </Typography>
              <ImageSelector
                imgSizeSx={{ width: 32, height: 32 }}
                onSelect={(option) => {
                  onClick("weatherStripping", option);
                }}
                selected={selections.weatherStripping}
                options={finishOptions.WEATHERSTRIP}
                optionType="Weather Stripping Finish"
                gridContainerProps={{ spacing: 0 }}
                forceExtraSmallIcons
                showDescription={false}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

FinishDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  finishError: PropTypes.string,
  hasCustomColor: PropTypes.bool.isRequired,
  finishOptions: PropTypes.object.isRequired,
  customColors: PropTypes.array.isRequired,
  selections: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  retryFinishCall: PropTypes.func.isRequired,
  customPaintDeleteCheck: PropTypes.func.isRequired,
  openCustomColorDialogClick: PropTypes.func.isRequired,
};
