import React from "react";
import { jwtDecode } from "jwt-decode";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { AuthContext } from "./AuthContext";
import { LoginDialog, MaintenanceDialog } from "./components/Dialogs";

export const Type = Object.freeze({
  CONTRACTOR: "CONTRACTOR",
  CSR: "CSR",
  SALES_REP: "SALES_REP",
});

export const Authority = Object.freeze({
  ADMIN_DATA_EXPORT: "admin.data.export",
  ORDER_DATA_EXPORT: "order.data.export",
  ANALYTICS_PRODUCT_READ: "analytics.product.read",
  COMPANY_MANAGE_MULTIPLIER: "company.manage.multiplier",
  COMPANY_QUICKQUOTE: "company.quickquote",
  COMPANY_READ_ANY: "company.read.any",
  COMPANY_READ_OWN: "company.read.own",
  COMPANY_TIER_MODIFY: "company.tier.modify",
  COMPANY_UPDATE: "company.update",
  COMPANY_ADMIN: "company.admin",
  CONTACT_ADMIN: "contact.admin",
  CONTACT_CONTRACTOR_CREATE: "contact.contractor.create",
  CONTACT_CONTRACTOR_DELETE: "contact.contractor.delete",
  CONTACT_CONTRACTOR_READ: "contact.contractor.read",
  CONTACT_CONTRACTOR_UPDATE: "contact.contractor.update",
  CONTACT_CSR_CREATE: "contact.csr.create",
  CONTACT_DISABLE: "contact.disable",
  CONTACT_VIEW_MASONITE_CREDIT: "contact.view.masonite.credit",
  INQUIRY_READ: "inquiry.read",
  INQUIRY_CREATE: "inquiry.create",
  INQUIRY_ADMIN: "inquiry.admin",
  INQUIRY_ADMIN_READ: "inquiry.admin.read",
  INQUIRY_NOTE_CREATE: "inquiry.note.create",
  INQUIRY_NOTE_DELETE: "inquiry.note.delete",
  INQUIRY_NOTE_READ: "inquiry.note.read",
  LEAD_ADMIN_READ: "lead.admin.read",
  LEAD_ADMIN: "lead.admin",
  LEAD_DATA_EXPORT: "lead.data.export",
  ORDER_ADMIN: "order.admin",
  ORDER_CREATE: "order.create",
  ORDER_CUSTOM_PART: "order.custom.part",
  ORDER_DELETE: "order.delete",
  ORDER_MODIFY: "order.modify",
  ORDER_PURCHASE: "order.purchase",
  ORDER_READ: "order.read",
  ORDER_UPDATE: "order.update",
  ORDER_REFUND: "order.refund",
  ORDER_RESERVE: "order.reserve",
  ORDER_TRANSFER: "order.transfer",
  ORDER_VIEW_ITEM_CODE: "order.item.code.read",
  PURCHASE_ORDER_NUMBER_WRITE: "purchase.order.number.write",
  READ_ONLY: "admin.read",
  REORDER_CREATE: "reorder.create",
  REORDER_UPDATE: "reorder.update",
  REORDER_READ: "reorder.read",
  REORDER_DELETE: "reorder.delete",
  REORDER_ITEM_CREATE: "reorder.item.create",
  REORDER_ITEM_UPDATE: "reorder.item.update",
  REORDER_ITEM_DELETE: "reorder.item.delete",
  REORDER_WARRANTY_PURCHASE: "reorder.warranty.purchase",
  SERVICEAREA_ADMIN: "servicearea.admin",
  SERVICEAREA_READ: "servicearea.read",
  SERVICEAREA_WRITE: "servicearea.write",
  FEATURE_FINANCING_BEHALF: "feature.financing.behalf",
  FEATURE_PRODUCT_V3: "feature.product.v3",
  FEATURE_NOTIFICATION: "feature.notifications",
  FEATURE_SNACKBAR: "feature.snackbar",
  FEATURE_CONSUMER_LEADS: "feature.consumer.leads",
  FEATURE_CUSTOM_COLOR: "feature.custom.color",
  FEATURE_EDIT_LINE_ITEM: "feature.edit.line.item",
  FEATURE_SALES_INSIGHT: "feature.sales.insight",
  FEATURE_SALES_INSIGHT_ORDERS: "feature.sales.insight.orders",
  FEATURE_SALES_INSIGHT_QUOTES: "feature.sales.insight.quotes",
  FEATURE_SALES_INSIGHT_CONVERSIONS: "feature.sales.insight.conversions",
  FEATURE_SALES_INSIGHT_TEAM: "feature.sales.insight.team",
  FEATURE_SALES_PERSON: "feature.sales.person",
  FEATURE_CUT_DOWNS: "feature.cut.downs",
  FEATURE_MASONITE_CREDIT: "feature.masonite.credit",
  FEATURE_INQUIRY: "feature.inquiry",
  FEATURE_REORDER: "feature.reorder",
});

class Provider extends React.Component {
  constructor(props) {
    super(props);

    const accessToken = window.sessionStorage.getItem("accessToken");
    if (accessToken) {
      const payload = jwtDecode(accessToken);
      const authorities = payload.authorities;
      const classifiers = payload.classifiers;

      this.state = {
        isInverted: window.localStorage.getItem("isInverted") === "true",
        user: {
          companyId: payload.company_id || null,
          contactId: payload.contact_id,
          firstName: payload.first_name,
          lastName: payload.last_name,
          fullName: `${payload.first_name} ${payload.last_name}`,
          userId: payload.user_id,
          userName: payload.user_name,
          type: payload.type,
          warehouseNumber: payload.warehouse_number,
          presentationPriceEnabled: payload.presentation_pricing_enabled,
          classifiers: classifiers,
        },
        authorities,
        loginAgain: false,
        onLoginClick: () => window.location.reload(),
        maintenanceMode: false,
        maintenanceModeText: null,
        changedLocation: null,
        unListen: null,
      };
    } else {
      this.state = {
        isInverted: window.localStorage.getItem("isInverted") === "true",
        user: {
          contactId: "",
          userId: "",
          firstName: "",
          lastName: "",
          fullName: "",
          userName: "",
          type: "",
          warehouseNumber: "",
          presentationPricingEnabled: false,
          classifier: [],
        },
        authorities: [],
        loginAgain: false,
        onLoginClick: () => window.location.reload(),
        maintenanceMode: false,
        maintenanceModeText: null,
        changedLocation: null,
        unListen: null,
      };
    }
  }

  componentDidMount() {
    window.addEventListener("advisarLogin", this.handleLoginEvent);
    window.addEventListener("loginAgain", this.handleOpenLoginModal);
  }

  componentWillUnmount() {
    window.removeEventListener("advisarLogin", this.handleLoginEvent);
    window.removeEventListener("loginAgain", this.handleOpenLoginModal);
  }

  handleLoginEvent = (e) => {
    const payload = jwtDecode(e.accessToken);

    const authorities = payload.authorities;
    const classifiers = payload.classifiers;

    const user = {
      companyId: payload.company_id,
      contactId: payload.contact_id,
      userId: payload.user_id,
      firstName: payload.first_name,
      lastName: payload.last_name,
      fullName: `${payload.first_name} ${payload.last_name}`,
      userName: payload.user_name,
      type: payload.type,
      warehouseNumber: payload.warehouse_number,
      presentationPriceEnabled: payload.presentation_pricing_enabled,
      classifiers: classifiers,
    };

    this.setState(
      {
        user,
        authorities,
      },
      () => {
        console.debug(`Reset authorities`, authorities);
        console.debug(
          `Presentation Pricing ${
            payload.presentation_pricing_enabled ? "enabled" : "disabled"
          }`,
        );
      },
    );
  };

  handleOpenLoginModal = (event) => {
    this.setState({
      loginAgain: true,
      onLoginClick: async () => {
        window.location.reload();
      },
    });
  };

  handleErrorSnackbar = (error) => {
    if (error.status === 503) {
      this.setState({
        maintenanceMode: true,
        maintenanceModeText: error.message,
      });
    } else {
      enqueueSnackbar(error.message, {
        variant: "error",
        action: (key) => (
          <IconButton
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };

  hasAuthority = (authority) => {
    const result = this.state.authorities.includes(authority);
    console.debug(`hasAuthority(${authority}) => ${result}`);
    return result;
  };

  hasAnyAuthority = (authorities) => {
    const result = authorities.some(this.hasAuthority);
    console.debug(`hasAnyAuthority(${authorities}) => ${result}`);
    return result;
  };

  hasAllAuthorities = (authorities) => {
    return authorities.every(this.hasAuthority);
  };

  whoAmI = () => {
    return this.state.user;
  };

  onInvertLayoutChange = () => {
    const value = window.localStorage.getItem("isInverted") !== "true";
    window.localStorage.setItem("isInverted", JSON.stringify(value));
    this.setState({ isInverted: value });
  };

  trackRoutePath = (history, startLocation, analyticsEvent) => {
    const unListen = history.listen((location, action) => {
      // Handles going back to start page via navigation (i.e Redirect)
      if (action === "PUSH") {
        if (!this.state.changedLocation) {
          this.setState({ changedLocation: location.pathname });
        } else {
          if (startLocation.pathname === location.pathname) {
            // User went back to starting location via navigation.
            analyticsEvent();
            this.setState({ changedLocation: null });
            this.state.unListen();
          } else {
            // User did not go back to starting location via navigation.
            this.setState({ changedLocation: null });
            this.state.unListen();
          }
        }
      }

      // Handles forward and backward
      if (action === "POP") {
        if (startLocation.key === location.key) {
          // User went back to starting location.
          analyticsEvent();
          this.setState({ changedLocation: null });
          this.state.unListen();
        } else {
          // User did not go back to starting location.
          this.setState({ changedLocation: null });
          this.state.unListen();
        }
      }
    });

    this.setState({ unListen });
  };

  render() {
    const { children } = this.props;

    return (
      <AuthContext.Provider
        value={{
          hasAuthority: this.hasAuthority,
          hasAnyAuthority: this.hasAnyAuthority,
          hasAllAuthorities: this.hasAllAuthorities,
          whoAmI: this.whoAmI,
          isInverted: this.state.isInverted,
          changeInverted: this.onInvertLayoutChange,
          openErrorSnackbar: this.handleErrorSnackbar,
          trackRoutePath: this.trackRoutePath,
        }}
      >
        {children}
        <LoginDialog
          open={this.state.loginAgain}
          onLogin={this.state.onLoginClick}
          onCancelClick={() => this.setState({ loginAgain: false })}
        />
        <MaintenanceDialog
          open={this.state.maintenanceMode}
          onCloseClick={() => this.setState({ maintenanceMode: false })}
          message={this.state.maintenanceModeText}
        />
      </AuthContext.Provider>
    );
  }
}

export const AuthProvider = Provider;

export const AuthConsumer = AuthContext.Consumer;
