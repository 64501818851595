import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AlertDialog,
  SelectTextField,
} from "@masonite-digital/common-components";
import { Typography } from "@mui/material";

export default function ReorderDialog({
  reorderReasons,
  isEdit,
  open,
  onPrimaryClick,
  onClose,
  setIsEdit,
  setReorder,
  targetComponent,
  targetReorderItem,
  reorderResourceId,
}) {
  const [preEditReason, setPreEditReason] = React.useState(
    targetReorderItem?.reorderReasonCode
      ? targetReorderItem.reorderReasonCode
      : "",
  );
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [returnedResponse, setReturnedResponse] = React.useState(null);

  const history = useHistory();

  const isPeepSite = targetComponent.partType === "PeepSite";
  const isCustomPart = targetComponent.partType === "CustomPart";

  React.useEffect(() => {
    setPreEditReason(
      targetReorderItem?.reorderReasonCode
        ? targetReorderItem.reorderReasonCode
        : "",
    );
  }, [targetReorderItem]);

  const onExited = () => {
    if (returnedResponse) {
      // If the reorderResourceId is null then a new reorder is created.
      if (!reorderResourceId) {
        history.push(`/reorders/in-progress/${returnedResponse.resourceId}`);
        return;
      }
      setReorder(returnedResponse);
    }

    setPreEditReason(
      targetReorderItem?.reorderReasonCode
        ? targetReorderItem.reorderReasonCode
        : "",
    );
    setErrorMessage(null);
    setIsEdit(false);
  };

  const handlePrimaryClick = async () => {
    try {
      const response = await onPrimaryClick(preEditReason);
      setReturnedResponse(response);
      onClose();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const handleReasonChange = (event) => {
    setPreEditReason(event.target.value);
    setErrorMessage(null);
  };

  return (
    <AlertDialog
      open={open}
      title={isEdit ? "Edit Reorder Reason?" : "Add to Reorder?"}
      helperText={errorMessage}
      primaryLabel={isEdit ? "Save" : "Add to Reorder"}
      TransitionProps={{
        onExited,
      }}
      disablePrimary={
        preEditReason === "" ||
        (isEdit && targetReorderItem.reorderReasonCode === preEditReason)
      }
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onClose}
      customContent={
        <>
          <Typography component="span" style={{ fontWeight: "bold" }}>
            {` ${isCustomPart ? "" : `${targetComponent.display} -`} ${
              targetComponent.description
            } ${(!isPeepSite && targetComponent.finish?.description) || ""}`}
          </Typography>
          <SelectTextField
            required
            error={Boolean(errorMessage)}
            label="Reason"
            range={reorderReasons.map((reorderReason) => ({
              label: reorderReason.reasonDescription,
              value: reorderReason.reasonCode,
            }))}
            onChange={handleReasonChange}
            defaultOption="Select a Reason"
            value={preEditReason}
          />
        </>
      }
    />
  );
}

ReorderDialog.propTypes = {
  reorderReasons: PropTypes.array.isRequired,
  isEdit: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsEdit: PropTypes.func.isRequired,
  setReorder: PropTypes.func.isRequired,
  targetComponent: PropTypes.object.isRequired,
  targetReorderItem: PropTypes.object,
  reorderResourceId: PropTypes.string,
};
