import React, { Fragment } from "react";
import isEmpty from "lodash/isEmpty";
import AccountHardHatIcon from "mdi-material-ui/AccountHardHat";
import EditIcon from "mdi-material-ui/PencilPlus";
import PencilRulerIcon from "mdi-material-ui/PencilRuler";
import PropTypes from "prop-types";
import {
  AlertDialog,
  ResultMessage,
} from "@masonite-digital/common-components";
import PlusIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteIcon from "@mui/icons-material/Note";
import DoorIcon from "@mui/icons-material/SensorDoorOutlined";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { green, indigo, pink } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import AddConsumerLeadNoteDialog from "./AddConsumerLeadNoteDialog";
import ConsumerDeleteItemDialog from "./ConsumerDeleteItemDialog";
import DoorInfoBlock from "../../components/Blocks/DoorInfoBlock";
import InfoBlock from "../../components/Blocks/InfoBlock";
import { normalizeDoorPayload } from "../../utils/normalizeDoorPayloads";

const StyledAvatar = styled(Avatar)(({ theme, color, backgroundcolor }) => ({
  color: color,
  backgroundColor: backgroundcolor,
  width: theme.spacing(7),
  height: theme.spacing(7),
  [theme.breakpoints.down("sm")]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

export default function ConsumerLeadItems(props) {
  const {
    items,
    isMobile,
    isConsumer,
    isDisabled,
    canCreateQuote,
    onReload,
    onDelete,
    onAddDoorClick,
    onConfigureClick,
    onSaveNoteClick,
  } = props;

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openNoteDialog, setOpenNoteDialog] = React.useState(false);
  const [openContractorNoteViewDialog, setOpenContractorNoteViewDialog] =
    React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});

  const handleDeleteClick = async () => {
    await onDelete(selectedItem.resourceId);
  };

  const handleSaveNoteClick = async (note) => {
    await onSaveNoteClick(selectedItem.resourceId, note);
  };

  const STEPS = [
    {
      text: "1. Select your door layout",
      subText: "Choose one of the various door layout combinations",
      icon: (
        <StyledAvatar color={indigo[500]} backgroundcolor={indigo[50]}>
          <DoorIcon fontSize={isMobile ? "medium" : "large"} />
        </StyledAvatar>
      ),
    },
    {
      text: "2. Style your door",
      subText: "Select the design, glass, and finish for your door",
      icon: (
        <StyledAvatar color={pink[500]} backgroundcolor={pink[50]}>
          <PencilRulerIcon fontSize={isMobile ? "medium" : "large"} />
        </StyledAvatar>
      ),
    },
    {
      text: "3. Send to your contractor",
      subText: "Request a quote after making your door selections",
      icon: (
        <StyledAvatar color={green[500]} backgroundcolor={green[50]}>
          <AccountHardHatIcon fontSize={isMobile ? "medium" : "large"} />
        </StyledAvatar>
      ),
    },
  ];

  if (isEmpty(items)) {
    if (isConsumer) {
      return (
        <Grid
          data-test="three-step-section"
          container
          spacing={2}
          justifyContent="center"
        >
          {STEPS.map((step, i) => (
            <Grid
              key={i}
              item
              xs={12}
              sm={4}
              container
              direction={isMobile ? "row" : "column"}
              alignItems="center"
            >
              <Grid item xs={isMobile ? 3 : null}>
                {step.icon}
              </Grid>
              <Grid
                sx={(theme) => ({
                  textAlign: "center",
                  mt: 1,
                  [theme.breakpoints.down("sm")]: {
                    textAlign: "start",
                    mt: 0,
                  },
                })}
                item
                xs={isMobile ? 9 : null}
              >
                <Typography variant="body2" gutterBottom>
                  <strong>{step.text}</strong>
                </Typography>
                <Typography variant="caption">{step.subText}</Typography>
              </Grid>
            </Grid>
          ))}
          <Button
            data-test="add-door-btn"
            sx={{ mt: 1 }}
            onClick={onAddDoorClick}
            variant="contained"
            color="secondary"
            startIcon={<PlusIcon />}
          >
            Add Door
          </Button>
        </Grid>
      );
    } else {
      return (
        <ResultMessage
          type="DOOR_ITEMS"
          title="No items created"
          message="This customer hasn't created any items"
        />
      );
    }
  }

  return (
    <>
      <ConsumerDeleteItemDialog
        open={openDeleteDialog}
        onPrimaryClick={handleDeleteClick}
        onSecondaryClick={() => setOpenDeleteDialog(false)}
        onRefresh={onReload}
      />
      <AlertDialog
        open={openContractorNoteViewDialog}
        title="Customer Note"
        hidePrimaryButton
        secondaryLabel="Close"
        onSecondaryClick={() => setOpenContractorNoteViewDialog(false)}
        customContent={
          <InfoBlock
            type="info"
            sx={{
              display: "flex",
              mt: 2,
            }}
          >
            <Typography color="primary.600" variant="body1">
              {selectedItem.notes}
            </Typography>
          </InfoBlock>
        }
      />
      <AddConsumerLeadNoteDialog
        value={selectedItem.notes}
        open={openNoteDialog}
        setOpenNoteDialog={setOpenNoteDialog}
        onPrimaryClick={handleSaveNoteClick}
        onSecondaryClick={() => setOpenNoteDialog(false)}
        onReload={onReload}
      />
      <Grid container spacing={2}>
        {items.map((item, i) => {
          const normalizedDoor = normalizeDoorPayload(item);
          return (
            <Fragment key={i}>
              <Grid item xs={12}>
                <Divider
                  sx={(theme) => ({ margin: `0 -${theme.spacing(2)}px` })}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between">
                <Grid item xs={12} sm={9}>
                  <DoorInfoBlock
                    slabDesign={normalizedDoor.slabDesign}
                    sideliteGlass={normalizedDoor.sideliteGlass}
                    finish={normalizedDoor.finish}
                    glassDesignDescription={
                      normalizedDoor.glassDesignDescription
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {isConsumer ? (
                    <>
                      <IconButton
                        data-test="edit-notes-btn"
                        onClick={() => {
                          setSelectedItem(item);
                          setOpenNoteDialog(true);
                        }}
                      >
                        <Avatar>
                          <EditIcon />
                        </Avatar>
                      </IconButton>
                      <IconButton
                        data-test="delete-btn"
                        onClick={() => {
                          setOpenDeleteDialog(true);
                          setSelectedItem(item);
                        }}
                      >
                        <Avatar>
                          <DeleteIcon />
                        </Avatar>
                      </IconButton>
                    </>
                  ) : (
                    <>
                      {item.notes && (
                        <IconButton
                          data-test="view-note-btn"
                          onClick={() => {
                            setSelectedItem(item);
                            setOpenContractorNoteViewDialog(true);
                          }}
                        >
                          <Avatar>
                            <NoteIcon />
                          </Avatar>
                        </IconButton>
                      )}
                      {canCreateQuote && (
                        <Button
                          data-test="configure-btn"
                          disabled={isDisabled}
                          color="secondary"
                          variant="contained"
                          fullWidth={isMobile}
                          onClick={() => onConfigureClick(item.resourceId)}
                        >
                          Configure
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
}

ConsumerLeadItems.propTypes = {
  items: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isConsumer: PropTypes.bool,
  isDisabled: PropTypes.bool,
  canCreateQuote: PropTypes.bool,
  onReload: PropTypes.func,
  onDelete: PropTypes.func,
  onAddDoorClick: PropTypes.func,
  onConfigureClick: PropTypes.func,
  onEditNotesClick: PropTypes.func,
};
