import React, { useState } from "react";
import validator from "validator";
import AddIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export default function ColorCodeWorkflow({
  searchColorCode,
  colorResult,
  addChip,
  error,
}) {
  const [typedValue, setTypedValue] = useState("");
  const [message, setMessage] = useState(null);

  function checkAndSetValue(value) {
    if (validator.matches(value, /^\d{1,4}$/)) {
      // Any 4 digits number
      setTypedValue(value);
    } else if (!value) {
      setTypedValue("");
    }
  }

  async function search(code) {
    await searchColorCode("SW" + code);
    setMessage(null);
  }

  return (
    <div>
      <DialogContentText gutterBottom>
        Enter the 4 digits following SW in your Sherwin-Williams color code:
      </DialogContentText>
      <TextField
        type="search"
        variant="outlined"
        placeholder={"####"}
        autoFocus
        error={!!error}
        helperText={error && error.message}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          tabIndex: -1,
          inputProps: {
            className: "autoFocus colorCode",
            "data-test": "sw-color-code-text",
          },
          startAdornment: "SW",
          endAdornment: (
            <IconButton
              data-test="search-color-code-icon"
              onClick={async () => search(typedValue)}
              sx={{
                color: "black",
                mr: -1,
              }}
            >
              <SearchIcon />
            </IconButton>
          ),
          value: typedValue,
        }}
        label="Color Code"
        fullWidth
        onChange={(event) => {
          checkAndSetValue(event.target.value);
        }}
        onKeyPress={async (ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
            await search(typedValue);
          }
        }}
        data-test="color-code-text-input"
      />
      <SwatchBox />
    </div>
  );

  function SwatchBox() {
    if (colorResult && !message) {
      return (
        <Grid
          alignItems="center"
          bcolor="background.paper"
          color="text.secondary"
          container
          justifyContent="space-between"
          mt={2}
          mb={1}
          p={1}
          whiteSpace="nowrap"
          sx={{
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))",
          }}
        >
          <Grid item xs={6}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <div
                  style={{
                    margin: "4px",
                    borderRadius: "4px",
                    width: "40px",
                    height: "40px",
                    background: "#" + colorResult.finish.hexCode,
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="body2" data-test="custom-color-code">
                  {colorResult.colorNumber}
                </Typography>
                <Typography variant="body2" data-test="custom-color-name">
                  {colorResult.colorName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <IconButton
              data-test="add-swatch-icon"
              onClick={() => addChip(colorResult)}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      );
    } else if (error) {
      return <div>{error}</div>;
    } else if (message) {
      return <div> {message} </div>;
    } else {
      return <div />;
    }
  }
}
