import CreditCardForm from "./CreditCardForm";
import PaymentOptions from "./PaymentOptions";
import PaymentReview from "./PaymentReview";
import ResetBilling from "./ResetBilling";
import ShippingDetailsCard from "./ShippingDetailsCard";
import WarrantyRegistration from "./WarrantyRegistration";

const PaymentType = Object.freeze({
  BEHALF: "BEHALF",
  CREDIT_CARD: "CREDIT_CARD",
  MASONITE_CREDIT: "MASONITE_CREDIT",
  WARRANTY: "WARRANTY",
});

export {
  PaymentOptions,
  ShippingDetailsCard,
  CreditCardForm,
  WarrantyRegistration,
  PaymentReview,
  PaymentType,
  ResetBilling,
};
