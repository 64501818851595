import React from "react";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function getStyle(type, theme) {
  switch (type) {
    case "info":
      return {
        padding: "16px",
        boxShadow: "unset",
        background: theme.palette.primary["200"],
      };
    case "error":
      return {
        padding: "16px 20px",
        boxShadow: "unset",
        color: "#c8102e",
        background: theme.palette.common.white,
      };
    default:
      return {
        padding: "16px",
        boxShadow: "unset",
        background: theme.palette.action.hover,
      };
  }
}

export default function InfoBlock(props) {
  return (
    <Paper className={props.className} style={getStyle(props.type, useTheme())}>
      {props.children}
    </Paper>
  );
}
