import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DoorFilterSettings from "./DoorFilterSettings";
import { googleGenericEvent } from "../../../analytics/googleAnalyticsUtility";

function reducer(state, action) {
  switch (action.type) {
    case "SELECTION_CHANGE":
      return {
        ...state,
        ...action.value,
      };
    case "CLEAR":
      const clonedState = Object.assign({}, state);
      Object.keys(clonedState).forEach((key) => (clonedState[key] = false));
      return clonedState;
    case "RESET":
      return action.value;
    default:
      return state;
  }
}

export default function FilterDrawer({
  isLoading,
  open,
  doorViewOptions,
  isInverted,
  handleDrawerState,
  onSave,
  settings,
  options,
  slabLayout,
}) {
  const [state, dispatch] = React.useReducer(reducer, settings);

  useEffect(() => {
    dispatch({ type: "RESET", value: settings });
  }, [open, settings]);

  function onSettingsClick(key, value) {
    dispatch({ type: "SELECTION_CHANGE", value: { [key]: value } });
  }

  return (
    <Drawer
      style={{
        zIndex: 1202,
      }}
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleDrawerState(false);
        }
      }}
      SlideProps={{
        onExited: () => dispatch({ type: "RESET", value: settings }),
      }}
      anchor={isInverted ? "left" : "right"}
      PaperProps={{
        style: {
          maxWidth: "540px",
          padding: "24px 32px",
          justifyContent: "space-between",
        },
      }}
    >
      <>
        <div style={{ marginBottom: "16px" }}>
          <Grid container justifyContent="space-between">
            <Typography variant="h5" style={{ marginBottom: "16px" }}>
              Filter Configurations
            </Typography>
            <Button
              data-test="clear-btn"
              variant="outlined"
              style={{ marginBottom: "16px" }}
              onClick={() => {
                googleGenericEvent(
                  "Exterior Door| Design Your Door | Filter",
                  "Click: Clear Button",
                  ""
                );
                dispatch({ type: "CLEAR", value: state });
                if (
                  doorViewOptions.classifierRules &&
                  doorViewOptions.classifierRules.restrictHurricaneRatedOnly
                ) {
                  dispatch({
                    type: "SELECTION_CHANGE",
                    value: { hurricaneRated: true },
                  });
                  dispatch({
                    type: "SELECTION_CHANGE",
                    value: { impactRated: true },
                  });
                }
              }}
            >
              Clear
            </Button>
          </Grid>
          <DoorFilterSettings
            isLoading={isLoading}
            onChange={onSettingsClick}
            selections={state}
            options={options}
            doorViewOptions={doorViewOptions}
            selectedSlabLayout={slabLayout}
          />
        </div>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              fullWidth
              data-test="close-settings-btn"
              size="large"
              color="secondary"
              variant="outlined"
              onClick={() => {
                handleDrawerState(false);
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              data-test="save-settings-btn"
              size="large"
              color="secondary"
              variant="contained"
              onClick={() => {
                handleDrawerState(false);
                onSave(state);
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </>
    </Drawer>
  );
}

FilterDrawer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  isInverted: PropTypes.bool,
  doorViewOptions: PropTypes.object,
  handleDrawerState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  slabLayout: PropTypes.object,
  options: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};
