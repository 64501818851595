import React from "react";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Cancel";
import Chip from "@mui/material/Chip";

class DiscountChip extends React.Component {
  render() {
    const { label, adjustHeight, onDeleteChange } = this.props;
    return (
      <Chip
        label={label}
        data-test={`${kebabCase(label)}-chip`}
        onDelete={onDeleteChange}
        deleteIcon={<CloseIcon data-test="delete-chip-btn" />}
        style={{
          backgroundColor: "darkred",
          color: "white",
          height: adjustHeight ? adjustHeight : "34px",
          fontWeight: 500,
        }}
      />
    );
  }
}

DiscountChip.propTypes = {
  label: PropTypes.string.isRequired,
  adjustHeight: PropTypes.string,
  onDeleteChange: PropTypes.func,
};

export default DiscountChip;
