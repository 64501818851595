import React from "react";
import { kebabCase } from "lodash";
import PropTypes from "prop-types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

export default function CustomPopper(props) {
  const { placement, info, name } = props;
  const [isPopperOpen, setIsPopperOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setIsPopperOpen(!isPopperOpen);
    setAnchorEl(event.currentTarget);
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      onClickAway={() => setIsPopperOpen(false)}
    >
      <Box>
        <Popper
          open={isPopperOpen}
          anchorEl={anchorEl}
          placement={placement || "bottom-end"}
          transition
          sx={(theme) => ({
            zIndex: theme.zIndex.modal,
          })}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={8} sx={{ maxWidth: "400px", overflow: "auto" }}>
                <Typography variant="body2" color="textSecondary" sx={{ p: 3 }}>
                  {info}
                </Typography>
              </Paper>
            </Fade>
          )}
        </Popper>
        <IconButton onClick={handleClick} data-test={kebabCase(name)}>
          <InfoOutlinedIcon />
        </IconButton>
      </Box>
    </ClickAwayListener>
  );
}

CustomPopper.propTypes = {
  info: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placement: PropTypes.string,
};
