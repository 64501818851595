import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ImageOverlay from "./ImageOverlay";
import SelectionBox from "./SelectionBox";
import OptionSkeleton, { OptionSkeletonType } from "../Common/OptionSkeleton";
import { ConflictDialog } from "../Dialogs";
import NoImage from "../Icons/no-image-available.svg";

const NUMBER_OF_GLASS_DESIGNS = 18;
const NUMBER_OF_PAINTS = 12;
const NUMBER_OF_STAINS = 5;

function getNumberOfSkeletons(type, slabLayout) {
  switch (type.toUpperCase()) {
    case "SLAB LAYOUT":
      return 8;
    case "LIP-LITE":
      return isEmpty(slabLayout) ? 13 : slabLayout.numberOfLipLiteSlabDesigns;
    case "OPAQUE":
      return isEmpty(slabLayout) ? 5 : slabLayout.numberOfOpaqueSlabDesigns;
    case "FLUSHED":
      return isEmpty(slabLayout) ? 17 : slabLayout.numberOfFlushedSlabDesigns;
    case "GLASS":
      return NUMBER_OF_GLASS_DESIGNS;
    case "COLOR":
      return NUMBER_OF_PAINTS + NUMBER_OF_STAINS;
    case "PAINT":
      return NUMBER_OF_PAINTS;
    case "STAIN":
      return NUMBER_OF_STAINS;
    case "HANDING":
    case "CASING":
      return 4;
    case "PEEP SIGHT":
    case "FINISH":
      return 2;
    default:
      return 35;
  }
}

function getTypeOfSkeletons(type, slabLayout) {
  switch (type.toUpperCase()) {
    case "SLAB LAYOUT":
      return OptionSkeletonType.SLAB_LAYOUT;
    case "LIP-LITE":
    case "OPAQUE":
    case "FLUSHED":
      if (!isEmpty(slabLayout)) {
        if (slabLayout.description === "Single Door") {
          return OptionSkeletonType.SINGLE_DOOR;
        } else if (slabLayout.description === "Left Sidelite Single Door") {
          return OptionSkeletonType.SINGLE_DOOR_LSL;
        } else if (slabLayout.description === "Right Sidelite Single Door") {
          return OptionSkeletonType.SINGLE_DOOR_RSL;
        } else if (slabLayout.description === "2-Sidelite Single Door") {
          return OptionSkeletonType.SINGLE_DOOR_TWO_SL;
        } else if (slabLayout.description === "Double Door") {
          return OptionSkeletonType.DOUBLE_DOOR;
        } else if (slabLayout.description === "Left Sidelite Double Door") {
          return OptionSkeletonType.DOUBLE_DOOR_LSL;
        } else if (slabLayout.description === "Right Sidelite Double Door") {
          return OptionSkeletonType.DOUBLE_DOOR_RSL;
        } else if (slabLayout.description === "2-Sidelite Double Door") {
          return OptionSkeletonType.DOUBLE_DOOR_TWO_SL;
        }
      }
      return OptionSkeletonType.SINGLE_DOOR;
    case "GLASS":
      return OptionSkeletonType.GLASS_DESIGN;
    case "COLOR":
    case "PAINT":
    case "STAIN":
    case "FINISH":
      return OptionSkeletonType.FINISH;
    case "HANDING":
      return OptionSkeletonType.RECTANGLE_BOX;
    case "CASING":
    case "PEEP SIGHT":
      return OptionSkeletonType.SQUARE_BOX;
    default:
      return null;
  }
}

const ImageSelector = (props) => {
  const [openConflict, setOpenConflict] = useState(false);
  const [invalidSelection, setInvalidSelection] = useState({});

  const {
    isLoading,
    isLoadingCompatibility,
    options,
    selected,
    onSelect,
    gridContainerProps = {},
    gridItemProps = {},
    forceExtraSmallIcons,
    imgSizeSx,
    previewButtonProps,
    handleContinue = onSelect,
    optionType = "Option",
    imgRel = "selection-image",
    showDescription,
    slabLayout,
    onDelete,
    showDelete,
    addMargin,
  } = props;

  const validateSelection = (option) => {
    if (option.compatible == null ? false : !option.compatible) {
      setOpenConflict(true);
      setInvalidSelection(option);
    } else {
      if (option.resourceId !== selected.resourceId) {
        onSelect(option);
      }
    }
  };

  const renderOptions =
    isLoading && !isLoadingCompatibility
      ? Array.apply(null, Array(getNumberOfSkeletons(optionType, slabLayout)))
      : options;
  return (
    <Grid
      container
      spacing={2}
      data-test={kebabCase(`${optionType}-list`)}
      {...gridContainerProps}
    >
      {renderOptions.map((option, i) => {
        let isSelected,
          borderColor,
          imageHref,
          getCompatibility,
          getDescription,
          getStyle;
        if (!isLoading || isLoadingCompatibility) {
          isSelected = selected && selected.resourceId === option.resourceId;
          borderColor = isSelected ? "rgba(0, 0, 0, 0)" : "#cfd4da";
          imageHref =
            option.links && option.links.find((link) => link.rel === imgRel)
              ? option.links.find((link) => link.rel === imgRel).href
              : "";
        }

        if (!isLoading && !isLoadingCompatibility) {
          getCompatibility =
            option.compatible == null ? true : option.compatible;
          getDescription =
            optionType === "Peep Sight"
              ? option.description.replace("Peepsight Installed", "")
              : option.description;
          getStyle = option.hasPreview
            ? { display: "block" }
            : { display: "none" };
        }

        return (
          <Grid
            item
            {...gridItemProps}
            key={i}
            sx={{
              ...(props.previewButtonProps && {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }),
            }}
          >
            {isLoading && !isLoadingCompatibility ? (
              <OptionSkeleton
                type={getTypeOfSkeletons(optionType, slabLayout)}
              />
            ) : (
              <>
                <SelectionBox
                  dataTest={
                    option &&
                    option.description &&
                    kebabCase(option.description)
                  }
                  isLoadingCompatibility={isLoadingCompatibility}
                  compatible={getCompatibility}
                  selected={isSelected}
                  onClick={() => validateSelection(option)}
                  forceExtraSmallIcons={forceExtraSmallIcons}
                  toolTipText={option.description}
                  onDelete={() => onDelete(option)}
                  showDelete={showDelete}
                  addMargin={addMargin}
                >
                  <div
                    style={{
                      border: "solid 2px " + borderColor,
                      borderRadius: "3px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {option.name && option.name === "None" && (
                      <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          backgroundColor: "#dee2e6",
                        }}
                      >
                        <CancelIcon
                          style={{
                            width: 75,
                            height: 75,
                            color: "grey",
                          }}
                        />
                      </Grid>
                    )}
                    {!option.finishType ||
                    !option.finishType.includes("PAINT") ? (
                      <Box
                        component="img"
                        sx={imgSizeSx}
                        alt={option.description}
                        src={imageHref}
                        onError={(e) => {
                          e.target.src = NoImage;
                        }}
                      />
                    ) : (
                      <ImageOverlay
                        imgSizeSx={imgSizeSx}
                        imageHref={imageHref}
                        fillColor={`#${option.hexCode}`}
                        option={option}
                      />
                    )}
                  </div>
                </SelectionBox>
                {showDescription && option.description && (
                  <Typography
                    data-test={kebabCase(option.description) + "-description"}
                    sx={(theme) => ({
                      ...(typeof imgSizeSx === "function"
                        ? imgSizeSx(theme)
                        : imgSizeSx),
                      margin: "2px 0 0",
                      height: "auto",
                      lineHeight: "1.5",
                    })}
                    align="center"
                    variant="caption"
                    color="textSecondary"
                  >
                    {getDescription}
                  </Typography>
                )}
                {previewButtonProps.widthUpSmall && (
                  <Button
                    data-test={`${kebabCase(option.description)}-preview-btn`}
                    variant="outlined"
                    style={getStyle}
                    sx={{
                      color: "#495057",
                      backgroundColor: "#d7dbe1",
                      marginTop: "4px",
                      fontSize: "0.7rem",
                      marginLeft: 0,
                    }}
                    onClick={() =>
                      previewButtonProps.onClick(
                        option,
                        option.hiResImage.links[0].href,
                      )
                    }
                  >
                    Preview
                  </Button>
                )}
              </>
            )}
          </Grid>
        );
      })}
      <ConflictDialog
        open={openConflict}
        handleContinue={() => {
          setOpenConflict(false);
          handleContinue(invalidSelection);
        }}
        handleCancel={() => {
          setOpenConflict(false);
        }}
      />
    </Grid>
  );
};

ImageSelector.defaultProps = {
  previewButtonProps: {
    widthUpSmall: false,
    onClick: () => {
      /*Empty function is intentional*/
    },
  },
  showDescription: true,
};

ImageSelector.propTypes = {
  isLoading: PropTypes.bool,
  isLoadingCompatibility: PropTypes.bool,
  gridContainerProps: PropTypes.object,
  gridItemProps: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  forceSmallIcons: PropTypes.bool,
  imgSizeSx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  previewButtonProps: PropTypes.shape({
    widthUpSmall: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      compatible: PropTypes.bool,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          rel: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  optionType: PropTypes.string,
  handleContinue: PropTypes.func,
  imgRel: PropTypes.string,
  showDescription: PropTypes.bool,
  slabLayout: PropTypes.object,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool,
};

export default ImageSelector;
