import React, { Fragment } from "react";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import { BasicTabs, ResultMessage } from "@masonite-digital/common-components";
import ChevronDown from "@mui/icons-material/KeyboardArrowDown";
import ChevronUp from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { amber, red } from "@mui/material/colors";
import TableResults from "./TableResults";
import CopyButton from "../Buttons/CopyButton";
import { ColoredTypography } from "../Doors/payload/ExteriorDoorPayload";
import { googleGenericEvent } from "../../analytics/googleAnalyticsUtility";

const RowCells = (props) => {
  const { row, columns } = props;
  return columns.map(({ id, align = "inherit", formatContent }) => {
    const hasDiscount = Boolean(id === "totalPrice" && row.discount);

    if (formatContent) {
      return (
        <TableCell
          data-test={`column-content-${kebabCase(id)}`}
          key={id}
          align={align}
        >
          {formatContent(row)}
        </TableCell>
      );
    } else {
      return (
        <TableCell
          data-test={`column-content-${kebabCase(id)}`}
          key={id}
          align={align}
          style={hasDiscount ? { paddingRight: "32px" } : {}}
        >
          {hasDiscount ? row.discount : row[id]}
        </TableCell>
      );
    }
  });
};

class SimpleDataTable extends React.Component {
  state = {
    open: [],
  };

  handleClick = (resourceId, orderType) => {
    if (this.props.onRowClick) {
      this.props.onRowClick({ resourceId, orderType });
    } else {
      if (this.state.open.includes(resourceId)) {
        this.setState({
          open: [...this.state.open.filter((id) => id !== resourceId)],
        });
      } else {
        this.setState({
          open: [
            ...this.state.open.filter((id) => id !== resourceId),
            resourceId,
          ],
        });
      }
      googleGenericEvent("Order | Item", "click item");
    }
  };

  render() {
    const {
      columns,
      rows,
      RowActionComponent,
      toolTipText,
      objectSearchName,
      CustomTableResults,
      showCaret,
      dataTest,
      size = "medium",
      isItemsPurchased,
    } = this.props;

    const hasRowActionComponent = Boolean(RowActionComponent);

    return (
      <Paper elevation={0} style={{ width: "100%" }}>
        <Box sx={{ overflowX: "auto" }}>
          <Table
            data-test={dataTest ? dataTest : "order-item-table"}
            size={size}
          >
            {rows.length !== 0 && (
              <TableHead>
                <TableRow>
                  {showCaret && <TableCell padding="none" />}
                  {columns.map(({ align = "inherit", label }) => {
                    return (
                      <TableCell key={label} align={align} padding="normal">
                        <div>{label}</div>
                      </TableCell>
                    );
                  }, this)}
                  {hasRowActionComponent && <TableCell padding="none" />}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <TableResults
                      CustomTableResults={CustomTableResults}
                      hasSearched={false}
                      searchText={toolTipText}
                      objectName={objectSearchName}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row, i) => {
                  const isCustomPart =
                    row.product &&
                    row.product.specifications.some(
                      (spec) => spec.type === "CUSTOM_PART",
                    );
                  const { content, resourceId, rowStyle = {}, orderType } = row;
                  const isIndicatorBar = row.indicatorBar
                    ? `12px solid ${amber[500]}`
                    : "12px solid transparent";
                  return (
                    <Fragment key={resourceId}>
                      <TableRow
                        data-test={`item-row-${i}`}
                        hover
                        style={rowStyle}
                        onClick={() => this.handleClick(resourceId, orderType)}
                        sx={{ cursor: "pointer" }}
                      >
                        {showCaret && (
                          <TableCell
                            padding="none"
                            sx={{
                              borderLeft: row.warningBar
                                ? `12px solid ${red[500]}`
                                : isIndicatorBar,
                            }}
                            data-test="row-open-cell"
                          >
                            <IconButton
                              data-test={`item-caret-${i}`}
                              disableRipple
                              sx={{
                                color: "text.secondary",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                            >
                              {this.state.open.includes(resourceId) ? (
                                <ChevronUp />
                              ) : (
                                <ChevronDown />
                              )}
                            </IconButton>
                          </TableCell>
                        )}
                        <RowCells row={row} columns={columns} />
                        {hasRowActionComponent &&
                          !(isCustomPart && isItemsPurchased) && (
                            <TableCell align="right" padding="none">
                              <RowActionComponent {...row} />
                            </TableCell>
                          )}
                      </TableRow>
                      <TableRow
                        data-test={`row-details-${i}`}
                        style={{ height: 0 }}
                      >
                        <TableCell
                          colSpan={columns.length + 2}
                          sx={(theme) => ({
                            padding: 0,
                            backgroundColor: theme.palette.primary["100"],
                          })}
                        >
                          {isCustomPart ? (
                            <Collapse
                              in={this.state.open.includes(resourceId)}
                              timeout="auto"
                            >
                              <BasicTabs
                                tabs={[
                                  {
                                    label: "DETAILS",
                                    content: (
                                      <Box sx={{ pt: 1 }}>
                                        <ColoredTypography
                                          fontSize="14px"
                                          paddingLeft="8px"
                                          variant="body2"
                                        >
                                          Copy Item Code
                                          <CopyButton
                                            value={
                                              row.product.itemCodes[0].itemCode
                                            }
                                            style={{ marginLeft: "16px" }}
                                          />
                                        </ColoredTypography>
                                        <ResultMessage
                                          type="INFO"
                                          title="Not available"
                                          message="There is no available description for this line item"
                                        />
                                      </Box>
                                    ),
                                  },
                                ]}
                              />
                            </Collapse>
                          ) : (
                            <Collapse
                              in={this.state.open.includes(resourceId)}
                              timeout="auto"
                            >
                              <Box
                                sx={(theme) => ({
                                  borderLeft: `${
                                    theme.spacing(1) - 2
                                  }px solid ${theme.palette.primary["400"]}`,

                                  [theme.breakpoints.down("sm")]: {
                                    width: "90vw",
                                  },
                                })}
                              >
                                {content}
                              </Box>
                            </Collapse>
                          )}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    );
  }
}

SimpleDataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      align: PropTypes.string,
      label: PropTypes.string.isRequired,
      formatContent: PropTypes.func,
    }),
  ).isRequired,
  RowActionComponent: PropTypes.func,
  showCaret: PropTypes.bool,
  isItemsPurchased: PropTypes.bool,
};

export default SimpleDataTable;
