import { push } from "connected-react-router";
import camelCase from "lodash/camelCase";
import concat from "lodash/concat";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import {
  changeTab,
  resetState,
  setAccordionStep,
  setActiveStep,
  setAdaSelection,
  setBrickMoldSelection,
  setClassifierRule,
  setCollapse,
  setCustomColors,
  setDefaultSpecs,
  setDoorAvailability,
  setDoorDetailSelection,
  setDoorSpecOptions,
  setDoorSummary,
  setEditableDoorLoading,
  setFilterSettings,
  setFinishSelection,
  setGlassDesignSelection,
  setHardwareFinishSelection,
  setHardwareSelection,
  setHasSelectionChanged,
  setHingeSelection,
  setMode,
  setPeepSiteSelection,
  setPreviewImage,
  setResetRetiredComponents,
  setRetiredComponents,
  setSideliteSelection,
  setSlabDesignSelection,
  setSlabLayoutSelection,
  setSlide,
  setStoreState,
  startLoadingDoorDetails,
  startLoadingDoorSpecs,
  startLoadingExistingStateCheck,
  startLoadingFilteredOptions,
  startLoadingRecoveryOptions,
  startLoadingSlabDesigns,
} from "./exteriorPrehungActionCreators";
import {
  fetchBrickMold,
  fetchCasings,
  fetchCompatibleDimensions,
  fetchCustomDoorHeights,
  fetchDefaultDoor,
  fetchDimensions,
  fetchFinishes,
  fetchGlassDesigns,
  fetchGlazingTypes,
  fetchHandings,
  fetchHardwares,
  fetchHinges,
  fetchHurricaneRatings,
  fetchImpactRatings,
  fetchInactivePreps,
  fetchIsDoorCovered,
  fetchJambDepths,
  fetchOptionsByFilter,
  fetchPeepSites,
  fetchRoughOpenings,
  fetchSill,
  fetchSlabDesigns,
  fetchSlabLayouts,
} from "./exteriorPrehungFetchActions";
import builderFunctions from "../../activities/ProductBuilders/common/builderFunctions";

/**
 *
 * Finds an object through the provided array based on an arranged key for the objectList.
 *
 * @param {Array} array
 * @param {Object} objectList
 * @returns {Object|{}}
 */
function findOneObject(array, objectList) {
  return (
    array.find(
      (option) => option.value === objectList[camelCase(option.type)],
    ) || {}
  );
}

/**
 *
 * Filters through the provided array and arranges a key for the objectList to check if its value is true.
 *
 * @param {Array} array
 * @param {Object} objectList
 * @returns {Array|[]}
 */
export function findValuesToArray(array, objectList) {
  return array.filter(
    (option) =>
      objectList[camelCase(option.type + " " + option.value)] === true,
  );
}

/**
 * Load user classifier rules for UI.
 *
 * @param classifiers
 * @returns map of classifier rules are true
 */
export function loadClassifierRules(classifiers) {
  let rules = {};
  if (!isEmpty(classifiers)) {
    if (
      classifiers.findIndex(
        (classifier) => classifier.tag === "hvhz:non-hvhz-x",
      ) !== -1
    ) {
      rules.restrictHurricaneRatedOnly = true;
    }
  }
  return rules;
}

/**
 *
 * Goes through the array and adds the created key, based on the option's type and value, to the filterKeys object.
 *
 * @param {Array} array
 * @returns {Object}
 */
function createFilterSettingObject(array) {
  const filterKeys = {};
  array.forEach((option) => {
    filterKeys[camelCase(option.type + " " + option.value)] = false;
  });
  return filterKeys;
}

function createPreviewImage(state) {
  const selectedFinish = state.exteriorPrehung.selections.finish;
  const selectedSidelite = state.exteriorPrehung.selections.sideliteGlassDesign;
  const panelValue = state.exteriorPrehung.selections.slabDesign;

  try {
    let foundExterior =
      panelValue.links.length === 0
        ? ""
        : panelValue.links.find(
            (link) =>
              link.rel === "front-preview-image" &&
              link.type &&
              link.type ===
                (panelValue.hasSideliteGlass
                  ? selectedFinish.doorExterior.resourceId +
                    selectedSidelite.resourceId
                  : selectedFinish.doorExterior.resourceId),
          );
    const frontImage = foundExterior ? foundExterior.href : "";

    let foundInterior =
      panelValue.links.length === 0
        ? ""
        : panelValue.links.find(
            (link) =>
              link.rel === "back-preview-image" &&
              link.type &&
              link.type ===
                (panelValue.hasSideliteGlass
                  ? selectedFinish.doorInterior.resourceId +
                    selectedSidelite.resourceId
                  : selectedFinish.doorInterior.resourceId),
          );
    const backImage = foundInterior ? foundInterior.href : "";

    return {
      frontImage,
      backImage,
    };
  } catch (e) {
    console.log("error: ", e);
    return {
      frontImage: "",
      backImage: "",
    };
  }
}

/**
 *
 * Reset's the door builder and sets the default door specs to the previous selections in order to
 * recover the previous door configuration.
 *
 * prevProperties: {
 *    prevPropertiesSLABDESIGN: selections.slabDesign,
 *    SLABLAYOUT: selections.slabLayout,
 *    GLASSDESIGN: selections.glassDesign,
 *    FINISH: selections.exteriorFinish,
 *    SLABWIDTH: selections.slabWidth,
 *    SLABHEIGHT: selections.slabHeight,
 *    BRICKMOLD: selections.brickMold,
 *    HARDWAREPART: selections.hardware,
 *    HANDING: selections.handing,
 *    GLAZINGTYPE: selections.glazingType,
 * }
 *
 * @param prevProperties
 * @returns {Function}
 */
export function setPreviousSelections(prevProperties) {
  return async (dispatch, getState) => {
    const originalState = getState().exteriorPrehung;
    try {
      dispatch(resetState());
      dispatch(startLoadingRecoveryOptions(true));

      // Set the default specs to the previous door selection
      dispatch(setDefaultSpecs(prevProperties));

      if (prevProperties.sideliteGlassDesign) {
        dispatch(setSideliteSelection(prevProperties.sideliteGlassDesign));
      }

      // Fetches all the options in the first step
      await dispatch(existingStateCheck());
    } catch (e) {
      console.error(e);
      dispatch(setStoreState(originalState));
    } finally {
      dispatch(startLoadingRecoveryOptions(false));
    }
  };
}

export function clearState() {
  return async (dispatch, getState) => {
    try {
      const classifierRules =
        getState().exteriorPrehung.doorViewOptions.classifierRules;
      dispatch(resetState());
      await dispatch(existingStateCheck(classifierRules));
    } catch (e) {
      console.error(e);
    }
  };
}

/**
 *
 * Fetches the defaultSpecs and selectionOptions for step 1:
 *
 * fetchDefaultDoor
 * loadFilterSpecs
 * fetchFinishes
 * fetchSlabLayouts
 * fetchSlabDesigns
 * fetchGlassDesigns
 * setPreviewImage
 *
 * @returns {Function}
 */
export function existingStateCheck(classifierRules) {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingExistingStateCheck(true));
      if (isEmpty(getState().exteriorPrehung.defaultSpecs)) {
        await dispatch(fetchDefaultDoor());
      }

      const options = getState().exteriorPrehung.selectionOptions;

      if (!isEmpty(getState().exteriorPrehung.defaultSpecs)) {
        if (
          isEmpty(options.doorSpecs.dimensions.slabHeights) ||
          isEmpty(options.doorSpecs.hurricaneRatings) ||
          isEmpty(options.doorSpecs.impactRatings)
        ) {
          await dispatch(loadFilterSpecs());
        }
        if (
          isEmpty(options.finishes.PAINT) ||
          isEmpty(options.finishes.WEATHERSTRIP)
        ) {
          dispatch(fetchFinishes());
        }
        if (isEmpty(options.slabLayouts)) {
          await dispatch(fetchSlabLayouts());
        }
        if (isEmpty(options.slabDesigns)) {
          await dispatch(fetchSlabDesigns());
        }
        if (isEmpty(options.glassDesigns)) {
          await dispatch(fetchGlassDesigns());
        }

        if (!isEmpty(classifierRules)) {
          if (
            classifierRules.restrictHurricaneRatedOnly &&
            !getState().exteriorPrehung.doorViewOptions.mode.isInEditMode
          ) {
            await dispatch(
              filterOptions({
                hurricaneRated: true,
                impactRated: true,
              }),
            );
          }
          await dispatch(setClassifierRule(classifierRules));
        }

        dispatch(setPreviewImage(createPreviewImage(getState())));
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(startLoadingExistingStateCheck(false));
    }
  };
}

export function loadStep2Data() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingDoorDetails(true));

      const options = getState().exteriorPrehung.selectionOptions;
      const selections = getState().exteriorPrehung.selections;

      // If initial data load, set selection changes to false.
      if (
        isEmpty(options.doorSpecs.handings) ||
        isEmpty(options.doorSpecs.jambDepths)
      ) {
        dispatch(
          setHasSelectionChanged({
            hasSlabLayoutChanged: false,
            hasSlabDesignChanged: false,
            hasGlassDesignChanged: false,
            hasFilterChanged: false,
          }),
        );
      }
      if (isEmpty(options.doorSpecs.cutDownDoorHeights)) {
        dispatch(fetchCustomDoorHeights());
      }
      if (isEmpty(options.doorSpecs.handings)) {
        dispatch(fetchHandings());
      }
      if (isEmpty(options.casings)) {
        dispatch(fetchCasings());
      }
      if (isEmpty(options.brickMolds)) {
        dispatch(fetchBrickMold());
      }
      if (isEmpty(options.doorSpecs.jambDepths)) {
        dispatch(fetchJambDepths());
      }
      if (isEmpty(options.doorSpecs.doorCovered)) {
        dispatch(fetchIsDoorCovered());
      }
      if (isEmpty(options.doorSpecs.inactivePreps)) {
        dispatch(fetchInactivePreps());
      }
      if (isEmpty(options.dimensions) || isEmpty(selections.slabHeight)) {
        await dispatch(fetchCompatibleDimensions());
      }
      if (isEmpty(getState().exteriorPrehung.roughOpeningTable)) {
        dispatch(fetchRoughOpenings());
      }
      if (isEmpty(options.hardwares)) {
        await dispatch(fetchHardwares());
      }
      if (isEmpty(options.peepSites)) {
        dispatch(fetchPeepSites());
      }
      if (isEmpty(options.hinges)) {
        await dispatch(fetchHinges());
      }
      if (isEmpty(options.sill)) {
        dispatch(fetchSill(selections));
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(startLoadingDoorDetails(false));
    }
  };
}

/**
 *
 * Sets the selectionOptions for HurricaneRatings, ImpactRatings, GlazingTypes, and Dimensions for the filter settings
 * and sets the selections in step 2 based on the default specs for:
 * hurricaneRated, impactRated, glazingType, slabWidth, slabHeight, and sideLiteWidth.
 *
 * This function is mainly intended to run on the first initial load of step 1 for the filter drawer.
 *
 * @returns {Function}
 */
export function loadFilterSpecs() {
  return async (dispatch, getState) => {
    try {
      const selectedSlab =
        getState().exteriorPrehung.selections.slabLayout ||
        getState().exteriorPrehung.defaultSpecs.SLABLAYOUT;

      dispatch(startLoadingDoorSpecs(true));

      const dimensions = await dispatch(fetchDimensions(selectedSlab));
      const glazingTypes = await dispatch(fetchGlazingTypes());
      const impactRatings = await dispatch(fetchImpactRatings());
      const hurricaneRatings = await dispatch(fetchHurricaneRatings());

      dispatch(
        setDoorSpecOptions({
          dimensions,
          glazingTypes,
          impactRatings,
          hurricaneRatings,
        }),
      );

      // Step 2 selections
      const mode = getState().exteriorPrehung.doorViewOptions.mode;
      if (!mode.isInEditMode && !mode.isConsumerLead) {
        const defaultValues = getState().exteriorPrehung.defaultSpecs;
        dispatch(
          setDoorDetailSelection({
            hurricaneRated: hurricaneRatings.find(
              (option) => option.value === false,
            ),
            impactRated: impactRatings.find((option) => option.value === false),
            glazingType: glazingTypes.find(
              (option) =>
                option.resourceId === defaultValues.GLAZINGTYPE.resourceId,
            ),
            slabWidth: dimensions.slabWidths.find(
              (option) =>
                option.resourceId === defaultValues.SLABWIDTH.resourceId,
            ),
            slabHeight: dimensions.slabHeights.find(
              (option) =>
                option.resourceId === defaultValues.SLABHEIGHT.resourceId,
            ),
            sideLiteWidth: defaultValues.SIDELITEWIDTH
              ? dimensions.sideLiteWidths.find(
                  (option) =>
                    option.resourceId ===
                    defaultValues.SIDELITEWIDTH.resourceId,
                )
              : dimensions.sideLiteWidths[0],
          }),
        );
      } else {
        const selections = getState().exteriorPrehung.selections;
        if (isEmpty(selections.hurricaneRated)) {
          dispatch(
            setDoorDetailSelection({
              hurricaneRated: hurricaneRatings.find(
                (option) => option.value === false,
              ),
            }),
          );
        }
        if (isEmpty(selections.impactRated)) {
          dispatch(
            setDoorDetailSelection({
              impactRated: impactRatings.find(
                (option) => option.value === false,
              ),
            }),
          );
        }
      }

      const filterKeys = createFilterSettingObject(
        concat(
          dimensions.slabWidths,
          dimensions.slabHeights,
          dimensions.sideLiteWidths,
        ),
      );

      dispatch(
        setFilterSettings({
          hurricaneRated: false,
          impactRated: false,
          ...filterKeys,
        }),
      );
    } catch (error) {
      console.error("error", error);
    } finally {
      dispatch(startLoadingDoorSpecs(false));
    }
  };
}

export function filterOptions(properties, type) {
  return async (dispatch, getState) => {
    dispatch(startLoadingFilteredOptions(true));
    dispatch(setFilterSettings(properties));
    const filterSettings = getState().exteriorPrehung.filterSettings;
    const options = getState().exteriorPrehung.selectionOptions;

    const foundHurricaneRating = findOneObject(
      options.doorSpecs.hurricaneRatings,
      filterSettings,
    );
    const foundImpactRating = findOneObject(
      options.doorSpecs.impactRatings,
      filterSettings,
    );
    const foundHeights = findValuesToArray(
      options.doorSpecs.dimensions.slabHeights,
      filterSettings,
    );
    const foundWidths = findValuesToArray(
      options.doorSpecs.dimensions.slabWidths,
      filterSettings,
    );
    const foundSideliteWidths = findValuesToArray(
      options.doorSpecs.dimensions.sideLiteWidths,
      filterSettings,
    );

    // Pre-select hurricane rating, impact rating, and dimensions for step #2
    dispatch(
      setDoorDetailSelection({
        hurricaneRated: foundHurricaneRating,
        impactRated: foundImpactRating,
      }),
    );

    if (foundHeights.length > 0) {
      dispatch(
        setDoorDetailSelection({
          slabHeight: foundHeights[0],
        }),
      );
    }

    if (foundWidths.length > 0) {
      dispatch(
        setDoorDetailSelection({
          slabWidth: foundWidths[0],
        }),
      );
    }

    if (foundSideliteWidths.length > 0) {
      dispatch(
        setDoorDetailSelection({
          sideLiteWidth: foundSideliteWidths[0],
        }),
      );
    }
    dispatch(setHasSelectionChanged({ hasFilterChanged: true }));

    // Creating filter selections to send to the backend.
    let hurricaneRatings, impactRatings;

    if (foundHurricaneRating.value) {
      hurricaneRatings = [foundHurricaneRating];
    } else {
      hurricaneRatings = options.doorSpecs.hurricaneRatings;
    }
    if (foundImpactRating.value) {
      impactRatings = [foundImpactRating];
    } else {
      impactRatings = options.doorSpecs.impactRatings;
    }

    let dimensions = foundHeights.concat(foundWidths);

    if (
      getState().exteriorPrehung.selections.slabLayout.hasSidelite &&
      foundSideliteWidths.length !== 0
    ) {
      dimensions = dimensions.concat(foundSideliteWidths);
    }
    await dispatch(
      fetchOptionsByFilter(hurricaneRatings, impactRatings, dimensions, type),
    );

    if (!isEmpty(getState().exteriorPrehung.selections.cutDownDoorHeight)) {
      dispatch(setDoorDetailSelection({ cutDownDoorHeight: null }));
    }

    await dispatch(setPreviewImage(createPreviewImage(getState())));
    await dispatch(startLoadingFilteredOptions(false));
  };
}

/**
 * This method handles ONLY step 1 selections.
 * SLAB_LAYOUT
 * SLAB_DESIGN
 * GLASS_DESIGN
 * FINISH
 *
 * @param type
 * @param option
 * @returns {Function}
 */
export function reloadOptionsOnSelect(type, option) {
  return async (dispatch, getState) => {
    const options = getState().exteriorPrehung.selectionOptions;
    let filterSettings = getState().exteriorPrehung.filterSettings;
    let selections = getState().exteriorPrehung.selections;
    const foundHurricaneRating = findOneObject(
      options.doorSpecs.hurricaneRatings,
      filterSettings,
    );
    const foundImpactRating = findOneObject(
      options.doorSpecs.impactRatings,
      filterSettings,
    );
    let foundWidths = findValuesToArray(
      options.doorSpecs.dimensions.slabWidths,
      filterSettings,
    );
    let foundHeights = findValuesToArray(
      options.doorSpecs.dimensions.slabHeights,
      filterSettings,
    );

    let dimensions = foundWidths.concat(foundHeights);
    if (getState().exteriorPrehung.selections.slabLayout.hasSidelite) {
      const foundSideliteWidths = findValuesToArray(
        options.doorSpecs.dimensions.sideLiteWidths,
        filterSettings,
      );

      dimensions = dimensions.concat(foundSideliteWidths);
    }
    try {
      switch (type) {
        case "SLAB_LAYOUT": {
          dispatch(startLoadingFilteredOptions(true));
          dispatch(startLoadingSlabDesigns(true));
          dispatch(
            setHasSelectionChanged({
              hasAnySelectionChanged: true,
              hasChangedFromDefault: true,
              stepTwoComplete: false,
              stepThreeComplete: false,
            }),
          );

          // Must re-fetch dimensions on slab layout change
          const dimensionOptions = await dispatch(fetchDimensions(option));
          dispatch(setDoorSpecOptions({ dimensions: dimensionOptions }));
          dispatch(setSlabLayoutSelection(option));

          const filterKeys = createFilterSettingObject(
            concat(
              dimensionOptions.slabWidths,
              dimensionOptions.slabHeights,
              dimensionOptions.sideLiteWidths,
            ),
          );
          // Adjust filter selections when changing SlabLayout
          const originalFilterSettings =
            getState().exteriorPrehung.filterSettings;

          dimensionOptions.slabWidths.forEach((width) => {
            const foundMatchingWidth = Object.keys(originalFilterSettings).find(
              (key) =>
                originalFilterSettings[key] &&
                (key.includes(width.value) ||
                  key.includes((width.value * 2).toString()) ||
                  key.includes((width.value / 2).toString())),
            );
            if (foundMatchingWidth) {
              filterKeys[camelCase(width.type + " " + width.value)] = true;
            }
          });

          dimensionOptions.slabHeights.forEach((height) => {
            const foundMatchingHeight = Object.keys(
              originalFilterSettings,
            ).find(
              (key) =>
                originalFilterSettings[key] && key.includes(height.value),
            );

            if (foundMatchingHeight) {
              filterKeys[camelCase(height.type + " " + height.value)] = true;
            }
          });

          dispatch(
            setFilterSettings(
              {
                hurricaneRated: filterSettings.hurricaneRated,
                impactRated: filterSettings.impactRated,
                ...filterKeys,
              },
              true,
            ),
          );
          // Fetches Slab Designs and Glass Designs based on new Slab Layout
          await dispatch(
            filterOptions(getState().exteriorPrehung.filterSettings),
          );
          dispatch(setPreviewImage(createPreviewImage(getState())));
          break;
        }
        case "SLAB_DESIGN": {
          dispatch(setSlabDesignSelection(option));
          dispatch(
            setHasSelectionChanged({
              hasAnySelectionChanged: true,
              hasChangedFromDefault: true,
              stepTwoComplete: false,
              stepThreeComplete: false,
            }),
          );
          //Set glazingType
          const glazingType = get(
            getState().exteriorPrehung.selectionOptions,
            "doorSpecs.glazingTypes",
            [],
          ).find((glaze) => glaze.value === option.glazingType);

          if (glazingType) {
            dispatch(setDoorDetailSelection({ glazingType }));
          }

          //Set hurricaneRated option to false if new option is non-ratable
          if (selections.hurricaneRated.value && !option.rateable) {
            dispatch(
              setDoorDetailSelection({
                hurricaneRated: options.doorSpecs.hurricaneRatings.find(
                  (ratings) => ratings.value === false,
                ),
              }),
            );
          }

          //Reload on selecting an incompatible Slab Design
          if (!option.compatible) {
            await dispatch(
              filterOptions(getState().exteriorPrehung.filterSettings),
            ); //fetches Slab Designs and Glass Designs
          } else {
            await dispatch(
              fetchGlassDesigns(
                {
                  SLABLAYOUT: selections.slabLayout,
                  SLABDESIGN: option,
                  IMPACTRATED: foundImpactRating,
                },
                dimensions,
              ),
            );
            await dispatch(
              fetchSlabLayouts({
                GLASSDESIGN: selections.glassDesign,
                SLABDESIGN: option,
              }),
            );
          }

          dispatch(setPreviewImage(createPreviewImage(getState())));
          break;
        }
        case "GLASS_DESIGN": {
          dispatch(setGlassDesignSelection(option));
          dispatch(
            setHasSelectionChanged({
              hasAnySelectionChanged: true,
              hasChangedFromDefault: true,
              stepTwoComplete: false,
              stepThreeComplete: false,
            }),
          );

          const hasGlass = option.description !== "No Glass";
          const compatibleSidelites = option.sideLiteGlassDesign;
          const selectedSidelite =
            compatibleSidelites.find(
              (sidelite) =>
                //Currently the default sidelite glass design is Naples for doors with no glass.
                sidelite.description ===
                (hasGlass ? option.description : "Naples"),
            ) || compatibleSidelites[0];
          dispatch(setSideliteSelection(selectedSidelite));

          if (
            selections.impactRated.value &&
            (selectedSidelite?.rateable === false || option?.rateable === false)
          ) {
            dispatch(
              setDoorDetailSelection({
                hurricaneRated: { ...selections.hurricaneRated, value: false },
              }),
            );
            dispatch(
              setDoorDetailSelection({
                impactRated: { ...selections.impactRated, value: false },
              }),
            );
          }

          // If glassImpactRatedOnly is true -> ratable always is true. If ratable is false -> glassImpactRatedOnly always is false
          if (
            (option.glassImpactRatedOnly && !selections.impactRated.value) ||
            (!option.rateable &&
              selections.impactRated.value &&
              !selections.hurricaneRated.value &&
              !filterSettings.impactRated &&
              !filterSettings.hurricaneRated)
          ) {
            dispatch(
              setDoorDetailSelection({
                impactRated: options.doorSpecs.impactRatings.find(
                  (impactRating) =>
                    impactRating.value === option.glassImpactRatedOnly,
                ),
              }),
            );
          }

          //Reload on selecting an incompatible Glass Design
          if (!option.compatible) {
            await dispatch(
              filterOptions(
                getState().exteriorPrehung.filterSettings,
                "GLASS_DESIGN",
              ),
            ); //fetches Slab Designs and Glass Designs
          } else {
            await dispatch(
              fetchSlabDesigns(
                {
                  SLABLAYOUT: selections.slabLayout,
                  GLASSDESIGN: option,
                  HURRICANERATED: foundHurricaneRating,
                  IMPACTRATED: foundImpactRating,
                },
                dimensions,
              ),
            );

            await dispatch(
              fetchSlabLayouts({
                GLASSDESIGN: option,
                SLABDESIGN: selections.slabDesign,
              }),
            );
          }
          dispatch(setPreviewImage(createPreviewImage(getState())));
          break;
        }
        case "SIDE_LITE_GLASS_DESIGN": {
          dispatch(setSideliteSelection(option));
          dispatch(setPreviewImage(createPreviewImage(getState())));
          if (selections.impactRated.value && !option.rateable) {
            dispatch(
              setDoorDetailSelection({
                hurricaneRated: { ...selections.hurricaneRated, value: false },
              }),
            );
            dispatch(
              setDoorDetailSelection({
                impactRated: { ...selections.impactRated, value: false },
              }),
            );
          }
          break;
        }
        case "FINISH": {
          dispatch(
            setFinishSelection({
              doorExterior: option,
              doorInterior: option,
              frameExterior: option,
              frameInterior: option,
              casingColor: option,
            }),
          );
          dispatch(
            setHasSelectionChanged({
              hasAnySelectionChanged: true,
              hasChangedFromDefault: true,
              stepTwoComplete: false,
              stepThreeComplete: false,
            }),
          );

          dispatch(setPreviewImage(createPreviewImage(getState())));
          break;
        }
        default:
          break;
      }
    } catch (e) {
      console.error(e.message);
    }
  };
}

export function goToStep(type) {
  return async (dispatch, getState) => {
    switch (type) {
      case "SLAB_DESIGN":
        dispatch(setActiveStep(0));
        dispatch(changeTab(0));
        dispatch(setSlide(true));
        break;
      case "GLASS_DESIGN":
        dispatch(setActiveStep(0));
        dispatch(changeTab(1));
        dispatch(setSlide(true));
        break;
      case "FINISH":
        dispatch(setActiveStep(1));
        dispatch(setAccordionStep(1));
        break;
      case "DETAILS":
        dispatch(setActiveStep(1));
        dispatch(setAccordionStep(0));
        break;
      case "HARDWARE":
        dispatch(setActiveStep(1));
        dispatch(setAccordionStep(2));
        break;
      case "ADD_ONS":
        dispatch(setActiveStep(1));
        dispatch(setAccordionStep(3));
        break;
      default:
        break;
    }
  };
}

export function navigateTo(path) {
  return async (dispatch) => {
    dispatch(push(path));
  };
}

/**
 * Reloads the door builder with existing data from an order item.
 *
 * @param orderResourceId
 * @param orderItemResourceId
 * @param classifierRules
 * @param returnToDefault
 * @returns {Function}
 */
export function editDoor(
  orderResourceId,
  orderItemResourceId,
  classifierRules,
  returnToDefault = false,
) {
  return async (dispatch, getState) => {
    try {
      let quoteData = getState().exteriorPrehung.doorViewOptions.mode;
      let quoteItem = quoteData.quoteItem;

      dispatch(resetState());
      dispatch(setEditableDoorLoading(true));
      dispatch(setActiveStep(2));
      dispatch(setSlide(false));

      if (!returnToDefault) {
        quoteData = await builderFunctions.getQuote(orderResourceId);
        quoteItem = quoteData.items.find(
          (item) => item.resourceId === orderItemResourceId,
        );
      }

      dispatch(
        setMode({
          isInEditMode: true,
          name: quoteData.name,
          referenceNumber: quoteData.referenceNumber,
          orderResourceId: orderResourceId,
          orderItemResourceId: orderItemResourceId,
          companyResourceId: quoteData.company
            ? quoteData.company.resourceId
            : quoteData.companyResourceId,
          quoteItem,
        }),
      );

      dispatch(
        setDoorSummary({
          resource: {
            ...quoteItem.product,
            price: quoteItem.price.price,
          },
        }),
      );
      dispatch(setDoorAvailability(true));

      quoteItem.product.specifications.forEach((spec) => {
        switch (spec.type) {
          case "SLAB_LAYOUT":
            dispatch(setSlabLayoutSelection(spec));
            break;
          case "SLAB_DESIGN":
            dispatch(setSlabDesignSelection(spec));
            break;
          case "GLASS_DESIGN":
            dispatch(setGlassDesignSelection(spec));
            break;
          case "HURRICANE_RATED":
            dispatch(setDoorDetailSelection({ hurricaneRated: spec }));
            break;
          case "IMPACT_RATED":
            dispatch(setDoorDetailSelection({ impactRated: spec }));
            break;
          case "GLAZING_TYPE":
            dispatch(setDoorDetailSelection({ glazingType: spec }));
            break;
          case "SLAB_WIDTH":
            dispatch(setDoorDetailSelection({ slabWidth: spec }));
            break;
          case "SLAB_HEIGHT":
            dispatch(setDoorDetailSelection({ slabHeight: spec }));
            break;
          case "SIDE_LITE_WIDTH":
            dispatch(setDoorDetailSelection({ sideLiteWidth: spec }));
            break;
          case "SIDE_LITE_GLASS_DESIGN":
            dispatch(setDoorDetailSelection({ sideliteGlassDesign: spec }));
            break;
          case "CUT_DOWN_HEIGHT":
            dispatch(setDoorDetailSelection({ cutDownDoorHeight: spec }));
            break;
          case "JAMB_DEPTH":
            dispatch(setDoorDetailSelection({ jambDepth: spec }));
            break;
          case "HANDING":
            dispatch(setDoorDetailSelection({ handing: spec }));
            break;
          case "INACTIVE_PREP":
            dispatch(setDoorDetailSelection({ inactivePrep: spec }));
            break;
          case "DOOR_COVERED":
            dispatch(setDoorDetailSelection({ isDoorCovered: spec }));
            break;
          case "HARDWARE_PART":
            dispatch(setHardwareSelection(spec));
            break;
          case "SLAB_FINISH":
            dispatch(
              setFinishSelection({
                doorExterior: spec.exterior,
                doorInterior: spec.interior,
                frameExterior: spec.exteriorFrame,
                frameInterior: spec.interiorFrame,
                weatherStripping: spec.weatherstrip,
              }),
            );
            let specTypes = [
              spec.exterior,
              spec.interior,
              spec.exteriorFrame,
              spec.interiorFrame,
              spec.weatherstrip,
            ];
            let filteredSpecTypes = specTypes.filter(
              (x) => x.finishType === "CUSTOM_PAINT",
            );
            dispatch(addColors(filteredSpecTypes));
            break;
          case "CASING":
            dispatch(setDoorDetailSelection({ casing: spec }));
            dispatch(setFinishSelection({ casingColor: spec.finish }));
            break;
          case "BRICK_MOLD":
            dispatch(setBrickMoldSelection(spec));
            break;
          case "PEEP_SITE":
            dispatch(setPeepSiteSelection(spec));
            dispatch(setAdaSelection(spec.ada));
            break;
          case "FINISH":
            dispatch(setHingeSelection(spec));
            break;
          default:
            console.log("Uncaught Type: ", spec.type);
            break;
        }
      });

      await dispatch(existingStateCheck(classifierRules));
      await dispatch(loadStep2Data());

      // We need to load step 1 & 2 data to know what options are available before we can parse through and figure out what is invalid
      if (quoteItem.retired) {
        await dispatch(checkForRetiredComponents());
      }

      dispatch(setCollapse(true));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setEditableDoorLoading(false));
    }
  };
}

export function checkForRetiredComponents() {
  return async (dispatch, getState) => {
    const { selectionOptions, selections } = getState().exteriorPrehung;
    if (
      (selectionOptions.dimensions.slabHeights.length === 0 ||
        selectionOptions.dimensions.slabWidths.length === 0) &&
      selections.hurricaneRated.value
    ) {
      dispatch(
        setRetiredComponents({ hurricaneRated: selections.hurricaneRated }),
      );
    } else {
      if (selectionOptions.dimensions.slabHeights) {
        const hasValidSlabHeight = selectionOptions.dimensions.slabHeights.some(
          (height) => height.resourceId === selections.slabHeight.resourceId,
        );

        if (!hasValidSlabHeight) {
          dispatch(setRetiredComponents({ slabHeight: selections.slabHeight }));
        }
      }
      if (selectionOptions.dimensions.slabWidths) {
        const hasValidSlabWidth = selectionOptions.dimensions.slabWidths.some(
          (width) => width.resourceId === selections.slabWidth.resourceId,
        );

        if (!hasValidSlabWidth) {
          dispatch(setRetiredComponents({ slabWidth: selections.slabWidth }));
        }
      }
      if (
        selectionOptions.doorSpecs.cutDownDoorHeights &&
        selections.cutDownDoorHeight.resourceId
      ) {
        const hasValidCutDown =
          selectionOptions.doorSpecs.cutDownDoorHeights.some(
            (height) =>
              height.resourceId === selections.cutDownDoorHeight.resourceId,
          );

        if (!hasValidCutDown) {
          dispatch(
            setRetiredComponents({
              cutDownDoorHeight: selections.cutDownDoorHeight,
            }),
          );
        }
      }
      if (
        selections.impactRated.value &&
        selections.sideliteGlassDesign.resourceId ===
          "83e8b25d-04c4-4348-9796-4e08d345d0ad"
      ) {
        dispatch(
          setRetiredComponents({
            sideliteGlassDesign: selections.sideliteGlassDesign,
          }),
        );
      }
    }

    let hasValidHardware = false;
    let isDiscontinuedSchlageDeadbolt = false;
    if (selectionOptions.hardwares.length > 0) {
      selectionOptions.hardwares.forEach((hardware) => {
        if (
          hardware.part.resourceId === selections.hardware.resourceId &&
          hardware.finishes.some(
            (finish) =>
              finish.resourceId === selections.hardware.finish.resourceId,
          )
        ) {
          hasValidHardware = true;
        }
        if (
          selections.hardware.resourceId ===
          "2f9c297e-f28b-476d-9558-7b99414e9cfd"
        ) {
          isDiscontinuedSchlageDeadbolt = true;
        }
      });
      if (!hasValidHardware) {
        dispatch(setRetiredComponents({ hardware: selections.hardware }));
      }
      if (isDiscontinuedSchlageDeadbolt) {
        dispatch(setRetiredComponents({ hardware: selections.hardware }));
      }
    }

    if (selectionOptions.finishes.WEATHERSTRIP) {
      const hasValidWeatherstrip = selectionOptions.finishes.WEATHERSTRIP.some(
        (finish) =>
          finish.resourceId === selections.finish.weatherStripping.resourceId,
      );

      if (!hasValidWeatherstrip) {
        dispatch(
          setRetiredComponents({
            weatherStripping: selections.finish.weatherStripping,
          }),
        );
      }
    }

    let peepSiteComponent = selections.peepSite;

    if (
      (selections.slabDesign.description.includes("3P Heritage Craftsman") ||
        selections.slabDesign.description.includes(
          "3 Panel Heritage Craftsman",
        ) ||
        selections.slabDesign.description.includes("2P Cheyenne") ||
        selections.slabDesign.description.includes("2 Panel Cheyenne")) &&
      peepSiteComponent &&
      peepSiteComponent.resourceId != null &&
      peepSiteComponent.resourceId !== "68c8f49b-5efa-4ad0-83a2-1e09d8961e50"
    ) {
      dispatch(setRetiredComponents({ peepSite: peepSiteComponent }));
    }

    // Check if Satin Nickel is the being used for Hinge
    if (
      selections.hinge.resourceId === "26607d76-dc62-41d4-8c64-1656f5952b9e"
    ) {
      dispatch(setRetiredComponents({ hinge: selections.hinge }));
    }
  };
}

export function resetRetiredComponentsToDefault() {
  return async (dispatch, getState) => {
    const { retiredComponents, selectionOptions, selections } =
      getState().exteriorPrehung;
    let newDimension = false;
    if (retiredComponents.hurricaneRated) {
      const nonHvhz = selectionOptions.doorSpecs.hurricaneRatings.find(
        (h) => h.value === false,
      );
      await dispatch(setDoorDetailSelection({ hurricaneRated: nonHvhz }));
      await dispatch(
        setHasSelectionChanged({
          hasAnySelectionChanged: true,
          hasChangedFromDefault: true,
        }),
      );
      await dispatch(fetchCompatibleDimensions());
      newDimension = true;
      selectionOptions.dimensions =
        getState().exteriorPrehung.selectionOptions.dimensions;
    }
    if (retiredComponents.slabHeight || newDimension) {
      let defaultHeight = selectionOptions.dimensions.slabHeights.find(
        (slabHeight) =>
          slabHeight.resourceId === selections.slabHeight.resourceId ||
          slabHeight.resourceId === "7403181c-cbbe-4e81-8464-f68ee753528d",
      );
      if (defaultHeight === undefined) {
        defaultHeight = selectionOptions.dimensions.slabHeights[0];
      }
      await dispatch(setDoorDetailSelection({ slabHeight: defaultHeight }));

      await dispatch(
        setHasSelectionChanged({
          hasAnySelectionChanged: true,
          hasChangedFromDefault: true,
        }),
      );
    }
    if (retiredComponents.slabWidth || newDimension) {
      let defaultWidth = selectionOptions.dimensions.slabWidths.find(
        (slabWidth) =>
          slabWidth.resourceId === selections.slabWidth.resourceId ||
          slabWidth.resourceId === "e9d47605-f981-4589-88fd-3c8c6846ed9b",
      );
      if (defaultWidth === undefined) {
        defaultWidth = selectionOptions.dimensions.slabWidths[0];
      }
      await dispatch(setDoorDetailSelection({ slabWidth: defaultWidth }));

      await dispatch(
        setHasSelectionChanged({
          hasAnySelectionChanged: true,
          hasChangedFromDefault: true,
        }),
      );
    }
    if (retiredComponents.hardware || retiredComponents.weatherStripping) {
      if (
        retiredComponents.hardware?.resourceId ===
        "2f9c297e-f28b-476d-9558-7b99414e9cfd"
      ) {
        const schlageEncodeDeadbolt = selectionOptions.hardwares.find(
          (hardware) =>
            hardware.description ===
            "Ares w/ Electric Deadbolt, Poseidon Lever",
        );
        const previousFinishSelection = schlageEncodeDeadbolt.finishes.find(
          (finish) =>
            finish.resourceId === selections.hardware.finish.resourceId,
        );
        await dispatch(
          setDoorDetailSelection({ hardware: schlageEncodeDeadbolt.part }),
        );
        await dispatch(setHardwareFinishSelection(previousFinishSelection));
      } else {
        const defaultHardware = selectionOptions.hardwares.find(
          (hardware) =>
            hardware.part.resourceId === "14fb2630-42ee-401c-9f18-a9469adb8c39",
        ).part;
        await dispatch(setDoorDetailSelection({ hardware: defaultHardware }));
      }
      if (retiredComponents.weatherStripping) {
        const defaultWeatherstripping =
          selectionOptions.finishes.WEATHERSTRIP.find(
            (finish) =>
              finish.resourceId === "a058f72b-f19e-417f-8305-5c265a18754c",
          );
        await dispatch(
          setDoorDetailSelection({
            finish: {
              ...selections.finish,
              weatherStripping: defaultWeatherstripping,
            },
          }),
        );
      }

      await dispatch(fetchSill());
      await dispatch(fetchPeepSites());
      await dispatch(
        setHasSelectionChanged({
          hasAnySelectionChanged: true,
          hasChangedFromDefault: true,
        }),
      );
    }

    if (retiredComponents.sideliteGlassDesign) {
      const getSideliteGlassDesigns = selectionOptions.glassDesigns.find(
        (glassDesigns) => {
          return (
            glassDesigns.description === selections.glassDesign.description
          );
        },
      );
      const clearGlassSideliteOption =
        getSideliteGlassDesigns.sideLiteGlassDesign.find((sideliteGlass) => {
          return sideliteGlass.description === "Clear";
        });
      await dispatch(
        reloadOptionsOnSelect(
          "SIDE_LITE_GLASS_DESIGN",
          clearGlassSideliteOption,
        ),
      );
      await dispatch(
        setHasSelectionChanged({
          hasAnySelectionChanged: true,
          hasChangedFromDefault: true,
        }),
      );
    }

    if (retiredComponents.peepSite) {
      await dispatch(setPeepSiteSelection({}));
      await dispatch(
        setHasSelectionChanged({
          hasAnySelectionChanged: true,
        }),
      );
    }

    if (retiredComponents.hinge) {
      let replacementHinge = selectionOptions.hinges.find(
        // Set the hinge to use Stainless Steel
        (hinge) => hinge.resourceId === "7bb3b8e5-dc45-4a16-be0e-a9b56597c4f6",
      );

      await dispatch(setHingeSelection(replacementHinge));
      await dispatch(
        setHasSelectionChanged({
          hasAnySelectionChanged: true,
          hasChangedFromDefault: true,
        }),
      );
    }

    dispatch(setResetRetiredComponents());
  };
}

export function resetToOriginalEditDoor(classifierRules) {
  return async (dispatch, getState) => {
    const originalDoor = getState().exteriorPrehung.doorViewOptions.mode;
    await dispatch(
      editDoor(
        originalDoor.orderResourceId,
        originalDoor.orderItemResourceId,
        classifierRules,
        true,
      ),
    );
  };
}

export function retrySlabLayoutCall() {
  return async (dispatch, getState) => {
    const selections = getState().exteriorPrehung.selections;

    await dispatch(
      fetchSlabLayouts({
        GLASSDESIGN: selections.glassDesign,
        SLABDESIGN: selections.slabDesign,
      }),
    );
  };
}

export function retrySlabDesignCall() {
  return async (dispatch, getState) => {
    const options = getState().exteriorPrehung.selectionOptions;
    const filterSettings = getState().exteriorPrehung.filterSettings;
    const selections = getState().exteriorPrehung.selections;

    const foundHurricaneRating = findOneObject(
      options.doorSpecs.hurricaneRatings,
      filterSettings,
    );
    const foundImpactRating = findOneObject(
      options.doorSpecs.impactRatings,
      filterSettings,
    );
    const foundWidths = findValuesToArray(
      options.doorSpecs.dimensions.slabWidths,
      filterSettings,
    );
    const foundHeights = findValuesToArray(
      options.doorSpecs.dimensions.slabHeights,
      filterSettings,
    );

    let dimensions = foundWidths.concat(foundHeights);

    if (selections.slabLayout.hasSidelite) {
      const foundSideliteWidths = findValuesToArray(
        options.doorSpecs.dimensions.sideLiteWidths,
        filterSettings,
      );

      dimensions = dimensions.concat(foundSideliteWidths);
    }

    await dispatch(
      fetchSlabDesigns(
        {
          SLABLAYOUT: selections.slabLayout,
          GLASSDESIGN: selections.glassDesign,
          HURRICANERATED: foundHurricaneRating,
          IMPACTRATED: foundImpactRating,
        },
        dimensions,
      ),
    );
  };
}

export function retryGlassDesignCall() {
  return async (dispatch, getState) => {
    const options = getState().exteriorPrehung.selectionOptions;
    const filterSettings = getState().exteriorPrehung.filterSettings;
    const selections = getState().exteriorPrehung.selections;

    const foundImpactRating = findOneObject(
      options.doorSpecs.impactRatings,
      filterSettings,
    );
    const foundWidths = findValuesToArray(
      options.doorSpecs.dimensions.slabWidths,
      filterSettings,
    );
    const foundHeights = findValuesToArray(
      options.doorSpecs.dimensions.slabHeights,
      filterSettings,
    );

    let dimensions = foundWidths.concat(foundHeights);

    if (selections.slabLayout.hasSidelite) {
      const foundSideliteWidths = findValuesToArray(
        options.doorSpecs.dimensions.sideLiteWidths,
        filterSettings,
      );

      dimensions = dimensions.concat(foundSideliteWidths);
    }

    await dispatch(
      fetchGlassDesigns(
        {
          SLABLAYOUT: selections.slabLayout,
          SLABDESIGN: selections.slabDesign,
          IMPACTRATED: foundImpactRating,
        },
        dimensions,
      ),
    );
  };
}

export function addColors(colors) {
  return async (dispatch, getState) => {
    try {
      const colorArray = [
        ...colors,
        ...getState().exteriorPrehung.selectionOptions.customColors,
      ];
      dispatch(setCustomColors(uniqBy(colorArray, "resourceId")));
    } catch (error) {
      console.error("error", error);
    }
  };
}

export function deleteColor(color) {
  return async (dispatch, getState) => {
    try {
      let updatedCustomColors =
        getState().exteriorPrehung.selectionOptions.customColors.filter(
          (item) => item.resourceId !== color.resourceId,
        );
      dispatch(setCustomColors(updatedCustomColors));
    } catch (error) {
      console.error("error", error);
    }
  };
}

export function consumerLeadDoor(
  leadResourceId,
  leadItemResourceId,
  classifierRules,
) {
  return async (dispatch, getState) => {
    try {
      dispatch(resetState());
      dispatch(setEditableDoorLoading(true));
      dispatch(
        setMode({ isConsumerLead: true, leadResourceId, leadItemResourceId }),
      );

      const consumerLead = await builderFunctions.getLead(leadResourceId);
      const consumerLeadItem = consumerLead.items.find(
        (item) => item.resourceId === leadItemResourceId,
      );

      consumerLeadItem.specifications.forEach((spec) => {
        switch (spec.type) {
          case "SLAB_LAYOUT":
            dispatch(setSlabLayoutSelection(spec));
            break;
          case "SLAB_DESIGN":
            dispatch(setSlabDesignSelection(spec));
            break;
          case "GLASS_DESIGN":
            dispatch(setGlassDesignSelection(spec));
            break;
          case "HURRICANE_RATED":
            dispatch(setDoorDetailSelection({ hurricaneRated: spec }));
            break;
          case "IMPACT_RATED":
            dispatch(setDoorDetailSelection({ impactRated: spec }));
            break;
          case "GLAZING_TYPE":
            dispatch(setDoorDetailSelection({ glazingType: spec }));
            break;
          case "SLAB_WIDTH":
            dispatch(setDoorDetailSelection({ slabWidth: spec }));
            break;
          case "SLAB_HEIGHT":
            dispatch(setDoorDetailSelection({ slabHeight: spec }));
            break;
          case "SIDE_LITE_WIDTH":
            dispatch(setDoorDetailSelection({ sideLiteWidth: spec }));
            break;
          case "SIDE_LITE_GLASS_DESIGN":
            dispatch(setDoorDetailSelection({ sideliteGlassDesign: spec }));
            break;
          case "FINISH":
            dispatch(
              setFinishSelection({
                doorExterior: spec,
                doorInterior: spec,
                frameExterior: spec,
                frameInterior: spec,
                casingColor: spec,
              }),
            );

            if (spec.finishType === "CUSTOM_PAINT") {
              dispatch(addColors([spec]));
            }
            break;
          default:
            console.log("Uncaught Type: ", spec.type);
            break;
        }
      });

      await dispatch(existingStateCheck(classifierRules));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setEditableDoorLoading(false));
    }
  };
}
