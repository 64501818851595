import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import { useCheckSnackbar } from "../../utils";

export default function ConsumerDeleteItemDialog(props) {
  const { open, onPrimaryClick, onSecondaryClick, onRefresh } = props;

  const [shouldRefresh, setShouldRefresh] = React.useState(false);

  const enqueueSnackbar = useCheckSnackbar();

  const handlePrimaryClick = async () => {
    try {
      await onPrimaryClick();
      setShouldRefresh(true);
      onSecondaryClick();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const handOnExited = async () => {
    if (shouldRefresh) {
      await onRefresh();
    }
  };

  return (
    <AlertDialog
      open={open}
      title="Delete Door?"
      contentText="Are you sure about deleting this item from your cart?"
      primaryLabel="Delete"
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      TransitionProps={{
        onExited: handOnExited,
      }}
    />
  );
}

ConsumerDeleteItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  onRefresh: PropTypes.func,
};
