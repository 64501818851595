import React from "react";
import ResultsIcon from "mdi-material-ui/FileSearch";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const Wrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
}));

const InnerWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "customColor",
})(({ theme, customColor }) => ({
  color:
    theme.palette.mode === "light" ? customColor : theme.palette.text.primary,
}));

const HasSearched = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <ResultsIcon style={{ fontSize: "50px", color: "#CFD4DA" }} />
        <StyledTypography
          variant="subtitle1"
          data-test={"no-results-found"}
          customColor="#495057"
        >
          No results found
        </StyledTypography>
        <StyledTypography variant="body2" customColor="#868E96">
          Try searching for a different or less specific criteria, and check
          your spelling.
        </StyledTypography>
      </InnerWrapper>
    </Wrapper>
  );
};

const DefaultSearchResults = ({ searchText, objectName }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <ResultsIcon style={{ fontSize: "50px", color: "#CFD4DA" }} />
        <StyledTypography variant="subtitle1" customColor="#495057">
          Search for a {objectName}
        </StyledTypography>
        <StyledTypography align="center" variant="body2" customColor="#868E96">
          {searchText}
        </StyledTypography>
      </InnerWrapper>
    </Wrapper>
  );
};

class TableResults extends React.Component {
  render() {
    const { hasSearched, objectName, searchText, CustomTableResults } =
      this.props;

    return (
      <>
        {CustomTableResults ? (
          <CustomTableResults />
        ) : hasSearched ? (
          <HasSearched />
        ) : (
          <DefaultSearchResults
            searchText={searchText}
            objectName={objectName}
          />
        )}
      </>
    );
  }
}

TableResults.propTypes = {
  hasSearched: PropTypes.bool.isRequired,
  objectName: PropTypes.string,
  searchText: PropTypes.string,
  CustomTableResults: PropTypes.any,
};

export default TableResults;
