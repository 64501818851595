import React from "react";
import AdminIcon from "mdi-material-ui/AccountKey";
import ConsumerIcon from "@mui/icons-material/AccountBox";
import ContractorIcon from "@mui/icons-material/SupervisorAccount";
import { Type } from "../../AuthProvider";

export default React.forwardRef((props, ref) => {
  const { userType, ...other } = props;

  if (userType === "CONSUMER") {
    return <ConsumerIcon ref={ref} {...other} data-test="consumer-icon" />;
  } else if (userType === Type.CONTRACTOR) {
    return <ContractorIcon ref={ref} {...other} data-test="contractor-icon" />;
  } else if (userType === Type.CSR || userType === Type.SALES_REP) {
    return <AdminIcon ref={ref} {...other} data-test="csr-icon" />;
  } else {
    return <div />;
  }
});
