import React, { useState } from "react";
import { useDispatch } from "react-redux";
import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { ResultMessage } from "@masonite-digital/common-components";
import ZoomIcon from "@mui/icons-material/ZoomIn";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import DelayedIcon from "../../../components/Icons/DelayedIcon";
import {
  HardwareImageSelector,
  ImageSelector,
} from "../../../components/Selection";
import {
  setHasSelectionChanged,
  setHingeSelection,
} from "../../../redux/actions/exteriorPrehungActionCreators";
import {
  fetchPeepSites,
  fetchSill,
} from "../../../redux/actions/exteriorPrehungFetchActions";
import ZoomComponent from "../preview/ZoomComponent";

function getFinishes(part, wrappers) {
  for (let value of wrappers) {
    if (part.resourceId === value.part.resourceId) {
      return value.finishes;
    }
  }
  return [];
}

const HardwareSection = ({
  isLoading,
  isHingeLoading,
  isSillLoading,
  isBoreDetailsLoading,
  hardwareError,
  selectedHinge,
  selectedHardware,
  selectedHardwareFinish,
  onClick,
  hardwareOptions,
  onHardwareFinishChange,
  retryHardwareCall,
  slabLayout,
  onInactivePrepClick,
  inactivePrep,
  hingeError,
  sillError,
  boreDetailsError,
  sillOption,
  retrySillCall,
  retryBoreDetailsCall,
  selections,
  roughOpening,
  hingeOptions,
  boreMeasurements,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const dispatch = useDispatch();
  const isNoHardware = selectedHardware.description === "None";
  const noHvhzAndDoorCovered =
    !selections.hurricaneRated.value && selections.isDoorCovered.value;
  const hasHingeOrSillError =
    !isSillLoading &&
    !isHingeLoading &&
    (isEmpty(sillOption) || sillError || isEmpty(hingeOptions) || hingeError);
  const hingeOrSillErrorMessage = hingeError
    ? hingeError.message
    : sillError?.message;

  const sillTypeMessage = noHvhzAndDoorCovered
    ? "bumper sill"
    : "high-dam sill";
  const sillTypeMessage2 = noHvhzAndDoorCovered
    ? ""
    : "to prevent water from entering";

  const onHingeFinishChange = (option) => {
    dispatch(setHingeSelection(option));
    dispatch(
      setHasSelectionChanged({
        hasAnySelectionChanged: true,
        hasChangedFromDefault: true,
      })
    );
    dispatch(fetchSill());
    dispatch(fetchPeepSites());
  };

  if (isLoading) {
    return (
      <Grid container spacing={3}>
        <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
          <Grid container spacing={2}>
            {Array.apply(null, Array(8)).map((value, index) => (
              <Grid item key={index}>
                <Skeleton
                  style={{ margin: "6px" }}
                  variant="rectangular"
                  width={79}
                  height={79}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item sm={12} md={7} lg={6}>
            <Box mr="10px">
              <Skeleton variant="rectangular" width="100%" height={231} />
            </Box>
          </Grid>

          <Grid item sm={12} md={5} lg={6}>
            <div style={{ paddingBottom: "10px" }}>
              <Typography variant="subtitle1">
                <Skeleton
                  style={{ marginTop: "6px", marginBottom: "6px" }}
                  variant="rectangular"
                  width={65}
                  height={16}
                />
              </Typography>
              <Typography variant="body2">
                <Skeleton
                  style={{ marginTop: "3px", marginBottom: "3px" }}
                  variant="rectangular"
                  width={200}
                  height={14}
                />
              </Typography>
              <Typography variant="body2">
                <Skeleton
                  style={{ marginTop: "3px", marginBottom: "3px" }}
                  variant="rectangular"
                  width={75}
                  height={14}
                />
              </Typography>
            </div>
            <Typography variant="subtitle1">
              <Skeleton
                style={{ marginTop: "6px", marginBottom: "6px" }}
                variant="rectangular"
                width={45}
                height={16}
              />
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <Skeleton
                  style={{ margin: "6px" }}
                  variant="rectangular"
                  width={36}
                  height={36}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  style={{ margin: "6px" }}
                  variant="rectangular"
                  width={36}
                  height={36}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  style={{ margin: "6px" }}
                  variant="rectangular"
                  width={36}
                  height={36}
                />
              </Grid>
            </Grid>
            <Skeleton
              style={{ marginTop: "16px" }}
              variant="rectangular"
              width={70}
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (isEmpty(hardwareOptions)) {
    return (
      <ResultMessage
        type="WARNING"
        title="Something went wrong"
        message={hardwareError}
        onClick={retryHardwareCall}
      />
    );
  }

  let hardwareDetailSection;
  if (isNoHardware) {
    hardwareDetailSection = (
      <>
        <Typography variant="overline" color="textSecondary">
          {isBoreDetailsLoading ? <Skeleton /> : "Bore Details"}
        </Typography>
        <Grid container sx={{ mt: 1 }} spacing={0.5}>
          {boreDetailsError ? (
            <ResultMessage
              type="WARNING"
              title="Something went wrong"
              message={boreDetailsError}
              onClick={retryBoreDetailsCall}
            />
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {isBoreDetailsLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      Diameter:
                      <Typography
                        data-test="active-bore-diameter"
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        ml={1}
                      >
                        {boreMeasurements?.diameter}
                      </Typography>
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {isBoreDetailsLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      Backset:
                      <Typography
                        data-test="active-bore-backset"
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        ml={1}
                      >
                        {boreMeasurements?.backSet}
                      </Typography>
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {isBoreDetailsLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      Center Line:
                      <Typography
                        data-test="active-bore-center-line"
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        ml={1}
                      >
                        {boreMeasurements?.centerLine}
                      </Typography>
                    </>
                  )}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  } else {
    hardwareDetailSection = (
      <>
        <Typography variant="subtitle1" color="primary">
          Finish
        </Typography>
        <HardwareImageSelector
          imgSizeSx={{ width: 32, height: 32 }}
          forceExtraSmallIcons
          selectedHardware={selectedHardware}
          selectedHardwareFinish={selectedHardwareFinish}
          onSelect={onHardwareFinishChange}
          options={getFinishes(selectedHardware, hardwareOptions)}
          optionType="Hardware Finish"
        />
        <Chip
          sx={{ mt: 2 }}
          variant="outlined"
          label={
            <div data-test="package-type">{selectedHardware.packageType}</div>
          }
        />
      </>
    );
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid container item xs={12} pb="20px">
          <HardwareImageSelector
            imgSizeSx={{ width: 75, height: 75 }}
            selectedHardware={selectedHardware}
            selectedHardwareFinish={selectedHardwareFinish}
            onSelect={onClick}
            options={hardwareOptions}
            optionType="Hardware"
          />
        </Grid>
        <Grid container item xs={12}>
          {!isNoHardware && (
            <Grid item sm={12} md={7} lg={6}>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "column",
                  mr: "10px",
                  border: `1px solid ${theme.palette.primary["300"]}`,
                  backgroundColor: "common.white",
                })}
              >
                <Box
                  component="img"
                  sx={{ width: 224, height: "auto" }}
                  alt={selectedHardware.description}
                  src={get(
                    find(get(selectedHardware, "finish.links"), {
                      rel: "preview-image",
                    }),
                    "href",
                    ""
                  )}
                />
                <Box
                  sx={{
                    width: "fit-content",
                    border: "1px solid #cfd4da",
                    borderRadius: "3px",
                    mt: "-36px",
                    mr: "5px",
                    mb: "5px",
                    alignSelf: "flex-end",
                  }}
                >
                  <IconButton
                    data-test="hardware-zoom-btn"
                    onClick={() => setPreviewOpen(true)}
                    style={{ padding: "5px" }}
                  >
                    <ZoomIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item sm={12} md={5} lg={6}>
            <Box pb="10px">
              <Typography variant="subtitle1" color="primary">
                Selected:
              </Typography>
              <Typography
                data-test="selected-hardware-description"
                variant={isNoHardware ? "body1" : "body2"}
              >
                {isNoHardware ? "None" : `${selectedHardware.description}`}
              </Typography>
              {!isNoHardware && (
                <Typography
                  data-test="selected-hardware-finish"
                  variant="body2"
                  color="textSecondary"
                >
                  {selectedHardwareFinish.description}
                </Typography>
              )}
            </Box>
            {hardwareDetailSection}
            {!selectedHardwareFinish?.available && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <DelayedIcon />
                <Typography
                  data-test="delayed-hardware-text"
                  mt={2}
                  ml={2}
                  variant="body2"
                  color="error"
                >
                  This item is temporarily out of stock. Selecting it may
                  increase your delivery lead time.
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
        {slabLayout.slabType === "DOUBLE" && (
          <Grid item container spacing={1} direction="column">
            <Grid item>
              <Typography
                sx={(theme) => ({
                  fontSize: "0.75rem",
                  color: theme.palette.primary.main,
                  fontWeight: 500,
                  textTransform: "uppercase",
                })}
              >
                Inactive Prep
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Include Inactive Prep?"
                onChange={(event, value) => {
                  onInactivePrepClick(value);
                }}
                control={
                  <Checkbox
                    data-test="inactive-prep-check-box"
                    color="secondary"
                    checked={inactivePrep.description === "With Inactive Prep"}
                  />
                }
              />
            </Grid>
            <Grid container sx={{ ml: 5, mt: -0.5 }}>
              <Grid item>
                <Typography
                  data-test="inactive-prep-note"
                  variant="body2"
                  color="textSecondary"
                >
                  <strong>Note: </strong>
                  {isNoHardware
                    ? "Inactive Prep will prepare the passive side of your double door configuration for dummy hardware."
                    : "Inactive Prep is a duplicate version of your chosen door hardware that is installed on the passive side of your double door configuration."}
                </Typography>
              </Grid>
              {isNoHardware && (
                <>
                  <Grid item xs={12}>
                    <Typography
                      display="inline"
                      variant="body2"
                      color="textSecondary"
                    >
                      Diameter:
                    </Typography>
                    <Typography
                      data-test="inactive-bore-diameter"
                      display="inline"
                      ml={1}
                      variant="body2"
                    >
                      {boreMeasurements?.diameter}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      display="inline"
                      variant="body2"
                      color="textSecondary"
                    >
                      Backset:
                    </Typography>
                    <Typography
                      data-test="inactive-bore-backset"
                      display="inline"
                      ml={1}
                      variant="body2"
                    >
                      {boreMeasurements?.backSet}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        )}
        <Grid data-test="sill-section" item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Sill and Hinges
            </Typography>
          </Grid>
          {hasHingeOrSillError && (
            <ResultMessage
              type="WARNING"
              title="Something went wrong"
              message={hingeOrSillErrorMessage}
              onClick={retrySillCall}
            />
          )}
          {!hasHingeOrSillError && (
            <Grid item>
              {isSillLoading && (
                <Grid item xs={12} pb={1}>
                  <Skeleton variant="rectangular" width={232} height={24} />
                </Grid>
              )}
              {!isSillLoading && (
                <>
                  {sillOption.newHeight ? (
                    <Grid item xs={12}>
                      <Typography data-test="hi-dam-sill" variant="caption">
                        {`A ${sillTypeMessage} will be added to your door
                      ${sillTypeMessage2}
                      (metal finished in ${
                        selectedHardwareFinish.description
                      }). The height of your door will 
                      change to ${roughOpening && roughOpening.unitHeight}".`}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Typography data-test="default-sill" variant="caption">
                        Sill and hinges will be added to your door.
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography display="inline">Sill:</Typography>
                    <Typography
                      data-test="sill-finish-type"
                      display="inline"
                      ml={1}
                    >
                      {sillOption.description}
                    </Typography>
                  </Grid>
                </>
              )}
              {isHingeLoading && (
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" width={232} height={24} />
                </Grid>
              )}
              {!isHingeLoading && (
                <Grid item xs={12}>
                  <Typography display="inline">Hinges:</Typography>
                  <Typography
                    data-test="hinge-description"
                    display="inline"
                    ml={1}
                  >
                    {
                      selectedHinge?.hinges.find(
                        (hinge) =>
                          hinge.handingResourceId ===
                          selections.handing.resourceId
                      ).description
                    }
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textSecondary">
              Finish
            </Typography>
            <ImageSelector
              isLoading={isHingeLoading}
              imgSizeSx={{ width: 32, height: 32 }}
              options={hingeOptions}
              optionType="Finish"
              selected={selections.hinge}
              onSelect={onHingeFinishChange}
              gridContainerProps={{ spacing: 0 }}
              showDescription={false}
            />
          </Grid>
        </Grid>
      </Grid>
      {!isNoHardware && (
        <ZoomComponent
          title="Hardware"
          open={previewOpen}
          handleClose={() => setPreviewOpen(false)}
          previewImg={selectedHardwareFinish.links[0].href}
        />
      )}
    </>
  );
};
HardwareSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isHingeLoading: PropTypes.bool.isRequired,
  isSillLoading: PropTypes.bool.isRequired,
  isBoreDetailsLoading: PropTypes.bool.isRequired,
  hardwareError: PropTypes.string,
  selectedHinge: PropTypes.object,
  selectedHardware: PropTypes.object,
  selectedHardwareFinish: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  hardwareOptions: PropTypes.array,
  onHardwareFinishChange: PropTypes.func,
  retryHardwareCall: PropTypes.func.isRequired,
  slabLayout: PropTypes.object,
  inactivePrep: PropTypes.object,
  onInactivePrepClick: PropTypes.func.isRequired,
  sillOption: PropTypes.object,
  sillError: PropTypes.string,
  boreDetailsError: PropTypes.string,
  hingeError: PropTypes.string,
  retrySillCall: PropTypes.func.isRequired,
  retryBoreDetailsCall: PropTypes.func.isRequired,
  roughOpening: PropTypes.object,
  selections: PropTypes.object.isRequired,
  hingeOptions: PropTypes.array.isRequired,
  boreMeasurements: PropTypes.object,
};

export default HardwareSection;
