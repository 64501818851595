import React from "react";
import { detect } from "detect-browser";
import AppleSafariIcon from "mdi-material-ui/AppleSafari";
import FirefoxIcon from "mdi-material-ui/Firefox";
import GoogleChromeIcon from "mdi-material-ui/GoogleChrome";
import { useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { orange } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

export default function IncompatibleBrowserBox() {
  const [showMessage, setShowMessage] = React.useState(false);
  const [userOverride, setUserOverride] = React.useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  let currentBrowser = detect();

  React.useEffect(() => {
    if (
      currentBrowser &&
      !(
        currentBrowser.name === "chrome" ||
        currentBrowser.name === "firefox" ||
        currentBrowser.name === "safari" ||
        currentBrowser.name === "crios" ||
        currentBrowser.name === "fxios" ||
        currentBrowser.name === "ios" ||
        currentBrowser.name === "edge-chromium"
      )
    ) {
      setShowMessage(true);
    }
  }, [currentBrowser, setShowMessage]);

  if (!showMessage || userOverride) {
    return <></>;
  }

  return (
    <Paper
      elevation={0}
      square
      sx={(theme) => ({
        position: "relative",
        zIndex: 1300,
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
          1
        )}px ${theme.spacing(2)}px`,
        backgroundColor: orange[500],
        color: "white",
      })}
    >
      <Grid
        direction={matches ? "column" : "row"}
        container
        wrap="nowrap"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography>
            Sorry, our software is not currently supported on your browser.
            Please try using one of the following options:
          </Typography>
        </Grid>
        <Grid
          sx={{
            ...(matches && {
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }),
          }}
          item
        >
          <IconButton
            sx={{ color: "white" }}
            component="a"
            href="https://www.google.com/chrome/"
            rel="noopener"
            target="_blank"
          >
            <GoogleChromeIcon />
          </IconButton>
          <IconButton
            sx={{ color: "white" }}
            component="a"
            href="https://www.mozilla.org/en-US/firefox/new/"
            rel="noopener"
            target="_blank"
          >
            <FirefoxIcon />
          </IconButton>
          <IconButton
            sx={{ color: "white" }}
            component="a"
            href="https://support.apple.com/downloads/safari"
            rel="noopener"
            target="_blank"
          >
            <AppleSafariIcon />
          </IconButton>
          <Button
            sx={{
              color: "white",
              borderColor: "white",
              marginLeft: "8px",
            }}
            variant="outlined"
            onClick={() => setUserOverride(true)}
            TouchRippleProps={{
              style: {
                color: "white",
              },
            }}
          >
            Dismiss
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
