import React from "react";
import PropTypes from "prop-types";
import PlusIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

export default function Counter(props) {
  const { maxQuantity, selectedQuantity, setSelectedQuantity, title } = props;

  const updateQuantity = (type) => {
    if (type === "add") {
      setSelectedQuantity(selectedQuantity + 1);
    }
    if (type === "subtract") {
      setSelectedQuantity(selectedQuantity - 1);
    }
  };

  return (
    <>
      <Typography color="textSecondary" letterSpacing="1px" variant="overline">
        {title}
      </Typography>
      <Grid container item alignItems="center" wrap="nowrap">
        <IconButton
          disabled={selectedQuantity <= 1}
          onClick={() => updateQuantity("subtract")}
          data-test="quantity-subtract-button"
          sx={(theme) => ({
            color: theme.palette.text.primary,
            border: "1px solid " + theme.palette.border,
            "&:disabled": {
              color: theme.palette.text.disabled,
            },
          })}
        >
          <MinusIcon fontSize="small" />
        </IconButton>
        <Grid
          style={{ maxWidth: "100px" }}
          container
          item
          alignItems="center"
          justifyContent="space-around"
        >
          <Typography
            data-test="selected-quantity"
            component="span"
            variant="body1"
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              border: "1px solid " + theme.palette.border,
              p: "4px 8px",
            })}
          >
            {selectedQuantity}
          </Typography>
          <Typography component="span" variant="body1" color="textSecondary">
            of
          </Typography>
          <Typography component="span" variant="body1" data-test="max-quantity">
            {maxQuantity}
          </Typography>
        </Grid>
        <IconButton
          sx={(theme) => ({
            color: theme.palette.text.primary,
            border: "1px solid " + theme.palette.border,
            "&:disabled": {
              color: theme.palette.text.disabled,
            },
          })}
          disabled={selectedQuantity >= maxQuantity}
          onClick={() => updateQuantity("add")}
          data-test="quantity-add-button"
        >
          <PlusIcon fontSize="small" />
        </IconButton>
      </Grid>
    </>
  );
}

Counter.propTypes = {
  maxQuantity: PropTypes.number,
  selectedQuantity: PropTypes.number,
  setSelectedQuantity: PropTypes.func,
  title: PropTypes.string,
};
