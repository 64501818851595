import {
  CREATE_PAYMENT,
  DELETE_PAYMENT,
  LOCATION_CHANGE,
  RESET_PAYMENT,
  SET_BEHALF,
  SET_MASONITE_CREDIT,
  SET_PAYMENT_ACTIVE_STEP,
  SET_PAYMENT_FLOW_EXPIRATION,
  SET_PAYMENT_METHOD,
  SET_SHIPPING,
  SET_SHIPPING_DATES,
  SET_SHIPPING_DATES_LOADING,
  SET_SHOW_CANCEL_CONFIRMATION,
  SET_SHOW_SAVED_DATA_CONFIRMATION,
  SET_STATE,
  SET_STRIPE,
  SET_TAX_ESTIMATE,
  SET_WARRANTY,
} from "../actions/payment/paymentActionCreators";
import { paymentItemDefaultState } from "../defaultStates";

const paymentReducer = (state = {}, action) => {
  function setValue(key) {
    return {
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        [key]: action.payload.value,
      },
    };
  }

  function spreadValue(key) {
    return {
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        [key]: {
          ...state[action.payload.id][key],
          ...action.payload.value,
        },
      },
    };
  }

  switch (action.type) {
    case RESET_PAYMENT:
    case CREATE_PAYMENT:
      return {
        ...state,
        [action.payload.id]: {
          ...paymentItemDefaultState,
          // The default payment expiration is 4 hours.
          paymentFlowExpiration: new Date().getTime() + 14400000,
        },
      };
    case DELETE_PAYMENT:
      delete state[action.payload.id];
      return state;
    case SET_STATE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.value,
          showSavedDataConfirmation: true,
        },
      };
    case SET_PAYMENT_ACTIVE_STEP:
      return setValue("activeStep");
    case SET_SHIPPING:
      return spreadValue("shipping");
    case SET_SHIPPING_DATES:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          shipping: {
            ...state[action.payload.id].shipping,
            deliveryDate: action.payload.value[0],
          },
          shippingDates: action.payload.value,
        },
      };
    case SET_SHIPPING_DATES_LOADING:
      return setValue("shippingDatesLoading");
    case SET_PAYMENT_METHOD:
      return setValue("paymentMethod");
    case SET_STRIPE:
      return setValue("stripe");
    case SET_BEHALF:
      return spreadValue("behalf");
    case SET_MASONITE_CREDIT:
      return spreadValue("masoniteCredit");
    case SET_TAX_ESTIMATE:
      return setValue("tax");
    case SET_WARRANTY:
      return spreadValue("warranty");
    case SET_SHOW_CANCEL_CONFIRMATION:
      return setValue("showCancelConfirmation");
    case SET_SHOW_SAVED_DATA_CONFIRMATION:
      return setValue("showSavedDataConfirmation");
    case SET_PAYMENT_FLOW_EXPIRATION:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          paymentFlowExpiration: action.payload.value,
          isPaymentDurationFetched: true,
        },
      };
    case LOCATION_CHANGE:
      if (window.localStorage.getItem("state")) {
        return state;
      }
      return {};
    default:
      return state;
  }
};

export default paymentReducer;
