import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BillingAddressDetail from "./BillingAddressDetail";
import CreditCardDetail from "./CreditCardDetail";
import ResetBilling from "./ResetBilling";
import { PaymentType } from "./index";

const stripePromise = loadStripe(
  window.__RUNTIME_CONFIG__ && window.__RUNTIME_CONFIG__.REACT_APP_STRIPE_KEY
);

const CreditCardForm = React.forwardRef((props, ref) => {
  const { data, onChange, setFormComplete, resetPayment } = props;

  if (data.completed) {
    const cardInfo = data.token.card;

    return (
      <ResetBilling
        type={PaymentType.CREDIT_CARD}
        cardInfo={cardInfo}
        address={data}
        resetPayment={resetPayment}
      />
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Elements stripe={stripePromise}>
          <CreditCardDetail
            ref={ref}
            initialValues={{
              cardholderName: data.name,
            }}
            setFormComplete={setFormComplete}
            setBillingName={(name) => onChange({ name })}
          />
        </Elements>
      </Grid>
      <Grid item xs={12} md={6}>
        <BillingAddressDetail data={data} onChange={onChange} />
      </Grid>
    </Grid>
  );
});

CreditCardForm.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  setFormComplete: PropTypes.func.isRequired,
  resetPayment: PropTypes.func.isRequired,
};

export default CreditCardForm;
