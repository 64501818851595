import React from "react";
import { lowerCase } from "lodash";
import startCase from "lodash/startCase";
import PropTypes from "prop-types";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountIcon from "@mui/icons-material/Person";
import MapMarkerIcon from "@mui/icons-material/Room";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { formatCardDigits } from "./PaymentReview";
import masoniteCreditIcon from "./images/masonite-credit-icon.png";
import warrantyIcon from "./images/warranty-icon-lg.png";
import { PaymentType } from "./index";
import behalfIcon from "../../actions/Behalf/behalf-logo.png";
import CreditCardTypeIcon from "../../components/Icons/CreditCardTypeIcon";

export default function ResetBilling(props) {
  const { type, readOnly = false, cardInfo, address, resetPayment } = props;

  let avatar;
  if (type === PaymentType.MASONITE_CREDIT) {
    avatar = (
      <Box
        data-test="masonite-credit-icon"
        component="img"
        alt="masonite-credit-icon"
        src={masoniteCreditIcon}
        height="24px"
      />
    );
  }
  if (type === PaymentType.BEHALF) {
    avatar = (
      <Box
        data-test="behalf-icon"
        component="img"
        alt="behalf-icon"
        src={behalfIcon}
        height="24px"
      />
    );
  }
  if (type === PaymentType.WARRANTY) {
    avatar = (
      <Box
        data-test="warranty-icon"
        component="img"
        alt="warranty-icon"
        src={warrantyIcon}
        height="24px"
      />
    );
  }
  if (type === PaymentType.CREDIT_CARD) {
    avatar = (
      <CreditCardTypeIcon
        type={cardInfo.brand.toUpperCase()}
        sx={{ height: "32px" }}
      />
    );
  }

  const paymentType = startCase(lowerCase(type));
  const action =
    !readOnly && type !== PaymentType.MASONITE_CREDIT ? (
      <Button variant="outlined" onClick={resetPayment}>
        Reset
      </Button>
    ) : null;

  return (
    <Card elevation={0}>
      <CardHeader
        avatar={avatar}
        disableTypography
        title={
          <Typography data-test="billing-title" variant="body1">
            {paymentType}
          </Typography>
        }
        action={action}
      />
      {type === PaymentType.CREDIT_CARD && (
        <CardContent>
          <List>
            <ListItem>
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText
                data-test="card-last-4-digits"
                primary={formatCardDigits(
                  cardInfo.last4,
                  cardInfo.brand.toUpperCase()
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AccountIcon />
              </ListItemIcon>
              <ListItemText data-test="card-name" primary={cardInfo.name} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <MapMarkerIcon />
              </ListItemIcon>
              <ListItemText
                data-test="billing-address"
                primary={
                  <>
                    <div>{address.line1}</div>
                    {address.line2 && <div>{address.line2}</div>}
                    <div>{`${address.city}, ${address.state} ${address.zipCode}`}</div>
                  </>
                }
              />
            </ListItem>
          </List>
        </CardContent>
      )}
    </Card>
  );
}

ResetBilling.propTypes = {
  type: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  cardInfo: PropTypes.object,
  address: PropTypes.object,
  resetPayment: PropTypes.func,
};
