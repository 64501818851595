import {
  LOCATION_CHANGE,
  RESET_STATE,
  SET_ACCORDION_STEP,
  SET_ACTIVE_STEP,
  SET_ADA_COMPLIANT_SELECTION,
  SET_BORE_DETAILS,
  SET_BORE_DETAILS_ERROR,
  SET_BORE_DETAILS_LOADING,
  SET_BRICK_MOLD_ERROR,
  SET_BRICK_MOLD_LOADING,
  SET_BRICK_MOLD_OPTIONS,
  SET_BRICK_MOLD_SELECTION,
  SET_CASINGS_LOADING,
  SET_CASING_COLOR_SELECTION,
  SET_CASING_ERROR,
  SET_CASING_OPTIONS,
  SET_CASING_SELECTION,
  SET_CLASSIFIER_RULES,
  SET_COLLAPSE,
  SET_CUSTOM_COLOR_OPTIONS,
  SET_CUT_DOWN_DOOR_HEIGHTS,
  SET_DEFAULT_DOOR_ERROR,
  SET_DEFAULT_DOOR_LOADING,
  SET_DEFAULT_SPECS,
  SET_DIMENSIONS_LOADING,
  SET_DIMENSION_ERROR,
  SET_DIMENSION_OPTIONS,
  SET_DOOR_AVAILABILITY,
  SET_DOOR_DETAILS_LOADING,
  SET_DOOR_DETAIL_SELECTION,
  SET_DOOR_PREVIEW,
  SET_DOOR_SPECS_LOADING,
  SET_DOOR_SPEC_ERROR,
  SET_DOOR_SPEC_OPTIONS,
  SET_DOOR_SUMMARY,
  SET_EDITABLE_DOOR_LOADING,
  SET_EXISTING_STATE_CHECK_LOADING,
  SET_FILTERED_OPTIONS_LOADING,
  SET_FILTER_SETTING,
  SET_FINISHES_LOADING,
  SET_FINISH_ERROR,
  SET_FINISH_OPTIONS,
  SET_FINISH_SELECTION,
  SET_GET_PRODUCT_ERROR,
  SET_GET_PRODUCT_LOADING,
  SET_GLASS_DESIGNS_LOADING,
  SET_GLASS_DESIGN_ERROR,
  SET_GLASS_DESIGN_OPTIONS,
  SET_GLASS_DESIGN_SELECTION,
  SET_HANDING_ERROR,
  SET_HANDING_LOADING,
  SET_HARDWARES_LOADING,
  SET_HARDWARE_ERROR,
  SET_HARDWARE_FINISH_SELECTION,
  SET_HARDWARE_OPTIONS,
  SET_HARDWARE_SELECTION,
  SET_HAS_SELECTION_CHANGED,
  SET_HINGES_LOADING,
  SET_HINGE_ERROR,
  SET_HINGE_OPTIONS,
  SET_HINGE_SELECTION,
  SET_INACTIVE_PREP_OPTIONS,
  SET_INACTIVE_PREP_SELECTION,
  SET_JAMB_DEPTHS_LOADING,
  SET_JAMB_DEPTH_ERROR,
  SET_MODE,
  SET_MULTIPLE_LOADING_STATES,
  SET_PEEP_SITES_LOADING,
  SET_PEEP_SITE_ERROR,
  SET_PEEP_SITE_OPTIONS,
  SET_PEEP_SITE_SELECTION,
  SET_PREVIEW_IMAGE,
  SET_RECOVERY_OPTIONS_LOADING,
  SET_RESET_RETIRED_COMPONENTS,
  SET_RETIRED_COMPONENTS,
  SET_ROUGH_OPENING,
  SET_ROUGH_OPENING_ERROR,
  SET_ROUGH_OPENING_LOADING,
  SET_SETTING_DRAWER,
  SET_SIDELITE_SELECTION,
  SET_SILL_ERROR,
  SET_SILL_LOADING,
  SET_SILL_OPTIONS,
  SET_SLAB_DESIGNS_LOADING,
  SET_SLAB_DESIGN_ERROR,
  SET_SLAB_DESIGN_OPTIONS,
  SET_SLAB_DESIGN_SELECTION,
  SET_SLAB_LAYOUTS_LOADING,
  SET_SLAB_LAYOUT_ERROR,
  SET_SLAB_LAYOUT_OPTIONS,
  SET_SLAB_LAYOUT_SELECTION,
  SET_SLIDE,
  SET_STEP_TWO_COMPLETE,
  SET_STORE_STATE,
  SET_TAB_INDEX,
} from "../actions/exteriorPrehungActionCreators";
import { exteriorPrehungDefaultState } from "../defaultStates";

const exteriorPrehungReducer = (
  state = exteriorPrehungDefaultState,
  action
) => {
  switch (action.type) {
    case RESET_STATE:
      return exteriorPrehungDefaultState;
    case SET_STORE_STATE:
      return action.payload;
    case SET_DEFAULT_SPECS:
      return {
        ...state,
        defaultSpecs: action.payload,
      };
    case SET_MULTIPLE_LOADING_STATES:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          ...action.payload,
        },
      };
    case SET_EDITABLE_DOOR_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingEditableDoor: action.payload,
        },
      };
    case SET_EXISTING_STATE_CHECK_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingExistingStateCheck: action.payload,
        },
      };
    case SET_DOOR_DETAILS_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingDoorDetails: action.payload,
        },
      };
    case SET_RECOVERY_OPTIONS_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingRecoveryOptions: action.payload,
        },
      };
    case SET_DEFAULT_DOOR_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingDefaultDoor: action.payload,
        },
      };
    case SET_SLAB_LAYOUTS_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingSlabLayouts: action.payload,
        },
      };
    case SET_SLAB_DESIGNS_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingSlabDesigns: action.payload,
        },
      };
    case SET_GLASS_DESIGNS_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingGlassDesigns: action.payload,
        },
      };
    case SET_ROUGH_OPENING_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingRoughOpening: action.payload,
        },
      };
    case SET_FINISHES_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingFinishes: action.payload,
        },
      };
    case SET_HARDWARES_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingHardwares: action.payload,
        },
      };
    case SET_DIMENSIONS_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingDimensions: action.payload,
        },
      };
    case SET_PEEP_SITES_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingPeepSites: action.payload,
        },
      };
    case SET_CASINGS_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingCasings: action.payload,
        },
      };
    case SET_BRICK_MOLD_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingBrickMolds: action.payload,
        },
      };
    case SET_HANDING_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingHandings: action.payload,
        },
      };
    case SET_JAMB_DEPTHS_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingJambDepths: action.payload,
        },
      };
    case SET_DOOR_SPECS_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingDoorSpecs: action.payload,
        },
      };
    case SET_FILTERED_OPTIONS_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingFilteredOptions: action.payload,
        },
      };
    case SET_SILL_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingSill: action.payload,
        },
      };
    case SET_HINGES_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingHinges: action.payload,
        },
      };
    case SET_BORE_DETAILS_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingBoreDetails: action.payload,
        },
      };
    case SET_DEFAULT_DOOR_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          defaultDoorError: action.payload,
        },
      };
    case SET_SLAB_LAYOUT_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          slabLayoutError: action.payload,
        },
      };
    case SET_SLAB_DESIGN_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          slabDesignError: action.payload,
        },
      };
    case SET_GLASS_DESIGN_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          glassDesignError: action.payload,
        },
      };
    case SET_FINISH_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          finishError: action.payload,
        },
      };
    case SET_HARDWARE_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          hardwareError: action.payload,
        },
      };
    case SET_HINGE_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          hingeError: action.payload,
        },
      };
    case SET_PEEP_SITE_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          peepSiteError: action.payload,
        },
      };
    case SET_CASING_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          casingError: action.payload,
        },
      };
    case SET_BRICK_MOLD_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          brickMoldError: action.payload,
        },
      };
    case SET_HANDING_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          handingError: action.payload,
        },
      };
    case SET_JAMB_DEPTH_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          jambDepthError: action.payload,
        },
      };
    case SET_DOOR_SPEC_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          doorSpecError: action.payload,
        },
      };
    case SET_DIMENSION_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          dimensionError: action.payload,
        },
      };
    case SET_ROUGH_OPENING_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          roughOpeningError: action.payload,
        },
      };
    case SET_GET_PRODUCT_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          getProductError: action.payload,
        },
      };
    case SET_SILL_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          sillError: action.payload,
        },
      };
    case SET_BORE_DETAILS_ERROR:
      return {
        ...state,
        errorStates: {
          ...state.errorStates,
          boreDetailsError: action.payload,
        },
      };
    case SET_GET_PRODUCT_LOADING:
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          loadingGetProduct: action.payload,
        },
      };
    case SET_SLAB_DESIGN_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          slabDesigns: action.payload,
        },
      };
    case SET_SLAB_LAYOUT_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          slabLayouts: action.payload,
        },
      };
    case SET_GLASS_DESIGN_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          glassDesigns: action.payload,
        },
      };
    case SET_ROUGH_OPENING:
      return {
        ...state,
        roughOpeningTable: action.payload,
      };
    case SET_FINISH_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          finishes: action.payload,
        },
      };
    case SET_CUSTOM_COLOR_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          customColors: action.payload,
        },
      };
    case SET_HARDWARE_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          hardwares: action.payload,
        },
      };
    case SET_PEEP_SITE_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          peepSites: action.payload,
        },
      };
    case SET_DIMENSION_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          dimensions: action.payload,
        },
      };
    case SET_CASING_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          casings: action.payload,
        },
      };
    case SET_BRICK_MOLD_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          brickMolds: action.payload,
        },
      };
    case SET_INACTIVE_PREP_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          inactivePreps: action.payload,
        },
      };
    case SET_DOOR_SPEC_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          doorSpecs: {
            ...state.selectionOptions.doorSpecs,
            ...action.payload,
          },
        },
      };
    case SET_CUT_DOWN_DOOR_HEIGHTS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          doorSpecs: {
            ...state.selectionOptions.doorSpecs,
            cutDownDoorHeights: action.payload,
          },
        },
      };
    case SET_SILL_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          sill: action.payload,
        },
      };
    case SET_HINGE_OPTIONS:
      return {
        ...state,
        selectionOptions: {
          ...state.selectionOptions,
          hinges: action.payload,
        },
      };
    case SET_CLASSIFIER_RULES:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          classifierRules: {
            ...state.doorViewOptions.classifierRules,
            ...action.payload,
          },
        },
      };
    case SET_DOOR_SUMMARY:
      return {
        ...state,
        doorSummary: action.payload,
      };
    case SET_DOOR_AVAILABILITY:
      return {
        ...state,
        doorSummary: {
          ...state.doorSummary,
          available: action.payload,
        },
      };
    case SET_MODE:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          mode: {
            ...state.doorViewOptions.mode,
            ...action.payload,
          },
        },
      };
    case SET_SLAB_LAYOUT_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          slabLayout: action.payload,
        },
      };
    case SET_SLAB_DESIGN_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          slabDesign: action.payload,
        },
      };
    case SET_GLASS_DESIGN_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          glassDesign: action.payload,
        },
      };
    case SET_SIDELITE_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          sideliteGlassDesign: action.payload,
        },
      };
    case SET_FINISH_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          finish: {
            ...state.selections.finish,
            ...action.payload,
          },
        },
      };
    case SET_HARDWARE_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          hardware: action.payload,
        },
      };
    case SET_HINGE_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          hinge: action.payload,
        },
      };
    case SET_PEEP_SITE_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          peepSite: action.payload,
        },
      };
    case SET_BRICK_MOLD_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          brickMold: action.payload,
        },
      };
    case SET_INACTIVE_PREP_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          inactivePrep: action.payload,
        },
      };
    case SET_CASING_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          casing: action.payload,
        },
      };
    case SET_ADA_COMPLIANT_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          adaCompliant: action.payload,
        },
      };
    case SET_HARDWARE_FINISH_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          hardware: {
            ...state.selections.hardware,
            finish: action.payload,
          },
        },
      };
    case SET_CASING_COLOR_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          finish: {
            ...state.selections.finish,
            casingColor: action.payload,
          },
        },
      };
    case SET_TAB_INDEX:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          tabIndex: action.payload,
        },
      };
    case SET_ACTIVE_STEP:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          activeStep: action.payload,
        },
      };
    case SET_ACCORDION_STEP:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          accordionStep: action.payload,
        },
      };
    case SET_COLLAPSE:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          startCollapse: action.payload,
        },
      };
    case SET_SLIDE:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          startSlide: action.payload,
        },
      };
    case SET_DOOR_PREVIEW:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          openPreview: action.payload,
        },
      };
    case SET_PREVIEW_IMAGE:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          preview: {
            ...state.doorViewOptions.preview,
            ...action.payload,
          },
        },
      };
    case SET_SETTING_DRAWER:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          drawerOpen: action.payload,
        },
      };
    case SET_HAS_SELECTION_CHANGED:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          ...action.payload,
        },
      };
    case SET_FILTER_SETTING:
      if (action.replace) {
        return {
          ...state,
          filterSettings: action.payload,
        };
      } else {
        return {
          ...state,
          filterSettings: {
            ...state.filterSettings,
            ...action.payload,
          },
        };
      }
    case SET_DOOR_DETAIL_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          ...action.payload,
        },
      };
    case SET_RETIRED_COMPONENTS:
      return {
        ...state,
        retiredComponents: {
          ...state.retiredComponents,
          ...action.payload,
        },
      };
    case SET_RESET_RETIRED_COMPONENTS:
      return {
        ...state,
        retiredComponents: {},
      };
    case LOCATION_CHANGE:
      if (window.localStorage.getItem("state")) {
        return state;
      }
      return exteriorPrehungDefaultState;
    case SET_STEP_TWO_COMPLETE:
      return {
        ...state,
        doorViewOptions: {
          ...state.doorViewOptions,
          stepTwoComplete: action.payload,
        },
      };
    case SET_BORE_DETAILS:
      return {
        ...state,
        selections: {
          ...state.selections,
          boreDetails: action.payload,
        },
      };

    default:
      return state;
  }
};

export default exteriorPrehungReducer;
