import { fetch } from "../../../Network";
import { toJson } from "../../../api";
import { toSnakeCase } from "../../../utils";

const retrieve = async (url, options, data) => {
  const response = await fetch(url, options, toSnakeCase(data));
  return toJson(response);
};

const builderFunctions = {
  async addToNewQuote(
    name,
    product,
    quantity,
    leadResourceId,
    leadItemResourceId,
  ) {
    const encodedURIName = encodeURIComponent(name);
    let url = `/v3/orders?name=${encodedURIName}`;
    if (quantity) {
      url += `&quantity=${quantity}`;
    }
    if (leadResourceId && leadItemResourceId) {
      url += `&lead-resource-id=${leadResourceId}&lead-item-resource-id=${leadItemResourceId}`;
    }
    return retrieve(
      url,
      {
        method: "POST",
      },
      product,
    );
  },
  async addToExistingQuote(
    resourceId,
    product,
    quantity,
    leadResourceId,
    leadItemResourceId,
  ) {
    let url = `/v3/orders/${resourceId}/items`;
    if (quantity) {
      url += `?quantity=${quantity}`;
    }

    if (leadResourceId && leadItemResourceId) {
      const prefix = quantity ? "&" : "?";
      url += `${prefix}lead-resource-id=${leadResourceId}&lead-item-resource-id=${leadItemResourceId}`;
    }
    return retrieve(
      url,
      {
        method: "POST",
      },
      product,
    );
  },

  async updateExistingQuote(orderResourceId, orderItemResourceId, product) {
    return retrieve(
      `/v3/orders/${orderResourceId}/items/${orderItemResourceId}`,
      {
        method: "PUT",
      },
      product,
    );
  },

  async getDefaultSpecs() {
    return retrieve("/v3/products/doors/exterior/pre-hung/spec/default", {
      method: "GET",
    });
  },
  async getFilteredOptions(properties, productType) {
    const requestParam = productType ? `?productType=${productType}` : "";
    return retrieve(
      `/v3/products/doors/exterior/pre-hung/spec/filter${requestParam}`,
      {
        method: "POST",
      },
      properties,
    );
  },
  async getSlabLayouts(glassDesign) {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/slab-layouts",
      {
        method: "POST",
      },
      glassDesign,
    );
  },
  async getSlabDesigns(properties) {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/slab-designs",
      {
        method: "POST",
      },
      properties,
    );
  },
  async getGlassDesigns(options) {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/glass-designs",
      {
        method: "POST",
      },
      options,
    );
  },
  async getFinishes() {
    return retrieve("/v3/products/doors/exterior/pre-hung/spec/finishes", {
      method: "GET",
    });
  },
  async getHardwares() {
    return retrieve("/v3/products/doors/exterior/pre-hung/spec/hardwares", {
      method: "POST",
    });
  },
  async getPeepSites(properties) {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/peep-sites",
      {
        method: "POST",
      },
      properties,
    );
  },
  async getCasings() {
    return retrieve("/v3/products/doors/exterior/pre-hung/spec/casings", {
      method: "GET",
    });
  },
  async getBrickMolds() {
    return retrieve("/v3/products/doors/exterior/pre-hung/spec/brick-molds", {
      method: "GET",
    });
  },
  async getDimensions(properties) {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/dimensions",
      {
        method: "POST",
      },
      properties,
    );
  },
  async getDimensionCompatibleMatrix(properties) {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/dimensions/compatibility",
      {
        method: "POST",
      },
      properties,
    );
  },
  async getRoughOpenings(dimensions) {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/rough-opening",
      {
        method: "POST",
      },
      dimensions,
    );
  },
  async getGlazingTypes() {
    return retrieve("/v3/products/doors/exterior/pre-hung/spec/glazing-types", {
      method: "GET",
    });
  },
  async getHurricaneRatings() {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/hurricane-rated",
      {
        method: "GET",
      },
    );
  },
  async getImpactRatings() {
    return retrieve("/v3/products/doors/exterior/pre-hung/spec/impact-rated", {
      method: "GET",
    });
  },
  async getHandings() {
    return retrieve("/v3/products/doors/exterior/pre-hung/spec/handing", {
      method: "GET",
    });
  },
  async getJambDepths() {
    return retrieve("/v3/products/doors/exterior/pre-hung/spec/jamb-depth", {
      method: "GET",
    });
  },
  async getDoorCovered() {
    return retrieve("/v3/products/doors/exterior/pre-hung/spec/door-covered", {
      method: "GET",
    });
  },
  async getInactivePreps() {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/inactive-preps",
      {
        method: "GET",
      },
    );
  },
  async getSill(specs) {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/sill",
      {
        method: "POST",
      },
      specs,
    );
  },
  async getHingeFinishes() {
    return retrieve("/v3/products/doors/exterior/pre-hung/spec/hinge", {
      method: "GET",
    });
  },
  async getQuote(orderResourceId) {
    return retrieve(`/v3/orders/${orderResourceId}`, {
      method: "GET",
    });
  },
  async getProduct(specs, companyResourceId) {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung" +
        (companyResourceId !== null
          ? `?companyResourceId=${companyResourceId}`
          : ""),
      {
        method: "POST",
      },
      specs,
    );
  },
  async getColors(family) {
    return retrieve(
      `/v3/products/doors/exterior/pre-hung/custom-colors?family=${family}`,
      {
        method: "GET",
      },
    );
  },
  async getColor(colorCode) {
    return retrieve(
      `/v3/products/doors/exterior/pre-hung/custom-colors/${colorCode}`,
      {
        method: "GET",
      },
    );
  },
  async getLead(resourceId) {
    return retrieve(`/v3/leads/${resourceId}`, {
      method: "GET",
    });
  },
  async getCutDoorHeights() {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/cut-down-height",
      {
        method: "GET",
      },
    );
  },
  async getBoreMeasurement(doorHeight) {
    return retrieve(
      "/v3/products/doors/exterior/pre-hung/spec/bores",
      {
        method: "POST",
      },
      doorHeight,
    );
  },
};

export default builderFunctions;
