export const ORDER_STATUS = {
  OPEN: "OPEN",
  DELAYED: "DELAYED",
  PAYMENT_SHIPPING: "PAYMENT_SHIPPING",
  PAYMENT_CREDIT_CARD: "PAYMENT_CREDIT_CARD",
  PAYMENT_BEHALF: "PAYMENT_BEHALF",
  PENDING_BEHALF_REVIEW: "PENDING_BEHALF_REVIEW",
  PAYMENT_WARRANTY: "PAYMENT_WARRANTY",
  PAYMENT_REVIEW: "PAYMENT_REVIEW",
  PENDING: "PENDING",
  PURCHASED: "PURCHASED",
  RECEIVED: "RECEIVED",
  SHIPPED: "SHIPPED",
  CANCELED: "CANCELED",
  REFUNDED: "REFUNDED",
  CLOSED: "CLOSED",
};

export const ORDER_PARENT_STATUS = {
  OPEN: "OPEN",
  PAYMENT: "PAYMENT",
  PURCHASED: "PURCHASED",
  CLOSED: "CLOSED",
};

export const QUOTE_GROUP_FILTER = `orderType==SALE;state.parent_status=in=(${ORDER_PARENT_STATUS.OPEN},${ORDER_PARENT_STATUS.PAYMENT})`;

export const ORDER_GROUP_FILTER = `orderType==SALE;state.parent_status=in=(${ORDER_PARENT_STATUS.PURCHASED},${ORDER_PARENT_STATUS.CLOSED})`;

export function isOrder(state) {
  return (
    state.parentStatus === ORDER_PARENT_STATUS.PURCHASED ||
    state.parentStatus === ORDER_PARENT_STATUS.CLOSED
  );
}

export const STATUS = {
  EXPIRED: "EXPIRED",
  DISCONTINUED: "DISCONTINUED",

  ACTIVE: "Active",
  INACTIVE: "Inactive",

  ENABLED: "Enabled",
  DISABLED: "Disabled",

  RESERVED: "RESERVED",
  NOT_RESERVED: "NOT_RESERVED",
};

export const TAB_CONTEXT_TYPE = {
  METRICS_COMPANY: "METRICS_COMPANY",
  METRICS_CONTACT: "METRICS_CONTACT",
  CONTACTS: "CONTACTS",
  COMPANY_ORDER_QUOTE: "COMPANY_ORDER_QUOTE",
  CONTACT_ORDER_QUOTE: "CONTACT_ORDER_QUOTE",
};

export const RESERVATION_STATUS = {
  RELEASE: "RELEASE",
  CHECK_AVAILABILITY: "CHECK_AVAILABILITY",
  REQUEST: "REQUEST",
};

//TODO: Decide on official colors for these statuses.
export function getDefaultStatusColor(status) {
  switch (status) {
    case STATUS.EXPIRED:
    case STATUS.DISCONTINUED:
      return "#f44336";
    case STATUS.ACTIVE:
    case STATUS.ENABLED:
      return "#43A047";
    case STATUS.INACTIVE:
    case STATUS.DISABLED:
      return "#BDBDBD";
    case STATUS.RESERVED:
      return "#868e96";
    case STATUS.NOT_RESERVED:
      return "#f44336";
    default:
      return "#000";
  }
}
