import { createAction } from "@reduxjs/toolkit";

export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const RESET_PAYMENT = "RESET_PAYMENT";
export const SET_STATE = "SET_STATE";
export const SET_PAYMENT_ACTIVE_STEP = "SET_PAYMENT_ACTIVE_STEP";
export const SET_SHIPPING = "SET_SHIPPING";
export const SET_SHIPPING_DATES = "SET_SHIPPING_DATES";
export const SET_SHIPPING_DATES_LOADING = "SET_SHIPPING_DATES_SPINNER";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_STRIPE = "SET_STRIPE";
export const SET_BEHALF = "SET_BEHALF";
export const SET_MASONITE_CREDIT = "SET_MASONITE_CREDIT";
export const SET_TAX_ESTIMATE = "SET_TAX_ESTIMATE";
export const SET_WARRANTY = "SET_WARRANTY";
export const SET_SHOW_CANCEL_CONFIRMATION = "SET_SHOW_CANCEL_CONFIRMATION";
export const SET_SHOW_SAVED_DATA_CONFIRMATION =
  "SET_SHOW_SAVED_DATA_CONFIRMATION";
export const SET_PAYMENT_FLOW_EXPIRATION = "SET_PAYMENT_FLOW_EXPIRATION";
export const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";

export const createPayment = createAction(CREATE_PAYMENT);
export const deletePayment = createAction(DELETE_PAYMENT);
export const resetPayment = createAction(RESET_PAYMENT);
export const setSpecificState = createAction(SET_STATE);
export const setActiveStep = createAction(SET_PAYMENT_ACTIVE_STEP);
export const setShippingObject = createAction(SET_SHIPPING);
export const setShippingDates = createAction(SET_SHIPPING_DATES);
export const setShippingDatesLoading = createAction(SET_SHIPPING_DATES_LOADING);
export const setPaymentMethod = createAction(SET_PAYMENT_METHOD);
export const setStripe = createAction(SET_STRIPE);
export const setBehalf = createAction(SET_BEHALF);
export const setMasoniteCredit = createAction(SET_MASONITE_CREDIT);
export const setTaxEstimate = createAction(SET_TAX_ESTIMATE);
export const setWarranty = createAction(SET_WARRANTY);
export const setShowCancelConfirmation = createAction(
  SET_SHOW_CANCEL_CONFIRMATION
);
export const setShowSaveDataConfirmation = createAction(
  SET_SHOW_SAVED_DATA_CONFIRMATION
);
export const setPaymentFlowExpiration = createAction(
  SET_PAYMENT_FLOW_EXPIRATION
);
