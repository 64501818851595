import React from "react";
import kebabCase from "lodash/kebabCase";
import * as PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({
  open,
  onClose,
  label,
  content,
  onPrimaryClick,
  primaryLabel,
  primaryDisabled,
  hidePrimaryButton,
  hideSecondaryBtn,
  onSecondaryClick,
  tertiaryButton,
  secondaryLabel,
  dataTest,
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      data-test={dataTest}
    >
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            sx={{
              color: "text.primary",
            }}
            data-test="close-zoom-btn"
            edge="start"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">{label}</Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          padding: "18px",
        }}
      >
        {content}

        {onPrimaryClick && (
          <Grid container style={{ margin: "0 0 24px" }}>
            {!hideSecondaryBtn && (
              <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <Button
                  fullWidth
                  variant={"outlined"}
                  onClick={onSecondaryClick || onClose}
                  data-test={`${kebabCase(secondaryLabel)}-btn`}
                >
                  {secondaryLabel}
                </Button>
              </Grid>
            )}
            {tertiaryButton && (
              <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <Button
                  fullWidth
                  data-test="tertiary-btn"
                  onClick={tertiaryButton.onClick}
                  variant="outlined"
                  disabled={tertiaryButton.disabled}
                >
                  {tertiaryButton.label}
                </Button>
              </Grid>
            )}
            {!hidePrimaryButton && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color={"secondary"}
                  variant={"contained"}
                  onClick={onPrimaryClick}
                  disabled={primaryDisabled}
                  data-test={`${kebabCase(primaryLabel)}-btn`}
                >
                  {primaryLabel}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Dialog>
  );
};

FullScreenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  onPrimaryClick: PropTypes.func,
  primaryLabel: PropTypes.string,
  primaryDisabled: PropTypes.bool,
  showSecondaryBtn: PropTypes.bool,
  onSecondaryClick: PropTypes.func,
  secondaryLabel: PropTypes.string,
  dataTest: PropTypes.string,
};

export default FullScreenDialog;
