import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import anime from "animejs";
import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { ResultMessage } from "@masonite-digital/common-components";
import ZoomIcon from "@mui/icons-material/ZoomIn";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  MobileStepper,
  Skeleton,
  Typography,
} from "@mui/material";
import DelayedIcon from "../../../components/Icons/DelayedIcon";
import {
  HardwareImageSelector,
  ImageSelector,
} from "../../../components/Selection";
import {
  setHasSelectionChanged,
  setHingeSelection,
} from "../../../redux/actions/exteriorPrehungActionCreators";
import {
  fetchPeepSites,
  fetchSill,
} from "../../../redux/actions/exteriorPrehungFetchActions";
import ZoomComponent from "../preview/ZoomComponent";

function getFinishes(part, wrappers) {
  for (let value of wrappers) {
    if (part.resourceId === value.part.resourceId) {
      return value.finishes;
    }
  }
  return [];
}

const HardwareSectionMobile = ({
  isLoading,
  isSillLoading,
  isHingeLoading,
  isBoreDetailsLoading,
  hardwareError,
  sillError,
  hingeError,
  boreDetailsError,
  selectedHardware,
  selectedHardwareFinish,
  selectedHinge,
  onClick,
  hardwareOptions,
  onHardwareFinishChange,
  sillOption,
  hingeOptions,
  retryHardwareCall,
  retrySillCall,
  retryBoreDetailsCall,
  roughOpening,
  selections,
  slabLayout,
  onInactivePrepClick,
  inactivePrep,
  boreMeasurements,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const dispatch = useDispatch();
  const noHvhzAndDoorCovered =
    !selections.hurricaneRated.value && selections.isDoorCovered.value;

  const hasHingeOrSillError =
    !isSillLoading &&
    !isHingeLoading &&
    (isEmpty(sillOption) || sillError || isEmpty(hingeOptions) || hingeError);
  const hingeOrSillErrorMessage = hingeError
    ? hingeError.message
    : sillError?.message;
  const isNoHardware = selectedHardware.description === "None";
  const sillTypeMessage = noHvhzAndDoorCovered
    ? "bumper sill"
    : "high-dam sill";
  const sillTypeMessage2 = noHvhzAndDoorCovered
    ? ""
    : "to prevent water from entering";

  function getIndex() {
    for (let i = 0; i < hardwareOptions.length; i++) {
      if (hardwareOptions[i].part.resourceId === selectedHardware.resourceId) {
        return i;
      }
    }
    return 0;
  }

  const [activeStep, setActiveStep] = useState(getIndex());

  function transitionSlide(i) {
    anime({
      targets: `.slide-${i}`,
      borderColor: ["rgba(0, 0, 0, 0)", "#00aeef"],
      duration: 200,
      easing: "easeInOutQuad",
    });
    anime({
      targets: `.slide-${activeStep}`,
      borderColor: ["#00aeef", "rgba(0, 0, 0, 0)"],
      duration: 200,
      easing: "easeInOutQuad",
    });
    setActiveStep(i);
    onClick(hardwareOptions[i]);
  }

  const onHingeFinishChange = (option) => {
    dispatch(setHingeSelection(option));
    dispatch(
      setHasSelectionChanged({
        hasAnySelectionChanged: true,
        hasChangedFromDefault: true,
      })
    );
    dispatch(fetchSill());
    dispatch(fetchPeepSites());
  };

  if (isLoading) {
    return (
      <div>
        <Typography gutterBottom>
          <Skeleton
            style={{ marginTop: "4px", marginBottom: "10px" }}
            variant="rectangular"
            width="100%"
            height={40}
          />
        </Typography>
        <Skeleton
          style={{
            marginTop: "8px",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "16px",
          }}
          variant="rectangular"
          width={240}
          height={8}
        />
        <Skeleton
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "20px",
          }}
          variant="rectangular"
          width={226}
          height={231}
        />
        <div style={{ margin: "20px 0", minWidth: "210px" }}>
          <Typography variant="subtitle1">
            <Skeleton
              style={{ marginTop: "6px", marginBottom: "6px" }}
              variant="rectangular"
              width={65}
              height={16}
            />
          </Typography>
          <Typography variant="body2">
            <Skeleton
              style={{ marginTop: "3px", marginBottom: "3px" }}
              variant="rectangular"
              width={200}
              height={14}
            />
          </Typography>
          <Typography variant="body2" gutterBottom>
            <Skeleton
              style={{ marginTop: "3px", marginBottom: "3px" }}
              variant="rectangular"
              width={75}
              height={14}
            />
          </Typography>
          <Typography variant="subtitle1">
            <Skeleton
              style={{ marginTop: "6px", marginBottom: "6px" }}
              variant="rectangular"
              width={65}
              height={16}
            />
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Skeleton
                style={{ margin: "6px" }}
                variant="rectangular"
                width={36}
                height={36}
              />
            </Grid>
            <Grid item>
              <Skeleton
                style={{ margin: "6px" }}
                variant="rectangular"
                width={36}
                height={36}
              />
            </Grid>
            <Grid item>
              <Skeleton
                style={{ margin: "6px" }}
                variant="rectangular"
                width={36}
                height={36}
              />
            </Grid>
          </Grid>
          <Skeleton
            style={{ marginTop: "16px" }}
            variant="rectangular"
            width={70}
            height={30}
          />
        </div>
      </div>
    );
  }

  if (isEmpty(hardwareOptions)) {
    return (
      <ResultMessage
        type="WARNING"
        title="Something went wrong"
        message={hardwareError}
        onClick={retryHardwareCall}
      />
    );
  }

  let hardwareDetailSection;
  if (isNoHardware) {
    hardwareDetailSection = (
      <>
        <Typography variant="overline" color="textSecondary">
          {isBoreDetailsLoading ? <Skeleton /> : "Bore Details"}
        </Typography>
        <Grid container sx={{ mt: 1 }} spacing={0.5}>
          {boreDetailsError ? (
            <ResultMessage
              type="WARNING"
              title="Something went wrong"
              message={boreDetailsError}
              onClick={retryBoreDetailsCall}
            />
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {isBoreDetailsLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      Diameter:
                      <Typography
                        data-test="active-bore-diameter"
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        ml={1}
                      >
                        {boreMeasurements?.diameter}
                      </Typography>
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {isBoreDetailsLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      Backset:
                      <Typography
                        data-test="active-bore-backset"
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        ml={1}
                      >
                        {boreMeasurements?.backSet}
                      </Typography>
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {isBoreDetailsLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      Center Line:
                      <Typography
                        data-test="active-bore-center-line"
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        ml={1}
                      >
                        {boreMeasurements?.centerLine}
                      </Typography>
                    </>
                  )}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  } else {
    hardwareDetailSection = (
      <>
        <Typography variant="subtitle1" color="primary">
          Colors
        </Typography>
        <HardwareImageSelector
          imgSizeSx={{ width: 32, height: 32 }}
          forceExtraSmallIcons
          selectedHardware={selectedHardware}
          selectedHardwareFinish={selectedHardwareFinish}
          onSelect={onHardwareFinishChange}
          options={getFinishes(selectedHardware, hardwareOptions)}
          optionType="Hardware Finish"
        />
        <Chip
          data-test="package-type-chip"
          variant="outlined"
          label={
            <div data-test="package-type">{selectedHardware.packageType}</div>
          }
          sx={{ mt: 2 }}
        />
      </>
    );
  }

  return (
    <div>
      <Typography gutterBottom>
        Swipe left or right to select the type of hardware for your door:
      </Typography>
      <MobileStepper
        sx={{
          backgroundColor: "background.paper",
          marginBottom: "8px",
        }}
        position="static"
        activeStep={activeStep}
        backButton={<div />}
        nextButton={<div />}
        steps={hardwareOptions.length}
      />
      <SwipeableViews
        index={activeStep}
        onChangeIndex={transitionSlide}
        enableMouseEvents
      >
        {hardwareOptions.map((option, i) => {
          let previewImage = option.finishes[0]?.links;

          if (option.part.resourceId === selectedHardware.resourceId) {
            if (isNoHardware) {
              previewImage = option.part.finish.links;
            } else {
              previewImage = get(option, "finishes", []).find(
                (finish) =>
                  finish.resourceId === selectedHardwareFinish.resourceId
              ).links;
            }
          }

          return (
            <Box
              key={i}
              sx={{
                userSelect: "none",
                padding: "0 10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className={`slide-${i}`}
                sx={(theme) => ({
                  width: "fit-content",
                  border:
                    i === activeStep
                      ? `1px solid ${theme.palette.secondary.main}`
                      : "1px solid rgba(0, 0, 0, 0)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  backgroundColor: "common.white",
                })}
              >
                <Box
                  component="img"
                  sx={{ width: 224, height: "auto" }}
                  alt={option.description}
                  src={get(
                    find(previewImage, {
                      rel: "preview-image",
                    }),
                    "href",
                    ""
                  )}
                />

                {i === activeStep && !isNoHardware && (
                  <Box
                    sx={{
                      width: "fit-content",
                      border: "1px solid #cfd4da",
                      borderRadius: "3px",
                      marginTop: "-36px",
                      marginRight: "5px",
                      marginBottom: "5px",
                      alignSelf: "flex-end",
                    }}
                  >
                    <IconButton
                      data-test="hardware-zoom-btn"
                      onClick={() => setPreviewOpen(true)}
                      style={{ padding: "5px" }}
                    >
                      <ZoomIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </SwipeableViews>
      <div style={{ margin: "20px 0", minWidth: "210px" }}>
        <Typography variant="subtitle1" color="primary">
          Selected:
        </Typography>
        <Typography variant="body2">{selectedHardware.description}</Typography>
        {!isNoHardware && (
          <Typography variant="body2" color="primary" gutterBottom>
            {selectedHardwareFinish.description}
          </Typography>
        )}
        {hardwareDetailSection}
        {!selectedHardwareFinish.available && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <DelayedIcon />
            <Typography
              data-test="delayed-hardware-text"
              style={{ marginTop: "16px", marginLeft: "16px" }}
              variant="body2"
              color="error"
            >
              This item is temporarily out of stock. Selecting it may increase
              your delivery lead time.
            </Typography>
          </div>
        )}
      </div>
      {slabLayout.slabType === "DOUBLE" && (
        <Grid item container spacing={1} direction="column">
          <Grid item>
            <Typography
              sx={(theme) => ({
                fontSize: "0.75rem",
                color: theme.palette.primary.main,
                fontWeight: 500,
                textTransform: "uppercase",
              })}
            >
              Inactive Prep
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              label="Include Inactive Prep?"
              onChange={(event, value) => {
                onInactivePrepClick(value);
              }}
              control={
                <Checkbox
                  data-test="inactive-prep-check-box"
                  color="secondary"
                  checked={inactivePrep.description === "With Inactive Prep"}
                />
              }
            />
          </Grid>
          <Grid container sx={{ ml: 5, mt: -0.5 }}>
            <Grid item>
              <Typography
                data-test="inactive-prep-note"
                variant="body2"
                color="textSecondary"
              >
                <strong>Note: </strong>
                {isNoHardware
                  ? "Inactive Prep will prepare the passive side of your double door configuration for dummy hardware."
                  : "Inactive Prep is a duplicate version of your chosen door hardware that is installed on the passive side of your double door configuration."}
              </Typography>
            </Grid>
            {isNoHardware && (
              <>
                <Grid item xs={12}>
                  <Typography
                    display="inline"
                    variant="body2"
                    color="textSecondary"
                  >
                    Diameter:
                  </Typography>
                  <Typography
                    data-test="inactive-bore-diameter"
                    display="inline"
                    ml={1}
                    variant="body2"
                  >
                    {boreMeasurements?.diameter}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    display="inline"
                    variant="body2"
                    color="textSecondary"
                  >
                    Backset:
                  </Typography>
                  <Typography
                    data-test="inactive-bore-backset"
                    display="inline"
                    ml={1}
                    variant="body2"
                  >
                    {boreMeasurements?.backSet}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
      <Grid
        data-test="sill-section"
        item
        xs={12}
        container
        spacing={1}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary">
            Sill and Hinges
          </Typography>
        </Grid>
        {hasHingeOrSillError && (
          <ResultMessage
            type="WARNING"
            title="Something went wrong"
            message={hingeOrSillErrorMessage}
            onClick={retrySillCall}
          />
        )}
        {!hasHingeOrSillError && (
          <Grid item>
            {isSillLoading && (
              <Grid item xs={12} pb={1}>
                <Skeleton variant="rectangular" width={232} height={24} />
              </Grid>
            )}
            {!isSillLoading && (
              <>
                {sillOption.newHeight ? (
                  <Grid item xs={12}>
                    <Typography data-test="hi-dam-sill" variant="caption">
                      {`A ${sillTypeMessage} will be added to your door
                      ${sillTypeMessage2}
                      (metal finished in ${
                        selectedHardwareFinish.description
                      }). The height of your door will 
                      change to ${roughOpening && roughOpening.unitHeight}".`}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography data-test="default-sill" variant="caption">
                      Sill and hinges will be added to your door.
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography display="inline">Sill:</Typography>
                  <Typography
                    data-test="sill-finish-type"
                    display="inline"
                    ml={1}
                  >
                    {sillOption.description}
                  </Typography>
                </Grid>
              </>
            )}
            {isHingeLoading && (
              <Grid item xs={12}>
                <Skeleton variant="rectangular" width={232} height={24} />
              </Grid>
            )}
            {!isHingeLoading && (
              <Grid item xs={12}>
                <Typography display="inline">Hinges:</Typography>
                <Typography
                  data-test="hinge-description"
                  display="inline"
                  ml={1}
                >
                  {
                    selectedHinge?.hinges.find(
                      (hinge) =>
                        hinge.handingResourceId ===
                        selections.handing.resourceId
                    ).description
                  }
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="textSecondary">
            Finish
          </Typography>
          <ImageSelector
            isLoading={isHingeLoading}
            imgSizeSx={{ width: 32, height: 32 }}
            options={hingeOptions}
            optionType="Finish"
            selected={selections.hinge}
            onSelect={onHingeFinishChange}
            gridContainerProps={{ spacing: 0 }}
            showDescription={false}
          />
        </Grid>
      </Grid>
      {!isNoHardware && (
        <ZoomComponent
          title="Hardware"
          open={previewOpen}
          handleClose={() => setPreviewOpen(false)}
          previewImg={selectedHardwareFinish.links[0].href}
        />
      )}
    </div>
  );
};

HardwareSectionMobile.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hardwareError: PropTypes.object,
  selectedHardware: PropTypes.object,
  selectedHardwareFinish: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  hardwareOptions: PropTypes.array,
  onHardwareFinishChange: PropTypes.func,
  retryHardwareCall: PropTypes.func.isRequired,
  slabLayout: PropTypes.object,
  inactivePrep: PropTypes.object,
  onInactivePrepClick: PropTypes.func.isRequired,
  isSillLoading: PropTypes.bool.isRequired,
  isHingeLoading: PropTypes.bool.isRequired,
  isBoreDetailsLoading: PropTypes.bool.isRequired,
  sillError: PropTypes.string,
  hingeError: PropTypes.string,
  boreDetailsError: PropTypes.string,
  selectedHinge: PropTypes.object,
  sillOption: PropTypes.object,
  selections: PropTypes.object.isRequired,
  hingeOptions: PropTypes.array.isRequired,
  retrySillCall: PropTypes.func.isRequired,
  retryBoreDetailsCall: PropTypes.func.isRequired,
  roughOpening: PropTypes.object,
  boreMeasurements: PropTypes.object,
};

export default HardwareSectionMobile;
