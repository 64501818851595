import React from "react";
import PropTypes from "prop-types";
import {
  CustomDatePicker,
  MultilineTextField,
  NameTextField,
  PhoneNumberTextField,
  SelectTextField,
  ZipCodeTextField,
} from "@masonite-digital/common-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { convertDate, formatDate, states } from "../../utils";

function formatDateForCalendar(date) {
  // If it's an actual date object, we're safe to just pass it right along to the date picker
  // If it's a raw string then it probably came straight from our DB, in which case we need to tweak it to avoid wacky UTC/EST conversion shenanigans.
  return typeof date === "string" ? formatDate(date) : date;
}

function handleDisabledDates(date, shippingDates) {
  const dates = typeof date === "string" ? date : convertDate(date);
  return !(shippingDates.length === 0 ? true : shippingDates.includes(dates));
}

export default function ShippingDetailsCard({
  data,
  shippingDates,
  loading,
  onChange,
}) {
  const { errors } = data;

  function createChangeHandler(type) {
    return function handleChange(e) {
      onChange({ [type]: e.target.value });
    };
  }

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} md={6}>
        <Grid item xs={12}>
          <NameTextField
            autoFocus
            label="Name"
            onChange={createChangeHandler("name")}
            value={data.name}
            error={errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <NameTextField
            label="Address Line 1"
            onChange={createChangeHandler("line1")}
            value={data.line1}
            error={errors.line1}
          />
        </Grid>
        <Grid item xs={12}>
          <NameTextField
            label="Address Line 2 (Optional)"
            onChange={createChangeHandler("line2")}
            value={data.line2}
            error={errors.line2}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <NameTextField
                label="City"
                onChange={createChangeHandler("city")}
                value={data.city}
                error={errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectTextField
                label="State"
                id="state"
                range={states}
                onChange={createChangeHandler("state")}
                value={data.state || ""}
                error={errors.state}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ZipCodeTextField
                onChange={createChangeHandler("zipCode")}
                value={data.zipCode}
                error={errors.zipCode}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PhoneNumberTextField
            onChange={createChangeHandler("phone")}
            value={data.phone}
            error={errors.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <MultilineTextField
            label="Notes (Optional)"
            dataTest="notes-input"
            onChange={createChangeHandler("notes")}
            value={data.notes}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={6}
        justifyContent="center"
        alignItems={"center"}
      >
        <Grid item style={{ height: "332px" }}>
          {loading && (
            <Skeleton variant="rectangular" width={310} height={335} />
          )}
          {!loading && shippingDates.length > 0 ? (
            <div style={{ width: "100%", justify: "center" }}>
              <FormLabel>Select a Delivery Date:</FormLabel>
              <Paper
                data-test="select-delivery-date"
                style={{ overflow: "hidden", marginTop: "10px" }}
              >
                <CustomDatePicker
                  isLoading={loading}
                  shouldDisableDate={(date) =>
                    handleDisabledDates(date, shippingDates)
                  }
                  date={formatDateForCalendar(data.deliveryDate)}
                  handleChange={(deliveryDate) =>
                    onChange({ deliveryDate: convertDate(deliveryDate) })
                  }
                />
              </Paper>
            </div>
          ) : (
            !loading && (
              <FormLabel>
                To select a delivery date, please enter your ZIP code.
              </FormLabel>
            )
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems={"center"}
        >
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={
              <Switch
                color="secondary"
                className="default"
                data-test="leave-at-site-toggle"
                checked={data.leaveAtSite}
                onChange={(e) => onChange({ leaveAtSite: e.target.checked })}
              />
            }
            label={
              <Typography variant={"body2"} color={"inherit"}>
                Deliver the item(s) even if I’m not present.
              </Typography>
            }
          />
          <Typography
            component="div"
            style={{ paddingLeft: "22%", fontSize: "0.7rem" }}
          >
            Note: Unattended delivery is not currently available for
            Condominiums or other Restricted Access Communities.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

ShippingDetailsCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  shippingDates: PropTypes.array,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};
