import React from "react";
import { AlertDialog } from "@masonite-digital/common-components";

export default class UncaughtErrorBoundary extends React.Component {
  state = {
    showModal: false,
    title: "",
    message: "",
    isRenderError: false,
    redirectHome: false,
  };

  componentDidCatch(error, errorInfo) {
    console.log("componentDidCatch: ", error);
    this.showRenderError();
  }

  handleError = (error) => {
    console.log("error: ", error);
    if (
      error.message.startsWith("Uncaught SyntaxError: Unexpected token <") ||
      error.message.startsWith("SyntaxError: expected expression, got '<'")
    ) {
      this.showLoadError();
    } else {
      this.showError(error.message);
    }
  };

  handleRejection = (error) => {
    console.log("unhandledRejection: ", error);
    this.showError(error.reason && error.reason.message);
  };

  showError = (msg) => {
    if (!this.state.showModal) {
      this.setState({
        showModal: true,
        title: "Unexpected Error",
        message: this.cleanErrorMessage(msg),
        isRenderError: false,
        redirectHome: false,
      });
    }
  };

  showRenderError = () => {
    this.setState({
      showModal: true,
      title: "Unexpected Error",
      message:
        "There was an error rendering the page. Please reload the application.",
      isRenderError: true,
      redirectHome: true,
    });
  };

  showLoadError = () => {
    this.setState({
      showModal: true,
      title: "Something Went Wrong",
      message:
        "It looks like the application has been updated. Please reload the page.",
      isRenderError: true,
      redirectHome: false,
    });
  };

  cleanErrorMessage = (msg) => {
    if (msg && msg.startsWith("Uncaught") && msg.includes("Error: ")) {
      return msg.substring(msg.indexOf("Error: ") + 7);
    }
    if (msg) {
      return msg;
    }
    return "An unexpected error has occurred. Try the action again. If the problem persists, please contact customer service.";
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  reloadSite = () => {
    localStorage.removeItem("state");
    this.closeModal();
    if (this.state.redirectHome) {
      window.location.href = "/";
    } else {
      window.location.reload(true);
    }
  };

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.handleRejection);
    window.addEventListener("error", this.handleError);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.handleRejection);
    window.removeEventListener("error", this.handleError);
  }

  render = () => {
    return (
      <>
        {this.state.showModal && (
          <AlertDialog
            open
            dialogType="ERROR"
            title={this.state.title}
            contentText={this.state.message}
            hidePrimaryButton={!this.state.isRenderError}
            hideSecondaryButton={this.state.isRenderError}
            primaryLabel="Reload"
            secondaryLabel="Close"
            onPrimaryClick={this.reloadSite}
            onSecondaryClick={this.closeModal}
          />
        )}
        {!this.state.isRenderError && this.props.children}
      </>
    );
  };
}
