import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";

export default function ResetStateDialog(props) {
  const { open, onPrimaryClick, onSecondaryClick, mode } = props;

  function handlePrimaryClick() {
    onSecondaryClick();
    onPrimaryClick();
  }

  let dialogText;
  if (mode.isInEditMode) {
    dialogText =
      "This will remove all of your current changes and restore back to the original quoted door configuration. How would you like to proceed?";
  } else if (mode.isConsumerLead) {
    dialogText =
      "This will remove all of your current changes and restore back to the original consumer lead door configuration. How would you like to proceed?";
  } else {
    dialogText =
      "This will remove all of your current configurations and restore back to the default door. How would you like to proceed?";
  }

  return (
    <AlertDialog
      open={open}
      dialogType="WARNING"
      title="Reset Door Configuration?"
      onPrimaryClick={handlePrimaryClick}
      primaryLabel="Reset"
      onSecondaryClick={onSecondaryClick}
      secondaryLabel="Close"
      contentText={dialogText}
    />
  );
}

ResetStateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  mode: PropTypes.object.isRequired,
};
