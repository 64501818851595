import React from "react";
import PropTypes from "prop-types";
import {
  AlertDialog,
  MultilineTextField,
  SelectTextField,
} from "@masonite-digital/common-components";
import { PaymentType } from "../../actions/Payment";

const reasonOptions = ["Duplicate", "Fraudulent", "Requested by Customer"];

class RefundDialog extends React.Component {
  state = {
    reason: "",
    reasonError: false,
    description: "",
    descriptionError: false,
    errorMessage: null,
    shouldRefresh: false,
  };

  defaultState = this.state;

  validateAllFields = () => {
    this.setState((prevState) => ({
      reasonError: !prevState.reason,
      descriptionError: !prevState.description,
    }));
  };

  handlePrimaryClick = async () => {
    this.validateAllFields();
    const { reason, description, reasonError, descriptionError } = this.state;

    if (reasonError || descriptionError) {
      return;
    }

    try {
      await this.props.onPrimaryClick(
        reason.toLowerCase().replace(/\s/g, "_"),
        description
      );
    } catch (e) {
      this.setState({
        errorMessage: e.message,
      });
    }

    this.setState({ shouldRefresh: true });
  };

  handleReasonChange = (event) => {
    this.setState({
      reason: event.target.value,
      reasonError: false,
      errorMessage: null,
    });
  };

  handleDescriptionChange = (event) => {
    if (event.target.value.length <= 500) {
      this.setState({
        description: event.target.value,
        descriptionError: false,
        errorMessage: null,
      });
    }
  };

  handleOnExited = async () => {
    const { onRefresh } = this.props;
    this.setState({
      ...this.defaultState,
    });

    if (this.state.shouldRefresh) {
      await onRefresh();
    }
  };

  render() {
    const { open, amount, paymentType, onSecondaryClick } = this.props;

    const paymentText =
      paymentType === PaymentType.CREDIT_CARD
        ? "credit card"
        : "Behalf account";
    const contentText = (
      <>
        <strong>Refunding an order is an irreversible operation.</strong>
        <br />
        This will result in a full refund of <strong>{amount}</strong> to the
        customer's {paymentText}. Customer Service and the order purchaser will
        be notified of the refund.
      </>
    );

    return (
      <AlertDialog
        open={open}
        primaryLabel="Refund"
        onPrimaryClick={this.handlePrimaryClick}
        onSecondaryClick={onSecondaryClick}
        dialogType={this.state.errorMessage ? "ERROR" : ""}
        title="Refund Order?"
        contentText={contentText}
        helperText={this.state.errorMessage}
        TransitionProps={{
          onExited: this.handleOnExited,
        }}
        customContent={
          <div style={{ marginTop: "8px" }}>
            <SelectTextField
              required
              error={this.state.reasonError}
              label="Reason for Refund"
              range={reasonOptions}
              onChange={this.handleReasonChange}
              defaultOption="Select a Reason"
              value={this.state.reason}
            />
            <MultilineTextField
              required
              error={this.state.descriptionError}
              placeholder="Please add a refund description..."
              value={this.state.description}
              onChange={this.handleDescriptionChange}
              label="Description"
              type="text"
              dataTest="description-field"
            />
          </div>
        }
      />
    );
  }
}

RefundDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  amount: PropTypes.string.isRequired,
  paymentType: PropTypes.string.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default RefundDialog;
