import React from "react";
import find from "lodash/find";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SelectionBox from "./SelectionBox";
import NoImage from "../Icons/no-image-available.svg";

const HardwareImageSelector = (props) => {
  const {
    options,
    selectedHardware,
    selectedHardwareFinish,
    onSelect,
    gridContainerProps = {},
    gridItemProps = {},
    forceExtraSmallIcons,
    imgSizeSx,
    optionType = "Option",
    imgRel = "selection-image",
    imgPre = "preview-image",
  } = props;

  const validateSelection = (option) => {
    onSelect(option);
  };

  return (
    <Grid
      container
      spacing={2}
      {...gridContainerProps}
      data-test={kebabCase(`${optionType}-list`)}
    >
      {options.map((option, i) => {
        let isSelected;
        let imageHref;

        if (optionType === "Hardware Finish") {
          isSelected =
            selectedHardwareFinish &&
            option.resourceId === selectedHardwareFinish.resourceId;

          let links = option.links;
          imageHref = find(links, { rel: imgRel }).href;
        } else {
          isSelected =
            selectedHardware &&
            selectedHardware.resourceId === option.part.resourceId;
          let links = option.part.finish?.links;
          imageHref = find(links, { rel: imgPre })?.href;
        }
        const borderColor = isSelected ? "rgba(0, 0, 0, 0)" : "#cfd4da";

        return (
          <Grid
            item
            {...gridItemProps}
            key={i}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SelectionBox
              dataTest={
                option &&
                option.description &&
                kebabCase(`${optionType}-${option.description}`)
              }
              toolTipText={option.description}
              compatible={option.compatible == null ? true : option.compatible}
              selected={isSelected}
              onClick={() => validateSelection(option)}
              forceExtraSmall={forceExtraSmallIcons}
            >
              <div
                style={{
                  border: "solid 2px " + borderColor,
                  borderRadius: "3px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {option.name && option.name === "None" ? (
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      backgroundColor: "#dee2e6",
                    }}
                  >
                    <CancelIcon
                      style={{
                        width: 75,
                        height: 75,
                        color: "grey",
                      }}
                    />
                  </Grid>
                ) : (
                  <Box
                    component="img"
                    sx={imgSizeSx}
                    alt={option.description}
                    src={imageHref}
                    onError={(e) => {
                      e.target.src = NoImage;
                    }}
                  />
                )}
              </div>
            </SelectionBox>
          </Grid>
        );
      })}
    </Grid>
  );
};

HardwareImageSelector.propTypes = {
  gridContainerProps: PropTypes.object,
  gridItemProps: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  imgSizeSx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      compatible: PropTypes.bool,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          rel: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  optionType: PropTypes.string,
  imgRel: PropTypes.string,
  imgPre: PropTypes.string,
};

export default HardwareImageSelector;
