import React from "react";
import groupBy from "lodash/groupBy";
import { NavigationalDrawer } from "@masonite-digital/common-components";
import { AuthContext } from "../../../AuthContext";
import { AdvisarLogoButton } from "../../../components/Icons";
import { IncompatibleBrowserBox } from "../../../components/MessageBoxes";
import Activities from "../../index";
import ProfileMenu from "../Login/ProfileMenu";

export default function Dashboard({ handleTheming }) {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(
    localStorage.getItem("isNavigationalDrawerOpen") === "true"
  );

  const context = React.useContext(AuthContext);
  const isInverted = context.isInverted;
  const menuItems = Activities.map((activity) => activity.routes(context))
    .flat()
    .filter((route) => route.label || route.groupName);

  const invertLayout = async () => {
    await context.changeInverted();
  };

  const convertMenuItems = () => {
    return Object.entries(groupBy(menuItems, "groupName")).map(
      ([key, value]) => {
        if (key === "undefined") {
          return value[0];
        } else {
          return {
            groupName: value[0].groupName === "None" ? "" : value[0].groupName,
            items: value.map((item) => item.items).flat(),
          };
        }
      }
    );
  };

  const handleDrawerOpen = () => {
    localStorage.setItem(
      "isNavigationalDrawerOpen",
      JSON.stringify(!isDrawerOpen)
    );
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <NavigationalDrawer
      isInverted={isInverted}
      startAppBarOptions={<AdvisarLogoButton alt="Advisar Logo" isLoggedIn />}
      endAppBarOptions={
        <ProfileMenu
          handleTheming={handleTheming}
          handleInverting={invertLayout}
          user={context.whoAmI()}
          isInverted={isInverted}
        />
      }
      listOfMenuItems={convertMenuItems()}
      isDrawerOpen={isDrawerOpen}
      onDrawerClick={handleDrawerOpen}
    >
      <IncompatibleBrowserBox />
    </NavigationalDrawer>
  );
}
