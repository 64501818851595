import React from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { ResultMessage } from "@masonite-digital/common-components";
import PlusIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/Send";
import QuoteIcon from "@mui/icons-material/ShoppingCart";
import { useMediaQuery } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import consumerLeadsActivityApi from "./consumerLeadFunctions";
import { ConsumerHelpDialog, ConsumerLeadItems } from "../../actions/Consumer";

const loadConsumerLeadDetail = async (
  resourceId,
  setIsLoading,
  setConsumerLead,
  setError,
  setRedirect
) => {
  try {
    setIsLoading(true);
    const response = await consumerLeadsActivityApi.getConsumerLeadDetail(
      resourceId
    );
    setConsumerLead(response);
    setError(null);
  } catch (e) {
    if (e.status === 401) {
      setRedirect("/expired");
    } else {
      setError(e.message);
    }
  }
  setIsLoading(false);
};

export default function ConsumerDetailPage() {
  const [redirect, setRedirect] = React.useState(null);
  const [consumerLead, setConsumerLead] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [openHelpDialog, setOpenHelpDialog] = React.useState(true);
  const [emailSent, setEmailSent] = React.useState(false);

  const theme = useTheme();
  const { resourceId } = useParams();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const emailAlreadySent = localStorage.getItem("emailAlreadySent") === "true";

  React.useEffect(() => {
    if (localStorage.getItem("completedTutorial") === "true") {
      setOpenHelpDialog(false);
    }

    const accessToken = sessionStorage.getItem("accessToken");
    const searchParams = new window.URLSearchParams(location.search);

    if (
      isEmpty(accessToken) ||
      (searchParams.has("t") && accessToken !== searchParams.get("t"))
    ) {
      //If the user does not have a token in the search params, redirect to 404 page
      if (!searchParams.has("t") || isEmpty(searchParams.get("t"))) {
        setRedirect("/not-authorized");
        return;
      } else {
        sessionStorage.setItem("accessToken", searchParams.get("t"));
      }
    }

    loadConsumerLeadDetail(
      resourceId,
      setIsLoading,
      setConsumerLead,
      setErrorMessage,
      setRedirect
    );
  }, [resourceId, location]);

  const handleLoadConsumerLeadDetail = async () => {
    await loadConsumerLeadDetail(
      resourceId,
      setIsLoading,
      setConsumerLead,
      setErrorMessage,
      setRedirect
    );
  };

  const handleAddDoorClick = () => {
    setRedirect(`/door-builder/${resourceId}`);
  };

  const handleDeleteDoorClick = async (itemResourceId) => {
    localStorage.setItem("emailAlreadySent", JSON.stringify(false));
    await consumerLeadsActivityApi.deleteLeadItem(resourceId, itemResourceId);
  };

  const handleSendClick = async () => {
    localStorage.setItem("emailAlreadySent", JSON.stringify(true));
    await consumerLeadsActivityApi.sendEmailToContractor(
      consumerLead.resourceId
    );
    setEmailSent(true);
  };

  const handleOnCloseHelpDialogClick = () => {
    localStorage.setItem("completedTutorial", JSON.stringify(true));
    setOpenHelpDialog(false);
  };

  const handleSaveNoteClick = async (itemResourceId, note) => {
    await consumerLeadsActivityApi.updateLeadItemNote(
      resourceId,
      itemResourceId,
      note
    );
  };

  if (emailSent) {
    return (
      <Paper>
        <ResultMessage
          type="SUCCESS"
          title="Door(s) Sent!"
          message="Your door(s) have been sent to your contractor - they will be in touch soon."
        />
      </Paper>
    );
  }

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  if (errorMessage) {
    return (
      <ResultMessage
        type="WARNING"
        title="Something went wrong"
        message={errorMessage}
        onClick={handleLoadConsumerLeadDetail}
      />
    );
  }

  if (isLoading || !consumerLead) {
    return (
      <Grid container item justifyContent="center">
        <Skeleton variant="rectangular" height={400} width={600} />
      </Grid>
    );
  }

  return (
    <>
      <ConsumerHelpDialog
        open={openHelpDialog}
        onClose={handleOnCloseHelpDialogClick}
      />
      <Grid container item justifyContent="center">
        <Card
          sx={(theme) => ({
            minWidth: "600px",
            [theme.breakpoints.down("sm")]: {
              minWidth: "300px",
            },
          })}
        >
          <CardHeader
            avatar={
              <Avatar>
                <QuoteIcon />
              </Avatar>
            }
            title={consumerLead.firstName + " " + consumerLead.lastName}
          />
          <CardContent>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  {isEmpty(consumerLead.items) ? "Your cart is empty" : "Items"}
                </Typography>
              </Grid>
              {isEmpty(consumerLead.items) && (
                <Grid item xs={12}>
                  <Divider
                    sx={(theme) => ({
                      margin: `0 -${theme.spacing(2)}px`,
                    })}
                  />
                </Grid>
              )}
              {!isEmpty(consumerLead.items) && (
                <Grid item xs={12} sm={6} container justifyContent="flex-end">
                  <Button
                    data-test="add-a-door-btn"
                    variant="outlined"
                    color="secondary"
                    startIcon={<PlusIcon />}
                    onClick={handleAddDoorClick}
                  >
                    Add a Door
                  </Button>
                  <Button
                    data-test="send-btn"
                    variant="contained"
                    color="secondary"
                    disabled={emailAlreadySent}
                    style={{ marginLeft: "8px" }}
                    startIcon={emailAlreadySent ? <CheckIcon /> : <SendIcon />}
                    onClick={handleSendClick}
                  >
                    {emailAlreadySent ? "Sent" : "Send"}
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <ConsumerLeadItems
                  items={consumerLead.items}
                  isConsumer
                  onReload={handleLoadConsumerLeadDetail}
                  isMobile={isMobile}
                  onAddDoorClick={handleAddDoorClick}
                  onDelete={handleDeleteDoorClick}
                  onSaveNoteClick={handleSaveNoteClick}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
