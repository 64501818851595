import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import { AuthContext } from "../../AuthContext";

export default function EditDeleteReservationDialog(props) {
  const { open, onPrimaryClick, onSecondaryClick } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [primaryClickFired, setPrimaryClickFired] = useState(false);
  const context = useContext(AuthContext);

  async function handlePrimaryClick(event) {
    setPrimaryClickFired(true);
    onSecondaryClick();
  }

  const handleOnExit = (event) => {
    if (primaryClickFired) {
      try {
        onPrimaryClick();
      } catch (e) {
        if (e.status === 503) {
          context.openErrorSnackbar(e);
        } else {
          setErrorMessage(e.message);
        }
      }
    }
  };

  return (
    <AlertDialog
      open={open}
      dialogType="WARNING"
      title="Saving Will Remove Reservation"
      contentText="Saving any changes made to your line item will remove your reservation."
      helperText={errorMessage}
      primaryLabel="Continue"
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      TransitionProps={{
        onExited: handleOnExit,
      }}
    />
  );
}

EditDeleteReservationDialog.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onExited: PropTypes.func,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};
