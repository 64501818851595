import React from "react";
import capitalize from "lodash/capitalize";
import kebabCase from "lodash/kebabCase";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import {
  ADA_PEEP_SITE_LOCATION,
  PEEP_SITE_LOCATION,
  getExteriorDoorVersion1,
  getExteriorDoorVersion2,
} from "./getPayload";
import { AuthContext } from "../../../AuthContext";
import { Authority } from "../../../AuthProvider";
import CopyButton from "../../Buttons/CopyButton";
import NoImage from "../../Icons/no-image-available.svg";

export const ColoredTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "inPrintView",
})(({ theme, inPrintView }) => ({
  color: getFontStyleColor(inPrintView, theme),
}));

const getFontStyleColor = (inPrintView, theme) => {
  if (inPrintView) {
    return "rgba(0, 0, 0, 0.54)";
  } else {
    return theme.palette.mode === "light"
      ? theme.palette.text.secondary
      : theme.palette.primary["600"];
  }
};

const FinishCircle = ({ finish }) => {
  const getFinishLinks = finish.links ? finish.links[0].href : null;
  const finishImage = finish.finishImage ? finish.finishImage : getFinishLinks;

  return (
    <Box sx={{ display: "inline-flex", alignItems: "center" }}>
      {finish.description}
      {finish && finish.hexCode ? (
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: "50%",
            width: theme.spacing(1.5),
            height: theme.spacing(1.5),
            minWidth: theme.spacing(1.5),
            minHeight: theme.spacing(1.5),
            marginLeft: theme.spacing(1.5),
            WebkitPrintColorAdjust: "exact",
            bgcolor: `#${finish.hexCode}`,
          })}
        />
      ) : (
        finishImage && (
          <Box
            component="img"
            sx={(theme) => ({
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: "50%",
              width: theme.spacing(1.5),
              height: theme.spacing(1.5),
              minWidth: theme.spacing(1.5),
              minHeight: theme.spacing(1.5),
              marginLeft: theme.spacing(1.5),
              WebkitPrintColorAdjust: "exact",
            })}
            src={finishImage}
            alt={finish.description}
            onError={(e) => {
              e.target.src = NoImage;
            }}
          />
        )
      )}
    </Box>
  );
};

function createBaseVersion(payload) {
  const {
    isDoubleDoor,
    hasDoorGlass,
    hasSidelite,
    isHvhz,
    isDoorGlassImpactRated,
    isSlGlassImpactRated,
    isOutSwing,
    door,
    doorGlass,
    sidelite,
    sideliteGlass,
    finish,
    casing,
    frame,
    hardware,
    brickMold,
    inactivePrep,
    peepSite,
    doorWidth,
    doorHeight,
    doorThickness,
    boreDetails,
  } = payload;

  const updatedDoorThickness = doorThickness === 1.75 ? "1 3/4" : doorThickness;
  const isGlassImpactRated = isDoorGlassImpactRated || isSlGlassImpactRated;

  return [
    {
      title: "General",
      generalInfo: [
        {
          label: "Layout:",
          info: door.layout ? door.layout : door.description,
        },
        {
          label: "Glazing Type:",
          info: capitalize(doorGlass.glazingType),
        },
        {
          label: "Hurricane-rated (HVHZ):",
          info: isHvhz ? "Yes" : "No",
          hide: !isHvhz,
        },
        {
          label: "Impact-rated:",
          info: isGlassImpactRated ? "Yes" : "No",
          hide: !isGlassImpactRated,
        },
      ],
    },
    {
      title: "Hardware",
      generalInfo: [
        {
          label: "Model:",
          info: hardware.description,
        },
        {
          label: "Finish:",
          info: hardware.finish,
          hide: hardware.finish === "N/A",
        },
        {
          label: "Inactive Prep:",
          info: inactivePrep.description,
          hide: !isDoubleDoor,
        },
      ],
    },
    {
      title: "Bore Details",
      hide: hardware.description !== "None",
      generalInfo: [
        {
          label: "Diameter:",
          info: boreDetails?.diameter,
        },
        {
          label: "Backset:",
          info: boreDetails?.backSet,
        },
        {
          label: "Center Line:",
          info: boreDetails?.centerLine,
        },
      ],
    },
    {
      title: "Door",
      generalInfo: [
        {
          label: "Design:",
          info: door.description,
        },
        {
          label: "Dimensions:",
          info: `W ${doorWidth}" x H ${doorHeight}" x T ${updatedDoorThickness}"`,
        },
        {
          label: "Glass:",
          info: hasDoorGlass ? doorGlass.doorGlassName : "None",
        },
        {
          label: "Glass Dimensions:",
          info: doorGlass.doorGlassSize,
          hide: !hasDoorGlass,
        },
      ],
    },
    {
      title: "Sidelite",
      hide: !hasSidelite,
      generalInfo: [
        {
          label: "Design:",
          info: sideliteGlass.slGlassLayout,
        },
        {
          label: "Dimensions:",
          info: sidelite.slSize,
        },
        {
          label: "Glass:",
          info:
            sideliteGlass.slGlassName +
            (isSlGlassImpactRated ? "| Impact Rated" : ""),
        },
        {
          label: "Glass Dimensions:",
          info: sideliteGlass.slGlassSize,
        },
      ],
    },
    {
      title: "Finishes",
      generalInfo: [
        {
          label: "Door Exterior:",
          info: finish.exteriorFinish.links ? (
            <FinishCircle finish={finish.exteriorFinish} />
          ) : (
            finish.exteriorFinish.description
          ),
        },
        {
          label: "Door Interior:",
          info: finish.interiorFinish.links ? (
            <FinishCircle finish={finish.interiorFinish} />
          ) : (
            finish.interiorFinish.description
          ),
        },
        {
          label: "Frame Exterior:",
          info: finish.exteriorFrameFinish.links ? (
            <FinishCircle finish={finish.exteriorFrameFinish} />
          ) : (
            finish.exteriorFrameFinish.description
          ),
        },
        {
          label: "Frame Interior:",
          info: finish.interiorFrameFinish.links ? (
            <FinishCircle finish={finish.interiorFrameFinish} />
          ) : (
            finish.interiorFrameFinish.description
          ),
        },
        {
          label: "Weather Stripping:",
          info: finish.weatherStrip.links ? (
            <FinishCircle finish={finish.weatherStrip} />
          ) : (
            finish.weatherStrip.description
          ),
        },
      ],
    },
    {
      title: "Details",
      generalInfo: [
        {
          label: "Handing:",
          info: frame.handing,
        },
        {
          label: "Door Covered:",
          info: frame.isDoorCovered,
          hide: !(isOutSwing && isHvhz),
        },
        {
          label: "Jamb Depth:",
          info: frame.jambDepth,
        },
        {
          label: "Casing Profile:",
          info: casing.description,
        },
        {
          label: "Casing Finish:",
          hide: casing.description === "None",
          info: casing.finish,
        },
      ],
    },
    {
      title: "Add-Ons",
      singleRow: true,
      generalInfo: [
        {
          label: "Brickmold:",
          info: brickMold.description,
        },
        {
          label: "Peep Sight:",
          info: peepSite.description,
        },
        {
          label: "ADA Peep Sight:",
          info: peepSite.description,
          hide: !peepSite.adaPeepSite,
        },
      ],
      secondColumn: [
        {
          label: "Brickmold:",
          info: brickMold.description,
          isHidden: true,
        },
        {
          label: "Peep Sight Location:",
          info: PEEP_SITE_LOCATION,
          hide: peepSite.description === "No",
        },
        {
          label: "ADA Peep Sight Location",
          info: ADA_PEEP_SITE_LOCATION,
          hide: !peepSite.adaPeepSite,
        },
      ],
    },
  ];
}

function createVersion2(inPrintView, payload) {
  const itemList = createBaseVersion(payload);
  const { casing, roughOpening, customHeight, approvalCode, doorSill, hinge } =
    payload;

  // Replace base casing finish with updated casing finish
  itemList[6].generalInfo.find(
    (detail) => detail.label === "Casing Finish:",
  ).info = <FinishCircle finish={casing.finish} />;

  // Add Rough Openings
  itemList.unshift({
    title: "Openings",
    singleRow: !inPrintView || customHeight !== "N/A",
    hide: roughOpening === "N/A",
    generalInfo: [
      {
        label: "Unit Size:",
        info: `W ${roughOpening.unitWidth}" x H ${roughOpening.unitHeight}"`,
      },
      {
        label: "Rough Opening:",
        info: `W ${roughOpening.roughOpeningWidth}" x H ${roughOpening.roughOpeningHeight}"`,
      },
      {
        label: "Outside Brickmold:",
        info: `W ${roughOpening.outsideBrickmoldWidth}" x H ${roughOpening.outsideBrickmoldHeight}"`,
      },
    ],
    secondColumn: [
      {
        label: "Custom Height:",
        info: "Yes",
        hide: customHeight === "N/A",
      },
      {
        label: "Height Reduced:",
        info: `${customHeight.description}"`,
        hide: customHeight === "N/A",
      },
    ],
  });

  // Add Approval Codes
  itemList.splice(itemList.length - 1, 0, {
    title: "Certifications",
    hide: approvalCode === "N/A",
    generalInfo: [
      {
        label: "Approval Code:",
        info: approvalCode.flApprovalCode,
      },
      {
        label: "DP Rating:",
        info: approvalCode.dpRating,
      },
    ],
  });

  // Add Hinge Style Description
  itemList
    .find((element) => element.title === "Hardware")
    .generalInfo.push({
      label: "Hinge:",
      info: <FinishCircle finish={hinge} />,
    });

  // Add Sill Description
  itemList
    .find((element) => element.title === "Hardware")
    .generalInfo.push({
      label: "Sill:",
      info: <FinishCircle finish={doorSill} />,
    });

  return itemList;
}

/**
 * Renders the item details for Quote, Order, and Print (Shipping Receipt) page.
 */
export default function ExteriorDoorPayload(props) {
  const { version, payload, itemCodes, inPrintView } = props;
  const context = React.useContext(AuthContext);
  const theme = useTheme();
  const canViewItemCode = context.hasAuthority(Authority.ORDER_VIEW_ITEM_CODE);
  const printViewXS = inPrintView ? 6 : 12;
  const printViewLG = inPrintView ? 6 : 4;
  let payloadList;

  if (version === 1) {
    payloadList = createBaseVersion(getExteriorDoorVersion1(payload));
  } else {
    payloadList = createVersion2(inPrintView, getExteriorDoorVersion2(payload));
  }

  const SingleRow = ({ firstColumnList, secondColumnList }) => {
    return (
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item xs={inPrintView ? 6 : 12} sm={6}>
          {firstColumnList.map((item, i) => {
            if (item.hide) {
              return null;
            }
            return (
              <Grid
                key={i}
                container
                justifyContent="space-between"
                style={{ marginBottom: inPrintView ? "2px" : "8px" }}
              >
                <Grid item xs={5}>
                  <ColoredTypography
                    inPrintView={inPrintView}
                    fontSize={inPrintView ? "9.5px" : "12px"}
                    variant="subtitle2"
                    color="primary"
                  >
                    {item.label}
                  </ColoredTypography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    fontSize={inPrintView ? "9.5px" : "12px"}
                    component="div"
                    variant="body2"
                    align="right"
                    data-test={`${kebabCase(item.label)}-value`}
                  >
                    {item.info}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={inPrintView ? 6 : 12} sm={6}>
          {secondColumnList.map((item, i) => {
            if (item.hide) {
              return null;
            }

            return (
              <Grid
                key={i}
                container
                justifyContent="space-between"
                style={{
                  marginBottom: inPrintView ? "2px" : "8px",
                  visibility: item.isHidden ? "hidden" : "none",
                }}
              >
                <Grid item xs={5}>
                  <ColoredTypography
                    inPrintView={inPrintView}
                    fontSize={inPrintView ? "9.5px" : "12px"}
                    variant="subtitle2"
                    color="primary"
                  >
                    {item.label}
                  </ColoredTypography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    fontSize={inPrintView ? "9.5px" : "12px"}
                    component="div"
                    variant="body2"
                    align="right"
                    data-test={`${kebabCase(item.label)}-value`}
                  >
                    {item.info}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {canViewItemCode &&
        (inPrintView ? (
          <>
            <ColoredTypography
              fontSize="14px"
              paddingLeft="8px"
              variant="body2"
              component="span"
            >
              Item Code:
            </ColoredTypography>
            <Typography
              variant="caption"
              component="span"
              data-test="print-item-code"
            >
              <strong> {itemCodes[0].itemCode}</strong>
            </Typography>
          </>
        ) : (
          <ColoredTypography fontSize="14px" paddingLeft="8px" variant="body2">
            Copy Item Code
            <CopyButton
              value={itemCodes
                .map(function (item) {
                  return item.itemCode;
                })
                .join(" , ")}
              style={{ marginLeft: "16px" }}
            />
          </ColoredTypography>
        ))}
      <Grid container spacing={1} style={{ padding: "8px" }}>
        {payloadList.map((section, i) => {
          if (section.hide) {
            return null;
          }
          return (
            <Grid
              key={i}
              item
              xs={section.singleRow ? 12 : printViewXS}
              sm={section.singleRow ? 12 : 6}
              lg={section.singleRow ? 12 : printViewLG}
              style={{
                visibility: section.hide ? "hidden" : "none",
              }}
            >
              <Divider
                style={{
                  backgroundColor: inPrintView
                    ? "rgba(0, 0, 0, 0.12)"
                    : theme.palette.divider,
                }}
              />
              <Typography
                variant="overline"
                sx={(theme) => ({
                  color:
                    inPrintView || theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.6)"
                      : "rgba(255, 255, 255, 0.6)",
                })}
              >
                {section.title}
              </Typography>
              {section.singleRow ? (
                <SingleRow
                  firstColumnList={section.generalInfo}
                  secondColumnList={section.secondColumn}
                />
              ) : (
                section.generalInfo.map((subSection, j) => {
                  if (subSection.hide) {
                    return null;
                  }
                  return (
                    <Grid
                      key={j}
                      container
                      justifyContent="space-between"
                      style={{ marginBottom: inPrintView ? "2px" : "8px" }}
                    >
                      <Grid item xs={5}>
                        <ColoredTypography
                          inPrintView={inPrintView}
                          fontSize={inPrintView ? "9.5px" : "12px"}
                          variant="subtitle2"
                          color="primary"
                        >
                          {subSection.label}
                        </ColoredTypography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          data-test={`${kebabCase(section.title)}-${kebabCase(
                            subSection.label,
                          )}-value`}
                          fontSize={inPrintView ? "9.5px" : "12px"}
                          component="div"
                          align="right"
                        >
                          {subSection.info}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
