import { createBrowserHistory } from "history";

const history = createBrowserHistory({
  getUserConfirmation(message, callback) {
    const trigger = window[Symbol.for(message)];
    if (trigger) {
      return trigger(callback);
    } else {
      return callback(window.confirm(message));
    }
  },
});

export default history;
