import React from "react";
import loadable from "@loadable/component";
import ActivityDashIcon from "@mui/icons-material/ViewList";
import { Type } from "../../AuthProvider";

const LazyCsrActivityPage = loadable(() => import("./Csr/CsrActivityPage"));
const LazyContractorActivityPage = loadable(() =>
  import("./Contractor/ContractorActivityPage")
);

export default class Activity {
  static routes(context) {
    const type = context.whoAmI().type;
    if (type === Type.CSR || type === Type.SALES_REP) {
      return [
        {
          groupName: "Dashboards",
          items: [
            {
              label: "Activity",
              icon: <ActivityDashIcon />,
              path: "/activity",
              component: <LazyCsrActivityPage />,
            },
          ],
        },
      ];
    } else {
      return [
        {
          groupName: "Dashboards",
          items: [
            {
              label: "Activity",
              icon: <ActivityDashIcon />,
              path: "/activity",
              component: <LazyContractorActivityPage />,
            },
          ],
        },
      ];
    }
  }
}
