import React from "react";
import loadable from "@loadable/component";
import HomeIcon from "@mui/icons-material/Home";

const LazyHomePage = loadable(() => import("./HomePage"));
const LazyChangePasswordPage = loadable(() => import("./ChangePassword"));

export default class Home {
  static routes(context) {
    return [
      {
        icon: <HomeIcon />,
        label: "Home",
        path: "/",
        component: <LazyHomePage />,
      },
      {
        path: "/change-password",
        component: <LazyChangePasswordPage />,
      },
    ];
  }
}
