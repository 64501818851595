import React from "react";
import HelpCircleOutline from "@mui/icons-material/HelpOutline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import { AdvisarLogo } from "../Icons";
import { IncompatibleBrowserBox } from "../MessageBoxes";

const Content = styled("main")(({ theme, isDoorBuilderMode }) => ({
  height: "100%",
  ...(isDoorBuilderMode
    ? {
        width: "100%",
      }
    : {
        height: "100%",
        flexGrow: 1,
        overflow: "auto",
        padding: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(2),
        },
      }),
}));

export default function InitialContainer({ children, location }) {
  const isDoorBuilderMode = location.pathname.includes("door");

  return (
    <Content isDoorBuilderMode={isDoorBuilderMode}>
      <AppBar position="absolute" sx={{ zIndex: 1201 }}>
        <Toolbar
          sx={(theme) => ({ backgroundColor: theme.palette.primary["800"] })}
        >
          <AdvisarLogo
            style={{
              "& #Deco": {
                fill: "#c8102e",
              },
            }}
          />
          <div style={{ flexGrow: 1 }} />
          <IconButton
            data-test="help-link"
            style={{ color: "white" }}
            component="a"
            href="/configurator-help/"
            target="_blank"
          >
            <HelpCircleOutline />
          </IconButton>
        </Toolbar>
        <IncompatibleBrowserBox />
      </AppBar>
      {isDoorBuilderMode ? (
        <Box
          sx={(theme) => ({
            // 64px is the height of the top AppBar
            height: "64px",
            [theme.breakpoints.down("sm")]: {
              // 56px is the height of the top AppBar on xs
              height: "56px",
            },
          })}
        />
      ) : (
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            padding: "0 8px",
            ...theme.mixins.toolbar,
          })}
        />
      )}
      {children}
    </Content>
  );
}
