import React from "react";
import PropTypes from "prop-types";
import {
  AlertDialog,
  RequestPaginatedTable,
  ResultMessage,
  Spinner,
} from "@masonite-digital/common-components";
import ContactIcon from "@mui/icons-material/Person";
import QuoteIcon from "@mui/icons-material/ShoppingCart";
import {
  Avatar,
  Button,
  DialogContentText,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { AuthContext } from "../../AuthContext";
import { useCheckSnackbar } from "../../utils";

const initialState = {
  activeStep: 0,
  company: null,
  contact: null,
  errorMessage: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "STEP_CHANGE":
    case "ON_CLICK":
    case "CLEAR_SELECTION":
      return {
        ...state,
        ...action.value,
      };
    case "ON_ERROR":
      return {
        ...state,
        errorMessage: action.value,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
}

export default function TransferQuoteDialog(props) {
  const {
    open,
    quote,
    reorder,
    ownCompanyId,
    companyApi,
    contactApi,
    transferQuote,
    refresh,
    closeModal,
    openFailedTransferDialog,
    isCompanySelected,
  } = props;
  const context = React.useContext(AuthContext);
  const enqueueSnackbar = useCheckSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [shouldRefresh, setShouldRefresh] = React.useState(false);

  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    async function preSelectCompany() {
      const company = await companyApi.getCompanyDetail(
        `resourceId==${quote.reservations.items[0].companyResourceId}`
      );
      dispatch({
        type: "STEP_CHANGE",
        value: { activeStep: 1, company },
      });
    }
    async function preSelectByLead() {
      const company = quote.lead.company;
      const contact = quote.lead.ownedBy;
      contact.fullName = `${contact.firstName} ${contact.lastName}`;
      dispatch({
        type: "STEP_CHANGE",
        value: { activeStep: 2, company, contact },
      });
    }

    if (open && isCompanySelected && !quote.lead) {
      preSelectCompany();
    }

    if (open && quote.lead) {
      preSelectByLead();
    }
  }, [open, isCompanySelected, quote, companyApi]);

  const handleOnExited = async () => {
    dispatch({ type: "RESET" });

    if (shouldRefresh) {
      await refresh();
    }
  };

  async function onNextClick() {
    switch (state.activeStep) {
      case 0:
        dispatch({
          type: "STEP_CHANGE",
          value: {
            activeStep: 1,
          },
        });
        break;
      case 1:
        dispatch({
          type: "STEP_CHANGE",
          value: {
            activeStep: 2,
          },
        });
        break;
      case 2:
        try {
          setIsLoading(true);
          const targetResourceId = reorder
            ? reorder.resourceId
            : quote.resourceId;
          await transferQuote(targetResourceId, {
            ownedByResourceId: state.contact.resourceId,
            companyResourceId: state.company.resourceId,
          });
          enqueueSnackbar(
            `${quote.name} was transferred to ${state.contact.fullName} at ${state.company.name}`,
            { variant: "success" }
          );
          setShouldRefresh(true);
          closeModal();
        } catch (e) {
          if (e.status === 409) {
            closeModal();
            openFailedTransferDialog();
          } else if (e.status === 503) {
            closeModal();
            context.openErrorSnackbar(e);
          } else {
            dispatch({ type: "ON_ERROR", value: e.message });
          }
        }
        setIsLoading(false);
        break;
      default:
        break;
    }
  }

  return (
    <AlertDialog
      open={open}
      title="Transfer Quote?"
      dialogType={state.errorMessage ? "ERROR" : ""}
      helperText={state.errorMessage}
      TransitionProps={{
        onExited: handleOnExited,
      }}
      customContent={
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stepper
              style={{ paddingLeft: "8px" }}
              alternativeLabel
              activeStep={state.activeStep}
            >
              <Step key={0}>
                <StepLabel>Search for a Company</StepLabel>
              </Step>
              <Step key={1}>
                <StepLabel>Select a Contractor</StepLabel>
              </Step>
              <Step key={2}>
                <StepLabel>Review</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <div
              data-test="company-search-table"
              hidden={state.activeStep !== 0}
            >
              <RequestPaginatedTable
                type="search"
                initialSort="name"
                constraint={`resourceId!=${ownCompanyId};active==true`}
                columns={[
                  { id: "name", label: "Company Name", sortable: false },
                ]}
                emptyStateComponent={
                  <ResultMessage
                    type="SEARCHED"
                    title="Search for a Company"
                    message="Search for a company by Company Name and MP Number."
                  />
                }
                fetchDataCall={companyApi.getCompanies}
                selectedRowId={state.company?.resourceId}
                onRequestClear={() =>
                  dispatch({
                    type: "CLEAR_SELECTION",
                    value: { company: null },
                  })
                }
                onRowClick={(company) =>
                  dispatch({
                    type: "ON_CLICK",
                    value: {
                      company: company,
                      contact: null,
                      contactTableSearchData: null,
                    },
                  })
                }
              />
            </div>
            {state.activeStep === 1 && (
              <div data-test="contact-search-table">
                <RequestPaginatedTable
                  type="filter"
                  initialSort="firstName"
                  constraint={`company.resourceId==${state.company.resourceId};active==true`}
                  columns={[
                    { id: "fullName", label: "Contact Name", sortable: false },
                  ]}
                  fetchDataCall={contactApi}
                  selectedRowId={state.contact?.resourceId}
                  onRowClick={(contact) =>
                    dispatch({ type: "ON_CLICK", value: { contact } })
                  }
                  emptyStateComponent={
                    <ResultMessage
                      type="SEARCHED"
                      title="No Contractors"
                      message="No Contractors could be found for this company."
                    />
                  }
                />
              </div>
            )}
            {state.activeStep === 2 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0 16px",
                }}
              >
                <DialogContentText style={{ marginTop: "16px" }}>
                  <strong>
                    Changing the ownership of a quote is an irreversible
                    operation.
                  </strong>
                </DialogContentText>
                <DialogContentText style={{ marginBottom: "32px" }}>
                  The new owner will be notified of the transfer and will then
                  have complete authority to modify, delete and purchase it.
                </DialogContentText>
                <DialogContentText>
                  <strong>New Ownership:</strong>
                </DialogContentText>
                <List>
                  <ListItem
                    style={{
                      border: "1px solid #c9c9c9",
                      marginBottom: "8px",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <QuoteIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={quote.name} />
                  </ListItem>
                  <ListItem style={{ border: "1px solid #c9c9c9" }}>
                    <ListItemAvatar>
                      <Avatar>
                        <ContactIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={state.contact && state.contact.fullName}
                      secondary={state.company && state.company.name}
                    />
                  </ListItem>
                </List>
              </div>
            )}
          </Grid>
        </Grid>
      }
      customDialogAction={
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              data-test="tertiary-btn"
              onClick={closeModal}
              variant="outlined"
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-test="secondary-btn"
              variant="outlined"
              disabled={
                isLoading ||
                state.activeStep === 0 ||
                (isCompanySelected && state.activeStep === 1) ||
                !!quote.lead
              }
              onClick={() =>
                dispatch({
                  type: "STEP_CHANGE",
                  value: {
                    activeStep: state.activeStep - 1,
                    errorMessage: null,
                  },
                })
              }
            >
              Back
            </Button>
            <Button
              data-test="primary-btn"
              sx={{ marginLeft: 1 }}
              color="secondary"
              variant="contained"
              disabled={
                isLoading ||
                (state.activeStep === 0 && !Boolean(state.company)) ||
                (state.activeStep === 1 &&
                  !(Boolean(state.contact) && Boolean(state.company)))
              }
              onClick={onNextClick}
            >
              {isLoading && <Spinner isMini />}
              {state.activeStep === 2 ? "Transfer" : "Next"}
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
}

TransferQuoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  ownCompanyId: PropTypes.string.isRequired,
  quote: PropTypes.object.isRequired,
  reorder: PropTypes.object,
  refresh: PropTypes.func.isRequired,
  transferQuote: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  companyApi: PropTypes.object.isRequired,
  contactApi: PropTypes.func.isRequired,
  openFailedTransferDialog: PropTypes.func.isRequired,
  isCompanySelected: PropTypes.bool,
};

TransferQuoteDialog.defaultProps = {
  open: false,
  ownCompanyId: "",
  isCompanySelected: false,
};
