import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import InfoBlock from "../Blocks/InfoBlock";

export default function ConflictDialog(props) {
  const { open, handleContinue, handleCancel } = props;

  return (
    <AlertDialog
      open={open}
      data-test="selected-item-is-not-compatible-dialog"
      dialogType="WARNING"
      title="Selected Item is Not Compatible"
      primaryLabel="Continue"
      onPrimaryClick={handleContinue}
      onSecondaryClick={handleCancel}
      customContent={
        <div style={{ marginTop: "10px" }}>
          <InfoBlock type={"error"}>
            The item you selected is not compatible with your current
            configuration.
          </InfoBlock>
          <div style={{ marginTop: "15px" }}>
            If you would like to continue with this selection, other items in
            your configuration may change. Would you like to proceed?
          </div>
        </div>
      }
    />
  );
}

ConflictDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleContinue: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
