import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";

class ContactUpdateDialog extends React.Component {
  render() {
    const { open, isMyAccount, changedType, onPrimaryClick, onSecondaryClick } =
      this.props;

    const isChangedTypeEmail = changedType === "EMAIL";

    let contentText;
    if (isMyAccount) {
      contentText = isChangedTypeEmail
        ? "The email address is also your login username and changing it will require confirmation that you have access to the new address. You will be logged out of the system, an email will be sent to the new email address, and you will not be able to login until you have verified your address."
        : "Changing your name will require you to re-login.";
    } else {
      contentText = isChangedTypeEmail
        ? "The email address is also the login username for this user and changing it will require confirmation that they have access to the new address. An email will be sent to the new email address and the user will not be able to login until they have verified their address."
        : "";
    }

    return (
      <AlertDialog
        open={open}
        dialogType="WARNING"
        title={`Change ${changedType === "EMAIL" ? "email address" : "name"}?`}
        contentText={contentText}
        onPrimaryClick={onPrimaryClick}
        onSecondaryClick={onSecondaryClick}
      />
    );
  }
}

ContactUpdateDialog.propTypes = {
  open: PropTypes.bool,
  isMyAccount: PropTypes.bool,
  changedType: PropTypes.string,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};

export default ContactUpdateDialog;
