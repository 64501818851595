function _camel2snake(input) {
  if (input === null) {
    return input; // garbage in, garbage out
  }
  let result = "";
  for (let i = 0; i < input.length; i++) {
    let c = input.charAt(i);
    if (i === 0 && c === "_") {
      continue;
    }
    let previousCharacter =
      result.length > 0 ? result.charAt(result.length - 1) : null;

    if (c >= "A" && c <= "Z" && !isCharacterSeparator(previousCharacter)) {
      result = result + "_";
    }
    result = result + c.toLowerCase();
  }
  return result.length ? result : input;
}

function isCharacterSeparator(c) {
  return c === "_" || c === "-" || c == null;
}

function _snake2camel(input) {
  if (input === null) {
    return input; // garbage in, garbage out
  }
  let lastUnderscore = false;
  let result = "";

  for (let i = 0; i < input.length; i++) {
    let c = input.charAt(i);
    if (c === "_") {
      if (result.length > 0) {
        lastUnderscore = true;
      }
    } else {
      if (lastUnderscore) {
        result += c.toUpperCase();
        lastUnderscore = false;
      } else {
        result += c;
      }
    }
  }
  return result;
}

function typeOf(input) {
  if (input === null) {
    return "null";
  } else {
    return Array.isArray(input) ? "array" : typeof input;
  }
}

function convert(input, conversion) {
  // Fix shitty types
  switch (typeOf(input)) {
    case "string": {
      return conversion(input);
    }
    case "object": {
      const converted = {};
      for (const [key, value] of Object.entries(input)) {
        converted[convert(key, conversion)] = ["object", "array"].includes(
          typeOf(value)
        )
          ? convert(value, conversion)
          : value;
      }
      return converted;
    }
    case "array": {
      return input.map((entry) =>
        typeOf(entry) !== "string" ? convert(entry, conversion) : entry
      );
    }
    default: {
      return input;
    }
  }
}

export function toCamelCase(input) {
  return convert(input, _snake2camel);
}

export function toSnakeCase(input) {
  return convert(input, _camel2snake);
}
