import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import Typography from "@mui/material/Typography";
import maintenanceGear from "../Icons/maintenance-gear.svg";

function MaintenanceDialog(props) {
  const { open, onCloseClick, message } = props;

  return (
    <AlertDialog
      open={open}
      dialogType="ERROR"
      title="Offline for Maintenance"
      secondaryLabel="Close"
      onSecondaryClick={onCloseClick}
      onClose={onCloseClick}
      hidePrimaryButton
      customContent={
        <>
          <div style={{ margin: "8px 0 ", textAlign: "center" }}>
            <img alt={"Maintenance Gear"} src={maintenanceGear} />
          </div>
          <Typography>{message}</Typography>
        </>
      }
    />
  );
}

MaintenanceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default MaintenanceDialog;
