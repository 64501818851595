import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function TwoLineDataCell({ line1, line1Custom, line2 }) {
  return (
    <>
      <Grid item>
        {line1Custom ? (
          line1Custom
        ) : (
          <Typography align="left" variant="body2">
            {line1}
          </Typography>
        )}

        {line2 && (
          <Typography
            color="primary.700"
            align="left"
            component="span"
            variant="caption"
          >
            {line2}
          </Typography>
        )}
      </Grid>
    </>
  );
}

TwoLineDataCell.propTypes = {
  line1: PropTypes.string,
  line1Custom: PropTypes.node,
  line2: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
