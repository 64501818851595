import { fetch } from "../../Network";
import { toJson } from "../../api";
import { toSnakeCase } from "../../utils";

const retrieve = async (url, options, data) => {
  const response = await fetch(url, options, toSnakeCase(data));
  return toJson(response);
};

function pageable(endpoint) {
  return async function ({ filter, pageNumber, pageSize, pageSort }) {
    const response = await fetch(
      `${endpoint}?filter=${filter}&size=${pageSize}&sort=${pageSort}&page=${pageNumber}`
    );
    return toJson(response);
  };
}

const consumerLeadFunctions = {
  getConsumerLeads: pageable("v3/leads/summary"),

  getConsumerLeadDetail: async (resourceId) => {
    return retrieve(`v3/leads/${resourceId}`, {
      method: "GET",
    });
  },
  createLead: async (accountInfo) => {
    return retrieve(
      "v3/leads",
      {
        method: "POST",
      },
      accountInfo
    );
  },
  updateLead: async (resourceId, accountInfo) => {
    return retrieve(
      `v3/leads/${resourceId}`,
      {
        method: "PATCH",
      },
      accountInfo
    );
  },
  resendLink: async (resourceId) => {
    return retrieve(`v3/leads/${resourceId}/resend`, {
      method: "POST",
    });
  },
  sendEmailToContractor: async (resourceId) => {
    return fetch(`v3/leads/${resourceId}/send-email`, {
      method: "POST",
    });
  },

  createLeadItem: async (resourceId, item) => {
    return retrieve(
      `v3/leads/${resourceId}/items`,
      {
        method: "POST",
      },
      item
    );
  },
  deleteLeadItem: async (resourceId, itemResourceId) => {
    return retrieve(`v3/leads/${resourceId}/items/${itemResourceId}`, {
      method: "DELETE",
    });
  },
  updateLeadItemNote: async (resourceId, itemResourceId, note) => {
    await fetch(
      `v3/leads/${resourceId}/items/${itemResourceId}/note`,
      { method: "PUT" },
      note
    );
  },
};

export default consumerLeadFunctions;
