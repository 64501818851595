import ReactGA from "react-ga4";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

const UUID = "dimension1";
const TYPE = "dimension2";
const SELECTED_RESOURCE_ID = "dimension3";
const DESCRIPTION = "dimension4";
const PAYLOAD = "dimension5";
const INSTALL_CITY = "dimension6";
const PURCHASE_STATUS = "dimension7";
const QUOTE_COMPANY_NAME = "dimension8";
const VIRTUAL_PATH = "dimension9";
const QUOTE_ID = "dimension10";
const QUOTE_NAME = "dimension11";
const CUT_DOWN_AMOUNT = "dimension12";
const PANEL_TYPE = "dimension13";
const SLAB_TYPE = "dimension14";
const HVHZ = "dimension15";
const IS_CUSTOM_PAINT = "dimension16";
const COMPONENTS_AFFECTED = "dimension17";
const INQUIRY_ID = "dimension18";

const ORDER_AMOUNT = "metric1";
const DOOR_QUANTITY = "metric2";
const CUSTOM_COLOR_COUNT = "metric3";
const CUSTOM_COLOR_ORDER_AMOUNT = "metric4";
const CUT_DOWN_ORDER_AMOUNT = "metric5";
const CUT_DOWN_COUNT = "metric6";
const SLAB_HEIGHT = "metric7";
const SLAB_WIDTH = "metric8";
const DOOR_SUBTOTAL = "metric9";
const ORDER_TAX = "metric10";

export const paths = [
  {
    path: "/login",
    section: "login",
    subsection: "sign in",
  },
  {
    path: "/forgot-password",
    section: "login",
    subsection: "forgot password",
  },
  {
    path: "/",
    section: "home",
    subsection: "home",
  },
  {
    path: "/products/residential/exterior/pre-hung",
    section: "products",
    subsection: "exterior doors",
  },
  {
    path: "/metrics",
    section: "dashboard",
    subsection: "metrics",
  },
  {
    path: "/analytics",
    section: "dashboard",
    subsection: "analytics",
  },
  {
    path: "/activity",
    section: "dashboard",
    subsection: "activity",
  },
  {
    path: "/quotes",
    section: "search",
    subsection: "quotes",
  },
  {
    path: "/orders",
    section: "search",
    subsection: "orders",
  },
  {
    path: "/companies",
    section: "search",
    subsection: "companies",
  },
  {
    path: "/employees",
    section: "dashboard",
    subsection: "employees",
  },
  {
    path: "/employees/all",
    section: "contacts",
    subsection: "all",
  },
  {
    path: "/employees/contractor",
    section: "contacts",
    subsection: "contractors",
  },
  {
    path: "/employees/csr",
    section: "contacts",
    subsection: "csr",
  },
  {
    path: "/my-quotes",
    section: "my items",
    subsection: "my quotes",
  },
  {
    path: "/sales",
    section: "dashboard",
    subsection: "sales",
  },
  {
    path: "/financing",
    section: "dashboard",
    subsection: "financing",
  },
];

const regExUuid =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

const initCustomFields = () => {
  ReactGA.set({
    [UUID]: window.sessionStorage.getItem("contactId") || "no data",
  });
  ReactGA.set({ [TYPE]: "not set" });
  ReactGA.set({ [SELECTED_RESOURCE_ID]: "not set" });
  ReactGA.set({ [DESCRIPTION]: "not set" });
  ReactGA.set({ [PAYLOAD]: "not set" });
  ReactGA.set({ [INSTALL_CITY]: "not set" });
  ReactGA.set({ [PURCHASE_STATUS]: "not set" });
  ReactGA.set({ [QUOTE_COMPANY_NAME]: "not set" });
  ReactGA.set({ [QUOTE_ID]: "not set" });
  ReactGA.set({ [QUOTE_NAME]: "not set" });
  ReactGA.set({ [CUT_DOWN_AMOUNT]: "not set" });

  ReactGA.set({ [ORDER_AMOUNT]: 0 });
  ReactGA.set({ [CUSTOM_COLOR_ORDER_AMOUNT]: 0 });
  ReactGA.set({ [DOOR_QUANTITY]: 0 });
  ReactGA.set({ [CUSTOM_COLOR_COUNT]: 0 });
  ReactGA.set({ [CUT_DOWN_COUNT]: 0 });
  ReactGA.set({ [CUT_DOWN_ORDER_AMOUNT]: 0 });
  ReactGA.set({ [SLAB_HEIGHT]: 0 });
  ReactGA.set({ [SLAB_WIDTH]: 0 });
};

export const googleGenericEventNoInit = (section, action, customAttributes) => {
  customAttributes = customAttributes ? customAttributes : "no data";
  ReactGA.event({
    category: `core-app | ${section ? section : "no data"} `,
    action: `${action ? action : "no data"}`,
    label: customAttributes,
  });
};

export const googleGenericEvent = (section, action, customAttributes) => {
  customAttributes = customAttributes ? customAttributes : "no data";
  initCustomFields();
  ReactGA.event({
    category: `core-app | ${section ? section : "no data"}`,
    action: `${action ? action : "no data"}`,
    label: customAttributes,
  });
};

export const googleDashBoardNavEvent = (
  section,
  subSection,
  action,
  customAttributes,
) => {
  customAttributes = customAttributes ? customAttributes : "no data";
  initCustomFields();

  ReactGA.event({
    category: `core-app | ${section ? section : "no data"} | ${
      subSection ? subSection : "no data"
    }`,
    action: `${action ? action : "no data"}`,
    label: `${customAttributes}`,
  });
  let path = document.location.pathname;
  path = path.replace(regExUuid, "");
  path = `${path}/virtual/${section}/${subSection} `;
  if (path.includes("//")) {
    path = path.replace("//", "/");
  }
  ReactGA.ga("set", "page", path);
  ReactGA.ga("send", "pageview", path);
};

export const googleErrorEvent = (section, subSection, action, error) => {
  let errorMsg = "";
  //parse the exta fields that get send in the payload field
  for (let i in error) {
    errorMsg += `|${i} : ${error[i]}`;
  }
  initCustomFields();
  ReactGA.event({
    category: `core-app | ${section} | ${subSection}`,
    action: `${action ? action : "no data"}`,
    label: `errors ${errorMsg}`,
  });
};

export const googleEmailEvent = (type, quote) => {
  initCustomFields();
  ReactGA.set({ [QUOTE_ID]: quote.resourceId });
  ReactGA.set({ [QUOTE_NAME]: quote.name });
  ReactGA.set({ [QUOTE_COMPANY_NAME]: quote.ownedBy.company.name });
  if (quote.items) {
    ReactGA.set({ [DOOR_QUANTITY]: countDoors(quote) });
  }
  ReactGA.set({ [ORDER_AMOUNT]: quote.price.price.value });
  googleGenericEventNoInit(
    "Quote | Details",
    `${type ? type : "no data"}`,
    `timestamp ${Date.now()}`,
  );
};

export const googlePaymentEvent = (
  purchaseAmount,
  purchaseStatus,
  paymentType,
  quote,
  shippingAddress,
  purchaseTax,
  itemIndex,
) => {
  initCustomFields();
  if (purchaseAmount === 0) {
    googleGenericEventNoInit(
      "Payment",
      `click confirm purchase`,
      `payment type: failed payment | purchase status: ${purchaseStatus} | timestamp ${Date.now()}`,
    );
  }
  let purchaseAmountWithTax = purchaseAmount;
  //this blocks NaN results if tax is null, or masonite credit, which tacks tax onto purchaseAmount.
  if (typeof purchaseTax === "number" && paymentType !== "masonite credit") {
    purchaseAmountWithTax += purchaseTax;
  }

  const item = quote.items[itemIndex];

  let customColorCount = countCustomColorDoorsInOrder(item);
  let customHeightCount = countCustomHeightDoorsInOrder(item);
  let payload = quote.orderType === "SALE" ? createPaymentPayload(quote) : "";
  let customColorAmount = customColorCount > 0 ? item.price.cost.value : 0;
  let cutDownOrderAmount = customHeightCount > 0 ? item.price.cost.value : 0;
  let doorSubtotalValue = item.price.cost.value * item.quantity;

  ReactGA.set({ [ORDER_AMOUNT]: purchaseAmountWithTax });
  ReactGA.set({ [ORDER_TAX]: purchaseTax });
  ReactGA.set({ [QUOTE_ID]: quote.resourceId });
  ReactGA.set({ [QUOTE_NAME]: quote.name });
  ReactGA.set({ [QUOTE_COMPANY_NAME]: quote.company.name });

  ReactGA.set({ [INSTALL_CITY]: shippingAddress });
  ReactGA.set({ [PURCHASE_STATUS]: purchaseStatus });
  ReactGA.set({ [PAYLOAD]: payload });
  ReactGA.set({ [CUSTOM_COLOR_COUNT]: customColorCount });
  ReactGA.set({ [DOOR_QUANTITY]: item.quantity });
  ReactGA.set({ [CUSTOM_COLOR_ORDER_AMOUNT]: customColorAmount });
  ReactGA.set({ [CUT_DOWN_ORDER_AMOUNT]: cutDownOrderAmount });
  ReactGA.set({ [CUT_DOWN_COUNT]: customHeightCount });
  ReactGA.set({ [SELECTED_RESOURCE_ID]: item.resourceId });
  ReactGA.set({ [DOOR_SUBTOTAL]: doorSubtotalValue });

  let HVHZValue, cutDownHeight, slabWidth, slabHeight;
  let specifications = item.product.specifications;
  for (let spec in specifications) {
    if (specifications[spec].type === "SLAB_DESIGN") {
      ReactGA.set({ [DESCRIPTION]: specifications[spec].description });
      ReactGA.set({ [PANEL_TYPE]: specifications[spec].description });
    }
    if (specifications[spec].type === "SLAB_LAYOUT") {
      ReactGA.set({ [TYPE]: specifications[spec].description });
      ReactGA.set({ [SLAB_TYPE]: specifications[spec].description });
    }
    if (specifications[spec].type === "HURRICANE_RATED") {
      HVHZValue = specifications[spec].value;
      if (HVHZ) {
        ReactGA.set({ [HVHZ]: "true" });
      }
    }
    if (specifications[spec].type === "SLAB_HEIGHT") {
      slabHeight = specifications[spec].value;
      if (slabHeight) {
        ReactGA.set({ [SLAB_HEIGHT]: slabHeight });
      }
    }
    if (specifications[spec].type === "SLAB_WIDTH") {
      slabWidth = specifications[spec].value;
      if (slabWidth) {
        ReactGA.set({ [SLAB_WIDTH]: slabWidth });
      }
    }
    if (specifications[spec].type === "CUT_DOWN_HEIGHT") {
      cutDownHeight = specifications[spec].description;
      ReactGA.set({ [CUT_DOWN_AMOUNT]: cutDownHeight });
    }
  }
  if (!HVHZValue) {
    ReactGA.set({ [HVHZ]: "not set" });
  }

  if (!cutDownHeight) {
    ReactGA.set({ [CUT_DOWN_AMOUNT]: "not set" });
  }
  googleGenericEventNoInit(
    "Payment",
    `click confirm purchase`,
    `payment type: ${paymentType} | purchase status: ${purchaseStatus} | timestamp ${Date.now()} | itemId: ${
      item.resourceId
    }`,
  );
  initCustomFields();
};

export const googleProductClickEvent = (
  options,
  isObject,
  productOrderCost,
  isCutDown,
) => {
  let mappedData = stripData(options);
  initCustomFields();
  if (options.quoteName) {
    ReactGA.set({ [QUOTE_NAME]: options.quoteName });
  }
  ReactGA.set({ [UUID]: window.sessionStorage.getItem("contactId") });
  ReactGA.set({ [TYPE]: mappedData["main"]["type"] });
  ReactGA.set({ [SELECTED_RESOURCE_ID]: mappedData["main"]["resourceId"] });
  ReactGA.set({ [DESCRIPTION]: mappedData["main"]["description"] });
  ReactGA.set({ [PAYLOAD]: parsePayload(mappedData["extraFields"], isObject) });
  ReactGA.set({ [VIRTUAL_PATH]: buildPath(options) });

  let slabHeight = options.slabHeight && options.slabHeight.value;
  let slabWidth = options.slabWidth && options.slabWidth.value;

  let slabType = options.slabLayout && options.slabLayout.description;
  let panelType = options.slabDesign && options.slabDesign.description;
  let hasCustomPaint = options.customPaint;
  let HVHZValue = "not set";
  if (options.option && options.option.impactRated) {
    HVHZValue = options.option.impactRated.value;
  } else if (!productOrderCost) {
    HVHZValue = options.HVHZ && options.HVHZ.value && "true";
  }

  if (productOrderCost && options.option) {
    slabHeight = options.option.find(
      (element) => element.type === "SLAB_HEIGHT",
    ).value;
    slabWidth = options.option.find(
      (element) => element.type === "SLAB_WIDTH",
    ).value;
    slabType = options.option.find(
      (element) => element.type === "SLAB_LAYOUT",
    ).description;
    panelType = options.option.find(
      (element) => element.type === "SLAB_DESIGN",
    ).description;
    let resultHVHZ = options.option.find(
      (element) => element.type === "HURRICANE_RATED",
    );
    HVHZValue = resultHVHZ ? resultHVHZ : "not set";
    hasCustomPaint = options.option.find((element) => element.exterior);
    ReactGA.set({ [ORDER_AMOUNT]: productOrderCost });
  } else {
    ReactGA.set({ [ORDER_AMOUNT]: "Not Set" });
  }

  const customPaintExterior = options.customPaint ? "doorExterior" : "exterior";
  const customPaintInterior = options.customPaint ? "doorInterior" : "interior";
  if (
    hasCustomPaint &&
    (hasCustomPaint[customPaintExterior].finishType === "CUSTOM_PAINT" ||
      hasCustomPaint[customPaintInterior].finishType === "CUSTOM_PAINT")
  ) {
    ReactGA.set({ [IS_CUSTOM_PAINT]: "true" });
  } else {
    ReactGA.set({ [IS_CUSTOM_PAINT]: "false" });
  }

  ReactGA.set({ [SLAB_TYPE]: slabType });
  ReactGA.set({ [PANEL_TYPE]: panelType });
  ReactGA.set({ [SLAB_HEIGHT]: slabHeight });
  ReactGA.set({ [SLAB_WIDTH]: slabWidth });
  ReactGA.set({ [HVHZ]: HVHZValue ? "true" : "not set" });

  if (isCutDown) {
    ReactGA.set({ [CUT_DOWN_AMOUNT]: options.cutDownAmount });
  } else {
    ReactGA.set({ [CUT_DOWN_AMOUNT]: "not set" });
  }
  ReactGA.ga("set", "page", buildPath(options));

  let action =
    `click ${mappedData["main"]["type"]} | ${mappedData["main"]["description"]} `
      .replace(/_/g, "-")
      .toLowerCase();

  ReactGA.event({
    category: `core-app | ${buildPathEvent(mappedData)}`,
    action: `${action ? action : ""}`,
  });
};

export const googleRetiredComponentEvent = (options, quoteData) => {
  initCustomFields();
  let mappedData = stripData(options);
  ReactGA.set({ [QUOTE_ID]: quoteData.orderResourceId });
  ReactGA.set({ [QUOTE_NAME]: quoteData.name });
  ReactGA.set({ [PAYLOAD]: options.option });

  let action = "click reset retired components";

  ReactGA.event({
    category: `core-app | ${buildPathEvent(mappedData)}`,
    action: action,
  });
};

export const googleInquiryEvent = (
  inquiry,
  order,
  orderItem,
  inquiryId,
  currentUser,
) => {
  initCustomFields();
  // We're capped at 20 dimensions so we must reuse dimensions; This is fine as long as we use "Inquiry" as the section in the GA event
  const submittedDoorDimensions = {
    COMPANY_NAME: "dimension10",
    COMPANY_ID: "dimension11",
    SUBMITTING_USER: "dimension12",
    ORDER_NAME: "dimension13",
    REFERENCE_ID: "dimension14",
    PANEL_DESIGN: "dimension15",
    ISSUE_TYPE: "dimension16",
    // These correspond to these values respectively:
    // QUOTE_ID
    // QUOTE_NAME
    // CUT_DOWN_AMOUNT
    // PANEL_TYPE
    // SLAB_TYPE
    // HVHZ
    // IS_CUSTOM_PAINT
  };

  // For readability
  const {
    COMPANY_NAME,
    COMPANY_ID,
    SUBMITTING_USER,
    ORDER_NAME,
    REFERENCE_ID,
    PANEL_DESIGN,
    ISSUE_TYPE,
  } = submittedDoorDimensions;
  const panelDesign =
    orderItem.product.specifications.length > 0
      ? orderItem.product.specifications.find(
          (specification) => specification.type === "SLAB_DESIGN",
        ).description
      : "null";

  let componentsAffected = checkTextForAffectedComponents(inquiry);

  ReactGA.set({ [COMPANY_NAME]: order.company.name });
  ReactGA.set({ [COMPANY_ID]: order.company.resourceId });
  ReactGA.set({ [SUBMITTING_USER]: currentUser });
  ReactGA.set({ [ORDER_NAME]: order.name });
  ReactGA.set({ [REFERENCE_ID]: order.referenceNumber });
  ReactGA.set({ [PANEL_DESIGN]: panelDesign });
  ReactGA.set({ [ISSUE_TYPE]: inquiry.type });
  ReactGA.set({ [COMPONENTS_AFFECTED]: componentsAffected });
  ReactGA.set({ [INQUIRY_ID]: inquiryId });

  googleGenericEventNoInit("Inquiry", `click submit door issue`);
};

const checkTextForAffectedComponents = (inquiry) => {
  const { affectedComponents } = inquiry;

  let componentsAffected = "";
  let isFirstComponent = true;

  affectedComponents.forEach((component) => {
    componentsAffected += `${!isFirstComponent ? " | " : ""}${
      component.display === "OTHER" ? component.description : component.partType
    }`;
    if (isFirstComponent) {
      isFirstComponent = false;
    }
  });

  return componentsAffected;
};

export const googleNavClickEvent = (options) => {
  let mappedData = stripData(options);
  initCustomFields();
  ReactGA.set({ [VIRTUAL_PATH]: buildPath(options) });

  googleGenericEventNoInit(
    `${buildPathEvent(mappedData)}`,
    `${options["action"]}.`.replace(/_/g, "-").toLowerCase(),
    `${options["label"]}`,
  );
  ReactGA.ga("set", "page", buildPath(options));
  ReactGA.ga("send", "pageview", buildPath(options));
};

export const googleContextClickEvent = (options) => {
  let mappedData = stripData(options);
  initCustomFields();
  ReactGA.set({ [VIRTUAL_PATH]: buildPath(options) });

  googleGenericEventNoInit(
    `${buildPathEvent(mappedData)}`,
    `${options["action"]}.`.replace(/_/g, "-").toLowerCase(),
    `${options["label"]}`,
  );
};

const buildPath = (mappedData) => {
  let path = document.location.pathname;
  path = path.replace(regExUuid, "");

  switch (mappedData["activeStep"]) {
    case 0:
      path +=
        "/virtual/design-your-door" +
        setTabStep(mappedData["activeStep"], mappedData["tabStep"]);
      break;
    case 1:
      path +=
        "/virtual/select-door-details" +
        setTabStep(mappedData["activeStep"], mappedData["tabStep"]);
      break;
    case 2:
      path += "/virtual/finish-and-order";
      break;
    default:
      break;
  }
  path += mappedData["url"] ? `/${mappedData["url"]}` : "";
  if (path.includes("//")) {
    path = path.replace("//", "/");
  }

  return path;
};

const setTabStep = (activeStep, tabStep) => {
  let output = "";
  switch (tabStep) {
    case 0:
      output = activeStep === 0 ? "/design" : "/door-details";
      break;
    case 1:
      output = activeStep === 0 ? "/glass" : "/finishes";
      break;
    case 2:
      output = activeStep === 0 ? "/finish" : "/hardware";
      break;
    case 3:
      output = "/add-ons";
      break;
    default:
      break;
  }
  return output;
};

const buildPathEvent = (mappedData) => {
  let path = "";
  switch (mappedData["main"]["activeStep"]) {
    case 0:
      path +=
        "Exterior Door | Design Your Door" +
        setTabStepEvent(
          mappedData["main"]["activeStep"],
          mappedData["main"]["tabStep"],
        );
      break;
    case 1:
      path +=
        "Exterior Door | Select Door Details" +
        setTabStepEvent(
          mappedData["main"]["activeStep"],
          mappedData["main"]["tabStep"],
        );
      break;
    case 2:
      path += "Exterior Door | Finish and Order";
      break;
    default:
      break;
  }
  path += mappedData["url"] ? ` | ${mappedData["url"]}` : "";

  return path;
};

const setTabStepEvent = (activeStep, tabStep) => {
  let output = "";
  switch (tabStep) {
    case 0:
      output = activeStep === 0 ? " | Design" : " | Door Details";
      break;
    case 1:
      output = activeStep === 0 ? " | Glass" : " | Finishes";
      break;
    case 2:
      output = activeStep === 0 ? " | Finish" : " | Hardware";
      break;
    case 3:
      output = " | Add Ons";
      break;
    default:
      break;
  }
  return output;
};

export const stripData = (clickData) => {
  let option;
  let googleData = {};
  let extraFields = {};
  if (clickData["option"]) {
    option = { ...clickData["option"] };
    googleData["type"] = option["type"];
    googleData["resourceId"] = option["resourceId"];
    googleData["description"] = option["description"];

    const currentlySelectedOption = Object.keys(option)[0];
    if (currentlySelectedOption) {
      googleData = {
        ...googleData,
        ...option[currentlySelectedOption],
      };
      if (isEmpty(option[currentlySelectedOption])) {
        googleData.description = `Remove ${currentlySelectedOption}`;
      }
    }
    delete option["type"];
    delete option["resourceId"];
    delete option["description"];
    delete option["links"];

    //parse the extra fields that get send in the payload field
    for (let i in option) {
      extraFields[i] = option[i];
    }
  }
  if (clickData["type"]) {
    googleData["type"] = clickData["type"];
  }
  if (clickData["activeStep"] >= 0) {
    googleData["activeStep"] = clickData["activeStep"];
  }
  if (clickData["tabStep"] >= 0) {
    googleData["tabStep"] = clickData["tabStep"];
  }
  return { main: googleData, extraFields: extraFields };
};

const countDoors = (quote) => {
  return quote.items
    .map((item) => item.quantity)
    .reduce((total, numDoors) => total + numDoors);
};

const parsePayload = (input, isObject) => {
  let parsedString = "| Payload |";

  if (isObject) {
    for (let key in input) {
      if (input[key]) {
        parsedString += ` ${input[key].type} :`;
        parsedString += ` ${input[key].description} |`;
      } else {
        parsedString = "null";
      }
    }
  } else {
    for (let key in input) {
      parsedString += ` ${key} :`;
      parsedString += ` ${input[key]} |`;
    }
  }
  return parsedString;
};

const getComponentDataByPayloadVersion = (item) => {
  // Checks if the item payload version is 2.5
  const isVersion25Payload = item.product.version === "2.5";

  if (isVersion25Payload) {
    // Return empty array if item payload version is 1.0.0, since it does not have the componentData property
    if (get(item.product, "components.version") === "1.0.0") {
      return [];
    } else {
      // Item payload version 1.2.0 path
      return item.product.components.component.componentData;
    }
  } else {
    // Version 3.0.0 path
    return item.product.components.componentData;
  }
};

export const getItemTypes = (item) => {
  let types = [];
  const payloadData = getComponentDataByPayloadVersion(item);
  payloadData.forEach((component) => types.push(component.partType));
  return types;
};

export const getSpecificPartType = (item, specType) => {
  const payloadData = getComponentDataByPayloadVersion(item);
  return payloadData.find((component) => component.partType === specType) || {};
};

export const countCustomColorDoorsInOrder = (item) => {
  // if any of those types are true, return 1, else return 0
  let count = 0;
  let finishSpec;
  let specifications = item.product.specifications;
  for (let spec in specifications) {
    if (specifications[spec].type === "SLAB_FINISH") {
      finishSpec = specifications[spec];
    }
    if (finishSpec) {
      let interiorPaintType = finishSpec.interior.finishType;
      let interiorFramePaintType = finishSpec.interiorFrame.finishType;
      let exteriorPaintType = finishSpec.exterior.finishType;
      let exteriorFramePaintType = finishSpec.exteriorFrame.finishType;
      if (
        interiorPaintType === "CUSTOM_PAINT" ||
        interiorFramePaintType === "CUSTOM_PAINT" ||
        exteriorPaintType === "CUSTOM_PAINT" ||
        exteriorFramePaintType === "CUSTOM_PAINT"
      ) {
        count = 1;
      }
    }
  }
  return count * item.quantity;
};

export const countCustomHeightDoorsInOrder = (item) => {
  let count = 0;
  let finishSpec;
  let specifications = item.product.specifications;
  for (let spec in specifications) {
    if (specifications[spec].type === "CUT_DOWN_HEIGHT")
      finishSpec = specifications[spec];
  }
  if (finishSpec && finishSpec.description !== "0") {
    count = 1;
  }
  return count * item.quantity;
};

export const getCutDownItems = (quote) => {
  let items = quote.items;
  let itemList = [];
  for (let item in items) {
    let specifications = items[item].product.specifications;
    for (let spec in specifications) {
      if (
        specifications[spec].type === "CUT_DOWN_HEIGHT" &&
        specifications[spec].description !== "0"
      )
        itemList.push(items[item]);
    }
  }
  return itemList;
};

export const getCustomColorItems = (quote) => {
  let items = quote.items;
  let finishSpec;
  let itemList = [];
  for (let item in items) {
    let specifications = items[item].product.specifications;
    for (let spec in specifications) {
      if (specifications[spec].type === "SLAB_FINISH")
        finishSpec = specifications[spec];
    }
    let interiorPaintType = finishSpec.interior.finishType;
    let interiorFramePaintType = finishSpec.interiorFrame.finishType;
    let exteriorPaintType = finishSpec.exterior.finishType;
    let exteriorFramePaintType = finishSpec.exteriorFrame.finishType;
    if (
      interiorPaintType === "CUSTOM_PAINT" ||
      interiorFramePaintType === "CUSTOM_PAINT" ||
      exteriorPaintType === "CUSTOM_PAINT" ||
      exteriorFramePaintType === "CUSTOM_PAINT"
    ) {
      itemList.push(items[item]);
    }
  }
  return itemList;
};

export const isSlabCustomColor = (doorSpec) => {
  return (
    doorSpec.exteriorDoorColor.description.toLowerCase().includes("sw") ||
    doorSpec.interiorDoorColor.description.toLowerCase().includes("sw")
  );
};

export const mapComponentData = (item) => {
  let listOfTypes = getItemTypes(item);
  let mapData = new Map();

  for (let type of listOfTypes) {
    mapData.set(type, getSpecificPartType(item, type));
  }
  return mapData;
};

export const createPaymentPayload = (quote) => {
  let payload = `Number of Items: ${quote.items.length}`;
  let items = quote.items;
  for (let item of items) {
    let mappedComponentData = mapComponentData(item);
    if (!isEmpty(mappedComponentData)) {
      if (mappedComponentData.get("CustomPart")) {
        payload += mappedComponentData.get("CustomPart").description;
      } else {
        payload += isSlabCustomColor(mappedComponentData.get("Door"))
          ? " | slab_custom_color: true "
          : " | slab_custom_color: false ";
        payload += ` | panel: ${mappedComponentData.get("Door").description}`;
        payload += ` | door_glass: ${
          mappedComponentData.get("DoorGlass")
            ? mappedComponentData.get("DoorGlass").doorGlassName.description
            : "none"
        } `;
        if (mappedComponentData.get("Hardware")) {
          payload += ` | hardware: ${
            mappedComponentData.get("Hardware").description
          } hardware_finish: ${mappedComponentData.get("Hardware").finish} `;
        }
        payload += ` | frame: ${
          mappedComponentData.get("Frame").description
        }  `;
        payload += ` | interior_color: ${
          mappedComponentData.get("Door").interiorDoorColor.description
        }  `;
        payload += ` | exterior_color: ${
          mappedComponentData.get("Door").exteriorDoorColor.description
        }  `;
      }
    }
    payload += ` { item_resource_id: ${item.resourceId}`;
    payload += ` }, `;
  }

  return payload;
};

export const getValueOfCustomDoors = (quote) => {
  let valueOfCustomDoors = 0;
  let listOfCustomDoorItems = getCustomColorItems(quote);

  valueOfCustomDoors = listOfCustomDoorItems
    .map((item) => item.price.cost.value * item.quantity)
    .reduce((total, price) => total + price);

  return valueOfCustomDoors;
};

export const getValueOfCutDownDoors = (quote) => {
  let valueOfCutDownDoors = 0;
  let listOfCutDownDoorItems = getCutDownItems(quote);
  if (!listOfCutDownDoorItems.length) {
    return 0;
  }
  valueOfCutDownDoors = listOfCutDownDoorItems
    .map((item) => item.price.cost.value * item.quantity)
    .reduce((total, price) => total + price);

  return valueOfCutDownDoors;
};

export const getValueOfAllDoors = (quote) => {
  let valueOfDoors = 0;
  let listOfDoorItems = quote.items;

  valueOfDoors = listOfDoorItems
    .map((item) => item.price.cost.value * item.quantity)
    .reduce((total, price) => total + price);

  return valueOfDoors;
};
