import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import { ResultMessage } from "@masonite-digital/common-components";
import EditIcon from "@mui/icons-material/Edit";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { googleContextClickEvent } from "../../../analytics/googleAnalyticsUtility";
import {
  DoubleBothSLSlab,
  DoubleLeftSLSlab,
  DoubleRightSLSlab,
  DoubleSlab,
  SingleBothSLSlab,
  SingleLeftSLSlab,
  SingleRightSLSlab,
  SingleSlab,
} from "../../../components/Icons/Slabs";
import { ImageSelector } from "../../../components/Selection";
import { groupBy } from "../../../utils";
import ZoomComponent from "../preview/ZoomComponent";

const coloredDoorStyle = (theme) => ({
  "& #Color": {
    fill: theme.palette.primary["400"],
  },
  "& #Door": {
    fill: theme.palette.secondary.main,
  },
});

const coloredSideliteStyle = (theme) => ({
  "& #Color": {
    fill: theme.palette.primary["400"],
  },
  "& #Sidelite": {
    fill: theme.palette.secondary.main,
  },
});

const StyledSingleBothSLSlab = styled(SingleBothSLSlab, {
  shouldForwardProp: (prop) => prop !== "isDoorSide",
})(({ isDoorSide, theme }) =>
    isDoorSide ? coloredDoorStyle(theme) : coloredSideliteStyle(theme),
);

const StyledSingleLeftSLSlab = styled(SingleLeftSLSlab, {
  shouldForwardProp: (prop) => prop !== "isDoorSide",
})(({ isDoorSide, theme }) =>
    isDoorSide ? coloredDoorStyle(theme) : coloredSideliteStyle(theme),
);

const StyledDoubleLeftSLSlab = styled(DoubleLeftSLSlab, {
  shouldForwardProp: (prop) => prop !== "isDoorSide",
})(({ isDoorSide, theme }) =>
    isDoorSide ? coloredDoorStyle(theme) : coloredSideliteStyle(theme),
);

const StyledSingleRightSLSlab = styled(SingleRightSLSlab, {
  shouldForwardProp: (prop) => prop !== "isDoorSide",
})(({ isDoorSide, theme }) =>
    isDoorSide ? coloredDoorStyle(theme) : coloredSideliteStyle(theme),
);

const StyledDoubleRightSLSlab = styled(DoubleRightSLSlab, {
  shouldForwardProp: (prop) => prop !== "isDoorSide",
})(({ isDoorSide, theme }) =>
    isDoorSide ? coloredDoorStyle(theme) : coloredSideliteStyle(theme),
);

const StyledDoubleBothSLSlab = styled(DoubleBothSLSlab, {
  shouldForwardProp: (prop) => prop !== "isDoorSide",
})(({ isDoorSide, theme }) =>
    isDoorSide ? coloredDoorStyle(theme) : coloredSideliteStyle(theme),
);

const StyledSingleSlab = styled(SingleSlab)(({ theme }) =>
    coloredDoorStyle(theme),
);

const StyledDoubleSlab = styled(DoubleSlab)(({ theme }) =>
    coloredDoorStyle(theme),
);

export default function OptionList({
                                     title,
                                     isLoading,
                                     // hasCustomColor,
                                     selections,
                                     optionError,
                                     hasSubsections,
                                     sideliteGlassDesign,
                                     slabLayout,
                                     defaultSelected,
                                     optionsList = [],
                                     onOptionClick,
                                     // onCustomClick,
                                     // onCustomDelete,
                                     retryCall,
                                     openSideliteModal,
                                     // customColors,
                                   }) {
  const theme = useTheme();
  const [selected, setSelected] = useState(defaultSelected);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedPreviewName, setSelectedPreviewName] = useState("");
  const [selectedPreviewSrc, setSelectedPreviewSrc] = useState("");
  const widthUpSmall = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  React.useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  function handleClick(option) {
    if (option.resourceId !== selected.resourceId) {
      onOptionClick(option);

      // Do not set selected option when there is a custom height and the option is not FINISH, because the RemoveCutDownDialog will appear
      if (option.type !== "FINISH" && isEmpty(selections.cutDownDoorHeight)) {
        setSelected(option);
      }
    }
  }

  function handlePreviewOpen(option, src) {
    setOpenPreview(true);
    setSelectedPreviewName(option.name);
    setSelectedPreviewSrc(src);
    googleContextClickEvent({
      actionStep: "0",
      tabStep: "2",
      action: "click glass preview",
      label: option.description,
    });
  }

  function handlePreviewClose() {
    setOpenPreview(false);
  }

  function getSlabLayoutIcon(side) {
    if (slabLayout.hasSidelite) {
      const isDoorSide = side === "DOOR";
      switch (slabLayout.sidelite) {
        case "L":
          if (slabLayout.slabType === "SINGLE") {
            return <StyledSingleLeftSLSlab isDoorSide={isDoorSide} />;
          } else {
            return <StyledDoubleLeftSLSlab isDoorSide={isDoorSide} />;
          }
        case "R":
          if (slabLayout.slabType === "SINGLE") {
            return <StyledSingleRightSLSlab isDoorSide={isDoorSide} />;
          } else {
            return <StyledDoubleRightSLSlab isDoorSide={isDoorSide} />;
          }
        case "B":
          if (slabLayout.slabType === "SINGLE") {
            return <StyledSingleBothSLSlab isDoorSide={isDoorSide} />;
          } else {
            return <StyledDoubleBothSLSlab isDoorSide={isDoorSide} />;
          }
        default:
          return <div />;
      }
    } else {
      if (slabLayout.slabType === "SINGLE") {
        return <StyledSingleSlab />;
      } else {
        return <StyledDoubleSlab />;
      }
    }
  }

  function renderSelectionsContent() {
    const hasSideLiteGlassDesign =
        selections &&
        selections.glassDesign &&
        selections.glassDesign.sideLiteGlassDesign;

    if (title !== "Glass") {
      return (
          <>
            <Grid item>
              {isLoading ? (
                  <Skeleton variant="rectangular" width={75} height={24} />
              ) : (
                  <Typography>Selected:</Typography>
              )}
              {isLoading ? (
                  <Skeleton
                      variant="rectangular"
                      width={200}
                      height={30}
                      style={{ marginTop: "2px", marginBottom: "7px" }}
                  />
              ) : (
                  <Typography
                      data-test="selected-option"
                      variant="h6"
                      style={{ fontWeight: 600 }}
                      gutterBottom
                  >
                    {selected.description}
                  </Typography>
              )}
            </Grid>
          </>
      );
    }

    return (
        <>
          <Grid item>
            {isLoading ? (
                <>
                  <Skeleton variant="rectangular" width={75} height={24} />
                  <Skeleton
                      variant="rectangular"
                      width={200}
                      height={30}
                      style={{ marginTop: "2px", marginBottom: "7px" }}
                  />
                </>
            ) : (
                <ButtonBase disabled>
                  <div style={{ marginRight: "8px" }}>
                    {getSlabLayoutIcon("DOOR")}
                  </div>
                  <Grid container direction="column" alignItems={"flex-start"} item>
                    <Typography
                        style={{ fontSize: "12px", letterSpacing: "0.6px" }}
                    >
                      {" "}
                      DOOR:{" "}
                    </Typography>
                    <Typography
                        data-test="selected-glass-option"
                        variant="h6"
                        style={{ fontWeight: 600, lineHeight: "1.2" }}
                    >
                      {selected.description}
                    </Typography>
                  </Grid>
                </ButtonBase>
            )}
          </Grid>
          {sideliteGlassDesign && (
              <Grid container item xs={6} alignItems="center">
                <Grid item data-test="sidelite-glass">
                  {isLoading ? (
                      <>
                        <Skeleton variant="rectangular" width={75} height={24} />
                        <Skeleton
                            variant="rectangular"
                            width={200}
                            height={30}
                            style={{ marginTop: "2px", marginBottom: "7px" }}
                        />
                      </>
                  ) : (
                      <ButtonBase
                          onClick={openSideliteModal}
                          disabled={!hasSideLiteGlassDesign}
                      >
                        <div style={{ marginRight: "8px" }}>
                          {getSlabLayoutIcon()}
                        </div>
                        <Grid
                            container
                            direction="column"
                            alignItems={"flex-start"}
                            item
                        >
                          <Typography
                              style={{ fontSize: "12px", letterSpacing: "0.6px" }}
                          >
                            SIDELITE:
                          </Typography>
                          <Typography
                              data-test="selected-sidelite-option"
                              variant="h6"
                              style={{ fontWeight: 600, lineHeight: "1.2" }}
                          >
                            {sideliteGlassDesign.description}
                          </Typography>
                        </Grid>
                        {hasSideLiteGlassDesign && (
                            <Icon
                                sx={(theme) => ({
                                  color: theme.palette.secondary.main,
                                  margin: "0 8px",
                                })}
                                data-test="edit-sidelite-glass-btn"
                            >
                              <EditIcon />
                            </Icon>
                        )}
                      </ButtonBase>
                  )}
                </Grid>
              </Grid>
          )}
        </>
    );
  }

  function getSubSections() {
    return subSectionList.map((group, i) => {
      return (
          <React.Fragment key={i}>
            <Grid
                item
                xs={12}
                sx={(theme) => ({
                  background: theme.palette.primary["200"],
                  position: "sticky",
                  top: "48px",
                  zIndex: 999,
                  marginBottom: "8px",
                })}
            >
              {isLoading ? (
                  <Skeleton width={75} height={24} />
              ) : (
                  <>
                    <Typography
                        data-test={kebabCase(group.title[0] + "-label")}
                        variant={"body2"}
                        sx={{
                          color: "#868e96",
                          margin: "8px 0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                        }}
                        component="div"
                    >
                      {group.title[0].toUpperCase().replace("_", " ")}
                      {group.link}
                    </Typography>
                    <Divider />
                  </>
              )}
            </Grid>
            <ImageSelector
                isLoading={isLoading}
                gridContainerProps={{ marginBottom: "8px" }}
                options={group.title[1]}
                optionType={group.title[0]}
                selected={selected}
                slabLayout={slabLayout}
                onSelect={handleClick}
                imgSizeSx={(theme) => ({
                  width: "85px",
                  height: "auto",
                  ...(title === "Design" && {
                    width: "auto",
                    height: "160px",
                  }),
                  ...(title === "Finish" && {
                    width: "85px",
                    height: "85px",
                    [theme.breakpoints.down(1440)]: {
                      width: "48px",
                      height: "48px",
                    },
                  }),
                })}
                previewButtonProps={{
                  widthUpSmall: widthUpSmall,
                  onClick: handlePreviewOpen,
                }}
                showDescription={title !== "Design"}
                // showDelete={group.title[0] === "CUSTOM_PAINT"}
                // onDelete={onCustomDelete}
            />
          </React.Fragment>
      );
    });
  }

  // function getCustomColorButton() {
  //   return (
  //       <Grid item>
  //         <Button
  //             sx={(theme) => ({
  //               padding: 0,
  //               color: theme.palette.secondary.main,
  //             })}
  //             data-test={"custom-color-btn"}
  //             onClick={onCustomClick}
  //         >
  //           <PlusIcon /> CUSTOM
  //         </Button>
  //       </Grid>
  //   );
  // }

  if (!isLoading && (isEmpty(optionsList) || optionError)) {
    if (isEmpty(optionsList)) {
      return (
          <ResultMessage
              type="WARNING"
              title="Something went wrong"
              message=""
              onClick={retryCall}
          />
      );
    } else {
      return (
          <ResultMessage
              type={optionError.type}
              title={optionError.title}
              onClick={retryCall}
              message={optionError.message}
              buttonText={optionError.buttonText}
          />
      );
    }
  }

  let subSectionList = [];
  if (hasSubsections) {
    if (title === "Finish") {
      subSectionList = isLoading
          // Commenting the code (for removing Stains) instead of removing it, in case it is needed in the future
          // ? [{ title: "STAIN" }, { title: "PAINT" }]
          ? [{ title: "PAINT" }]
          : Object.entries(groupBy(optionsList, "finishType")).map((section) => {
            return {
              title: section,
              // link:
              //   section[0] === "CUSTOM_PAINT" ? getCustomColorButton() : null,
              link: null,
            };
          });
    } else if (title === "Design") {
      subSectionList = isLoading
          ? [
            { title: ["lip-lite", []] },
            { title: ["flushed", []] },
            { title: ["opaque", []] },
          ]
          : Object.entries(groupBy(optionsList, "glazingType")).map((section) => {
            return { title: section };
          });
    }
  }

  return (
      <Grid
          container
          sx={{
            minHeight: "100px",
            padding: "24px",
          }}
      >
        <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: "8px" }}
        >
          {renderSelectionsContent()}
        </Grid>
        <Grid item container>
          {hasSubsections ? (
              <>
                {getSubSections()}
                {/*{title === "Finish" &&*/}
                {/*  hasCustomColor &&*/}
                {/*  (!customColors || customColors.length === 0) &&*/}
                {/*  getCustomColorButton()}*/}
              </>
          ) : (
              <ImageSelector
                  isLoading={isLoading}
                  isLoadingCompatibility={isLoading && !isEmpty(optionsList)}
                  gridContainerProps={{ marginBottom: "8px" }}
                  options={optionsList}
                  optionType={title}
                  selected={selected}
                  onSelect={handleClick}
                  imgSizeSx={{ width: "85px", height: "auto" }}
                  previewButtonProps={{
                    widthUpSmall: widthUpSmall,
                    onClick: handlePreviewOpen,
                  }}
              />
          )}
          <ZoomComponent
              open={openPreview}
              handleClose={handlePreviewClose}
              title={selectedPreviewName}
              previewImg={selectedPreviewSrc}
          />
        </Grid>
      </Grid>
  );
}

OptionList.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // hasCustomColor: PropTypes.bool,
  optionError: PropTypes.object,
  selections: PropTypes.object.isRequired,
  sideliteGlassDesign: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  slabLayout: PropTypes.object,
  defaultSelected: PropTypes.object,
  optionsList: PropTypes.array.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  retryCall: PropTypes.func.isRequired,
  hasSubsections: PropTypes.bool,
  // onCustomClick: PropTypes.func,
  // onCustomDelete: PropTypes.func,
};
