import React from "react";
import PropTypes from "prop-types";
import {
  EmailTextField,
  NameTextField,
  PhoneNumberTextField,
  SelectTextField,
  ZipCodeTextField,
} from "@masonite-digital/common-components";
import AccountIcon from "@mui/icons-material/Person";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { states } from "../../utils";

export default function WarrantyRegistration({ onChange, values }) {
  const { errors } = values;

  function createChangeHandler(type) {
    return function handleChange(e) {
      onChange({ [type]: e.target.value });
    };
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography paragraph gutterBottom>
          Register your consumer for an Advisar Warranty using the fields below.
          This information is used to print the End Consumer’s contact
          information on the package.
        </Typography>
        <Typography variant={"subtitle2"} gutterBottom>
          See complete Advisar warranty details{" "}
          <Link
            href="https://p.widencdn.net/wfe9nt/2019-EXT-Warranty-final"
            target="_new"
          >
            here
          </Link>
          .
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ padding: "0 16px" }}>
        <Typography>
          <span>Customer Information</span>
          <Typography component="span" color="error">
            *
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} style={{ paddingTop: "0" }}>
        <NameTextField
          autoFocus
          name="name"
          label="Full Customer Name"
          onChange={createChangeHandler("name")}
          error={Boolean(errors.name)}
          value={values.name}
          dataTest="name-input"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountIcon style={{ color: "darkgray" }} />
              </InputAdornment>
            ),
          }}
        />
        <EmailTextField
          name="email"
          label="Customer Email"
          onChange={createChangeHandler("email")}
          error={Boolean(errors.email)}
          value={values.email}
        />
        <PhoneNumberTextField
          name="phoneNumber"
          label="Customer Phone"
          onChange={createChangeHandler("phone")}
          error={Boolean(errors.phone)}
          value={values.phone}
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ paddingTop: "0" }}>
        <form action="">
          <Grid item xs={12}>
            <NameTextField
              label="Customer Street Address Line 1"
              onChange={createChangeHandler("line1")}
              error={Boolean(errors.line1)}
              value={values.line1}
              dataTest="street-address-line1-input"
            />
          </Grid>
          <Grid item xs={12}>
            <NameTextField
              label="Customer Street Address Line 2"
              onChange={createChangeHandler("line2")}
              value={values.line2}
              dataTest="street-address-line2-input"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <NameTextField
                  label="City"
                  onChange={createChangeHandler("city")}
                  error={Boolean(errors.city)}
                  value={values.city}
                  dataTest={"city-input"}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SelectTextField
                  label="State"
                  id="state"
                  range={states}
                  value={values.state || ""}
                  onChange={createChangeHandler("state")}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ZipCodeTextField
                  onChange={createChangeHandler("zipCode")}
                  error={Boolean(errors.zipCode)}
                  value={values.zipCode}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

WarrantyRegistration.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    line1: PropTypes.string.isRequired,
    line2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    errors: PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      email: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      line1: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      city: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      state: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }).isRequired,
  }).isRequired,
};
