import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import ButtonBase from "@mui/material/ButtonBase";
import { ReactComponent as Logo } from "./AdvisarLogo.svg";

const AdvisarLogo = styled(Logo)`
  #Deco {
    fill: #c8102e;
  }
`;

function AdvisarLogoButton({ isLoggedIn }) {
  const environmentConfig = window.__RUNTIME_CONFIG__
      ? window.__RUNTIME_CONFIG__
      : process.env;

  return (
    <>
      {isLoggedIn ? (
        <ButtonBase
          component={Link}
          style={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          disableRipple
          disableTouchRipple
          to="/"
        >
          <AdvisarLogo />
        </ButtonBase>
      ) : (
        <ButtonBase
          component="a"
          style={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          disableRipple
          disableTouchRipple
          href={environmentConfig.REACT_APP_CMS_HOME_LINK}
        >
          <AdvisarLogo />
        </ButtonBase>
      )}
    </>
  );
}

AdvisarLogoButton.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AdvisarLogoButton;
