import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import MinusIcon from "@mui/icons-material/Remove";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { usd_decimal } from "../../utils/format";
import { PriceMaskCustom } from "../TextFields";

class DiscountUpdateDialog extends React.Component {
  state = {
    errorMessage: null,
    shouldRefresh: false,
  };

  handlePrimaryClick = async (event) => {
    try {
      await this.props.onPrimaryClick(event);
      this.setState({
        shouldRefresh: true,
      });
      this.props.onSecondaryClick();
    } catch (e) {
      this.setState({
        errorMessage: e.message,
      });
    }
  };

  handleTextFieldChange = (values) => {
    if (isNaN(values.floatValue)) {
      this.props.textFieldOnChange({ target: { value: "" } });
    } else {
      this.props.textFieldOnChange({ target: { value: values.floatValue } });
    }
    this.setState({
      errorMessage: null,
    });
  };

  handleOnExited = async () => {
    this.props.onExited();

    if (this.state.shouldRefresh) {
      await this.props.onRefresh();
    }
  };

  render() {
    const {
      open,
      value = 0,
      onSecondaryClick,
      orderItemSubTotalPrice = {},
    } = this.props;

    return (
      <AlertDialog
        open={open}
        title="Update Discount"
        primaryLabel="Save"
        onPrimaryClick={this.handlePrimaryClick}
        onSecondaryClick={onSecondaryClick}
        disablePrimary={value === 0}
        dialogType={this.state.errorMessage ? "ERROR" : ""}
        helperText={
          this.state.errorMessage ||
          "*Subject to the approval of the Sales Manager"
        }
        TransitionProps={{
          onExited: this.handleOnExited,
        }}
        customContent={
          <Grid container spacing={2} direction="column" alignItems="flex-end">
            <Grid item container xs={12} justifyContent="space-between">
              <Grid item xs={6}>
                <Typography>Total Price</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography data-test="order-item-subtotal">
                  {orderItemSubTotalPrice.displayValue}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={6}>
                <Typography>Discount</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <TextField
                  autoFocus
                  variant="standard"
                  value={value}
                  onChange={this.handleTextFieldChange}
                  margin="dense"
                  type="tel"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      "data-test": "discount-input",
                      inputMode: "decimal",
                      style: {
                        textAlign: "right",
                      },
                      limit: orderItemSubTotalPrice.value,
                    },
                    inputComponent: PriceMaskCustom,
                    startAdornment: (
                      <InputAdornment position="start">
                        <MinusIcon style={{ fontSize: "16px" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="space-between">
              <Grid item xs={6} style={{ whiteSpace: "nowrap" }}>
                <Typography>Discounted Price</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography data-test="discounted-price">
                  {usd_decimal(orderItemSubTotalPrice.value - value)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    );
  }
}

DiscountUpdateDialog.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onExited: PropTypes.func,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  textFieldOnChange: PropTypes.func.isRequired,
  orderItemSubTotalPrice: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default DiscountUpdateDialog;
