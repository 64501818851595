import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import { useCheckSnackbar } from "../../utils";

export default function DeclinedByCustomerDialog(props) {
  const { open, onPrimaryClick, onSecondaryClick, refresh } = props;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const enqueueSnackbar = useCheckSnackbar();

  async function handlePrimaryClick() {
    try {
      await onPrimaryClick();
      enqueueSnackbar("Customer lead declined", { variant: "success" });
      onSecondaryClick();
      refresh();
    } catch (e) {
      setErrorMessage(e.message);
    }
  }

  return (
    <AlertDialog
      open={open}
      title="Declined by Customer?"
      contentText="Was this lead declined by your customer?"
      helperText={errorMessage}
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      primaryLabel="Decline"
    />
  );
}

DeclinedByCustomerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};
