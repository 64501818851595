import { green } from "@mui/material/colors";
import { darken } from "@mui/material/styles";

export const overridesTheme = (theme) => ({
  components: {
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.common.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: `${theme.palette.common.white} !important`,
          boxShadow: "none",

          "&:disabled": {
            color: `${theme.palette.text.disabled} !important`,
          },
        },
        outlined: {
          color: theme.palette.secondary.main,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary["200"],
          padding: theme.spacing(2),
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary["200"],
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
          },
        },
        action: {
          alignSelf: "center",
          marginTop: 0,
          marginRight: 0,
          textAlign: "right",
          [theme.breakpoints.down("sm")]: {
            textAlign: "center",
          },
        },
        title: {
          fontSize: "1.5rem",
          [theme.breakpoints.down("sm")]: {
            textAlign: "center",
          },
        },
        subheader: {
          [theme.breakpoints.down("sm")]: {
            textAlign: "center",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable:not(.colorChip) > .MuiChip-deleteIcon": {
            color: "#fff",
            opacity: "0.7",
          },
        },
      },
    },
    MuiExpansionPanelSummary: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          "&$expanded": {
            color: theme.palette.text.primary,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputAdornedStart: {
          "&.colorCode": {
            paddingLeft: "6px !important",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&$error": {
            "&:hover:not($disabled):not($focused):after": {
              borderBottom: `2px solid ${theme.palette.secondary.main}`,
            },
          },
        },
        underline: {
          "&:after": {
            borderBottomColor: theme.palette.secondary.main,
          },
          "&:hover:not($disabled):not($focused):before": {
            borderBottom: `2px solid ${theme.palette.secondary.main} !important`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&:focused": {
            color: theme.palette.secondary.main,
          },
          "&$error": {
            color: `${theme.palette.error.main} !important`,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary["400"],
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&$selected": {
            color: theme.palette.common.white,
          },
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        dot: {
          backgroundColor: theme.palette.primary["400"],
          margin: "0 12px",
        },
        dotActive: {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          "&:not(disabled)": {
            fontWeight: "bold",
          },
          "&:focus": {
            "&.Mui-selected": {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
            },
          },
          "&.Mui-selected": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
            "&:hover": {
              backgroundColor: darken(theme.palette.secondary.main, 0.2),
            },
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          backgroundColor: theme.palette.primary["200"],
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: theme.palette.secondary.main,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: theme.palette.secondary.main,
          },
          "&.Mui-completed": {
            color: green[500],
          },
        },
        text: {
          fill: theme.palette.common.white,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontSize: "0.9rem",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          "&$selected": {
            backgroundColor: theme.palette.secondary["100"],
          },
        },
        hover: {
          "&:hover": {
            backgroundColor: `${theme.palette.primary["100"]} !important`,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "&.tabBar": {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary["300"],
          },
        },
        indicator: {
          height: "0.25rem",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary["200"],
        },
      },
    },
  },
});
