import React from "react";
import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import OptionSkeleton, { OptionSkeletonType } from "../Common/OptionSkeleton";

export default function LabeledCheckboxGroup({
  isLoading,
  options,
  onChange,
  disabled,
  label,
  description,
  selections,
}) {
  const renderOptions = isLoading ? [{}, {}, {}] : options;

  return (
    <Grid container>
      {label &&
        (isLoading ? (
          <Skeleton
            style={{ margin: "8px 0" }}
            variant="rectangular"
            height={24}
            width={90}
          />
        ) : (
          <Grid item xs={12}>
            <Typography
              sx={(theme) => ({
                ...(disabled && {
                  color: theme.palette.action.disabled,
                }),
              })}
            >
              {label}:
            </Typography>
          </Grid>
        ))}

      {description && (
        <Grid item xs={12}>
          <Typography variant="caption">{description}</Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <FormControl component="fieldset" disabled={disabled}>
          <FormGroup data-test={kebabCase(`${label}-list`)} row>
            {renderOptions.map((option, i) => {
              if (isLoading) {
                return (
                  <OptionSkeleton key={i} type={OptionSkeletonType.CHECK_BOX} />
                );
              }

              const key = camelCase(option.type + " " + option.value);
              return (
                <FormControlLabel
                  key={option.resourceId}
                  disabled={disabled}
                  label={option.description}
                  onChange={() => onChange(key, selections[key])}
                  control={
                    <Checkbox
                      data-test={kebabCase(key)}
                      color="secondary"
                      checked={selections[key]}
                    />
                  }
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

LabeledCheckboxGroup.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selections: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
};
