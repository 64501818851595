import React from "react";
import PropTypes from "prop-types";
import {
  AlertDialog,
  EmailTextField,
  NameTextField,
} from "@masonite-digital/common-components";
import AccountIcon from "@mui/icons-material/Person";
import InputAdornment from "@mui/material/InputAdornment";
import { googleEmailEvent } from "../../analytics/googleAnalyticsUtility";
import { isEmailValid, useCheckSnackbar } from "../../utils";

export default function SendEmailDialog(props) {
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(null);
  const [fullNameError, setFullNameError] = React.useState(null);
  const [error, setError] = React.useState(null);
  const enqueueSnackbar = useCheckSnackbar();

  const { open, onPrimaryClick, onSecondaryClick, quote } = props;

  const handleOnExit = () => {
    setError(null);
    setEmail("");
    setFullName("");
  };

  const onEnter = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await handlePrimaryClick();
    }
  };

  const handlePrimaryClick = async () => {
    if (!Boolean(fullName)) {
      setFullNameError("Name of Recipient is required.");
    }
    if (!isEmailValid(email)) {
      setEmailError(true);
    }

    if (!Boolean(fullName) || !isEmailValid(email)) {
      return;
    }

    try {
      await onPrimaryClick(quote.resourceId, { fullName, email });
      googleEmailEvent("click send email", quote);
      onSecondaryClick();
      enqueueSnackbar(`Quote emailed to ${email}`, { variant: "success" });
    } catch (e) {
      googleEmailEvent("click send email failure", quote);
      setError(e.message);
    }
  };

  const handleNameChange = (event) => {
    setFullNameError(null);
    setError(null);
    setFullName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailError(null);
    setError(null);
    setEmail(event.target.value);
  };

  return (
    <AlertDialog
      open={open}
      title="Email Quote?"
      primaryLabel="Email"
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      helperText={error}
      TransitionProps={{
        onExited: handleOnExit,
      }}
      customContent={
        <>
          <NameTextField
            autoFocus
            autoComplete="name"
            label="Name of Recipient"
            value={fullName}
            error={Boolean(fullNameError)}
            labelErrorMessage={fullNameError}
            onChange={handleNameChange}
            onKeyPress={onEnter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountIcon style={{ color: "darkgray" }} />
                </InputAdornment>
              ),
            }}
          />
          <EmailTextField
            label="Email of Recipient"
            value={email}
            error={Boolean(emailError)}
            onChange={handleEmailChange}
            onKeyPress={onEnter}
          />
        </>
      }
    />
  );
}

SendEmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};
