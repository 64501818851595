import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import CutDownDoor from "../../actions/ProductBuilder/step2/CutDownDoor.js";

export default function CutDownDialog(props) {
  const {
    open,
    onPrimaryClick,
    onSecondaryClick,
    doorHeight,
    cutDownValue,
    cutDownDoorHeights,
    cutDownIndex,
    setCutDownIndex,
    getRoughOpenings,
    selections,
    roughOpening,
  } = props;
  return (
    <AlertDialog
      open={open}
      title="Customize Height?"
      onPrimaryClick={onPrimaryClick}
      primaryLabel="Customize"
      onSecondaryClick={onSecondaryClick}
      secondaryLabel="Cancel"
      contentText="Customize and fine tune the height of your door by removing up to 3” from the height of your door."
      customContent={
        <CutDownDoor
          cutDownValue={cutDownValue}
          doorHeight={doorHeight}
          originalUnitHeight={roughOpening.originalUnitHeight}
          cutDownIndex={cutDownIndex}
          setCutDownIndex={setCutDownIndex}
          cutDownDoorHeights={cutDownDoorHeights}
          getRoughOpenings={getRoughOpenings}
          selections={selections}
        />
      }
    />
  );
}

CutDownDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  doorHeight: PropTypes.number.isRequired,
  cutDownValue: PropTypes.number.isRequired,
  cutDownDoorHeights: PropTypes.array.isRequired,
  setInches: PropTypes.func,
};
