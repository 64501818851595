import React from "react";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";

const usdOptions = Object.freeze({
  style: "currency",
  currency: "USD",
});

/**
 * Accepts an integer, null or undefined and returns a formatted Dollar value.
 * Throws on wrong type. By default will always include cents.
 *
 * To return no cents, both options:
 *  - minimumFractionDigits
 *  - maximumFractionDigits
 * must be set to 0.
 *
 * @param {number} amount value expressed in whole cents.
 * @param {object} options override default options.
 * @returns {string} a formatted Dollar value.
 */
export function usd(amount, options = {}) {
  if (!amount) {
    amount = 0;
  }
  if (!Number.isInteger(amount)) {
    throw new TypeError("Amount must be of type Integer.");
  }
  const us = new Intl.NumberFormat("en-US", {
    ...usdOptions,
    ...options,
  });
  return us.format(amount / 100);
}

export function usd_integer(amount, options = {}) {
  if (!amount) {
    amount = 0;
  }
  const value = Number.parseInt(amount);
  const usdInt = new Intl.NumberFormat("en-US", {
    ...usdOptions,
    ...options,
  });
  return usdInt.format(value);
}

export function usd_decimal(amount, options = {}) {
  if (!amount) {
    amount = 0;
  }

  const usdDecimal = new Intl.NumberFormat("en-US", {
    ...usdOptions,
    ...options,
  });
  return usdDecimal.format(amount);
}

/**
 * Accepts an integer, null or undefined and returns a formatted Dollar value in thousands.
 * Throws on wrong type.
 * @param {number} amount value expressed in whole cents.
 * @param {object} options override default options.
 * @returns {string} a formatted Dollar value in thousands if it's over 100k.
 */
export function usd_k(amount, options = usdOptions) {
  if (amount / 100 >= 100000) {
    return (
      usd(Number.parseInt(amount / 1000), {
        ...options,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }) + "k"
    );
  }
  return usd(amount, options);
}

/**
 * Accepts any value, except strings with letters, and returns a converted cents value.
 * If the value is null, undefined, empty, or contains only special characters ($,.), it returns 0.
 * @param amount
 * @returns {number} a converted cents amount.
 */
export function toCents(amount) {
  if (!amount) {
    return 0;
  }

  if (typeof amount == "string") {
    const replacedAmount = amount.replace(/[$|,]/g, "");
    if (replacedAmount !== "." && replacedAmount !== "") {
      amount = Number(replacedAmount);
    } else {
      amount = 0;
    }
  }
  return Number.parseInt(amount * 100);
}

export function formatPhoneNumber(phoneNumberString) {
  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export function mapsAddress(address) {
  if (
    address != null &&
    address.line1 &&
    address.city &&
    address.state &&
    address.zipCode
  ) {
    return `https://www.google.com/maps/place/${address.line1},+${address.city},+${address.state}+${address.zipCode}`;
  } else {
    return "#";
  }
}

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZone: "UTC",
};

export function formatDate(date, options = dateOptions) {
  const dateObject = new Date(date);
  return dateObject.toLocaleString("en-US", options);
}

export function msToTwelveHours(time) {
  const newTime = new Date(time);

  const hours = newTime.getHours() % 12;
  let minutes = newTime.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const ampm = newTime.getHours() >= 12 ? "pm" : "am";

  return (hours ? hours : 12) + ":" + minutes + " " + ampm;
}

/**
 * Takes in a Date object and returns DD/MM/YYYY.
 * @param date
 * @returns {string} 01/01/2019
 */
export function convertDate(date) {
  return `${date.getUTCFullYear()}-${
    date.getUTCMonth() + 1 < 10
      ? "0" + (date.getUTCMonth() + 1)
      : date.getUTCMonth() + 1
  }-${date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate()}`;
}

/**
 * Takes in a Date object and returns MMDDYYYY.
 * @param date
 * @returns {string} 12312020
 */
export function convertDateUS(date) {
  return `${
    date.getUTCMonth() + 1 < 10
      ? "0" + (date.getUTCMonth() + 1)
      : date.getUTCMonth() + 1
  }${
    date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate()
  }${date.getUTCFullYear()}`;
}

export function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export function formatNumberWithCommas(num) {
  return num.toLocaleString("en-US");
}

export function formatAddress(address, singleLine) {
  if (isEmpty(address)) {
    return null;
  }

  if (singleLine) {
    return (
      startCase(address.line1.toLowerCase()) +
      (address.line2 ? ` ${startCase(address.line2.toLowerCase())},` : ",") +
      ` ${startCase(address.city.toLowerCase())}, ${address.state}, ${
        address.zipCode
      }`
    );
  }

  return (
    <div>
      <div>{address.line1 || "N/A"}</div>
      {address.line2 && <div>{address.line2}</div>}
      <div>{`${address.city || "N/A"}, ${address.state || "N/A"} ${
        address.zipCode || "N/A"
      }`}</div>
    </div>
  );
}

export function formatPercent(amount) {
  if (!amount) {
    return "0%";
  }
  return amount.toFixed(2) + "%";
}
