import React from "react";
import PropTypes from "prop-types";
import { Box, Skeleton, styled } from "@mui/material";

export const OptionSkeletonType = Object.freeze({
  SLAB_LAYOUT: "SLAB_LAYOUT",
  SINGLE_DOOR: "SINGLE_DOOR",
  SINGLE_DOOR_LSL: "SINGLE_DOOR_LSL",
  SINGLE_DOOR_RSL: "SINGLE_DOOR_RSL",
  SINGLE_DOOR_TWO_SL: "SINGLE_DOOR_TWO_SL",
  DOUBLE_DOOR: "DOUBLE_DOOR",
  DOUBLE_DOOR_LSL: "DOUBLE_DOOR_LSL",
  DOUBLE_DOOR_RSL: "DOUBLE_DOOR_RSL",
  DOUBLE_DOOR_TWO_SL: "DOUBLE_DOOR_TWO_SL",
  GLASS_DESIGN: "GLASS_DESIGN",
  FINISH: "FINISH",
  CHECK_BOX: "CHECK_BOX",
  SQUARE_BOX: "SQUARE_BOX",
  RECTANGLE_BOX: "RECTANGLE_BOX",
});

const StyledWrapperBox = styled(Box)(() => ({
  position: "relative",
  padding: "4px",
  border: "4px solid transparent",
}));

const StyledCompatibilitySkeleton = styled(Skeleton)(({ theme }) => ({
  position: "absolute",
  bottom: "-10px",
  right: "-10px",
  width: 20,
  height: 20,
  [theme.breakpoints.down("sm")]: {
    right: "-5px",
    bottom: "-5px",
    width: 12,
    height: 12,
  },
}));

export function CompatibilityIconSkeleton() {
  return (
    <>
      <StyledCompatibilitySkeleton
        sx={{
          backgroundColor: "primary.200",
        }}
        variant="circular"
        animation={false}
      />
      <StyledCompatibilitySkeleton variant="circular" />
    </>
  );
}

export default function OptionSkeleton(props) {
  const { type, optionWidth, optionHeight } = props;

  switch (type) {
    case OptionSkeletonType.SLAB_LAYOUT:
      return (
        <StyledWrapperBox>
          <Skeleton width={50} height={50} variant="rectangular" />
          <CompatibilityIconSkeleton />
        </StyledWrapperBox>
      );
    case OptionSkeletonType.SINGLE_DOOR:
      return (
        <StyledWrapperBox>
          <Skeleton
            sx={(theme) => ({
              width: 78,
              height: 160,
              [theme.breakpoints.down("sm")]: {
                width: 35,
                height: 75,
              },
            })}
            variant="rectangular"
          />
          <CompatibilityIconSkeleton />
        </StyledWrapperBox>
      );
    case OptionSkeletonType.SINGLE_DOOR_LSL:
    case OptionSkeletonType.SINGLE_DOOR_RSL:
      return (
        <StyledWrapperBox>
          <Skeleton
            sx={(theme) => ({
              width: 108,
              height: 160,
              [theme.breakpoints.down("sm")]: {
                width: 48,
                height: 75,
              },
            })}
            variant="rectangular"
          />
          <CompatibilityIconSkeleton />
        </StyledWrapperBox>
      );
    case OptionSkeletonType.SINGLE_DOOR_TWO_SL:
      return (
        <StyledWrapperBox>
          <Skeleton
            sx={(theme) => ({
              width: 108,
              height: 160,
              [theme.breakpoints.down("sm")]: {
                width: 48,
                height: 75,
              },
            })}
            variant="rectangular"
          />
          <CompatibilityIconSkeleton />
        </StyledWrapperBox>
      );
    case OptionSkeletonType.DOUBLE_DOOR:
      return (
        <StyledWrapperBox>
          <Skeleton
            sx={(theme) => ({
              width: 152,
              height: 160,
              [theme.breakpoints.down("sm")]: {
                width: 68,
                height: 75,
              },
            })}
            variant="rectangular"
          />
          <CompatibilityIconSkeleton />
        </StyledWrapperBox>
      );
    case OptionSkeletonType.DOUBLE_DOOR_LSL:
    case OptionSkeletonType.DOUBLE_DOOR_RSL:
      return (
        <StyledWrapperBox>
          <Skeleton
            sx={(theme) => ({
              width: 181,
              height: 160,
              [theme.breakpoints.down("sm")]: {
                width: 81,
                height: 75,
              },
            })}
            variant="rectangular"
          />
          <CompatibilityIconSkeleton />
        </StyledWrapperBox>
      );
    case OptionSkeletonType.DOUBLE_DOOR_TWO_SL:
      return (
        <StyledWrapperBox>
          <Skeleton
            sx={(theme) => ({
              width: 209,
              height: 160,
              [theme.breakpoints.down("sm")]: {
                width: 94,
                height: 75,
              },
            })}
            variant="rectangular"
          />
          <CompatibilityIconSkeleton />
        </StyledWrapperBox>
      );
    case OptionSkeletonType.GLASS_DESIGN:
      return (
        <StyledWrapperBox>
          <Skeleton
            sx={(theme) => ({
              width: 85,
              height: 118,
              [theme.breakpoints.down("sm")]: {
                width: 40,
                height: 55,
              },
            })}
            variant="rectangular"
          />
          <CompatibilityIconSkeleton />
        </StyledWrapperBox>
      );
    case OptionSkeletonType.FINISH:
      return (
        <Box p="4px" border="4px solid transparent">
          <Skeleton
            sx={(theme) => ({
              width: 85,
              height: 85,
              [theme.breakpoints.down("sm")]: {
                width: 40,
                height: 40,
              },
            })}
            variant="rectangular"
          />
        </Box>
      );
    case OptionSkeletonType.CHECK_BOX:
      return (
        <Skeleton
          sx={{
            mr: 2,
            mb: 1,
          }}
          variant="rectangular"
          height={optionHeight || 24}
          width={optionWidth || 65}
        />
      );
    case OptionSkeletonType.SQUARE_BOX:
      return (
        <Skeleton
          sx={{
            mr: 1,
            mb: 1,
          }}
          variant="rectangular"
          height={optionHeight || 75}
          width={optionWidth || 75}
        />
      );
    case OptionSkeletonType.RECTANGLE_BOX:
      return (
        <Skeleton
          sx={{
            mr: 1,
            mb: 1,
          }}
          variant="rectangular"
          height={optionHeight || 64}
          width={optionWidth || 116}
        />
      );
    default:
      return <div />;
  }
}

OptionSkeleton.propTypes = {
  type: PropTypes.string,
  optionWidth: PropTypes.number,
  optionHeight: PropTypes.number,
};
