import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import NoImage from "../Icons/no-image-available.svg";

export default function ImageOverlay({
  imgSizeSx,
  option,
  imageHref,
  fillColor,
}) {
  return (
    <Box sx={imgSizeSx}>
      <Box
        sx={{
          position: "absolute",
          display: "block",
          backfaceVisibility: "initial",
          mixBlendMode: "multiply",
          filter: "contract(1.16)",
          fillOpacity: 0.85,
        }}
      >
        <Box component="svg" sx={imgSizeSx}>
          <Box component="rect" sx={imgSizeSx} fill={fillColor} />
        </Box>
      </Box>
      <Box
        component="img"
        sx={imgSizeSx}
        alt={option.description}
        src={imageHref}
        onError={(e) => {
          e.target.src = NoImage;
        }}
      />
    </Box>
  );
}

ImageOverlay.propTypes = {
  imgSizeSx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  option: PropTypes.object.isRequired,
  imageHref: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};
