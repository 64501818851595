import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

export default function ProgressLinear(props) {
  const { open, title } = props;

  return (
    <Dialog
      open={open}
      data-test={props.dataTest || "loading-dialog"}
      PaperProps={{
        style: {
          width: "284px",
          padding: "32px",
        },
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Typography data-test="loading-text" style={{ marginBottom: "16px" }}>
          {title}
        </Typography>
        <LinearProgress color="secondary" />
      </div>
    </Dialog>
  );
}

ProgressLinear.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};
