import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import { ResultMessage } from "@masonite-digital/common-components";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import AddToExistingQuote from "./AddToExistingQuote";
import AddToQuoteDialog from "./AddToQuoteDialog";
import ReviewDoorSpec from "./ReviewDoorSpec";
import { AuthContext } from "../../../AuthContext";
import { Authority } from "../../../AuthProvider";
import { googleContextClickEvent } from "../../../analytics/googleAnalyticsUtility";
import { ProgressLinear } from "../../../components/Common";
import { AddToNewQuote, QuoteSuccessDialog } from "../../../components/Dialogs";
import { DelayedIcon } from "../../../components/Icons";
import { checkSnackbar, validateQuoteName } from "../../../utils";

export const DIALOG_TYPES = Object.freeze({
  EXISTING: "EXISTING",
  NEW: "NEW",
});

const StyledQuoteButton = styled(Button)(() => ({
  width: "100%",
  letterSpacing: "2px",
}));

const AddToQuoteSection = (props) => {
  const context = React.useContext(AuthContext);
  const theme = useTheme();
  const {
    openNewQuoteDialog,
    openExistingQuoteDialog,
    isMobile,
    isLoading,
    price,
    saveEditAndReturnToQuotes,
    discardEditAndReturnToQuotes,
    mode,
    payload,
  } = props;
  const isInEditMode = mode && mode.isInEditMode;

  const hasSignature = payload && payload.signature;

  const canCreateQuote = context.hasAuthority(Authority.ORDER_CREATE);

  return (
    <div style={{ padding: "0 15px" }}>
      <Divider style={{ margin: "15px 0" }} />
      <div>
        {!isMobile && canCreateQuote && (
          <Typography variant={"h6"}>
            {isInEditMode ? "Finish & Update Quote" : "Finish & Add to Quote"}
          </Typography>
        )}
        {isInEditMode && (
          <List style={{ display: "flex" }}>
            <ListItem disableGutters alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor: "primary.400",
                    color: "primary.700",
                  }}
                >
                  <ShoppingCartIcon alt="Shopping Cart" />
                </Avatar>
              </ListItemAvatar>
              <div>
                <Typography variant={"body2"} color={"primary"}>
                  SAVING TO:
                </Typography>
                <Typography variant={"subtitle1"}>
                  {mode.name ? mode.name.toUpperCase() : "N/A"}
                </Typography>
                <Typography variant={"body1"} color={"primary"}>
                  {mode.referenceNumber ? mode.referenceNumber : "N/A"}
                </Typography>
              </div>
            </ListItem>
          </List>
        )}
      </div>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant={"overline"}>Price</Typography>
          <Typography
            variant={"h4"}
            style={{ paddingBottom: "20px" }}
            data-test="door-price"
          >
            {isLoading ? <Skeleton variant="rectangular" width={200} /> : price}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "16px",
          }}
        >
          {canCreateQuote && (
            <>
              {isInEditMode ? (
                <div>
                  <Button
                    sx={{
                      width: "100%",
                      letterSpacing: "2px",
                      marginBottom: "8px",
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={saveEditAndReturnToQuotes}
                    data-test="save-changes-btn"
                    disabled={!hasSignature}
                  >
                    Save Changes
                  </Button>
                  <StyledQuoteButton
                    variant="outlined"
                    color="primary"
                    onClick={discardEditAndReturnToQuotes}
                    data-test="cancel-btn"
                  >
                    Cancel
                  </StyledQuoteButton>
                </div>
              ) : (
                <div>
                  <StyledQuoteButton
                    variant="contained"
                    color="secondary"
                    style={{ width: "100%" }}
                    onClick={openNewQuoteDialog}
                    data-test="add-to-new-quote-btn"
                  >
                    Add to new quote
                  </StyledQuoteButton>
                  <div style={{ textAlign: "center" }}>
                    <Typography variant={"overline"}>or</Typography>
                  </div>
                  <StyledQuoteButton
                    variant="contained"
                    color="secondary"
                    onClick={openExistingQuoteDialog}
                    data-test="add-to-existing-quote-btn"
                  >
                    Add to Existing Quote
                  </StyledQuoteButton>
                </div>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {!isLoading && !payload.available && (
        <Grid container>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <DelayedIcon />
          </Grid>
          <Grid item xs={11} md={11}>
            <Typography
              data-test="qoh-message"
              variant="body2"
              style={{
                color: theme.palette.error.dark,
                paddingLeft: "5px",
              }}
            >
              This door configuration may be delayed and have increased delivery
              times.
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export class DoorSummary extends React.Component {
  state = {
    dialogOpen: false,
    dialogContent: DIALOG_TYPES.EXISTING,
    quoteSelected: null,
    quotes: [],
    quoteName: "",
    error: null,
    savedOrder: null,
  };

  static checkAddEnabled(dialogContent, quoteSelected, quoteName, error) {
    return (
      !error &&
      (dialogContent === DIALOG_TYPES.EXISTING
        ? !(!quoteSelected || quoteSelected === -1)
        : !!quoteName)
    );
  }

  closeDialog = () => {
    this.setState({ error: null, dialogOpen: false });
  };

  openExistingQuoteDialog = async () => {
    const { doorViewOptions } = this.props;
    const quotes = await this.props.getQuotes({
      filter: this.props.isCsr
        ? `companyResourceId==${this.props.companyId};state.status==OPEN;orderType==SALE`
        : "state.status==OPEN;orderType==SALE",
      pageNumber: 0,
      pageSize: 0,
      pageSort: "-",
    });

    this.setState({
      dialogContent: DIALOG_TYPES.EXISTING,
      dialogOpen: true,
      quoteName: "",
      quotes: quotes.content,
      error: null,
    });

    googleContextClickEvent({
      activeStep: doorViewOptions.activeStep,
      tabStep: doorViewOptions.tabIndex,
      action: "Click Add To Existing Quote",
    });
  };

  openNewQuoteDialog = () => {
    const { doorViewOptions } = this.props;
    this.setState({
      dialogContent: DIALOG_TYPES.NEW,
      dialogOpen: true,
      quoteSelected: null,
      error: null,
      quoteName: "",
    });

    googleContextClickEvent({
      activeStep: doorViewOptions.activeStep,
      tabStep: doorViewOptions.tabIndex,
      action: "Click Add To New Quote",
    });
  };

  handleAddDoorToExistingQuote = async (selectedQuote, quantity) => {
    const { addToExistingQuote, payload, doorViewOptions } = this.props;

    try {
      let savedOrder = await addToExistingQuote(
        selectedQuote.resourceId,
        payload,
        quantity,
        doorViewOptions.mode.leadResourceId,
        doorViewOptions.mode.leadItemResourceId
      );
      this.setState({ doorAddedDialogOpen: true, savedOrder, error: null });
      this.closeDialog();
    } catch (error) {
      if (error.status === 401) {
        this.closeDialog();
      } else {
        this.setState({ error });
      }
    }
  };

  handleAddDoorToNewQuote = async (quoteName, quantity) => {
    const { addToNewQuote, payload, doorViewOptions, selections } = this.props;
    const isCutDown = !isEmpty(selections.cutDownDoorHeight);
    try {
      let savedOrder = await addToNewQuote(
        quoteName,
        payload,
        quantity,
        doorViewOptions.mode.leadResourceId,
        doorViewOptions.mode.leadItemResourceId,
        isCutDown
      );
      this.setState({ doorAddedDialogOpen: true, savedOrder, error: null });
      this.closeDialog();
    } catch (error) {
      if (error.status === 401) {
        this.closeDialog();
      } else {
        this.setState({ error });
      }
    }
  };

  cancelEditAndReturnToQuotes = async () => {
    const { resetState, goToQuote, doorViewOptions } = this.props;
    await resetState();
    goToQuote(`/quotes/${doorViewOptions.mode.orderResourceId}`);
  };

  saveEditAndReturnToQuotes = async () => {
    const {
      updateExistingQuote,
      resetState,
      payload,
      doorViewOptions,
      goToQuote,
    } = this.props;

    const { orderItemResourceId, orderResourceId } =
      doorViewOptions && doorViewOptions.mode;
    try {
      await updateExistingQuote(orderResourceId, orderItemResourceId, payload);
      checkSnackbar(
        "Line item updated",
        { variant: "success" },
        enqueueSnackbar
      );
      await resetState();
      goToQuote(`/quotes/${orderResourceId}`);
    } catch (error) {
      if (error.status === 401) {
        this.closeDialog();
      } else {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }
  };

  handleGoToQuote = async () => {
    const { goToQuote, resetState, doorViewOptions } = this.props;
    const { savedOrder } = this.state;
    if (doorViewOptions.mode.isConsumerLead) {
      await resetState();
    }
    goToQuote(`/quotes/${savedOrder && savedOrder.resourceId}`);
  };

  setQuoteName = (quoteName) => {
    this.setState({ quoteName, error: null });

    const quoteValidationError = validateQuoteName(quoteName);

    if (quoteValidationError) {
      this.setState({
        error: { message: quoteValidationError },
      });
    } else {
      this.setState({ error: null });
    }
  };

  render() {
    const {
      isLoading,
      isLoadingEditableDoor,
      retryGetProduct,
      getProductError,
      isMobile,
      payload,
      goToStep,
      doorViewOptions,
      roughOpening,
      selections,
      location,
    } = this.props;
    const {
      quotes,
      quoteSelected,
      dialogOpen,
      dialogContent,
      quoteName,
      doorAddedDialogOpen,
      error,
    } = this.state;

    if (!isLoading && isEmpty(payload)) {
      return (
        <Paper
          elevation={2}
          style={{
            padding: "16px",
            margin: "16px 3.5%",
            height: "calc(100% - 32px)",
          }}
        >
          <ResultMessage
            type="WARNING"
            title="Something went wrong"
            message={getProductError}
            onClick={retryGetProduct}
          />
        </Paper>
      );
    }

    return (
      <Paper
        elevation={2}
        style={{
          padding: "16px",
          margin: "16px 3.5%",
        }}
      >
        <ProgressLinear
          open={isLoading}
          title={
            isLoadingEditableDoor
              ? "Loading Door to Edit"
              : "Pricing Configuration"
          }
        />
        {isMobile && (
          <Typography
            variant="h6"
            gutterBottom
            style={{
              paddingLeft: "20px",
            }}
          >
            Review and Add to Quote
          </Typography>
        )}
        <ReviewDoorSpec
          isMobile={isMobile}
          isLoading={isLoading}
          doorConfig={get(payload, "resource", {})}
          doorViewOptions={doorViewOptions}
          roughOpening={roughOpening}
          handing={selections.handing}
          goToStep={goToStep}
        />
        <AddToQuoteSection
          isLoading={isLoading}
          isMobile={isMobile}
          openExistingQuoteDialog={this.openExistingQuoteDialog}
          openNewQuoteDialog={this.openNewQuoteDialog}
          saveEditAndReturnToQuotes={this.saveEditAndReturnToQuotes}
          discardEditAndReturnToQuotes={this.cancelEditAndReturnToQuotes}
          price={get(payload, "resource.price.displayValue", "$0.00")}
          location={location}
          mode={doorViewOptions.mode}
          payload={payload}
        />
        <AddToQuoteDialog
          isMobile={isMobile}
          open={!!dialogOpen}
          title={
            dialogContent === DIALOG_TYPES.EXISTING
              ? "Add to Existing Quote?"
              : "Add to New Quote?"
          }
          enableAddButton={DoorSummary.checkAddEnabled(
            dialogContent,
            quoteSelected,
            quoteName,
            error
          )}
          handleClose={this.closeDialog}
          handleAddToQuote={
            dialogContent === DIALOG_TYPES.EXISTING
              ? () => this.handleAddDoorToExistingQuote(quoteSelected)
              : () => this.handleAddDoorToNewQuote(quoteName)
          }
        >
          {dialogContent === DIALOG_TYPES.EXISTING ? (
            <AddToExistingQuote
              addToNewQuote={this.openNewQuoteDialog}
              onChange={(e) => this.setState({ quoteSelected: e.target.value })}
              isMobile={isMobile}
              quotes={quotes}
              quoteSelected={quoteSelected}
              error={error}
            />
          ) : (
            <AddToNewQuote
              addToExistingQuote={this.openExistingQuoteDialog}
              onChange={this.setQuoteName}
              isMobile={isMobile}
              quoteName={quoteName}
              error={error}
            />
          )}
        </AddToQuoteDialog>
        <QuoteSuccessDialog
          quoteName={quoteName}
          isMobile={isMobile}
          open={!!doorAddedDialogOpen}
          title="New Door Added!"
          selections={selections}
          handleClose={() =>
            this.setState({
              error: null,
              quoteName: "",
              doorAddedDialogOpen: false,
            })
          }
          handleGoToQuote={this.handleGoToQuote}
        />
      </Paper>
    );
  }
}

DoorSummary.propTypes = {
  isCsr: PropTypes.bool.isRequired,
  enqueueSnackbar: PropTypes.func,
  companyId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoadingEditableDoor: PropTypes.bool.isRequired,
  retryGetProduct: PropTypes.func.isRequired,
  getProductError: PropTypes.string,
  goToQuote: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  getQuotes: PropTypes.func.isRequired,
  addToExistingQuote: PropTypes.func.isRequired,
  addToNewQuote: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  payload: PropTypes.object,
  doorViewOptions: PropTypes.object,
  roughOpening: PropTypes.object,
  selections: PropTypes.object,
};

export default DoorSummary;
