import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SlabLayoutSpeedDial } from "./index";
import { ImageSelector } from "../../../components/Selection";

export default function SlabLayoutList({
  isLoading,
  slabLayoutError,
  onChange,
  retryCall,
  slabLayouts = [],
  selected,
}) {
  const theme = useTheme();
  const showSpeedDial = useMediaQuery(theme.breakpoints.down("xl"), {
    noSsr: true,
  });

  const [openWarning, setOpenWarning] = useState(false);

  const handleSlabTypeSelection = (slabType) => {
    onChange(slabType);
  };

  const handleRetryClick = async () => {
    await retryCall();
    setOpenWarning(false);
  };

  const hasError = Boolean(isEmpty(slabLayouts) || slabLayoutError);

  return (
    <Box>
      {showSpeedDial || hasError ? (
        <SlabLayoutSpeedDial
          isLoading={isLoading}
          hasError={hasError}
          slabLayouts={slabLayouts}
          onChange={onChange}
          selected={selected}
          setOpenWarning={setOpenWarning}
        />
      ) : (
        <ImageSelector
          vertical
          isLoading={isLoading}
          isLoadingCompatibility={isLoading && !isEmpty(slabLayouts)}
          imgRel="option-image"
          imgSizeSx={{ width: 50, height: 50 }}
          options={slabLayouts}
          optionType={"Slab Layout"}
          selected={selected}
          onSelect={handleSlabTypeSelection}
          gridItemProps={{ style: { paddingTop: 0, paddingBottom: "16px" } }}
          gridContainerProps={{
            style: {
              marginTop: "24px",
              height: "100%",
              paddingLeft: "16px",
            },
            direction: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          showDescription={false}
        />
      )}

      <AlertDialog
        open={openWarning}
        dialogType="WARNING"
        title="Something Went Wrong"
        primaryLabel="Retry"
        onPrimaryClick={handleRetryClick}
        onSecondaryClick={() => setOpenWarning(false)}
        contentText="Unfortunately, an error occurred when loading the door layouts. Would you like to retry loading the layouts?"
      />
    </Box>
  );
}

SlabLayoutList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  slabLayoutError: PropTypes.object,
  slabLayouts: PropTypes.arrayOf(
    PropTypes.shape({
      compatible: PropTypes.bool.isRequired,
      description: PropTypes.string.isRequired,
      hasSidelite: PropTypes.bool.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          rel: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
        })
      ),
      resourceId: PropTypes.string.isRequired,
      slabType: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  selected: PropTypes.shape({
    compatible: PropTypes.bool,
    description: PropTypes.string,
    hasSidelite: PropTypes.bool,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        rel: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      })
    ),
    resourceId: PropTypes.string,
    slabType: PropTypes.string,
    type: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  retryCall: PropTypes.func.isRequired,
};
