import React, { useState } from "react";
import findChip from "lodash/find";
import groupBy from "lodash/groupBy";
import GridIcon from "mdi-material-ui/ViewGrid";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import CloseCircleIcon from "@mui/icons-material/Cancel";
import MagnifyIcon from "@mui/icons-material/SearchOutlined";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import ColorCodeWorkflow from "../../actions/ProductBuilder/ColorCodeWorkflow";
import ColorExploreWorkflow from "../../actions/ProductBuilder/ColorExploreWorkflow";

const SELECTION_KNOW = "Lookup";
const SELECTION_EXPLORE = "Explore";

export default function CustomColorDialog({
  open,
  onSecondaryClick,
  getColors,
  searchColor,
  addColors,
  onOptionClick,
}) {
  const [colorSelectionMode, setColorSelectionMode] = useState(null);
  const [workflowStep, setWorkflowStep] = useState(0);
  const [chipData, setChipData] = React.useState([]);
  const [family, setFamily] = useState(null);
  const [colorSwatches, setColorSwatches] = useState({});
  const [colorCodeResult, setColorCodeResult] = useState(null);
  const [codeSearchError, setCodeSearchError] = useState(null);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const closeAndReset = () => {
    onSecondaryClick();
    setChipData([]);
    resetState(0);
  };

  const resetState = (step) => {
    setWorkflowStep(step);
    step === 0 && setColorSelectionMode(null);
    setFamily(null);
    setColorCodeResult(null);
    setCodeSearchError(null);
    setColorSwatches({});
  };

  const setMode = (mode) => {
    setColorSelectionMode(mode);
    setWorkflowStep(1);
  };

  const searchColorCode = async (colorCode) => {
    setColorCodeResult(null);
    setCodeSearchError(null);

    let result = null;

    try {
      result = await searchColor(colorCode);
    } catch (e) {
      console.error(e.message);
    }
    result
      ? setColorCodeResult(result)
      : setCodeSearchError("Color code not found");
  };

  const selectFamily = async (selectedFamily) => {
    try {
      setFamily(selectedFamily);
      let colorCodes = await getColors(selectedFamily);
      setColorSwatches(groupBy(colorCodes, "gridGroup"));
      setWorkflowStep(2);
    } catch (e) {
      console.error(e.message);
    }
  };

  const handleAddColors = () => {
    let finishes = chipData.map((chip) => {
      chip.finish.links = chip.links;
      return chip.finish;
    });
    onOptionClick(finishes[0]);
    addColors(finishes);
    setColorSelectionMode(null);
    closeAndReset();
  };

  function BreadCrumbNav() {
    return (
      <Breadcrumbs
        sx={{
          bgcolor: "background.paper",
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))",
          position: "sticky",
          top: "-16px",
          width: "100%",
          padding: "4px 0 16px",
        }}
        aria-label="breadcrumb"
      >
        <Link
          data-test="custom-color-link"
          style={{ cursor: "pointer" }}
          onClick={() => resetState(0)}
        >
          Custom Color
        </Link>
        {workflowStep > 1 ? (
          <Link
            data-test="color-selection-mode-link"
            style={{ cursor: "pointer" }}
            onClick={() => resetState(1)}
          >
            <Typography>{colorSelectionMode}</Typography>
          </Link>
        ) : (
          <Typography color="textPrimary">{colorSelectionMode}</Typography>
        )}
        {family && <Typography>{family}</Typography>}
      </Breadcrumbs>
    );
  }

  function ChipsArray() {
    const handleDelete = (chipToDelete) => () => {
      setChipData(
        (chips) =>
          chips && chips.filter((chip) => chip.key !== chipToDelete.key)
      );
    };

    chipData && chipData.reverse();

    return chipData.map((data, index) => {
      let icon = (
        <Box
          sx={{
            border: "solid 1px white",
            margin: "4px",
            borderRadius: "100%",
            width: "16px",
            height: "16px",
            background: "#" + data.finish.hexCode,
          }}
        />
      );

      return (
        <Chip
          style={{ margin: "4px" }}
          className={"colorChip"}
          key={index}
          icon={icon}
          label={data.colorNumber}
          onDelete={handleDelete(data)}
          deleteIcon={<CloseCircleIcon data-test="delete-chip-icon" />}
          size="small"
          variant="outlined"
        />
      );
    });
  }

  function toggleChip(color) {
    if (!findDuplicateChips(color)) {
      setChipData([...chipData, { key: chipData.length, ...color }]);
    } else {
      let newData = chipData.filter(
        (chip) => chip.colorNumber !== color.colorNumber
      );

      setChipData([...newData]);
    }
    setColorCodeResult(null);
  }

  function findDuplicateChips(chipToAdd) {
    return !!findChip(chipData, { colorNumber: chipToAdd.colorNumber });
  }

  const dialogContent = (
    <Grid container spacing={1} sx={{ maxHeight: "400px" }}>
      <Grid item container justifyContent="flex-start">
        {workflowStep > 0 && <BreadCrumbNav />}
      </Grid>
      <Grid item container justifyContent="center">
        <ModalContent
          colorSelectionMode={colorSelectionMode}
          setColorSelectionMode={setColorSelectionMode}
        />
      </Grid>
      <Box
        sx={{
          bgcolor: "background.paper",
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))",
          bottom: "-8px",
          position: "sticky",
          padding: isMobile ? "0px" : "10px 0",
          width: "100%",
          minHeight: `100px`,
          overflowY: "scroll",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {chipData.length > 0 ? (
          <ChipsArray />
        ) : (
          <Typography data-test="no-custom-colors-message">
            No custom colors selected
          </Typography>
        )}
      </Box>
      <Grid item>
        {workflowStep === 0 && (
          <div style={{ margin: "8px 0 16px" }}>
            <Typography
              align="left"
              component="div"
              variant="subtitle2"
              color="error"
            >
              *Additional costs may apply
            </Typography>
            <Typography
              align="left"
              component="div"
              variant="subtitle2"
              color="error"
            >
              *Lead time may be extended with the addition of a custom color
            </Typography>
          </div>
        )}
      </Grid>
    </Grid>
  );

  function ModalContent() {
    switch (workflowStep) {
      case 0:
        return <ModeSelection />;
      case 1:
      case 2:
        return <WorkflowStepper />;
      default:
        return <div>Unexpected workflowStep: {workflowStep}</div>;
    }

    function ModeSelection() {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography color="primary">
              Add a custom Sherwin-Williams<sup>&reg;</sup> color to your paint
              palette:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List dense>
              <ListItem>
                <ListItemButton
                  data-test="custom-color-know"
                  onClick={() => setMode(SELECTION_KNOW)}
                >
                  <ListItemIcon>
                    <Avatar>
                      <MagnifyIcon />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary="I know my color code"
                    secondary="Use the color code (SW####) found on a Sherwin-Williams paint chip or website"
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton
                  data-test="custom-color-explore"
                  onClick={() => setMode(SELECTION_EXPLORE)}
                >
                  <ListItemIcon>
                    <Avatar>
                      <GridIcon />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary="I want to explore"
                    secondary="View all of the Sherwin-Williams offerings and filter them by color family"
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </List>
          </Grid>
        </Grid>
      );
    }

    function WorkflowStepper() {
      return colorSelectionMode === SELECTION_EXPLORE ? (
        <ColorExploreWorkflow
          currentStep={workflowStep}
          toggleChip={toggleChip}
          selectedColors={chipData}
          colorSwatches={colorSwatches}
          selectFamily={selectFamily}
          isMobile={isMobile}
        />
      ) : (
        <ColorCodeWorkflow
          searchColorCode={searchColorCode}
          colorResult={colorCodeResult}
          addChip={toggleChip}
          error={codeSearchError}
        />
      );
    }
  }

  return (
    <AlertDialog
      open={open}
      fullScreen={isMobile}
      title="Add Custom Color?"
      onPrimaryClick={handleAddColors}
      onSecondaryClick={closeAndReset}
      customContent={dialogContent}
      scroll="paper"
      TransitionProps={{
        onExited: onSecondaryClick,
      }}
      customDialogAction={
        <Grid container justifyContent="space-between">
          <Grid item>
            {workflowStep !== 0 && (
              <Button
                data-test="tertiary-btn"
                onClick={() => setWorkflowStep(workflowStep - 1)}
                variant="outlined"
              >
                Back
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              data-test="secondary-btn"
              variant="outlined"
              onClick={onSecondaryClick}
            >
              Cancel
            </Button>
            {chipData && chipData.length > 0 && (
              <Button
                sx={{
                  marginLeft: 1,
                }}
                data-test="primary-btn"
                color="secondary"
                variant="contained"
                onClick={handleAddColors}
              >
                Add
              </Button>
            )}
          </Grid>
        </Grid>
      }
    />
  );
}

CustomColorDialog.propTypes = {
  open: PropTypes.bool,
  onSecondaryClick: PropTypes.func.isRequired,
  getColors: PropTypes.func.isRequired,
  searchColor: PropTypes.func.isRequired,
  addColors: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
};
