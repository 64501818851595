import React from "react";
import AdminIcon from "mdi-material-ui/AccountKey";
import BriefcaseIcon from "mdi-material-ui/BriefcaseAccount";
import HardHatIcon from "mdi-material-ui/HardHat";
import loadable from "@loadable/component";
import BankIcon from "@mui/icons-material/AccountBalance";
import BusinessIcon from "@mui/icons-material/Domain";
import AccountIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";
import ContactsIcon from "@mui/icons-material/SupervisorAccount";
import { Authority, Type } from "../../AuthProvider";

const LazyEmployeeListPage = loadable(() =>
  import("./Contractor/Contact/EmployeeListPage")
);
const LazyEmployeeDetailPage = loadable(() =>
  import("./Contractor/Contact/EmployeeDetailPage")
);

const LazyAllContactsListPage = loadable(() =>
  import("./Csr/Contact/AllContactsListPage")
);
const LazyContractorsListPage = loadable(() =>
  import("./Csr/Contact/ContractorsListPage")
);
const LazyCsrsListPage = loadable(() => import("./Csr/Contact/CsrsListPage"));
const LazyContactDetailPage = loadable(() =>
  import("./Csr/Contact/ContactDetailPage")
);

const LazySalesRepListPage = loadable(() =>
  import("./SalesRep/Contact/SalesRepListPage")
);

const LazySalesRepDetailPage = loadable(() =>
  import("./SalesRep/Contact/SalesRepDetailPage")
);

const LazyCompanyDetailPage = loadable(() =>
  import("./Contractor/Company/CompanyDetailPage")
);
const LazyFinancingPage = loadable(() =>
  import("./Contractor/Company/FinancingPage")
);

const LazyCsrCompanyListPage = loadable(() =>
  import("./Csr/Company/CsrCompanyListPage")
);
const LazyCsrCompanyDetailPage = loadable(() =>
  import("./Csr/Company/CsrCompanyDetailPage")
);

export default class Account {
  static routes(context) {
    const type = context.whoAmI().type;
    if (type === Type.CSR || type === Type.SALES_REP) {
      return [
        {
          groupName: "Search",
          items: [
            {
              label: "Contacts",
              icon: <ContactsIcon />,
              nestedListItems: [
                {
                  label: "All",
                  icon: <StarIcon />,
                  path: "/employees/all",
                  component: <LazyAllContactsListPage />,
                },
                {
                  label: "Contractors",
                  icon: <HardHatIcon />,
                  path: "/employees/contractor",
                  component: <LazyContractorsListPage />,
                  alternativeRoutes: [
                    {
                      path: "/employees/contractor/:resourceId",
                      component: <LazyContactDetailPage />,
                    },
                  ],
                },
                {
                  label: "CSR",
                  icon: <AdminIcon />,
                  path: "/employees/csr",
                  component: <LazyCsrsListPage />,
                  alternativeRoutes: [
                    {
                      path: "/employees/csr/:resourceId",
                      component: <LazyContactDetailPage />,
                    },
                  ],
                },
                {
                  label: "Sales Rep",
                  icon: <BriefcaseIcon />,
                  path: "/employees/sales-rep",
                  component: <LazySalesRepListPage />,
                  alternativeRoutes: [
                    {
                      path: "/employees/sales-rep/:resourceId",
                      component: <LazySalesRepDetailPage />,
                    },
                  ],
                },
              ],
            },
            {
              label: "Companies",
              icon: <BusinessIcon />,
              path: "/companies",
              component: <LazyCsrCompanyListPage />,
              alternativeRoutes: [
                {
                  path: "/companies/:resourceId",
                  component: <LazyCsrCompanyDetailPage />,
                },
              ],
            },
          ],
        },
      ];
    }

    return [
      {
        groupName: "My Items",
        items: [
          {
            label: "Employees",
            icon: <AccountIcon />,
            path: "/employees",
            component: <LazyEmployeeListPage />,
            alternativeRoutes: [
              {
                path: "/employees/contractor/:resourceId",
                component: <LazyEmployeeDetailPage />,
              },
            ],
          },
          {
            label: "Company",
            icon: <BusinessIcon />,
            path: "/company",
            component: <LazyCompanyDetailPage />,
          },
        ],
      },
      {
        groupName: "None",
        items: [
          {
            label: "Financing",
            hide: !context.hasAllAuthorities([
              Authority.ORDER_PURCHASE,
              Authority.FEATURE_MASONITE_CREDIT,
            ]),
            icon: <BankIcon />,
            path: "/financing",
            component: <LazyFinancingPage />,
          },
        ],
      },
    ];
  }
}
