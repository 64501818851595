import { routerMiddleware } from "connected-react-router";
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";
import history from "../../History";
import createRootReducer from "../reducers";

export default function configureStore(preloadedState) {
  const middlewares = [
    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
    routerMiddleware(history),
  ];

  return createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(compose(applyMiddleware(...middlewares))),
  );
}
