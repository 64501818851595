export const exteriorPrehungDefaultState = {
  version: "1.0.11",
  loadingStates: {
    loadingExistingStateCheck: false,
    loadingEditableDoor: false,
    loadingDoorDetails: false,
    loadingRecoveryOptions: false,
    loadingDefaultDoor: false,
    loadingSlabLayouts: false,
    loadingSlabDesigns: false,
    loadingGlassDesigns: false,
    loadingHardwares: false,
    loadingHandings: false,
    loadingJambDepths: false,
    loadingDoorSpecs: false,
    loadingFinishes: false,
    loadingDimensions: false,
    loadingPeepSites: false,
    loadingCasings: false,
    loadingBrickMolds: false,
    loadingRoughOpening: false,
    loadingFilteredOptions: false,
    loadingGetProduct: false,
    loadingSill: false,
    loadingHinges: false,
    loadingBoreDetails: false,
  },
  errorStates: {
    defaultDoorError: null,
    slabLayoutError: null,
    slabDesignError: null,
    glassDesignError: null,
    hardwareError: null,
    addOnError: null,
    hingeError: null,
    sillError: null,
    handingError: null,
    jambDepthError: null,
    doorSpecError: null,
    finishError: null,
    peepSiteError: null,
    casingError: null,
    brickMoldError: null,
    dimensionError: null,
    roughOpeningError: null,
    getProductError: null,
    boreDetailsError: null,
  },
  defaultSpecs: {},
  roughOpeningTable: {},
  selectionOptions: {
    doorSpecs: {
      dimensions: {},
      hurricaneRatings: [],
      impactRatings: [],
      handings: [],
      jambDepths: [],
      doorCovered: [],
      cutDownDoorHeights: [],
    },
    slabLayouts: [],
    slabDesigns: [],
    glassDesigns: [],
    dimensions: {},
    finishes: {
      PAINT: [],
      STAIN: [],
    },
    customColors: [],
    hardwares: [],
    peepSites: [],
    casings: [],
    brickMolds: [],
    inactivePreps: [],
    hinges: [],
    sill: {},
  },
  doorViewOptions: {
    mode: {
      isInEditMode: false,
      name: null,
      referenceNumber: null,
      orderResourceId: null,
      orderItemResourceId: null,
      companyResourceId: null,
      quoteItem: {},
      isConsumerLead: false,
      leadResourceId: null,
      leadItemResourceId: null,
    },
    activeStep: 0,
    tabIndex: 0,
    stepTwoComplete: false,
    stepThreeComplete: false,
    accordionStep: 0,
    startCollapse: false,
    startSlide: true,
    drawerOpen: false,
    openPreview: false,
    classifierRules: null,
    preview: {
      frontImage: null,
      backImage: null,
    },
    hasSlabLayoutChanged: false,
    hasSlabDesignChanged: false,
    hasGlassDesignChanged: false,
    hasFilterChanged: false,
    hasAnySelectionChanged: false,
    hasChangedFromDefault: false,
  },
  filterSettings: {},
  selections: {
    hurricaneRated: {},
    impactRated: {},
    glazingType: {},
    slabHeight: {},
    slabWidth: {},
    jambDepth: {},
    sideLiteWidth: {},
    sideliteGlassDesign: {},
    isDoorCovered: {},
    handing: {},
    hardware: {},
    hinge: {},
    slabLayout: {},
    slabDesign: {},
    glassDesign: {},
    finish: {
      doorExterior: {},
      doorInterior: {},
      frameExterior: {},
      frameInterior: {},
      weatherStripping: {},
      casingColor: {},
    },
    casing: {},
    brickMold: {},
    peepSite: {},
    adaCompliant: false,
    inactivePrep: {},
    cutDownDoorHeight: {},
    boreDetails: {},
  },
  doorSummary: {},
  retiredComponents: {},
};

export const paymentItemDefaultState = {
  paymentFlowExpiration: null,
  isPaymentDurationFetched: false,
  activeStep: 0,
  shipping: {
    name: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    notes: "",
    leaveAtSite: false,
    deliveryDate: null,
    editedFields: {},
    errors: {},
  },
  shippingDates: [],
  shippingDatesLoading: false,
  paymentMethod: null,
  stripe: {
    completed: false,
    name: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    token: {},
    editedFields: {},
    errors: {},
  },
  behalf: {
    completed: false,
    paymentToken: null,
    paymentStatus: null,
    behalfBuyerId: null,
  },
  masoniteCredit: {
    completed: false,
    paymentAmount: null,
    authorize: null,
    availableCredit: null,
    creditLimit: null,
  },
  tax: null,
  warranty: {
    name: "",
    email: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    editedFields: {},
    errors: {},
  },
  showCancelConfirmation: false,
  showSavedDataConfirmation: false,
};

export const customPartDefaultState = {
  formViewOptions: {
    activeStep: 0,
  },
  defaultSpecs: {},
  customPartResponse: null,
  selections: {
    itemCode: "",
    description: "",
    itemType: "",
    itemIsImpactRated: false,
    itemPrice: "",
  },
  errorStates: {
    itemCodeErrorMessage: null,
    descriptionErrorMessage: null,
    itemTypeErrorMessage: null,
    itemPriceErrorMessage: null,
    customPartErrorMessage: null,
  },
};
