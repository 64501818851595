import { jwtDecode } from "jwt-decode";
import history from "./History";
import { toCamelCase, toSnakeCase } from "./utils";

function simpleHeaders() {
  const headers = new Headers();
  headers.set("Content-Type", "application/json");
  headers.set("Accept", "application/json");
  return headers;
}

function authorizedHeaders() {
  const headers = simpleHeaders();
  headers.set(
    "Authorization",
    `Bearer ${sessionStorage.getItem("accessToken")}`,
  );
  return headers;
}

class CustomError extends Error {
  constructor(message, status) {
    super(message);
    this.name = "CustomError";
    this.status = status;
  }
}

/**
 * Expanded version of the Fetch API with support for request parameters in options and a third parameter – entity –
 * that will be applied to the request as options.body after JSON.stringify is applied to it.
 *
 * The Authorization header will always be applied if a JSON Web Token is available.
 *
 * @param {string} url - Either an absolute URL or a relative URL that will use the API Gateway as its host.
 * @param {Object} [options={}] - Options to be passed through to fetch
 * @param {string} [options.method=GET] - HTTP method
 * @param {Object} [options.params] - Custom option that will have its key-value pairs applied as request parameters
 * @param {Object} [body] - Entity that will have JSON.stringify applied to it
 * @returns {Promise.<Response>}
 */
export async function retrieve(url, body, options = {}) {
  const originalCall = () => retrieve(url, body, options);

  // Clone headers in order to add Authorization header
  const headers = authorizedHeaders();

  // Clone options in order to add cloned headers with optional Authorization
  const actualOptions = {
    ...options,
    headers,
  };

  delete actualOptions.params;

  // Convenient support for params object to be converted to request parameters
  const params = [];
  for (const [key, value] of Object.entries(options.params || {})) {
    if (value !== undefined && value !== null) {
      params.push(`${key}=${value}`);
    }
  }

  const actualURL = params.length ? `${url}?${params.join("&")}` : url;

  // Convenient support for optional third parameter – body
  if (body !== undefined) {
    actualOptions.body = JSON.stringify(body);
  }

  const response = await fetch(
    new URL(
      actualURL,
      window.__RUNTIME_CONFIG__
        ? window.__RUNTIME_CONFIG__.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL,
    ),
    actualOptions,
  );

  if (response.status === 401) {
    // Assume the status is due to an invalid token
    const refreshedTokenResponse = await fetch(
      new URL(
        `tokens/${sessionStorage.getItem("refreshToken")}/refresh`,
        window.__RUNTIME_CONFIG__
          ? window.__RUNTIME_CONFIG__.REACT_APP_API_URL
          : process.env.REACT_APP_API_URL,
      ),
      {
        headers: simpleHeaders(),
        method: "POST",
      },
    );

    if (refreshedTokenResponse.ok) {
      try {
        const json = await refreshedTokenResponse.json();
        sessionStorage.setItem("accessToken", json["access_token"]);
        return originalCall();
      } catch (e) {
        throw new Error(
          "User session has expired. Please logout and log back in.",
        );
      }
    } else if (refreshedTokenResponse.status === 401) {
      history.push("/login", { from: history.location.pathname });
      document.cookie = "aut=; path=/; domain=" +
        (window.__RUNTIME_CONFIG__
          ? window.__RUNTIME_CONFIG__.REACT_APP_COOKIE_DOMAIN
          : process.env.REACT_APP_COOKIE_DOMAIN)
        + "; Max-Age=0";
    } else {
      throw new Error(
        (await refreshedTokenResponse.json()).message ||
          "While refreshing the session, an error occurred and no message was available.",
      );
    }
  }

  return response;
}

export async function toJson(response) {
  if (!response) {
    return null;
  }

  const json =
    response.status === 204 ? response : toCamelCase(await response.json());

  if (!response.ok) {
    throw new CustomError(json.message, json.status);
  }
  return json;
}

/**
 * Just like toJson(retrieve(url)) except doesn't perform CamelCasing.
 * Graphs cannot use CamelCased responses because values can contain
 * references to keys.
 * @param url
 * @param options
 * @param body
 * @returns {Promise<void>}
 */
export async function fetchJson(url, body, options = {}) {
  const response = await retrieve(url, body, options);
  const json = await response.json();

  if (!response.ok) {
    throw new Error(json.message);
  }
  return json;
}

export async function conversionFetch(url, body, options = {}) {
  return toCamelCase(await fetchJson(url, toSnakeCase(body), options));
}

export async function login(credentials) {
  const response = await fetch(
    `${
      window.__RUNTIME_CONFIG__
        ? window.__RUNTIME_CONFIG__.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL
    }/tokens`,
    {
      method: "post",
      headers: simpleHeaders(),
      body: JSON.stringify(credentials),
    },
  );
  const json = await response.json();
  if (!response.ok) {
    throw new Error(json.message);
  }

  const payload = jwtDecode(json["access_token"]);

  if (payload.type === "PROGRAM") {
    throw new Error(
      "Incorrect username or password. Please re-enter your credentials and try again.",
    );
  }

  const previousContactId = sessionStorage.getItem("previousContactId");
  if (previousContactId && payload["contact_id"] !== previousContactId) {
    window.localStorage.removeItem("state");
    window.localStorage.removeItem("metricsFilterChips");
  }

  sessionStorage.setItem("contactId", payload["contact_id"]);
  sessionStorage.setItem("accessToken", json["access_token"]);
  sessionStorage.setItem("refreshToken", json["refresh_token"]);
  sessionStorage.setItem("isAuthenticated", JSON.stringify(true));

  document.cookie =
    "aut=" +
      Date.now() +
      "|" +
      payload["type"] +
      "; domain=" +
      (window.__RUNTIME_CONFIG__
        ? window.__RUNTIME_CONFIG__.REACT_APP_COOKIE_DOMAIN
        : process.env.REACT_APP_COOKIE_DOMAIN) +
      "; path=/" || "";

  const storageEvent = new Event("advisarLogin");
  storageEvent.accessToken = json["access_token"];

  window.dispatchEvent(storageEvent);
}

export function logout() {
  const previousContactId = sessionStorage.getItem("contactId");
  sessionStorage.clear();
  document.cookie = "aut=; path=/; domain=" +
    (window.__RUNTIME_CONFIG__
      ? window.__RUNTIME_CONFIG__.REACT_APP_COOKIE_DOMAIN
      : process.env.REACT_APP_COOKIE_DOMAIN)
    + "; Max-Age=0";
  sessionStorage.setItem("previousContactId", previousContactId);
}

export async function changePassword(user) {
  const response = await retrieve(
    `${
      window.__RUNTIME_CONFIG__
        ? window.__RUNTIME_CONFIG__.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL
    }/oauth/users/change-password`,
    user,
    {
      method: "post",
    },
  );

  return toJson(response);
}

export async function forgotPassword(user) {
  const response = await fetch(
    `${
      window.__RUNTIME_CONFIG__
        ? window.__RUNTIME_CONFIG__.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL
    }/oauth/users/reset-password`,
    {
      method: "post",
      headers: simpleHeaders(),
      body: JSON.stringify(user),
    },
  );
  const json = response.status !== 202 ? await response.json() : {};
  if (!response.ok) throw new Error(json.message);
}

export async function resetPassword(user) {
  const response = await fetch(
    `${
      window.__RUNTIME_CONFIG__
        ? window.__RUNTIME_CONFIG__.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL
    }/oauth/users/reset-password`,
    {
      method: "put",
      headers: simpleHeaders(),
      body: JSON.stringify(user),
    },
  );
  const json = response.status !== 204 ? await response.json() : {};
  if (!response.ok) throw new Error(json.message);
}
