import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import throttle from "lodash/throttle";
import { StyledEngineProvider } from "@mui/material/styles";
import App from "./App";
import history from "./History";
import { init } from "./Network";
import { loadState, saveState } from "./redux/localStorage";
import configureStore from "./redux/store/configureStore";
import reportWebVitals from "./reportWebVitals";

init({
  baseUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
  accessToken: () => window.sessionStorage.getItem("accessToken") || "",
  refreshToken: () => window.sessionStorage.getItem("refreshToken") || "",
  onRefreshFailure: (
    callback = () => {
      /*Empty function is intentional*/
    },
  ) => {
    const loginEvent = new Event("loginAgain");
    loginEvent.callback = callback;
    window.dispatchEvent(loginEvent);
  },
  onRefreshSuccess: (token) => {
    window.sessionStorage.setItem("accessToken", token);
    const storageEvent = new Event("advisarLogin");
    storageEvent.accessToken = token;
    window.dispatchEvent(storageEvent);
  },
});

const store = configureStore(loadState());

store.subscribe(
  throttle(() => {
    saveState({
      exteriorPrehung: store.getState().exteriorPrehung,
      payments: store.getState().payments,
      customPart: store.getState().customPart,
    });
  }, 1000),
);

function initGoogleAnalytics() {
  const environment = window.__RUNTIME_CONFIG__
    ? window.__RUNTIME_CONFIG__.NODE_ENV
    : process.env.NODE_ENV;

  if (environment === "development") {
    return [
      {
        trackingId: "G-VDGLC7TNFQ",
        gaOptions: {
          name: "development",
        },
      },
    ];
  } else if (environment === "testing") {
    return [
      {
        trackingId: "G-79B2J61YDF",
        gaOptions: {
          name: "staging",
        },
      },
    ];
  } else if (environment === "staging") {
    return [
      {
        trackingId: "G-79B2J61YDF",
        gaOptions: {
          name: "staging",
        },
      },
    ];
  } else if (environment === "production") {
    return [
      {
        trackingId: "G-LFXVG1KLVF",
        gaOptions: {
          name: "production",
        },
      },
    ];
  } else {
    return [
      {
        trackingId: "G-VDGLC7TNFQ",
        gaOptions: {
          name: "local",
        },
      },
    ];
  }
}

ReactGA.initialize(initGoogleAnalytics());

// Initialize google analytics page view tracking
history.listen(() => {
  if (
    !(
      document.location.pathname.indexOf(
        "/products/residential/exterior/pre-hung",
      ) > -1
    )
  ) {
    ReactGA.set({ page: window.location.pathname }); // Update the user's current page
    ReactGA.send("pageview");
  }
});

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
