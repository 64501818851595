import React from "react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import DoorSVG from "../Common/DoorSVG";

const StyledSpan = styled("span")(({ theme }) => ({
  color: theme.palette.primary["600"],
}));

const StyledColorCircleImg = styled("img")(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "50%",
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
  minWidth: theme.spacing(1.5),
  minHeight: theme.spacing(1.5),
  marginLeft: theme.spacing(1.5),
}));

export default function DoorInfoBlock({
  slabDesign,
  sideliteGlass,
  finish,
  glassDesignDescription,
  masterpackProductDescription,
  itemCode,
  itemType,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

  return (
    <Grid
      xs={12}
      item
      container
      wrap="nowrap"
      alignItems={isMobile ? "flex-start" : "center"}
      direction={isMobile ? "column" : "row"}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          backgroundColor: "primary.200",
          border: "1px solid #00000014",
        }}
      >
        <DoorSVG
          isCustomPart={Boolean(
            masterpackProductDescription || itemCode || itemType,
          )}
          sx={(theme) => ({
            height: "80px",
            width: "auto",
            [theme.breakpoints.down("sm")]: {
              height: "100px",
            },
          })}
          slabDesign={slabDesign}
          sideliteGlassDesign={sideliteGlass}
          selectedFinish={finish}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          ml: 2,
          [theme.breakpoints.down("sm")]: {
            margin: `${theme.spacing(2)}px 0`,
          },
        })}
      >
        {!isEmpty(itemType) && (
          <Typography
            variant="body1"
            fontWeight="bold"
            gutterBottom
            data-test="custom-order-item-type"
          >
            {itemType}
          </Typography>
        )}
        {!isEmpty(masterpackProductDescription) && (
          <Typography
            variant="body2"
            gutterBottom
            data-test="custom-order-description"
          >
            {masterpackProductDescription.toUpperCase()}
          </Typography>
        )}
        {!isEmpty(itemCode) && (
          <Typography
            variant="body2"
            gutterBottom
            data-test="custom-order-item-code"
          >
            {itemCode}
          </Typography>
        )}
        {!isEmpty(slabDesign) && (
          <>
            <Typography
              variant="body2"
              gutterBottom
              data-test="door-design-description"
            >
              <StyledSpan> Design: </StyledSpan>
              {slabDesign.description}
            </Typography>
            {glassDesignDescription !== "No Glass" && (
              <Typography
                variant="body2"
                gutterBottom
                data-test="door-glass-description"
              >
                <StyledSpan> Glass: </StyledSpan>
                {glassDesignDescription}
              </Typography>
            )}
          </>
        )}
        {!isEmpty(sideliteGlass) && (
          <Typography
            variant="body2"
            gutterBottom
            data-test="sidelite-glass-description"
          >
            <StyledSpan> Sidelite: </StyledSpan>
            {sideliteGlass.description}
          </Typography>
        )}
        {!isEmpty(finish) && (
          <Typography
            component="div"
            variant="body2"
            gutterBottom
            data-test="exterior-finish-description"
          >
            <StyledSpan> Finish: </StyledSpan>
            <Box display="inline-flex" alignItems="center">
              {finish.description}
              {finish.hexCode ? (
                <Box
                  sx={(theme) => ({
                    background: `#${finish.hexCode}`,
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "50%",
                    width: theme.spacing(1.5),
                    height: theme.spacing(1.5),
                    minWidth: theme.spacing(1.5),
                    minHeight: theme.spacing(1.5),
                    marginLeft: theme.spacing(1.5),
                  })}
                />
              ) : (
                finish.links && (
                  <StyledColorCircleImg
                    src={finish.links[0].href}
                    alt={finish.description}
                  />
                )
              )}
            </Box>
          </Typography>
        )}
      </Box>
    </Grid>
  );
}

DoorInfoBlock.propTypes = {
  slabDesign: PropTypes.object,
  sideliteGlass: PropTypes.object,
  finish: PropTypes.object,
  glassDesignDescription: PropTypes.string,
  masterpackProductDescription: PropTypes.string,
  itemCode: PropTypes.string,
  itemType: PropTypes.string,
};
