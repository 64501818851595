import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import Typography from "@mui/material/Typography";
import { useCheckSnackbar } from "../../utils";

export default function ResendLinkDialog(props) {
  const { open, onPrimaryClick, onSecondaryClick, email, expirationTime } =
    props;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const enqueueSnackbar = useCheckSnackbar();

  async function handlePrimaryClick() {
    try {
      await onPrimaryClick();
      enqueueSnackbar(`Link sent to ${email}`, { variant: "success" });
      onSecondaryClick();
    } catch (e) {
      setErrorMessage(e.message);
    }
  }

  return (
    <AlertDialog
      open={open}
      title="Resend Link?"
      customContent={
        <div data-test="resend-link-content">
          <Typography variant="body2">
            Send a link to the door configurator:
            <span>Send to: </span>
            <Typography variant="body2" component="a" href={`mailto:${email}`}>
              {email}
            </Typography>
          </Typography>
          <Typography variant="body2">
            Link expires in <span>{expirationTime}</span>
          </Typography>
        </div>
      }
      helperText={errorMessage}
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      primaryLabel="Resend"
    />
  );
}

ResendLinkDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  expirationTime: PropTypes.string.isRequired,
};
