import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";

export default function LeavingOrderInquiryDialog(props) {
  const { open, onExited, onPrimaryClick, onSecondaryClick } = props;
  const [shouldLeavePage, setShouldLeavePage] = React.useState(false);

  const handlePrimaryClick = () => {
    setShouldLeavePage(true);
    onPrimaryClick();
  };

  const handleOnExited = () => {
    if (shouldLeavePage) {
      onExited();
    }
  };

  return (
    <AlertDialog
      open={open}
      dialogType="WARNING"
      title="Discard Inquiry?"
      contentText="Your inquiry has not been submitted. Leaving this page will discard any information you entered. How would you like to continue?"
      primaryLabel="Discard"
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      TransitionProps={{
        onExited: handleOnExited,
      }}
    />
  );
}

LeavingOrderInquiryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onExited: PropTypes.func.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};
