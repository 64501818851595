import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import { ResultMessage } from "@masonite-digital/common-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { ImageSelector } from "../../../components/Selection";
import { groupBy } from "../../../utils";
import ZoomComponent from "../preview/ZoomComponent";

export default function OptionListMobile({
  title,
  isLoading,
  hasCustomColor,
  optionError,
  hasSubsections,
  sideliteGlassDesign,
  slabLayout,
  defaultSelected,
  selections,
  optionsList = [],
  onOptionClick,
  onCustomClick,
  onCustomDelete,
  retryCall,
  openSideliteModal,
}) {
  const theme = useTheme();
  const [selected, setSelected] = useState(defaultSelected);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedPreviewName, setSelectedPreviewName] = useState("");
  const [selectedPreviewSrc, setSelectedPreviewSrc] = useState("");

  React.useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  function handleClick(option) {
    if (option.resourceId !== selected.resourceId) {
      onOptionClick(option);

      // Do not set selected option when there is a custom height and the option is not FINISH, because the RemoveCutDownDialog will appear
      if (option.type !== "FINISH" && isEmpty(selections.cutDownDoorHeight)) {
        setSelected(option);
      }
    }
  }

  function handlePreviewOpen(option, src) {
    setOpenPreview(true);
    setSelectedPreviewName(option.name);
    setSelectedPreviewSrc(src);
  }

  function handlePreviewClose() {
    setOpenPreview(false);
  }

  if (!isLoading && isEmpty(optionsList)) {
    if (!optionError) {
      return (
        <ResultMessage
          type="WARNING"
          title="Something went wrong"
          message=""
          onClick={retryCall}
        />
      );
    }

    return (
      <ResultMessage
        type={optionError.type}
        title={optionError.title}
        onClick={retryCall}
        message={optionError.message}
        buttonText={optionError.type === "FIND" ? "Reset Filters" : undefined}
      />
    );
  }

  let imgSize = { height: "auto", width: "40px" };
  let subSectionList = [];
  if (hasSubsections) {
    if (title === "Finish") {
      imgSize = { height: "40px", width: "40px" };
      subSectionList = isLoading
        ? [["STAIN"], ["PAINT"]]
        : Object.entries(groupBy(optionsList, "finishType"));
      if (hasCustomColor && subSectionList.length === 2) {
        subSectionList.push(["CUSTOM_PAINT", []]);
      }
    } else if (title === "Design") {
      if (slabLayout.slabType === "SINGLE") {
        if (slabLayout.hasSidelite) {
          imgSize = { height: "auto", width: "48px" };
        }
      } else if (slabLayout.slabType === "DOUBLE") {
        imgSize = { height: "auto", width: "88px" };
      }
      subSectionList = isLoading
        ? [["lip-lite"], ["flushed"], ["opaque"]]
        : Object.entries(groupBy(optionsList, "glazingType"));
    }
  }

  return (
    <div style={{ position: "relative", flex: 1 }}>
      {isLoading ? (
        <Skeleton
          width={75}
          height={24}
          style={{ position: hasSubsections ? "absolute" : "relative" }}
        />
      ) : (
        <Typography
          data-test="option-title"
          variant="button"
          sx={(theme) => ({
            background: theme.palette.primary["200"],
            zIndex: 999,
            paddingLeft: "8px",
            top: "6px",
            left: 0,
          })}
          style={{ position: hasSubsections ? "absolute" : "relative" }}
        >
          {title}
        </Typography>
      )}
      {sideliteGlassDesign && (
        <>
          {isLoading ? (
            <Skeleton
              width={75}
              height={24}
              sx={(theme) => ({
                color: theme.palette.secondary.main,
                cursor: "pointer",
                position: "absolute",
                top: "8px",
                right: "8px",
              })}
            />
          ) : (
            <Typography
              sx={(theme) => ({
                color: theme.palette.secondary.main,
                cursor: "pointer",
                position: "absolute",
                top: "8px",
                right: "8px",
              })}
              variant="button"
              onClick={openSideliteModal}
              data-test="edit-sidelite-glass"
            >
              SIDELITE?
            </Typography>
          )}
        </>
      )}
      <Grid
        sx={{
          minHeight: "100px",
          padding: "0 10px",
          ...(hasSubsections && {
            overflowX: "scroll",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            display: "flex",
            position: "relative",
            height: "100%",
          }),
        }}
      >
        {hasSubsections ? (
          subSectionList.map(([group, values], i) => {
            const isCustomColor = hasCustomColor && group === "CUSTOM_PAINT";
            return (
              <div
                key={i}
                style={{
                  borderRight:
                    i !== subSectionList.length - 1 &&
                    `1px solid ${theme.palette.divider}`,
                  paddingRight: "8px",
                  paddingLeft: i !== 0 && "8px",
                }}
              >
                <Grid
                  alignItems="center"
                  item
                  container
                  xs={12}
                  justifyContent="flex-end"
                >
                  <Typography
                    data-test={kebabCase(group + "-label")}
                    variant={"body2"}
                    sx={{
                      color: "#868e96",
                      margin: "8px 0",
                      position: "sticky",
                      right: 0,
                    }}
                    component="div"
                  >
                    {isCustomColor ? (
                      <Box
                        sx={(theme) => ({
                          color: theme.palette.secondary.main,
                          cursor: "pointer",
                        })}
                        onClick={onCustomClick}
                      >
                        + CUSTOM
                      </Box>
                    ) : (
                      group.toUpperCase()
                    )}
                  </Typography>
                </Grid>
                <Grid item container key={i}>
                  <ImageSelector
                    isLoading={isLoading}
                    gridContainerProps={{
                      flexWrap: "nowrap",
                      overflowX: "auto",
                    }}
                    options={values}
                    optionType={group}
                    selected={selected}
                    slabLayout={slabLayout}
                    onSelect={handleClick}
                    imgSizeSx={imgSize}
                    previewButtonProps={{
                      onClick: handlePreviewOpen,
                    }}
                    showDescription={false}
                    showDelete={isCustomColor}
                    onDelete={onCustomDelete}
                  />
                </Grid>
              </div>
            );
          })
        ) : (
          <Grid style={{ marginTop: "8px" }}>
            <ImageSelector
              isLoading={isLoading}
              isLoadingCompatibility={isLoading && !isEmpty(optionsList)}
              gridContainerProps={{ flexWrap: "nowrap", overflowX: "auto" }}
              options={optionsList}
              optionType={title}
              selected={selected}
              onSelect={handleClick}
              imgSizeSx={imgSize}
              previewButtonProps={{
                onClick: handlePreviewOpen,
              }}
              showDescription={false}
            />
          </Grid>
        )}
        <ZoomComponent
          open={openPreview}
          handleClose={handlePreviewClose}
          title={selectedPreviewName}
          previewImg={selectedPreviewSrc}
        />
      </Grid>
    </div>
  );
}

OptionListMobile.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasCustomColor: PropTypes.bool,
  optionError: PropTypes.object,
  selections: PropTypes.object.isRequired,
  sideliteGlassDesign: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  slabLayout: PropTypes.object,
  defaultSelected: PropTypes.object,
  optionsList: PropTypes.array.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  retryCall: PropTypes.func.isRequired,
  hasSubsections: PropTypes.bool,
  onCustomClick: PropTypes.func,
  onCustomDelete: PropTypes.func,
};
