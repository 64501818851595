import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import DoorInfoBlock from "../Blocks/DoorInfoBlock";
import InfoBlock from "../Blocks/InfoBlock";

const StyledInfoBlock = styled(InfoBlock)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(2),
}));

const QuoteSuccessDialog = (props) => {
  const {
    isMobile,
    open,
    title,
    handleClose,
    handleGoToQuote,
    selections,
    quoteName,
    isCustomPart,
    masterpackProductDescription,
    itemCode,
    itemType,
  } = props;

  return (
    <AlertDialog
      open={open}
      PaperProps={{
        "data-test": "new-door-added-dialog",
      }}
      fullScreen={isMobile}
      dialogType="SUCCESS"
      title={title}
      primaryLabel="View Quote"
      secondaryLabel="Close"
      onPrimaryClick={handleGoToQuote}
      onSecondaryClick={handleClose}
      TransitionProps={{
        onExited: handleClose,
      }}
      customContent={
        <div>
          <Typography>
            Your {isCustomPart ? "item" : "door"} was successfully added to the
            quote <strong>{quoteName.toUpperCase()}</strong>.
          </Typography>
          <StyledInfoBlock>
            {isCustomPart ? (
              <DoorInfoBlock
                masterpackProductDescription={masterpackProductDescription}
                itemCode={itemCode}
                itemType={itemType}
              />
            ) : (
              <DoorInfoBlock
                slabDesign={selections.slabDesign}
                sideliteGlass={selections.sideliteGlassDesign}
                finish={selections.finish.doorExterior}
                glassDesignDescription={selections.glassDesign.description}
              />
            )}
          </StyledInfoBlock>
        </div>
      }
    />
  );
};

QuoteSuccessDialog.propTypes = {
  isMobile: PropTypes.bool,
  isCustomPart: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleGoToQuote: PropTypes.func.isRequired,
  selections: PropTypes.object,
  quoteName: PropTypes.string.isRequired,
};

export default QuoteSuccessDialog;
