import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import { AuthContext } from "../../AuthContext";
import { Authority } from "../../AuthProvider";
import { GenericNumberTextField } from "../TextFields";

export default function QuantityUpdateDialog(props) {
  const {
    open,
    value,
    reserved,
    onPrimaryClick,
    onSecondaryClick,
    textFieldOnChange,
    onExited,
    onRefresh,
  } = props;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [shouldRefresh, setShouldRefresh] = React.useState(false);
  const context = React.useContext(AuthContext);
  const maxQuantity =
    !context.hasAuthority(Authority.ORDER_RESERVE) && reserved ? 5 : 100;

  async function handlePrimaryClick(event) {
    try {
      await onPrimaryClick(event);
      setShouldRefresh(true);
      onSecondaryClick();
    } catch (e) {
      if (e.status === 503) {
        context.openErrorSnackbar(e);
      } else {
        setErrorMessage(e.message);
      }
    }
  }

  function handleTextFieldChange(values) {
    if (isNaN(values.floatValue)) {
      textFieldOnChange({ target: { value: "" } });
    } else {
      textFieldOnChange({ target: { value: values.floatValue } });
    }
    setErrorMessage("");
  }

  async function handleOnExit() {
    onExited();
    if (shouldRefresh) {
      await onRefresh();
    }
  }

  return (
    <AlertDialog
      open={open}
      dialogType={errorMessage ? "ERROR" : ""}
      primaryLabel="Save"
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      title="Update Quantity"
      contentText={`The maximum amount is ${maxQuantity}.`}
      helperText={errorMessage}
      TransitionProps={{
        onExited: handleOnExit,
      }}
      customContent={
        <GenericNumberTextField
          autoFocus
          value={value}
          onChange={handleTextFieldChange}
          margin="normal"
          label="Quantity"
          dataTest="quantity-input"
          limit={maxQuantity}
        />
      }
    />
  );
}

QuantityUpdateDialog.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onExited: PropTypes.func,
  onRefresh: PropTypes.func,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  textFieldOnChange: PropTypes.func.isRequired,
};
