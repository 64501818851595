import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";

const AddToQuoteDialog = (props) => {
  const {
    isMobile,
    children,
    open,
    title,
    enableAddButton,
    handleClose,
    handleAddToQuote,
  } = props;

  return (
    <AlertDialog
      open={open}
      fullScreen={isMobile}
      data-test="add-to-new-quote-dialog"
      title={title}
      primaryLabel="Add"
      secondaryLabel="Cancel"
      onPrimaryClick={handleAddToQuote}
      disablePrimary={!enableAddButton}
      onSecondaryClick={handleClose}
      TransitionProps={{
        onExited: handleClose,
      }}
      customContent={children}
    />
  );
};

AddToQuoteDialog.propTypes = {
  isMobile: PropTypes.bool,
  children: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  enableAddButton: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleAddToQuote: PropTypes.func.isRequired,
};

export default AddToQuoteDialog;
