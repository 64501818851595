import { createAction } from "@reduxjs/toolkit";

export const RESET_STATE = "RESET_STATE";
export const SET_STORE_STATE = "SET_STORE_STATE";
export const SET_DEFAULT_SPECS = "SET_DEFAULT_SPECS";

export const SET_EDITABLE_DOOR_LOADING = "SET_EDITABLE_DOOR_LOADING";
export const SET_MULTIPLE_LOADING_STATES = "SET_MULTIPLE_LOADING_STATES";
export const SET_EXISTING_STATE_CHECK_LOADING =
  "SET_EXISTING_STATE_CHECK_LOADING";
export const SET_DOOR_DETAILS_LOADING = "SET_DOOR_DETAILS_LOADING";
export const SET_RECOVERY_OPTIONS_LOADING = "SET_RECOVERY_OPTIONS_LOADING";
export const SET_DEFAULT_DOOR_LOADING = "SET_DEFAULT_DOOR_LOADING";
export const SET_SLAB_LAYOUTS_LOADING = "SET_SLAB_LAYOUTS_LOADING";
export const SET_SLAB_DESIGNS_LOADING = "SET_SLAB_DESIGNS_LOADING";
export const SET_GLASS_DESIGNS_LOADING = "SET_GLASS_DESIGNS_LOADING";
export const SET_FINISHES_LOADING = "SET_FINISHES_LOADING";
export const SET_HARDWARES_LOADING = "SET_HARDWARES_LOADING";
export const SET_DIMENSIONS_LOADING = "SET_DIMENSION_LOADING";
export const SET_PEEP_SITES_LOADING = "SET_PEEP_SITES_LOADING";
export const SET_CASINGS_LOADING = "SET_CASINGS_LOADING";
export const SET_BRICK_MOLD_LOADING = "SET_BRICK_MOLD_LOADING";
export const SET_HANDING_LOADING = "SET_HANDING_LOADING";
export const SET_JAMB_DEPTHS_LOADING = "SET_JAMB_DEPTHS_LOADING";
export const SET_DOOR_SPECS_LOADING = "SET_DOOR_SPECS_LOADING";
export const SET_ROUGH_OPENING_LOADING = "SET_ROUGH_OPENING_LOADING";
export const SET_FILTERED_OPTIONS_LOADING = "SET_FILTERED_OPTIONS_LOADING";
export const SET_GET_PRODUCT_LOADING = "SET_GET_PRODUCT_LOADING";
export const SET_SILL_LOADING = "SET_SILL_LOADING";
export const SET_HINGES_LOADING = "SET_HINGES_LOADING";
export const SET_CLASSIFIER_RULES = "SET_CLASSIFIER_RULES";
export const SET_BORE_DETAILS_LOADING = "SET_BORE_DETAILS_LOADING";

export const SET_DEFAULT_DOOR_ERROR = "SET_DEFAULT_DOOR_ERROR";
export const SET_SLAB_LAYOUT_ERROR = "SET_SLAB_LAYOUT_ERROR";
export const SET_SLAB_DESIGN_ERROR = "SET_SLAB_DESIGN_ERROR";
export const SET_GLASS_DESIGN_ERROR = "SET_GLASS_DESIGN_ERROR";
export const SET_FINISH_ERROR = "SET_FINISH_ERROR";
export const SET_HARDWARE_ERROR = "SET_HARDWARE_ERROR";
export const SET_HINGE_ERROR = "SET_HINGE_ERROR";
export const SET_PEEP_SITE_ERROR = "SET_PEEP_SITE_ERROR";
export const SET_CASING_ERROR = "SET_CASING_ERROR";
export const SET_BRICK_MOLD_ERROR = "SET_BRICK_MOLD_ERROR";
export const SET_HANDING_ERROR = "SET_HANDING_ERROR";
export const SET_JAMB_DEPTH_ERROR = "SET_JAMB_DEPTH_ERROR";
export const SET_DOOR_SPEC_ERROR = "SET_DOOR_SPEC_ERROR";
export const SET_DIMENSION_ERROR = "SET_DIMENSION_ERROR";
export const SET_ROUGH_OPENING_ERROR = "SET_ROUGH_OPENING_ERROR";
export const SET_GET_PRODUCT_ERROR = "SET_GET_PRODUCT_ERROR";
export const SET_RESET_RETIRED_COMPONENTS = "RESET_RETIRED_COMPONENTS";
export const SET_SILL_ERROR = "SET_SILL_ERROR";
export const SET_BORE_DETAILS_ERROR = "SET_BORE_DETAILS_ERROR";

export const SET_SLAB_LAYOUT_OPTIONS = "SET_SLAB_LAYOUT_OPTIONS";
export const SET_SLAB_DESIGN_OPTIONS = "SET_SLAB_DESIGN_OPTIONS";
export const SET_GLASS_DESIGN_OPTIONS = "SET_GLASS_DESIGN_OPTIONS";
export const SET_FINISH_OPTIONS = "SET_FINISH_OPTIONS";
export const SET_CUSTOM_COLOR_OPTIONS = "SET_CUSTOM_COLOR_OPTIONS";
export const SET_HARDWARE_OPTIONS = "SET_HARDWARE_OPTIONS";
export const SET_ADD_ON_OPTIONS = "SET_ADD_ON_OPTIONS";
export const SET_DIMENSION_OPTIONS = "SET_DIMENSION_OPTIONS";
export const SET_PEEP_SITE_OPTIONS = "SET_PEEP_SITE_OPTIONS";
export const SET_CASING_OPTIONS = "SET_CASING_OPTIONS";
export const SET_BRICK_MOLD_OPTIONS = "SET_BRICK_MOLD_OPTIONS";
export const SET_DOOR_SPEC_OPTIONS = "SET_DOOR_SPEC_OPTIONS";
export const SET_INACTIVE_PREP_OPTIONS = "SET_INACTIVE_PREP_OPTIONS";
export const SET_HINGE_OPTIONS = "SET_HINGE_OPTIONS";
export const SET_SILL_OPTIONS = "SET_SILL_OPTIONS";
export const SET_DOOR_SUMMARY = "SET_DOOR_SUMMARY";
export const SET_DOOR_AVAILABILITY = "SET_DOOR_AVAILABILITY";
export const SET_MODE = "SET_MODE";
export const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";

export const SET_ROUGH_OPENING = "SET_ROUGH_OPENING";

export const SET_SLAB_LAYOUT_SELECTION = "SET_SLAB_LAYOUT_SELECTION";
export const SET_SLAB_DESIGN_SELECTION = "SET_SLAB_DESIGN_SELECTION";
export const SET_GLASS_DESIGN_SELECTION = "SET_GLASS_DESIGN_SELECTION";
export const SET_FINISH_SELECTION = "SET_FINISH_SELECTION";
export const SET_DOOR_DETAIL_SELECTION = "SET_DOOR_DETAIL_SELECTION";
export const SET_CUT_DOWN_DOOR_HEIGHTS = "SET_CUT_DOWN_DOOR_HEIGHTS";
export const SET_HINGE_SELECTION = "SET_HINGE_SELECTION";
export const SET_HARDWARE_SELECTION = "SET_HARDWARE_SELECTION";
export const SET_HARDWARE_FINISH_SELECTION = "SET_HARDWARE_FINISH_SELECTION";
export const SET_PEEP_SITE_SELECTION = "SET_PEEP_SITE_SELECTION";
export const SET_ADA_COMPLIANT_SELECTION = "SET_ADA_COMPLIANT_SELECTION";
export const SET_BRICK_MOLD_SELECTION = "SET_BRICK_MOLD_SELECTION";
export const SET_CASING_SELECTION = "SET_CASING_SELECTION";
export const SET_INACTIVE_PREP_SELECTION = "SET_INACTIVE_PREP_SELECTION";
export const SET_CASING_COLOR_SELECTION = "SET_CASING_COLOR_SELECTION";
export const SET_SIDELITE_SELECTION = "SET_SIDELITE_SELECTION";
export const SET_BORE_DETAILS = "SET_BORE_DETAILS";

export const SET_TAB_INDEX = "SET_TAB_INDEX";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_ACCORDION_STEP = "SET_ACCORDION_STEP";
export const SET_COLLAPSE = "SET_COLLAPSE";
export const SET_SLIDE = "SET_SLIDE";
export const SET_STEP_TWO_COMPLETE = "SET_STEP_TWO_COMPLETE";

export const SET_DOOR_PREVIEW = "SET_DOOR_PREVIEW";
export const SET_PREVIEW_IMAGE = "SET_PREVIEW_IMAGE";
export const SET_SETTING_DRAWER = "SET_SETTING_DRAWER";
export const SET_HAS_SELECTION_CHANGED = "SET_HAS_SELECTION_CHANGED";
export const SET_FILTER_SETTING = "SET_FILTER_SETTING";
export const SET_HAS_RETIRED_COMPONENT = "SET_HAS_RETIRED_COMPONENT";
export const SET_RETIRED_COMPONENTS = "SET_RETIRED_COMPONENTS";

export const resetState = createAction(RESET_STATE);
export const setStoreState = createAction(SET_STORE_STATE);
export const setDefaultSpecs = createAction(SET_DEFAULT_SPECS);

export const setEditableDoorLoading = createAction(SET_EDITABLE_DOOR_LOADING);
export const setMultipleLoadingStates = createAction(
  SET_MULTIPLE_LOADING_STATES
);
export const startLoadingExistingStateCheck = createAction(
  SET_EXISTING_STATE_CHECK_LOADING
);
export const startLoadingDoorDetails = createAction(SET_DOOR_DETAILS_LOADING);
export const startLoadingRecoveryOptions = createAction(
  SET_RECOVERY_OPTIONS_LOADING
);
export const startLoadingDefaultDoor = createAction(SET_DEFAULT_DOOR_LOADING);
export const startLoadingSlabLayouts = createAction(SET_SLAB_LAYOUTS_LOADING);
export const startLoadingSlabDesigns = createAction(SET_SLAB_DESIGNS_LOADING);
export const startLoadingGlassDesigns = createAction(SET_GLASS_DESIGNS_LOADING);
export const startLoadingFinishes = createAction(SET_FINISHES_LOADING);
export const startLoadingHardwares = createAction(SET_HARDWARES_LOADING);
export const startLoadingDimensions = createAction(SET_DIMENSIONS_LOADING);
export const startLoadingPeepSites = createAction(SET_PEEP_SITES_LOADING);
export const startLoadingCasings = createAction(SET_CASINGS_LOADING);
export const startLoadingBrickMold = createAction(SET_BRICK_MOLD_LOADING);
export const startLoadingBoreDetails = createAction(SET_BORE_DETAILS_LOADING);
export const startLoadingHandings = createAction(SET_HANDING_LOADING);
export const startLoadingJambDepths = createAction(SET_JAMB_DEPTHS_LOADING);
export const startLoadingDoorSpecs = createAction(SET_DOOR_SPECS_LOADING);
export const startLoadingRoughOpening = createAction(SET_ROUGH_OPENING_LOADING);
export const startLoadingFilteredOptions = createAction(
  SET_FILTERED_OPTIONS_LOADING
);
export const startLoadingGetProduct = createAction(SET_GET_PRODUCT_LOADING);
export const startLoadingSill = createAction(SET_SILL_LOADING);
export const startLoadingHinges = createAction(SET_HINGES_LOADING);

export const setDefaultDoorError = createAction(SET_DEFAULT_DOOR_ERROR);
export const setSlabLayoutError = createAction(SET_SLAB_LAYOUT_ERROR);
export const setSillError = createAction(SET_SILL_ERROR);
export const setSlabDesignError = createAction(SET_SLAB_DESIGN_ERROR);
export const setGlassDesignError = createAction(SET_GLASS_DESIGN_ERROR);
export const setFinishError = createAction(SET_FINISH_ERROR);
export const setHardwareError = createAction(SET_HARDWARE_ERROR);
export const setHingeError = createAction(SET_HINGE_ERROR);
export const setPeepSiteError = createAction(SET_PEEP_SITE_ERROR);
export const setCasingError = createAction(SET_CASING_ERROR);
export const setBrickMoldError = createAction(SET_BRICK_MOLD_ERROR);
export const setHandingError = createAction(SET_HANDING_ERROR);
export const setJambDepthError = createAction(SET_JAMB_DEPTH_ERROR);
export const setDoorSpecError = createAction(SET_DOOR_SPEC_ERROR);
export const setDimensionError = createAction(SET_DIMENSION_ERROR);
export const setRoughOpeningError = createAction(SET_ROUGH_OPENING_ERROR);
export const setGetProductError = createAction(SET_GET_PRODUCT_ERROR);
export const setBoreDetailsError = createAction(SET_BORE_DETAILS_ERROR);

export const setSlabLayouts = createAction(SET_SLAB_LAYOUT_OPTIONS);
export const setSlabDesigns = createAction(SET_SLAB_DESIGN_OPTIONS);
export const setGlassDesigns = createAction(SET_GLASS_DESIGN_OPTIONS);
export const setFinishes = createAction(SET_FINISH_OPTIONS);
export const setCustomColors = createAction(SET_CUSTOM_COLOR_OPTIONS);
export const setHardwares = createAction(SET_HARDWARE_OPTIONS);
export const setDimensions = createAction(SET_DIMENSION_OPTIONS);
export const setPeepSites = createAction(SET_PEEP_SITE_OPTIONS);
export const setCasings = createAction(SET_CASING_OPTIONS);
export const setBrickMolds = createAction(SET_BRICK_MOLD_OPTIONS);
export const setInactivePreps = createAction(SET_INACTIVE_PREP_OPTIONS);
export const setDoorSpecOptions = createAction(SET_DOOR_SPEC_OPTIONS);
export const setHinges = createAction(SET_HINGE_OPTIONS);
export const setSill = createAction(SET_SILL_OPTIONS);
export const setBoreDetails = createAction(SET_BORE_DETAILS);
export const setCutDownDoorHeights = createAction(SET_CUT_DOWN_DOOR_HEIGHTS);
export const setSlabLayoutSelection = createAction(SET_SLAB_LAYOUT_SELECTION);
export const setSlabDesignSelection = createAction(SET_SLAB_DESIGN_SELECTION);
export const setGlassDesignSelection = createAction(SET_GLASS_DESIGN_SELECTION);
export const setFinishSelection = createAction(SET_FINISH_SELECTION);
export const setDoorDetailSelection = createAction(SET_DOOR_DETAIL_SELECTION);
export const setHardwareSelection = createAction(SET_HARDWARE_SELECTION);
export const setHingeSelection = createAction(SET_HINGE_SELECTION);
export const setBrickMoldSelection = createAction(SET_BRICK_MOLD_SELECTION);
export const setCasingSelection = createAction(SET_CASING_SELECTION);
export const setInactivePrepSelection = createAction(
  SET_INACTIVE_PREP_SELECTION
);
export const setSideliteSelection = createAction(SET_SIDELITE_SELECTION);
export const setPeepSiteSelection = createAction(SET_PEEP_SITE_SELECTION);
export const setAdaSelection = createAction(SET_ADA_COMPLIANT_SELECTION);
export const setHardwareFinishSelection = createAction(
  SET_HARDWARE_FINISH_SELECTION
);
export const setCasingColorSelection = createAction(SET_CASING_COLOR_SELECTION);
export const changeTab = createAction(SET_TAB_INDEX);
export const setActiveStep = createAction(SET_ACTIVE_STEP);
export const setAccordionStep = createAction(SET_ACCORDION_STEP);
export const setCollapse = createAction(SET_COLLAPSE);
export const setSlide = createAction(SET_SLIDE);
export const setDoorPreview = createAction(SET_DOOR_PREVIEW);
export const setPreviewImage = createAction(SET_PREVIEW_IMAGE);
export const setHasSelectionChanged = createAction(SET_HAS_SELECTION_CHANGED);
export const setSettingsDrawer = createAction(SET_SETTING_DRAWER);
export const setRoughOpening = createAction(SET_ROUGH_OPENING);
export const setDoorSummary = createAction(SET_DOOR_SUMMARY);
export const setDoorAvailability = createAction(SET_DOOR_AVAILABILITY);
export const setMode = createAction(SET_MODE);
export const setStepTwoComplete = createAction(SET_STEP_TWO_COMPLETE);
export const setClassifierRule = createAction(SET_CLASSIFIER_RULES);
export const setHasRetiredComponent = createAction(SET_HAS_RETIRED_COMPONENT);
export const setRetiredComponents = createAction(SET_RETIRED_COMPONENTS);
export const setResetRetiredComponents = createAction(
  SET_RESET_RETIRED_COMPONENTS
);

export function setFilterSettings(payload, replace = false) {
  return {
    type: SET_FILTER_SETTING,
    payload,
    replace,
  };
}
