import React from "react";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import MeasurementIcon from "@mui/icons-material/BorderStyle";
import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/Warning";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddOns from "./AddOns";
import DoorDetailSpecs from "./DoorDetailSpecs";
import FinishDetails from "./FinishDetails";
import HardwareSection from "./HardwareSection";
import HardwareSectionMobile from "./HardwareSectionMobile";
import {
  googleNavClickEvent,
  googleProductClickEvent,
} from "../../../analytics/googleAnalyticsUtility";
import { FullScreenDialog } from "../../../components/Dialogs";
import MobileRoughOpening from "../selection/MobileRoughOpening";

const hasError = (label, errorStates) => {
  switch (label.replace(/(\d\.)+/g, "").toUpperCase()) {
    case "DETAILS":
      return (
        errorStates.dimensionError ||
        errorStates.handingError ||
        errorStates.jambDepthError
      );
    case "FINISHING":
      return errorStates.finishError;
    case "HARDWARE":
      return errorStates.hardwareError;
    case "ADD-ONS":
      return (
        errorStates.addOnError ||
        errorStates.peepSiteError ||
        errorStates.casingError ||
        errorStates.brickMoldError
      );
    default:
      return false;
  }
};

const DoorDetailOptions = (props) => {
  const {
    accordionStep,
    customPaintDeleteCheck,
    doorViewOptions,
    errorStates,
    getRoughOpenings,
    hasCustomColor,
    isMobile,
    loadingStates,
    onAccordionChange,
    onAdaChange,
    onBrickMoldChange,
    onCasingChange,
    onCasingColorChange,
    onDetailsChange,
    onFinishingChange,
    onHardwareChange,
    onHardwareFinishChange,
    onInactivePrepChange,
    onPeepSiteChange,
    openCustomColorDialogClick,
    options,
    reloadDimension,
    reloadHardware,
    retryBrickMoldCall,
    retryCasingCall,
    retryDimensionsCall,
    retryFinishCall,
    retryHandingCall,
    retryPeepSiteCall,
    retrySillCall,
    retryBoreDetailsCall,
    roughOpening,
    selections,
  } = props;

  const [activeDialog, setActiveDialog] = React.useState(null);
  const theme = useTheme();
  const widthDownXS = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const widthDownMedium = useMediaQuery(theme.breakpoints.down("lg"), {
    noSsr: true,
  });

  const wrappedOnHardwareChange = (wrappedHardware) => {
    googleProductClickEvent({
      activeStep: 1,
      tabStep: 2,
      option: wrappedHardware.part,
    });

    return onHardwareChange(wrappedHardware.part);
  };

  const handleClick = (event, value) => {
    onAccordionChange(value);
    googleNavClickEvent({ activeStep: 1, tabStep: value, label: event });
  };

  const sections = [
    {
      label: "Details",
      content: (
        <DoorDetailSpecs
          isDoorDetailsLoading={loadingStates.loadingDoorDetails}
          isRoughOpeningLoading={loadingStates.loadingRoughOpening}
          isDimensionsLoading={loadingStates.loadingDimensions}
          isHandingLoading={loadingStates.loadingHandings}
          doorSpecOptions={options.doorSpecs}
          dimensionOptions={options.dimensions}
          selections={selections}
          onChange={onDetailsChange}
          reloadHardware={reloadHardware}
          reloadDimension={reloadDimension}
          retryDimensionsCall={retryDimensionsCall}
          retryHandingCall={retryHandingCall}
          dimensionError={errorStates.dimensionError}
          handingError={errorStates.handingError}
          roughOpening={roughOpening}
          classifierRules={doorViewOptions.classifierRules}
          getRoughOpenings={getRoughOpenings}
        />
      ),
    },
    {
      label: "Finishes",
      content: (
        <FinishDetails
          isLoading={loadingStates.loadingFinishes}
          finishError={errorStates.finishError}
          finishOptions={options.finishes}
          customColors={options.customColors}
          selections={selections.finish}
          onClick={onFinishingChange}
          retryFinishCall={retryFinishCall}
          hasCustomColor={hasCustomColor}
          customPaintDeleteCheck={customPaintDeleteCheck}
          openCustomColorDialogClick={openCustomColorDialogClick}
        />
      ),
    },
    {
      label: "Hardware",
      content: widthDownXS ? (
        <HardwareSectionMobile
          isLoading={loadingStates.loadingHardwares}
          hardwareError={errorStates.hardwareError}
          selectedHardware={selections.hardware}
          selectedHardwareFinish={selections.hardware.finish}
          hardwareOptions={options.hardwares}
          onClick={wrappedOnHardwareChange}
          onHardwareFinishChange={onHardwareFinishChange}
          retryHardwareCall={reloadHardware}
          slabLayout={selections.slabLayout}
          inactivePrep={selections.inactivePrep}
          onInactivePrepClick={onInactivePrepChange}
          isHingeLoading={loadingStates.loadingHinges}
          isSillLoading={loadingStates.loadingSill}
          isBoreDetailsLoading={loadingStates.loadingBoreDetails}
          hingeError={errorStates.hingeError}
          sillError={errorStates.sillError}
          boreDetailsError={errorStates.boreDetailsError}
          selectedHinge={selections.hinge}
          retrySillCall={retrySillCall}
          retryBoreDetailsCall={retryBoreDetailsCall}
          sillOption={options.sill}
          selections={selections}
          roughOpening={roughOpening}
          hingeOptions={options.hinges}
          boreMeasurements={selections.boreDetails}
        />
      ) : (
        <HardwareSection
          isLoading={loadingStates.loadingHardwares}
          isHingeLoading={loadingStates.loadingHinges}
          isSillLoading={loadingStates.loadingSill}
          isBoreDetailsLoading={loadingStates.loadingBoreDetails}
          hardwareError={errorStates.hardwareError}
          selectedHinge={selections.hinge}
          selectedHardware={selections.hardware}
          selectedHardwareFinish={selections.hardware.finish}
          hardwareOptions={options.hardwares}
          onClick={wrappedOnHardwareChange}
          onHardwareFinishChange={onHardwareFinishChange}
          retryHardwareCall={reloadHardware}
          slabLayout={selections.slabLayout}
          inactivePrep={selections.inactivePrep}
          onInactivePrepClick={onInactivePrepChange}
          hingeError={errorStates.hingeError}
          sillError={errorStates.sillError}
          boreDetailsError={errorStates.boreDetailsError}
          retrySillCall={retrySillCall}
          retryBoreDetailsCall={retryBoreDetailsCall}
          sillOption={options.sill}
          selections={selections}
          roughOpening={roughOpening}
          hingeOptions={options.hinges}
          boreMeasurements={selections.boreDetails}
        />
      ),
    },
    {
      label: "Add-ons",
      content: (
        <AddOns
          options={options}
          selections={selections}
          isMobile={isMobile}
          isCasingsLoading={loadingStates.loadingCasings}
          casingError={errorStates.casingError}
          isBrickMoldsLoading={loadingStates.loadingBrickMolds}
          brickMoldError={errorStates.brickMoldError}
          isPeepSitesLoading={loadingStates.loadingPeepSites}
          peepSiteError={errorStates.peepSiteError}
          onCasingClick={onCasingChange}
          retryCasingCall={retryCasingCall}
          onPeepSiteClick={onPeepSiteChange}
          retryPeepSiteCall={retryPeepSiteCall}
          onAdaClick={onAdaChange}
          onCasingColorClick={onCasingColorChange}
          onBrickMoldClick={onBrickMoldChange}
          retryBrickMoldCall={retryBrickMoldCall}
          hasCustomColor={hasCustomColor}
          customPaintDeleteCheck={customPaintDeleteCheck}
          openCustomColorDialogClick={openCustomColorDialogClick}
        />
      ),
    },
  ];

  if (isMobile) {
    return (
      <>
        <Grid
          container
          spacing={2}
          style={{ width: "100vw", margin: "0px" }}
          justifyContent={"space-between"}
        >
          <Grid item xs={12}>
            <Typography
              style={{
                width: "90%",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              Tap a section to edit the respective door properties:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ButtonBase
              onClick={() => setActiveDialog(-1)}
              style={{ width: "90%" }}
              data-test="measurement-details-btn"
            >
              <Paper
                style={{
                  width: "100%",
                  padding: "10px 12px",
                }}
              >
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={(theme) => ({
                      color:
                        theme.palette.mode === "light" ? "#6e6e6e" : "inherit",
                    })}
                  >
                    Measurement Details
                  </Typography>
                  <MeasurementIcon style={{ color: "rgb(0, 174, 239)" }} />
                </div>
              </Paper>
            </ButtonBase>
          </Grid>
          <FullScreenDialog
            open={activeDialog === -1}
            onClose={() => setActiveDialog(null)}
            label="Measurement Details"
            content={
              <MobileRoughOpening
                handing={selections.handing}
                roughOpening={roughOpening}
              />
            }
          />

          {sections.map((panel, i) => {
            const label = i + 1 + ". " + panel.label;

            return (
              <Grid
                item
                key={i}
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ButtonBase
                  data-test={`${kebabCase(panel.label)}-btn`}
                  onClick={() => setActiveDialog(i)}
                  style={{ width: "90%" }}
                >
                  <Paper
                    style={{
                      width: "100%",
                      padding: "10px 12px",
                    }}
                  >
                    <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <Typography
                        sx={(theme) => ({
                          color:
                            theme.palette.mode === "light"
                              ? "#6e6e6e"
                              : "inherit",
                        })}
                      >
                        {label}
                      </Typography>
                      <EditIcon style={{ color: "rgb(0, 174, 239)" }} />
                    </div>
                  </Paper>
                </ButtonBase>
              </Grid>
            );
          })}
        </Grid>
        {sections.map((panel, i) => {
          const label = i + 1 + ". " + panel.label;

          return (
            <FullScreenDialog
              key={i}
              open={activeDialog === i}
              onClose={() => setActiveDialog(null)}
              label={label}
              content={panel.content}
            />
          );
        })}
      </>
    );
  } else {
    return (
      <>
        <AppBar position="sticky">
          <Tabs
            className="tabBar"
            variant={widthDownMedium ? "scrollable" : "fullWidth"}
            value={accordionStep}
            onChange={handleClick}
            indicatorColor="secondary"
          >
            {sections.map((item, i) => (
              <Tab
                key={i}
                data-test={kebabCase(item.label) + "-tab"}
                label={
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ paddingRight: "16px" }}>{item.label}</div>
                    {hasError(item.label, errorStates) && (
                      <WarningIcon
                        data-test={kebabCase(item.label) + "-tab-error"}
                        color="error"
                      />
                    )}
                  </Box>
                }
              />
            ))}
          </Tabs>
        </AppBar>
        <Paper
          elevation={0}
          square
          sx={{
            overflow: "auto",
            height: "calc( 100vh - 72px - 48px - 64px )",
            padding: "32px 24px",
          }}
        >
          {sections[accordionStep].content}
        </Paper>
      </>
    );
  }
};

DoorDetailOptions.propTypes = {
  accordionStep: PropTypes.number.isRequired,
  customPaintDeleteCheck: PropTypes.func.isRequired,
  errorStates: PropTypes.object.isRequired,
  hasCustomColor: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  loadingStates: PropTypes.object.isRequired,
  onAccordionChange: PropTypes.func.isRequired,
  onAdaChange: PropTypes.func.isRequired,
  onBrickMoldChange: PropTypes.func.isRequired,
  onCasingChange: PropTypes.func.isRequired,
  onCasingColorChange: PropTypes.func.isRequired,
  onDetailsChange: PropTypes.func.isRequired,
  onFinishingChange: PropTypes.func.isRequired,
  onHardwareChange: PropTypes.func.isRequired,
  onHardwareFinishChange: PropTypes.func.isRequired,
  onInactivePrepChange: PropTypes.func.isRequired,
  onPeepSiteChange: PropTypes.func.isRequired,
  openCustomColorDialogClick: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  reloadDimension: PropTypes.func.isRequired,
  reloadHardware: PropTypes.func.isRequired,
  retryBrickMoldCall: PropTypes.func.isRequired,
  retryCasingCall: PropTypes.func.isRequired,
  retryDimensionsCall: PropTypes.func.isRequired,
  retryFinishCall: PropTypes.func.isRequired,
  retryHandingCall: PropTypes.func.isRequired,
  retryPeepSiteCall: PropTypes.func.isRequired,
  retryBoreDetailsCall: PropTypes.func.isRequired,
  roughOpening: PropTypes.object.isRequired,
  selections: PropTypes.object.isRequired,
};

export default DoorDetailOptions;
