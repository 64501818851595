import React from "react";
import loadable from "@loadable/component";
import SearchIcon from "@mui/icons-material/Search";
import { Authority } from "../../AuthProvider";
const LazyCustomParts = loadable(() => import("./CustomPartsPage"));

export default class DoorBuilder {
  static routes(context) {
    if (context.hasAnyAuthority([Authority.ORDER_CUSTOM_PART])) {
      return [
        {
          groupName: "Products",
          items: [
            {
              label: "Custom Parts",
              icon: <SearchIcon data-test="custom-part-menu-btn" />,
              disablePadding: true,
              path: "/products/custom-parts",
              component: <LazyCustomParts />,
            },
          ],
        },
      ];
    } else {
      return [];
    }
  }
}
