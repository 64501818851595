import React from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

export default function ClickableCard({
  onClick,
  imageSrc,
  altName,
  text,
  active,
  disabled,
  showInfo = false,
  onInfoClick,
  infoText,
  isUnavailable,
}) {
  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        height: "100%",
        // This allows the check icon to be visible.
        overflow: "initial",
        backgroundColor: theme.palette.action.hover,
        ...(active &&
          !showInfo && {
            border: `4px solid ${theme.palette.secondary.main}`,
            color: "secondary.main",
            backgroundColor: "secondary.100",
          }),
      })}
    >
      <CardActionArea
        data-test={altName}
        onClick={onClick}
        disabled={disabled || showInfo || isUnavailable}
        sx={(theme) => ({
          height: "100%",
          borderRadius: theme.shape.borderRadius,
          opacity: disabled && theme.palette.action.disabledOpacity,
          ...(!showInfo && {
            background: theme.palette.action.disabledOpacity,
            "&:hover .MuiCardActionArea-focusHighlight": {
              backgroundColor: "transparent",
              opacity: 0,
            },
          }),
        })}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          item
          // Minimum height for the Grid to take.
          minHeight="86px"
        >
          <CardMedia
            component="img"
            alt={altName}
            image={imageSrc}
            sx={(theme) => ({
              padding: theme.spacing(2, 1, 0, 1),
              height: "100%",
              maxHeight: "86px",
              width: "auto",
              maxWidth: "100%",

              opacity: showInfo && theme.palette.action.disabledOpacity,
            })}
          />
        </Grid>
        <CardContent sx={() => (showInfo || isUnavailable) && { pb: 0 }}>
          <Typography align="center" variant="h5" color="textSecondary">
            {text}
          </Typography>
        </CardContent>
        {active && (
          <CheckCircleIcon
            data-test="active"
            fontSize="large"
            sx={{
              position: "absolute",
              bottom: "-10px",
              right: "-10px",
              borderRadius: "20px",
              background: "white",
              color: "secondary.main",
            }}
          />
        )}
      </CardActionArea>
      {((showInfo && !disabled) || isUnavailable) && (
        <CardActions
          sx={(theme) => ({
            padding: theme.spacing(0, 0, 1, 0),
            background: theme.palette.action.disabledOpacity,
          })}
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Typography align="center" variant="caption">
                {infoText}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                data-test="info-btn"
                onClick={onInfoClick}
                sx={{ pointerEvents: "auto" }}
              >
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </CardActions>
      )}
    </Card>
  );
}

ClickableCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  imageSrc: PropTypes.string.isRequired,
  altName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  showInfo: PropTypes.bool,
  onInfoClick: PropTypes.func,
  infoText: PropTypes.string,
  isUnavailable: PropTypes.bool,
};
