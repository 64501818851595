import React from "react";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { googleNavClickEvent } from "../../../analytics/googleAnalyticsUtility";
import DoorSVG from "../../../components/Common/DoorSVG";
import { FullScreenDialog } from "../../../components/Dialogs";

const StyledInnerImageZoom = styled(InnerImageZoom)(({ theme }) => ({
  "& > div": {
    //Styling for imageSrc
    "& > img": {
      maxHeight: "calc( 100vh - 64px - 200px)",
      width: "auto !important",
      border:
        theme.palette.mode === "light"
          ? "none"
          : "4px solid rgba(255,255,255, 0.24);",
    },
  },
}));

const ZoomContent = ({
  previewImg,
  isFlipped,
  slabDesign,
  slabLayout,
  sideliteGlassDesign,
  doorFinishSide,
}) => {
  const fireGAEvent = () => {
    googleNavClickEvent({
      activeStep: 0,
      tabStep: 2,
      action: "click zoom dialog door",
      url: "door-preview",
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      mt="16px"
    >
      {doorFinishSide && doorFinishSide.finishType !== "STAIN" ? (
        <div onClick={fireGAEvent}>
          <DoorSVG
            sx={{
              height: "calc( 100vh - 64px - 200px)",
              width: "auto",
              transform: isFlipped ? "rotateY(180deg)" : "inherit",
            }}
            selectedFinish={doorFinishSide}
            slabDesign={slabDesign}
            sideliteGlassDesign={sideliteGlassDesign}
            slabLayout={slabLayout}
            isBackImage={isFlipped}
          />
        </div>
      ) : (
        <div onClick={fireGAEvent}>
          <StyledInnerImageZoom
            src={previewImg}
            zoomScale={1.4}
            hideHint
            fullscreenOnMobile
          />
          <Typography align="center" variant="h6" color="inherit" mt="16px">
            Click image to zoom
          </Typography>
        </div>
      )}
    </Grid>
  );
};

class ZoomComponent extends React.Component {
  render() {
    const {
      previewImg = "",
      open,
      handleClose,
      title,
      isFlipped,
      finish = {},
      slabLayout,
      slabDesign,
      sideliteGlassDesign,
    } = this.props;
    const doorFinishSide = isFlipped
      ? finish.doorInterior
      : finish.doorExterior;

    return (
      <FullScreenDialog
        open={open}
        onClose={handleClose}
        label={title}
        content={
          <ZoomContent
            previewImg={previewImg}
            isFlipped={isFlipped}
            slabLayout={slabLayout}
            slabDesign={slabDesign}
            sideliteGlassDesign={sideliteGlassDesign}
            doorFinishSide={doorFinishSide}
          />
        }
      />
    );
  }
}

ZoomComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  previewImg: PropTypes.string,
  slabDesign: PropTypes.object,
  slabLayout: PropTypes.object,
  sideliteGlassDesign: PropTypes.object,
};
export default ZoomComponent;
