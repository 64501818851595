import {
  getExteriorDoorVersion1,
  getExteriorDoorVersion2,
} from "../components/Doors/payload/getPayload";

export function normalizeDoorPayload(itemPayload) {
  const { version } = itemPayload.product ? itemPayload.product : {};

  let doorPayload;

  if (version === "2.5") {
    doorPayload = getExteriorDoorVersion1(itemPayload.product.components);
  } else {
    // Consumer lead items does not have product
    if (!itemPayload.product) {
      doorPayload = getExteriorDoorVersion2(itemPayload);
    } else {
      doorPayload = getExteriorDoorVersion2(itemPayload.product);
    }
  }

  return {
    slabDesign: {
      description: doorPayload.door.description,
      links: doorPayload.door.links,
    },
    glassDesignDescription:
      doorPayload.hasDoorGlass && doorPayload.doorGlass.doorGlassName,
    finish:
      doorPayload.finish.exteriorFinish !== "N/A"
        ? doorPayload.finish.exteriorFinish
        : {},
    sideliteGlass: doorPayload.hasSidelite
      ? {
          resourceId: doorPayload.sideliteGlass.resourceId,
          description: doorPayload.sideliteGlass.slGlassName,
        }
      : {},
    slabLayoutDescription: doorPayload.door.layout,
  };
}
