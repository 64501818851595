import React from "react";
import PropTypes from "prop-types";
import {
  AlertDialog,
  NameTextField,
} from "@masonite-digital/common-components";
import Typography from "@mui/material/Typography";
import { isQuoteNameValid } from "../../utils";

export default function SplitQuoteDialog(props) {
  const {
    open,
    initialSplitQuoteName,
    onPrimaryClick,
    onSecondaryClick,
    onExited,
  } = props;
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const [splitQuoteName, setSplitQuoteName] = React.useState(
    initialSplitQuoteName
  );
  const [errorMessage, setErrorMessage] = React.useState(null);

  const checkNewQuoteNameLabel = () => {
    const label = "New Quote Name";
    if (!isQuoteNameValid(splitQuoteName)) {
      return label + " is invalid";
    } else {
      return label;
    }
  };

  const handlePrimaryClick = async () => {
    try {
      await onPrimaryClick(splitQuoteName);
      setIsSuccessful(true);
      onSecondaryClick();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const handleOnExited = () => {
    if (isSuccessful) {
      onExited();
    }

    setSplitQuoteName(initialSplitQuoteName);
  };

  const onSplitQuoteNameChange = (e) => {
    setSplitQuoteName(e.target.value);
  };

  return (
    <AlertDialog
      open={open}
      dialogType="WARNING"
      title="Split quote and purchase?"
      primaryLabel="Split"
      helperText={errorMessage}
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      disablePrimary={
        splitQuoteName === "" || !isQuoteNameValid(splitQuoteName)
      }
      TransitionProps={{
        onExited: handleOnExited,
      }}
      customContent={
        <>
          <Typography paragraph>
            Only reserved items can be purchased. If you wish to proceed, select
            split to move the items that are not reserved into a new quote and
            purchase the reserved items.
          </Typography>
          <NameTextField
            label={checkNewQuoteNameLabel()}
            autoFocus
            error={!isQuoteNameValid(splitQuoteName)}
            value={splitQuoteName}
            onChange={onSplitQuoteNameChange}
          />
        </>
      }
    />
  );
}

SplitQuoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  initialSplitQuoteName: PropTypes.string.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
};
