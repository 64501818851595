let baseUrl = null;
let accessToken = null;
let refreshToken = null;
let onRefreshFailure = null;
let onRefreshSuccess = null;

/**
 * This callback type is called `accessTokenCallback` and is displayed as a global symbol.
 *
 * @callback accessTokenCallback
 * @returns {string} The access token
 */

/**
 * This callback type is called `refreshTokenCallback` and is displayed as a global symbol.
 *
 * @callback refreshTokenCallback
 * @returns {string} The refresh token
 */

/**
 * This callback type is called `onRefreshFailureCallback` and is displayed as a global symbol.
 *
 * @callback onRefreshFailureCallback
 */

/**
 * This callback type is called `onRefreshSuccessCallback` and is displayed as a global symbol.
 *
 * @callback onRefreshSuccessCallback
 * @param {string} accessToken The access token
 */

/**
 * This should be called before first use. This can be used without first
 * initializing but is not recommended. This can be called as often as wanted
 * with or without all parameters and it will reuse any existing parameters.
 *
 * @param {Object} options
 * @param {string} [options.baseUrl] baseUrl URL that starts with http:// or https://
 * @param {string|accessTokenCallback} [options.accessToken] accessToken
 * @param {string|refreshTokenCallback} [options.refreshToken] refreshToken
 * @param {onRefreshFailureCallback} [options.onRefreshFailure] onRefreshFailure handle failure to refresh the access token
 * @param {onRefreshSuccessCallback} [options.onRefreshSuccess] onRefreshSuccess handle successful refresh of the access token
 */
export function init(
  options = {
    baseUrl,
    accessToken,
    refreshToken,
    onRefreshFailure,
    onRefreshSuccess,
  }
) {
  if (options.baseUrl) {
    if (typeof options.baseUrl !== "string") {
      throw new Error("baseUrl is required to be of type string");
    }
    if (!options.baseUrl.match(/^https?:\/\/.*/)) {
      throw new Error("baseUrl must start with http:// or https://");
    }
    baseUrl = options.baseUrl;
  }

  if (options.accessToken) {
    if (
      !(
        typeof options.accessToken === "function" ||
        typeof options.accessToken === "string"
      )
    ) {
      throw new Error(
        "accessToken is required to be of type function or string."
      );
    }
    if (
      typeof options.accessToken === "function" &&
      typeof options.accessToken() !== "string"
    ) {
      throw new Error(
        "The return value of a call to accessToken is required to be of type string."
      );
    }
    accessToken = options.accessToken;
  }

  if (options.refreshToken) {
    if (
      !(
        typeof options.refreshToken === "function" ||
        typeof options.refreshToken === "string"
      )
    ) {
      throw new Error(
        "refreshToken is required to be of type function or string."
      );
    }
    if (
      typeof options.refreshToken === "function" &&
      typeof options.refreshToken() !== "string"
    ) {
      throw new Error(
        "The return value of a call to refreshToken is required to be of type string."
      );
    }
    refreshToken = options.refreshToken;
  }

  if (options.onRefreshFailure) {
    if (typeof options.onRefreshFailure !== "function") {
      throw new Error("onRefreshFailure is required to be of type function.");
    }
    onRefreshFailure = options.onRefreshFailure;
  }

  if (options.onRefreshSuccess) {
    if (typeof options.onRefreshSuccess !== "function") {
      throw new Error("onRefreshSuccess is required to be of type function.");
    }
    onRefreshSuccess = options.onRefreshSuccess;
  }
}

/**
 * Expanded version of the Fetch API with support for request parameters in options and a third parameter – entity –
 * that will be applied to the request as options.body after JSON.stringify is applied to it.
 *
 * The Authorization header will always be applied if a JSON Web Token is available.
 *
 * @param {string} url - Either an absolute URL or a relative URL that will use the API Gateway as its host.
 * @param {Object} [options={}] - Options to be passed through to fetch
 * @param {string} [options.method=GET] - HTTP method
 * @param {Object} [options.headers] - Headers to be merged with Content-Type and Accept: application/json
 * @param {Object} [options.params] - Custom option that will have its key-value pairs applied as request parameters
 * @param {Object} [body] - Entity that will have JSON.stringify applied to it
 * @returns {Promise.<Response>}
 */
export async function fetch(url, options = {}, body) {
  const originalCall = () => fetch(url, options, body);

  // Clone headers in order to add Authorization header
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    ...options.headers,
  };

  if (accessToken) {
    headers["Authorization"] = `Bearer ${
      typeof accessToken === "function" ? accessToken() : accessToken
    }`;
  }

  // Clone options in order to add cloned headers with optional Authorization
  const actualOptions = {
    ...options,
    headers,
  };

  // Convenient support for params object to be converted to request parameters
  delete actualOptions.params;

  const params = [];
  for (const [key, value] of Object.entries(options.params || {})) {
    if (value !== undefined && value !== null) {
      params.push(`${key}=${value}`);
    }
  }

  const actualURL = params.length ? `${url}?${params.join("&")}` : url;

  // Convenient support for optional third parameter – body
  if (body !== undefined) {
    actualOptions.body = JSON.stringify(body);
  }

  const response = await window.fetch(
    new URL(actualURL, baseUrl),
    actualOptions
  );

  if (response.status === 401 && accessToken && refreshToken() !== "") {
    // Assume the status is due to an invalid token
    return refresh(originalCall);
  }
  if (!response.ok) {
    let responseJson;
    try {
      responseJson = await response.json();
    } catch (e) {
      throw new Error();
    }
    throw Object.assign(
      new Error(responseJson.message || responseJson.error_description),
      {
        status: responseJson.status || response.status,
        ...responseJson,
      }
    );
  }
  return response;
}

/**
 * This callback type is called `refreshCallback` and is displayed as a global symbol.
 *
 * @callback refreshCallback
 */

/**
 * Force a call to refresh the access token. If the call is successful and
 * onRefreshSuccess exists, onRefreshSuccess will be called with the new token.
 *
 * After the refresh is complete, if a callback is supplied, it will be called.
 *
 * @param {refreshCallback} [callback]
 * @return {Promise<*>}
 */
export async function refresh(callback) {
  const refreshedTokenResponse = await window.fetch(
    new URL(
      `tokens/${
        typeof refreshToken === "function" ? refreshToken() : refreshToken
      }/refresh`,
      baseUrl
    ),
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
    }
  );

  // Handle Refresh Token response
  if (refreshedTokenResponse.ok) {
    const token = (await refreshedTokenResponse.json())["access_token"];
    if (onRefreshSuccess) {
      await onRefreshSuccess(token);
    } else if (typeof accessToken === "string") {
      accessToken = token;
    }
    if (callback) {
      return callback();
    }
  } else if (refreshedTokenResponse.status === 401) {
    onRefreshFailure(callback);

    //Throw 401 error to the front end.
    let responseJson = await refreshedTokenResponse.json();
    throw Object.assign(new Error(responseJson.message), {
      status: responseJson.status,
      ...responseJson,
    });
  } else {
    throw new Error(
      (await refreshedTokenResponse.json()).message ||
        "While refreshing the session, an error occurred and no message was available."
    );
  }
}
