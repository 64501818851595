import React from "react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { ResultMessage } from "@masonite-digital/common-components";
import AddIcon from "@mui/icons-material/ControlPoint";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import OptionSkeleton, {
  OptionSkeletonType,
} from "../../../components/Common/OptionSkeleton";
import { ImageSelector } from "../../../components/Selection";

const LabelTypography = styled(Typography)({
  fontSize: "0.75rem",
  color: "primary.main",
  fontWeight: 500,
  textTransform: "uppercase",
});

export default function AddOns(props) {
  const {
    options,
    selections,
    isMobile,
    isCasingsLoading,
    casingError,
    isBrickMoldsLoading,
    brickMoldError,
    isPeepSitesLoading,
    peepSiteError,
    onCasingClick,
    onCasingColorClick,
    retryCasingCall,
    onPeepSiteClick,
    retryPeepSiteCall,
    onBrickMoldClick,
    retryBrickMoldCall,
    onAdaClick,
    hasCustomColor,
    customPaintDeleteCheck,
    openCustomColorDialogClick,
  } = props;

  const finishOptions = options.finishes;

  //This is to set brickmold to WM180 in case the selected brickmold is 'Shipped Loose' (while editing old quotes)
  if (selections.brickMold.description.includes("Shipped Loose")) {
    onBrickMoldClick(
      options.brickMolds.find((brickmold) => brickmold.description === "WM180"),
    );
  }

  const isPrimedOnly =
    selections.finish.doorExterior.description === "Primed Only";

  function renderCasingSection() {
    if (!isCasingsLoading && (isEmpty(options.casings) || casingError)) {
      return (
        <Grid item>
          <ResultMessage
            type="WARNING"
            title="Something went wrong"
            message={casingError.message}
            onClick={retryCasingCall}
          />
        </Grid>
      );
    } else {
      return (
        <>
          <Grid item>
            <Typography color="primary" component="span">
              {isCasingsLoading ? (
                <Skeleton variant="rectangular" width={100} height={18} />
              ) : (
                <span style={{ marginLeft: "5px" }}>
                  Selected:{" "}
                  <b data-test="casing-description">
                    {selections.casing.description}
                  </b>
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item>
            <ImageSelector
              isLoading={isCasingsLoading}
              imgSizeSx={{ width: 75, height: 75 }}
              onSelect={onCasingClick}
              selected={selections.casing}
              options={options.casings}
              gridContainerProps={{ spacing: 0 }}
              optionType="Casing"
            />
          </Grid>
          {selections.casing && selections.casing.description !== "None" && (
            <Grid item container data-test="casing-color-wrapper">
              <Grid item xs={12} container>
                <Grid item xs={6}>
                  <Typography color="primary">Casing Finish:</Typography>
                  <Typography
                    data-test="casing-finish-description"
                    variant="body2"
                    gutterBottom
                  >
                    {selections.finish.casingColor.description}
                  </Typography>
                </Grid>
                {hasCustomColor && !isPrimedOnly && (
                  <Grid item xs={6} container justifyContent="flex-end">
                    <Grid>
                      <IconButton
                        data-test="add-custom-color-to-casing-btn"
                        onClick={() => openCustomColorDialogClick("casing")}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                {/* Commenting the code (for removing Stains) instead of removing it, in case it is needed in the future*/}
                {/*{!isPrimedOnly && (*/}
                {/*  <>*/}
                {/*    <ImageSelector*/}
                {/*      isLoading={isCasingsLoading}*/}
                {/*      imgSizeSx={{ width: 32, height: 32 }}*/}
                {/*      onSelect={onCasingColorClick}*/}
                {/*      selected={selections.finish.casingColor}*/}
                {/*      options={finishOptions.STAIN}*/}
                {/*      optionType="Casing Finish"*/}
                {/*      gridContainerProps={{ spacing: 0 }}*/}
                {/*      forceExtraSmallIcons*/}
                {/*      showDescription={false}*/}
                {/*    />*/}
                {/*    <Divider style={{ margin: "5px 0" }} />*/}
                {/*  </>*/}
                {/*)}*/}
                <ImageSelector
                  isLoading={isCasingsLoading}
                  imgSizeSx={{ width: 32, height: 32 }}
                  onSelect={onCasingColorClick}
                  selected={selections.finish.casingColor}
                  options={finishOptions.PAINT.filter((finish) => {
                    // If selected Prime Only then filter for Prime Only
                    if (isPrimedOnly) {
                      return finish.description === "Primed Only";
                    }
                    return finish.description !== "Primed Only";
                  })}
                  optionType="Casing Finish"
                  gridContainerProps={{ spacing: 0 }}
                  forceExtraSmallIcons
                  showDescription={false}
                />
                {!isPrimedOnly && (
                  <>
                    <Divider style={{ margin: "5px 0" }} />
                    <ImageSelector
                      isLoading={isCasingsLoading}
                      imgSizeSx={{ width: 32, height: 32 }}
                      onSelect={onCasingColorClick}
                      selected={selections.finish.casingColor}
                      options={options.customColors}
                      optionType="Casing Finish"
                      gridContainerProps={{ spacing: 0 }}
                      forceExtraSmallIcons
                      showDescription={false}
                      showDelete
                      onDelete={customPaintDeleteCheck}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </>
      );
    }
  }

  function renderPeepSiteSection() {
    if (!isPeepSitesLoading && peepSiteError) {
      return (
        <Grid item data-test="peep-sight-wrapper">
          <ResultMessage
            type="WARNING"
            title="Something went wrong"
            message={peepSiteError.message}
            onClick={retryPeepSiteCall}
          />
        </Grid>
      );
    } else if (isPeepSitesLoading || !isEmpty(options.peepSites)) {
      return (
        <Grid
          item
          container
          direction="column"
          spacing={2}
          data-test="peep-sight-wrapper"
        >
          <Grid item>
            <LabelTypography>Peep Sight</LabelTypography>
          </Grid>
          <Grid item container>
            <Grid item xs={12}>
              <ImageSelector
                isLoading={isPeepSitesLoading}
                imgSizeSx={{ width: 75, height: 75 }}
                onSelect={onPeepSiteClick}
                selected={selections.peepSite}
                options={options.peepSites}
                optionType="Peep Sight"
                gridContainerProps={{ spacing: 0 }}
              />
            </Grid>
            {isPeepSitesLoading ? (
              <>
                <OptionSkeleton
                  type={OptionSkeletonType.CHECK_BOX}
                  optionWidth={200}
                />
              </>
            ) : (
              selections.peepSite.description !== "None" && (
                <>
                  <Grid item xs={12} style={{ marginTop: "4px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-test="peep-sight-ada-compliant"
                          color="secondary"
                          checked={selections.adaCompliant}
                        />
                      }
                      label={"ADA Compliant Peep Sight"}
                      onChange={(e) => onAdaClick(!selections.adaCompliant)}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      data-test="peep-sight-ada-note"
                      variant="caption"
                    >
                      <strong>Note: </strong>
                      When you choose an ADA Compliant Peep Sight, you will get
                      two peep sights instead of one; one at the standard height
                      and the other accessible from a wheelchair.
                    </Typography>
                  </Grid>
                </>
              )
            )}
          </Grid>
        </Grid>
      );
    }
  }

  function renderBrickMoldSection() {
    const brickmoldOptions = options.brickMolds;

    if (
      !isBrickMoldsLoading &&
      (isEmpty(options.brickMolds) || brickMoldError)
    ) {
      return (
        <Grid item>
          <ResultMessage
            type="WARNING"
            title="Something went wrong"
            message={brickMoldError.message}
            onClick={retryBrickMoldCall}
          />
        </Grid>
      );
    } else {
      return (
        <>
          <Grid item>
            <Typography color="primary" component="span">
              {isBrickMoldsLoading ? (
                <Skeleton variant="rectangular" />
              ) : (
                selections.brickMold && (
                  <span style={{ marginLeft: "5px" }}>
                    Selected:{" "}
                    <b data-test="brickmold-description">
                      {selections.brickMold.description}
                    </b>
                  </span>
                )
              )}
            </Typography>
          </Grid>
          <Grid item>
            <ImageSelector
              isLoading={isBrickMoldsLoading}
              imgSizeSx={{ width: 75, height: 75 }}
              onSelect={onBrickMoldClick}
              selected={selections.brickMold}
              options={brickmoldOptions}
              gridContainerProps={{ spacing: 0 }}
              optionType="Brick Mold"
            />
            <Grid data-test="brickmold-note" item>
              <Typography variant="caption">
                <strong>Note: </strong>Brickmold is shipped loose
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }
  }

  return (
    <Grid container spacing={2}>
      {isMobile && (
        <Grid item data-test="casing-wrapper">
          <LabelTypography>
            Select which add-ons to add to your door:
          </LabelTypography>
        </Grid>
      )}
      <Grid item container spacing={1} direction="column">
        <Grid item>
          <LabelTypography>Casing</LabelTypography>
        </Grid>
        {renderCasingSection()}
      </Grid>
      {renderPeepSiteSection()}
      <Grid
        item
        container
        spacing={1}
        direction="column"
        data-test="brick-mold-wrapper"
      >
        <Grid item>
          <LabelTypography>Brickmold</LabelTypography>
        </Grid>
        {renderBrickMoldSection()}
      </Grid>
    </Grid>
  );
}

AddOns.propTypes = {
  options: PropTypes.object.isRequired,
  selections: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isCasingsLoading: PropTypes.bool.isRequired,
  casingError: PropTypes.string,
  isBrickMoldsLoading: PropTypes.bool.isRequired,
  brickMoldError: PropTypes.string,
  isPeepSitesLoading: PropTypes.bool.isRequired,
  peepSiteError: PropTypes.string,
  onCasingClick: PropTypes.func.isRequired,
  onCasingColorClick: PropTypes.func.isRequired,
  retryCasingCall: PropTypes.func.isRequired,
  onPeepSiteClick: PropTypes.func.isRequired,
  retryPeepSiteCall: PropTypes.func.isRequired,
  onBrickMoldClick: PropTypes.func.isRequired,
  retryBrickMoldCall: PropTypes.func.isRequired,
  onAdaClick: PropTypes.func.isRequired,
  hasCustomColor: PropTypes.bool.isRequired,
  customPaintDeleteCheck: PropTypes.func.isRequired,
  openCustomColorDialogClick: PropTypes.func.isRequired,
};
