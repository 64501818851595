import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const ColumnHeader = styled("th")(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "10px",
  fontWeight: "500",
  backgroundColor: theme.palette.primary["100"],
  color: theme.palette.primary["700"],
  border: `solid 1px ${theme.palette.primary["300"]}`,
  padding: "8px",
}));

const CellContent = styled("td")(({ theme }) => ({
  fontSize: "12px",
  border: `solid 1px ${theme.palette.primary["300"]}`,
  padding: "16px 8px",
}));

const StyledTable = styled("table")(({ theme }) => ({
  backgroundColor: theme.palette.primary["400"],
  border: `solid 2px ${theme.palette.primary["400"]}`,
  borderCollapse: "collapse",
  width: "100%",
  maxWidth: "550px",
}));

export default function RoughOpening(props) {
  const { isLoading, roughOpeningError, handing, roughOpening, retryCall } =
    props;

  if (!isLoading && (isEmpty(roughOpening) || roughOpeningError)) {
    return (
      <Box style={{ textAlign: "center" }}>
        <Typography gutterBottom>
          An error occurred when loading your configuration’s rough dimensions.
        </Typography>
        <Button variant="outlined" onClick={retryCall}>
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <StyledTable data-test="rough-opening-table">
      <tbody>
        <tr>
          <ColumnHeader> Swing Type </ColumnHeader>
          <ColumnHeader> Unit Size </ColumnHeader>
          <ColumnHeader> Rough Opening </ColumnHeader>
          <ColumnHeader> OS Brickmold </ColumnHeader>
        </tr>
        <tr>
          <CellContent data-test="handing">
            {isLoading ? (
              <Skeleton variant="rectangular" height={18} width={107} />
            ) : (
              get(handing, "description", "N/A")
            )}
          </CellContent>
          <CellContent data-test="unit-size">
            {isLoading ? (
              <Skeleton variant="rectangular" height={18} width={107} />
            ) : (
              `W: ${roughOpening.unitWidth}" | H: ${roughOpening.unitHeight}"`
            )}
          </CellContent>
          <CellContent data-test="rough-opening">
            {isLoading ? (
              <Skeleton variant="rectangular" height={18} width={107} />
            ) : (
              `W: ${roughOpening.roughOpeningWidth}" | H: ${roughOpening.roughOpeningHeight}"`
            )}
          </CellContent>
          <CellContent data-test="os-brickmold">
            {isLoading ? (
              <Skeleton variant="rectangular" height={18} width={107} />
            ) : (
              `W: ${roughOpening.outsideBrickmoldWidth}" | H: ${roughOpening.outsideBrickmoldHeight}"`
            )}
          </CellContent>
        </tr>
      </tbody>
    </StyledTable>
  );
}

RoughOpening.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  roughOpeningError: PropTypes.string,
  handing: PropTypes.object.isRequired,
  retryCall: PropTypes.func.isRequired,
  roughOpening: PropTypes.shape({
    unitWidth: PropTypes.string,
    unitHeight: PropTypes.string,
    roughOpeningWidth: PropTypes.string,
    roughOpeningHeight: PropTypes.string,
    outsideBrickmoldWidth: PropTypes.string,
    outsideBrickmoldHeight: PropTypes.string,
  }),
};
