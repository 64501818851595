import validator from "validator";

const ZIP_CODE = /^\d{5}(-\d{4})?$/;
const ANY_NUMBERS = /\d+/;

const E_CANNOT_START_WITH_HYPHEN = "cannot start with hyphen";
const E_CANNOT_START_WITH_APOSTROPHE = "cannot start with apostrophe";
const E_CANNOT_END_WITH_HYPHEN = "cannot end with hyphen";
const E_CANNOT_END_WITH_APOSTROPHE = "cannot end with apostrophe";
const E_NAME = "contains invalid characters";
const E_CANNOT_BE_BLANK = "cannot be blank";

export function validateName(name, canBeBlank) {
  if (isEmpty(name)) {
    if (canBeBlank) {
      return E_CANNOT_BE_BLANK;
    } else {
      return null;
    }
  }
  if (name.startsWith("-")) {
    return E_CANNOT_START_WITH_HYPHEN;
  }
  if (name.startsWith("'")) {
    return E_CANNOT_START_WITH_APOSTROPHE;
  }
  if (name.endsWith("-")) {
    return E_CANNOT_END_WITH_HYPHEN;
  }
  if (name.endsWith("'")) {
    return E_CANNOT_END_WITH_APOSTROPHE;
  }
  if (!validator.isAlpha(name, "en-US", { ignore: "-'" })) {
    return E_NAME;
  }

  return null;
}

export function validateFullName(name) {
  if (isEmpty(name)) {
    return E_CANNOT_BE_BLANK;
  }
  if (name.startsWith("-")) {
    return E_CANNOT_START_WITH_HYPHEN;
  }
  if (name.startsWith("'")) {
    return E_CANNOT_START_WITH_APOSTROPHE;
  }
  if (name.endsWith("-")) {
    return E_CANNOT_END_WITH_HYPHEN;
  }
  if (name.endsWith("'")) {
    return E_CANNOT_END_WITH_APOSTROPHE;
  }
  if (!validator.isAlphanumeric(name, "en-US", { ignore: "-'# " })) {
    return E_NAME;
  }
  return null;
}

export function isNameValid(value, canBeBlank) {
  return !validateName(value, canBeBlank);
}

export function isFullNameValid(value) {
  return !validateFullName(value);
}

/**
 * The format of phoneNumber must be (123) 456-7890
 * in order to be validated correctly.
 * @param phoneNumber
 * @returns {boolean}
 */
export function isPhoneNumberValid(phoneNumber) {
  return phoneNumber && validator.isMobilePhone(phoneNumber, "en-US");
}

export function isUrlValid(url) {
  let trimmed = url.trim();

  //Accept empty url
  if (trimmed === "") {
    return true;
  } else {
    return validator.isURL(trimmed.toLowerCase());
  }
}

export function isNumberValid(value) {
  return value && validator.matches(value, ANY_NUMBERS);
}

export function isCityValid(city) {
  return city && !validator.matches(city, ANY_NUMBERS);
}

export function isZipValid(value) {
  return value && validator.matches(value, ZIP_CODE);
}

export function isEmailValid(email) {
  return email && validator.isEmail(email);
}

export function isQuoteNameValid(name) {
  let trimmed = name.trim();
  return trimmed !== "";
}

export function validateQuoteName(name) {
  if (name.match(/[^a-zA-Z0-9-.@&_ ]/)) {
    return "Quote name cannot contain any of the following characters: () , \\ / ~ * = + ? ! ; < > \" '";
  } else if (name.trim().length < 3) {
    return "Quote name must be at least 3 characters";
  } else {
    return "";
  }
}

export function isEmpty(value) {
  if (value === null || value === undefined || value === "") return true;
  if (typeof value === "string") {
    return validator.isEmpty(value.trim());
  }
  return false;
}
