import React, { Suspense } from "react";
import { use100vh } from "react-div-100vh";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { SnackbarProvider } from "notistack";
import { Spinner, parseDesignToken } from "@masonite-digital/common-components";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AuthContext } from "./AuthContext";
import { AuthProvider, Authority } from "./AuthProvider";
import history from "./History";
import WebSocket from "./WebSocketClient";
import Activities, { UnauthenticatedRoutes } from "./activities";
import ConsumerDetailPage from "./activities/Consumer/ConsumerDetailPage";
import PlaceHolderPage from "./activities/Miscellaneous/PlaceHolderPage";
import ConsumerDoorBuilder from "./activities/ProductBuilders/ExteriorPreHung/ConsumerDoorBuilder";
import Dashboard from "./activities/User/Drawer/Dashboard";
import AsyncLogin from "./activities/User/Login";
import { logout } from "./api";
import InitialContainer from "./components/Blocks/InitialContainer";
import UncaughtErrorBoundary from "./components/ErrorBoundaries/UncaughtErrorBoundary";
import designToken from "./design-token.json";
import { overridesTheme } from "./themeStyles";

function isAuthenticated() {
  return JSON.parse(sessionStorage.getItem("isAuthenticated"));
}

function isFullscreen(route) {
  return Boolean(route.fullscreen);
}

const toRoutes = (context, filter) => (activity) => {
  return activity.routes(context).filter(filter).map(toPrivateRoute);
};

function toPrivateRoute(route) {
  if (route.path) {
    return (
      <PrivateRoute
        key={route.path}
        exact
        path={route.path}
        component={route.component}
      />
    );
  } else {
    return route.items.map((item) => {
      if (item.path) {
        return (
          <PrivateRoute
            key={item.path}
            exact
            path={item.path}
            component={item.component}
          />
        );
      } else {
        return item.nestedListItems.map((nestedItem) => {
          return (
            <PrivateRoute
              key={nestedItem.path}
              exact
              path={nestedItem.path}
              component={nestedItem.component}
            />
          );
        });
      }
    });
  }
}

function toPublicRoute(route, i) {
  if (route.component) {
    return (
      <Route key={i} exact path={route.path} component={route.component} />
    );
  } else {
    return (
      <Route
        key={i}
        exact
        path={route.path}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }
}

const AppRouter = ({ handleTheming }) => {
  const context = React.useContext(AuthContext);

  return (
    <UncaughtErrorBoundary>
      {context.hasAuthority(Authority.FEATURE_NOTIFICATION) && (
        <WebSocket
          endpoint={window.__RUNTIME_CONFIG__.REACT_APP_WEBSOCKET_HOST}
        />
      )}
      <ConnectedRouter history={history}>
        <Suspense fallback={<Spinner fullScreen />}>
          <Switch>
            <Route exact path="/logout" component={logoutFlow} />
            {AsyncLogin.routes(context).map(toPublicRoute)}
            {!isAuthenticated() && (
              <InitialContainer>
                <Switch>
                  <Route
                    exact
                    path="/consumer/:resourceId"
                    component={ConsumerDetailPage}
                  />
                  <Route
                    exact
                    path="/door-builder/:resourceId"
                    component={ConsumerDoorBuilder}
                  />
                  <Route exact path="/expired" component={PlaceHolderPage} />
                  {UnauthenticatedRoutes.map(toPublicRoute)}
                  <Route component={PlaceHolderPage} />
                </Switch>
              </InitialContainer>
            )}
            {isAuthenticated() && (
              <Switch>
                {Activities.map(toRoutes(context, isFullscreen)).flat()}
                <Route
                  path="/"
                  render={() => {
                    return <Dashboard handleTheming={handleTheming} />;
                  }}
                />
              </Switch>
            )}
            <Route
              path="*"
              render={(renderProps) => (
                <Redirect
                  to={
                    isAuthenticated
                      ? "/"
                      : {
                          pathname: "/login",
                          state: { from: renderProps.location },
                        }
                  }
                />
              )}
            />
          </Switch>
        </Suspense>
      </ConnectedRouter>
    </UncaughtErrorBoundary>
  );
};

function logoutFlow() {
  logout();
  return <Redirect to="/login" />;
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default function App() {
  if (!window.localStorage.getItem("isLightTheme")) {
    window.localStorage.setItem("isLightTheme", JSON.stringify(true));
  }

  if (!window.localStorage.getItem("isInverted")) {
    window.localStorage.setItem("isInverted", JSON.stringify(false));
  }

  const isLightTheme = window.localStorage.getItem("isLightTheme") === "true";
  const initialTheme = parseDesignToken(designToken, isLightTheme);
  const height = use100vh();

  const [theme, setTheme] = React.useState({
    ...initialTheme,
    palette: {
      ...initialTheme.palette,
      innerHeight: height,
    },
    ...overridesTheme(initialTheme),
  });

  React.useEffect(() => {
    setTheme((previousState) => ({
      ...previousState,
      palette: {
        ...previousState.palette,
        innerHeight: height,
      },
    }));
  }, [height]);

  const onThemeChange = () => {
    if (window.localStorage.getItem("isLightTheme") === "true") {
      window.localStorage.setItem("isLightTheme", JSON.stringify(false));
      const darkTheme = parseDesignToken(designToken, false);

      setTheme({
        ...darkTheme,
        palette: {
          ...darkTheme.palette,
          innerHeight: height,
        },
        ...overridesTheme(darkTheme),
      });
    } else {
      window.localStorage.setItem("isLightTheme", JSON.stringify(true));
      const lightTheme = parseDesignToken(designToken, true);

      setTheme({
        ...lightTheme,
        palette: {
          ...lightTheme.palette,
          innerHeight: height,
        },
        ...overridesTheme(lightTheme),
      });
    }
  };

  return (
    <ThemeProvider theme={createTheme(theme)}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <AuthProvider>
          <AppRouter handleTheming={onThemeChange} />
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
