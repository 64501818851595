import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import ClickableCard from "./ClickableCard";
import CreditCardImage from "./images/credit-card-row.png";
import MasoniteCreditImage from "./images/masonite-credit-option.png";
import WarrantyIcon from "./images/warranty-icon.png";
import { PaymentType } from "./index";
import { AuthContext } from "../../AuthContext";
import { Type } from "../../AuthProvider";

function PaymentOptions({
  hasMasoniteCredit,
  paymentMethod,
  setPaymentMethod,
  isReorder,
}) {
  const context = React.useContext(AuthContext);

  const isCsr = context.whoAmI().type === Type.CSR;

  return (
    <Grid container spacing={2} justifyContent="space-around">
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          component="h2"
          color="textSecondary"
          gutterBottom
        >
          Choose A Payment Type:
        </Typography>
      </Grid>
      {isReorder && isCsr ? (
        <Grid item xs={12} md={6}>
          <ClickableCard
            altName="warranty-option"
            onClick={() => setPaymentMethod(PaymentType.WARRANTY)}
            imageSrc={WarrantyIcon}
            active={paymentMethod === PaymentType.WARRANTY}
            data-test="warranty-card"
            text="Warranty"
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={6}>
          {hasMasoniteCredit ? (
            <ClickableCard
              altName="masonite-option"
              onClick={() => setPaymentMethod(PaymentType.MASONITE_CREDIT)}
              imageSrc={MasoniteCreditImage}
              active={paymentMethod === PaymentType.MASONITE_CREDIT}
              data-test="masonite-card"
              text="Masonite Credit"
            />
          ) : (
            <ClickableCard
              altName="credit-card-option"
              onClick={() => setPaymentMethod(PaymentType.CREDIT_CARD)}
              imageSrc={CreditCardImage}
              active={paymentMethod === PaymentType.CREDIT_CARD}
              data-test="stripe-card"
              text="Credit Card"
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}

PaymentOptions.propTypes = {
  hasMasoniteCredit: PropTypes.bool,
  paymentMethod: PropTypes.string,
  setPaymentMethod: PropTypes.func.isRequired,
  isReorder: PropTypes.bool.isRequired,
};

export default PaymentOptions;
