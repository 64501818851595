import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CreditAmexIcon from "./Cards/credit-card-american-express.png";
import CreditDiscoverIcon from "./Cards/credit-card-discover.png";
import CreditMCardIcon from "./Cards/credit-card-mastercard.png";
import CreditIcon from "./Cards/credit-card-row.png";
import CreditVisaIcon from "./Cards/credit-card-visa.png";

const CreditCardTypeIcon = ({ sx, type }) => {
  const imgSrc = () => {
    switch (type && type.toUpperCase()) {
      case "AMERICAN EXPRESS": {
        return CreditAmexIcon;
      }
      case "DISCOVER": {
        return CreditDiscoverIcon;
      }
      case "MASTERCARD": {
        return CreditMCardIcon;
      }
      case "VISA": {
        return CreditVisaIcon;
      }
      default:
        return CreditIcon;
    }
  };

  return (
    <Box
      component="img"
      data-test="credit-card-logo"
      alt={type}
      src={imgSrc()}
      sx={sx}
    />
  );
};

CreditCardTypeIcon.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  type: PropTypes.string,
};

CreditCardTypeIcon.defaultProps = {
  sx: null,
  type: null,
};

export default CreditCardTypeIcon;
