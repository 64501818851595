import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import { styled } from "@mui/material/styles";
import DoorInfoBlock from "../Blocks/DoorInfoBlock";
import InfoBlock from "../Blocks/InfoBlock";

const StyledInfoBlock = styled(InfoBlock)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(2),
}));

export default function ContinueEditDialog(props) {
  const {
    open,
    onPrimaryClick,
    onSecondaryClick,
    glassDesignDescription,
    finish,
    slabDesign,
    sideliteGlassDesign,
    mode,
  } = props;

  const nonEditModeText = mode.isConsumerLead ? "consumer lead" : "";

  function handleSecondaryClick() {
    onPrimaryClick();
    onSecondaryClick();
  }

  return (
    <AlertDialog
      open={open}
      dialogType="WARNING"
      title="Continue Editing?"
      onPrimaryClick={onPrimaryClick}
      primaryLabel="Continue"
      onSecondaryClick={handleSecondaryClick}
      secondaryLabel="Start Over"
      contentText={`It seems like you were still editing a previous door configuration. Did you want to continue editing your previous configuration or start over using the default ${
        mode.isInEditMode ? "quoted door" : nonEditModeText
      } configuration?`}
      customContent={
        <StyledInfoBlock>
          <DoorInfoBlock
            slabDesign={slabDesign}
            sideliteGlass={sideliteGlassDesign}
            finish={finish}
            glassDesignDescription={glassDesignDescription}
          />
        </StyledInfoBlock>
      }
    />
  );
}

ContinueEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  glassDesignDescription: PropTypes.string,
  finish: PropTypes.object,
  slabDesign: PropTypes.object,
  sideliteGlassDesign: PropTypes.object,
  mode: PropTypes.object,
};
