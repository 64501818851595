import React from "react";
import PropTypes from "prop-types";
import FilterIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
import FlipIcon from "@mui/icons-material/SwapHoriz";
import ZoomIcon from "@mui/icons-material/ZoomIn";
import { IconButton, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { ResetStateDialog } from "../../../components/Dialogs";

function PreviewOptions({
  isLoading,
  activeStep,
  isFlipped,
  onFlipClick,
  onZoomClick,
  onResetClick,
  onFilterClick,
  mode,
}) {
  const theme = useTheme();
  const [openResetDialog, setOpenResetDialog] = React.useState(false);

  const isExtraSmallDown = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const listOfIcons = [
    {
      title: "Zoom",
      onClick: onZoomClick,
      dataTestTag: "zoom-btn",
      icon: ZoomIcon,
    },
    {
      title: "Flip",
      onClick: onFlipClick,
      dataTestTag: "flip-btn",
      hide: activeStep === 0,
      icon: FlipIcon,
    },
    {
      title: "Reset",
      onClick: () => {
        setOpenResetDialog(true);
      },
      dataTestTag: "reset-btn",
      icon: RefreshIcon,
    },
    {
      title: "Filter",
      onClick: onFilterClick,
      dataTestTag: "filter-btn",
      isMobileOnly: true,
      hide: activeStep !== 0,
      icon: FilterIcon,
    },
  ];

  return (
    <>
      <ResetStateDialog
        open={openResetDialog}
        onPrimaryClick={onResetClick}
        onSecondaryClick={() => setOpenResetDialog(false)}
        mode={mode}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            position: "absolute",
            right: 0,
          },
        }}
      >
        {listOfIcons.map((option, i) => {
          if ((!isExtraSmallDown && option.isMobileOnly) || option.hide) {
            return null;
          }

          const Component = option.icon;
          const isTitleFlipped = option.title === "Flip";
          const viewTitle = !isFlipped ? "View Interior" : "View Exterior";
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: theme.spacing(0, 3),
                [theme.breakpoints.down("md")]: {
                  padding: 1,
                  margin: 0,
                },
              }}
              key={i}
            >
              <IconButton
                data-test={option.dataTestTag}
                disabled={isLoading}
                onClick={option.onClick}
              >
                <Component
                  sx={{
                    color: "action.active",
                    fontSize: "33px",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "28px",
                    },
                  }}
                />
              </IconButton>
              <Typography
                align="center"
                variant="button"
                color="primary"
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: "12px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    display: "none",
                  },
                }}
              >
                {isTitleFlipped ? viewTitle : option.title}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

PreviewOptions.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  activeStep: PropTypes.number.isRequired,
  isFlipped: PropTypes.bool.isRequired,
  onFlipClick: PropTypes.func.isRequired,
  onZoomClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  mode: PropTypes.object.isRequired,
};

export default PreviewOptions;
