import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import {
  setAdaSelection,
  setBoreDetails,
  setBoreDetailsError,
  setBrickMoldError,
  setBrickMoldSelection,
  setBrickMolds,
  setCasingColorSelection,
  setCasingError,
  setCasingSelection,
  setCasings,
  setCutDownDoorHeights,
  setDefaultDoorError,
  setDefaultSpecs,
  setDimensionError,
  setDimensions,
  setDoorAvailability,
  setDoorDetailSelection,
  setDoorSpecOptions,
  setDoorSummary,
  setFinishError,
  setFinishSelection,
  setFinishes,
  setGetProductError,
  setGlassDesignError,
  setGlassDesignSelection,
  setGlassDesigns,
  setHandingError,
  setHardwareError,
  setHardwareSelection,
  setHardwares,
  setHasSelectionChanged,
  setHingeError,
  setHingeSelection,
  setHinges,
  setInactivePrepSelection,
  setInactivePreps,
  setJambDepthError,
  setMultipleLoadingStates,
  setPeepSiteError,
  setPeepSiteSelection,
  setPeepSites,
  setRoughOpening,
  setRoughOpeningError,
  setSideliteSelection,
  setSill,
  setSillError,
  setSlabDesignError,
  setSlabDesignSelection,
  setSlabDesigns,
  setSlabLayoutError,
  setSlabLayoutSelection,
  setSlabLayouts,
  startLoadingBoreDetails,
  startLoadingBrickMold,
  startLoadingCasings,
  startLoadingDefaultDoor,
  startLoadingDimensions,
  startLoadingFinishes,
  startLoadingGetProduct,
  startLoadingGlassDesigns,
  startLoadingHandings,
  startLoadingHardwares,
  startLoadingHinges,
  startLoadingJambDepths,
  startLoadingPeepSites,
  startLoadingRoughOpening,
  startLoadingSill,
  startLoadingSlabDesigns,
  startLoadingSlabLayouts,
} from "./exteriorPrehungActionCreators";
import {
  findValuesToArray,
  reloadOptionsOnSelect,
} from "./exteriorPrehungActions";
import consumerLeadFunctions from "../../activities/Consumer/consumerLeadFunctions";
import builderFunctions from "../../activities/ProductBuilders/common/builderFunctions";

/**
 * Chooses a compatible option from the possible list of items. If the currently selected item is compatible it will stay selected.
 * Otherwise this method will select a compatible item from the list of possibilities.
 * @param selected The currently selected item
 * @param possibles The newly loaded list of items against which to match
 * @param dispatch Method requires access to dispatch
 * @param action An action to dispatch
 * @param isSelectionEmpty Boolean on if the selections is empty
 * @returns {*}
 */
export function chooseCompatible(
  selected,
  possibles,
  dispatch,
  action,
  isSelectionEmpty,
) {
  const matchingSelectedOption = possibles.find((item) =>
    isEqual(item, selected),
  );

  if (
    matchingSelectedOption &&
    matchingSelectedOption.compatible === selected.compatible
  ) {
    if (isSelectionEmpty) {
      dispatch(action(matchingSelectedOption));
    } else {
      return matchingSelectedOption;
    }
  }

  const selectedItem = findMatchingItem(selected, possibles);
  if (!selectedItem) {
    throw new Error(
      "Selected item has no corresponding value in possibilities.",
    );
  }

  if (!selectedItem.compatible) {
    const firstCompatible = possibles.find((item) => item.compatible);
    if (firstCompatible) {
      dispatch(reloadOptionsOnSelect(firstCompatible.type, firstCompatible));
      return firstCompatible;
    } else {
      throw new Error("No compatible options found for " + selected.type);
    }
  } else {
    dispatch(action(selectedItem));
    return selectedItem;
  }
}

/**
 * Finds a matching item in a list based on resource id or parent resource id.
 * This is useful when a new list is loaded from an api call and the currently selected item needs to be located.
 * @param selected Currently selected item
 * @param items List of items in which to find a match
 * @returns the matching item from the list of items. Undefined if no match found
 */
function findMatchingItem(selected, items) {
  const foundItem = items.find(
    (item) => item.resourceId === selected.resourceId,
  );

  if (foundItem) {
    return foundItem;
  } else {
    if (
      selected.sideliteParentResourceId &&
      items[0].sideliteParentResourceId
    ) {
      return items.find(
        (item) =>
          item.sideliteParentResourceId === selected.sideliteParentResourceId &&
          item.parentResourceId === selected.parentResourceId &&
          item.compatible,
      );
    } else {
      return items.find(
        (item) =>
          item.parentResourceId === selected.parentResourceId &&
          item.compatible,
      );
    }
  }
}

/**
 * Puts together the specifications for getProduct()
 * @param selections
 * @returns {*[]}
 */
function buildProduct(selections) {
  const slabFinishes = {
    type: "SLAB_FINISH",
    description: "",
    exterior: selections.finish.doorExterior,
    interior: selections.finish.doorInterior,
    exteriorFrame: selections.finish.frameExterior,
    interiorFrame: selections.finish.frameInterior,
    weatherstrip: selections.finish.weatherStripping,
  };

  let specs = [
    selections.slabLayout,
    selections.slabDesign,
    selections.glassDesign,
    selections.glazingType,
    selections.slabWidth,
    selections.slabHeight,
    selections.jambDepth,
    selections.handing,
    selections.hardware,
    selections.hinge,
    slabFinishes,
  ];

  if (selections.slabLayout.hasSidelite) {
    specs.push(selections.sideLiteWidth);
    specs.push(selections.sideliteGlassDesign);
  }

  if (selections.hurricaneRated.value) {
    specs.push(selections.hurricaneRated);
  }

  if (!isEmpty(selections.cutDownDoorHeight)) {
    specs.push(selections.cutDownDoorHeight);
  }

  if (
    (selections.glassDesign.description !== "No Glass" ||
      selections.slabDesign.hasSideliteGlass) &&
    selections.impactRated.value
  ) {
    specs.push(selections.impactRated);
  }

  if (
    selections.handing.description.includes("Outswing") &&
    selections.isDoorCovered
  ) {
    specs.push(selections.isDoorCovered);
  }

  if (get(selections, "casing.description", "None").toUpperCase() !== "NONE") {
    selections.casing.finish = selections.finish.casingColor;
    specs.push(selections.casing);
  }

  if (
    get(selections, "brickMold.description", "NO BRICKMOLD").toUpperCase() !==
    "NO BRICKMOLD"
  ) {
    specs.push(selections.brickMold);
  }

  if (
    selections.slabLayout.slabType === "DOUBLE" &&
    selections.inactivePrep.description !== "Without Inactive Prep"
  ) {
    specs.push(selections.inactivePrep);
  }

  if (
    get(selections, "peepSite.description", "None").toUpperCase() !== "NONE"
  ) {
    selections.peepSite.ada = selections.adaCompliant;
    specs.push(selections.peepSite);
  }
  return specs;
}

export function fetchDefaultDoor() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingDefaultDoor(true));
      const response = await builderFunctions.getDefaultSpecs();
      dispatch(setDefaultSpecs(response));
      dispatch(setDefaultDoorError(null));
    } catch (error) {
      dispatch(
        setDefaultDoorError({
          ...error,
          type: "WARNING",
          message: error.message,
        }),
      );
    } finally {
      dispatch(startLoadingDefaultDoor(false));
    }
  };
}

export function fetchOptionsByFilter(
  hurricaneRatings,
  impactRatings,
  dimensions,
  type,
) {
  return async (dispatch, getState) => {
    try {
      dispatch(
        setMultipleLoadingStates({
          loadingSlabLayouts: true,
          loadingSlabDesigns: true,
          loadingGlassDesigns: true,
        }),
      );

      const selections = getState().exteriorPrehung.selections;
      const properties = [
        ...hurricaneRatings,
        ...impactRatings,
        ...dimensions,
        selections.slabLayout,
        selections.slabDesign,
        selections.glassDesign,
      ];

      const response = await builderFunctions.getFilteredOptions(
        properties,
        type,
      );
      dispatch(setSlabDesignError(null));
      dispatch(setGlassDesignError(null));

      if (isEmpty(response)) {
        dispatch(
          setSlabDesignError({
            type: "SEARCHED",
            title: "No Results Found",
            message: "0 items match the filters selected.",
            buttonText: "Edit Filters",
          }),
        );
        dispatch(
          setGlassDesignError({
            type: "SEARCHED",
            title: "No Results Found",
            message: "0 items match the filters selected.",
            buttonText: "Edit Filters",
          }),
        );
        return;
      }

      if (!isEmpty(response.slabLayouts)) {
        dispatch(setSlabLayouts(response.slabLayouts));
      }

      if (!isEmpty(response.selectedSlabLayout)) {
        dispatch(setSlabLayoutSelection(response.selectedSlabLayout[0]));
      }

      if (isEmpty(response.slabDesigns)) {
        dispatch(
          setSlabDesignError({
            type: "SEARCHED",
            title: "No Results Found",
            message: "0 items match the filters selected.",
            buttonText: "Edit Filters",
          }),
        );
      } else {
        dispatch(setSlabDesigns(response.slabDesigns));

        // If the selectedSlabDesign is returned, set the Slab Design to it.
        if (!isEmpty(response.selectedSlabDesign)) {
          dispatch(setSlabDesignSelection(response.selectedSlabDesign[0]));

          //Set glazingType
          const glazingType = get(
            getState().exteriorPrehung.selectionOptions,
            "doorSpecs.glazingTypes",
            [],
          ).find(
            (glaze) =>
              glaze.value === response.selectedSlabDesign[0].glazingType,
          );

          if (glazingType) {
            dispatch(setDoorDetailSelection({ glazingType }));
          }
        } else {
          // Find the same selected Slab Design in the new list of SlabDesigns.
          const selectedSlabDesign =
            getState().exteriorPrehung.selections.slabDesign;
          dispatch(
            setSlabDesignSelection(
              response.slabDesigns.find(
                (item) => item.resourceId === selectedSlabDesign.resourceId,
              ) || response.slabDesigns[0],
            ),
          );
        }
      }

      if (isEmpty(response.glassDesigns)) {
        dispatch(
          setGlassDesignError({
            type: "SEARCHED",
            title: "No Results Found",
            message: "0 items match the filters selected.",
            buttonText: "Edit Filters",
          }),
        );
      } else {
        dispatch(setGlassDesigns(response.glassDesigns));

        if (!isEmpty(response.selectedGlassDesign)) {
          // If the SlabLayout has a sidelite and the returned Glass Design's sideLiteGlassDesign is NOT empty,
          // set the sideliteGlassDesign.
          if (
            selections.slabLayout.sidelite &&
            !isEmpty(response.selectedGlassDesign[0].sideLiteGlassDesign)
          ) {
            dispatch(
              setSideliteSelection(
                response.selectedGlassDesign[0].sideLiteGlassDesign.find(
                  (glassDesign) =>
                    !isEmpty(selections.sideliteGlassDesign) &&
                    selections.sideliteGlassDesign.resourceId ===
                      glassDesign.resourceId,
                ) || response.selectedGlassDesign[0].sideLiteGlassDesign.find(
                      (glassDesign) => glassDesign.description === response.selectedGlassDesign[0].description
                  ) ||
                  //these door glass designs do not have a matching sidelite, their default should be "Clear Antique Black"
                  (["Tagus", "Luna", "Lyra", "Lumen", "Arlo", "Wren"].includes(
                      response.selectedGlassDesign[0].description
                  )
                      ? response.selectedGlassDesign[0].sideLiteGlassDesign.find(
                          (glassDesign) => glassDesign.description === "Clear Antique Black"
                      )
                      : response.selectedGlassDesign[0].sideLiteGlassDesign[0]),
              ),
            );
          }

          dispatch(setGlassDesignSelection(response.selectedGlassDesign[0]));
          if (response.selectedGlassDesign[0].glassImpactRatedOnly) {
            const foundImpactRating =
              getState().exteriorPrehung.selectionOptions.doorSpecs.impactRatings.find(
                (rating) => rating.value === true,
              );
            dispatch(
              setDoorDetailSelection({
                impactRated: foundImpactRating,
              }),
            );
          }
        } else {
          const selectedGlassDesign =
            response.glassDesigns.find(
              (item) =>
                item.resourceId ===
                getState().exteriorPrehung.selections.glassDesign.resourceId,
            ) || response.glassDesigns[0];
          dispatch(setGlassDesignSelection(selectedGlassDesign));
          if (
            selections.slabLayout.sidelite &&
            !isEmpty(selectedGlassDesign.sideLiteGlassDesign)
          ) {
            pickSideliteGlass(
              dispatch,
              selectedGlassDesign,
              getState().exteriorPrehung.selections.sideliteGlassDesign,
            );
          }
        }
      }
    } catch (error) {
      dispatch(setSlabDesigns([]));
      dispatch(setGlassDesigns([]));
      dispatch(
        setSlabDesignError({
          ...error,
          type: "WARNING",
          message: error.message,
          isFilterError: true,
        }),
      );
      dispatch(
        setGlassDesignError({
          ...error,
          type: "WARNING",
          message: error.message,
          isFilterError: true,
        }),
      );
    } finally {
      dispatch(
        setMultipleLoadingStates({
          loadingSlabLayouts: false,
          loadingSlabDesigns: false,
          loadingGlassDesigns: false,
        }),
      );
    }
  };
}

function pickSideliteGlass(
  dispatch,
  selectedGlassDesign,
  selectedSideliteGlassDesign,
) {
  const hasGlass = selectedGlassDesign.description !== "No Glass";
  const getNonGlassDescription = !isEmpty(selectedSideliteGlassDesign)
    ? selectedSideliteGlassDesign.description
    : "Naples";
  let selectedSidelite = selectedGlassDesign.sideLiteGlassDesign.find(
    (sidelite) => {
      //Currently the default sidelite glass design is Naples for doors with no glass.
      return (
        sidelite.description ===
        (hasGlass ? selectedSideliteGlassDesign.description : getNonGlassDescription)
      );
    },
  );
  if (isEmpty(selectedSidelite)) {
    selectedSidelite = selectedGlassDesign.sideLiteGlassDesign[0];
  }
  dispatch(setSideliteSelection(selectedSidelite));
}

export function fetchSlabLayouts(properties) {
  return async (dispatch, getState) => {
    try {
      const doorViewOptions = getState().exteriorPrehung.doorViewOptions;
      dispatch(startLoadingSlabLayouts(true));

      if (!properties) {
        const selections = getState().exteriorPrehung.selections;
        if (
          doorViewOptions.mode.isInEditMode ||
          doorViewOptions.mode.isConsumerLead
        ) {
          properties = {
            SLABDESIGN: selections.slabDesign,
            GLASSDESIGN: selections.glassDesign,
          };
        } else {
          properties = getState().exteriorPrehung.defaultSpecs;
        }
      }
      const slabs = await builderFunctions.getSlabLayouts([
        properties.SLABDESIGN,
        properties.GLASSDESIGN,
      ]);
      dispatch(setSlabLayouts(slabs));
      dispatch(setSlabLayoutError(null));

      if (isEmpty(slabs)) {
        dispatch(setSlabLayoutError({ type: "WARNING", message: null }));
      } else if (
        !doorViewOptions.mode.isInEditMode ||
        doorViewOptions.activeStep !== 2
      ) {
        chooseCompatible(
          isEmpty(getState().exteriorPrehung.selections.slabLayout)
            ? getState().exteriorPrehung.defaultSpecs.SLABLAYOUT
            : getState().exteriorPrehung.selections.slabLayout,
          slabs,
          dispatch,
          setSlabLayoutSelection,
          isEmpty(getState().exteriorPrehung.selections.slabLayout),
        );
      }
    } catch (error) {
      dispatch(setSlabLayouts([]));
      dispatch(
        setSlabLayoutError({
          ...error,
          type: "WARNING",
          message: error.message,
        }),
      );
    } finally {
      dispatch(startLoadingSlabLayouts(false));
    }
  };
}

export function fetchSlabDesigns(properties, dimensions = []) {
  return async (dispatch, getState) => {
    try {
      const doorViewOptions = getState().exteriorPrehung.doorViewOptions;
      dispatch(startLoadingSlabDesigns(true));

      if (!properties) {
        if (
          doorViewOptions.mode.isInEditMode ||
          doorViewOptions.mode.isConsumerLead
        ) {
          const selections = getState().exteriorPrehung.selections;
          const defaults = getState().exteriorPrehung.defaultSpecs;
          properties = {
            SLABLAYOUT: selections.slabLayout,
            GLASSDESIGN: selections.glassDesign,
            HURRICANERATED: defaults.HURRICANERATED,
            IMPACTRATED: defaults.IMPACTRATED,
          };
        } else {
          properties = getState().exteriorPrehung.defaultSpecs;
        }
      }

      let options = [
        properties.SLABLAYOUT,
        properties.GLASSDESIGN,
        ...dimensions,
      ];

      if (properties.HURRICANERATED.value) {
        options.push(properties.HURRICANERATED);
      }

      if (properties.IMPACTRATED.value) {
        options.push(properties.IMPACTRATED);
      }

      const slabDesigns = await builderFunctions.getSlabDesigns(options);
      dispatch(setSlabDesigns(slabDesigns));
      dispatch(setSlabDesignError(null));

      if (isEmpty(slabDesigns)) {
        dispatch(setSlabDesignError({ type: "WARNING", message: null }));
      } else if (
        !doorViewOptions.mode.isInEditMode ||
        doorViewOptions.activeStep !== 2
      ) {
        const slabDesignValue = chooseCompatible(
          isEmpty(getState().exteriorPrehung.selections.slabDesign)
            ? getState().exteriorPrehung.defaultSpecs.SLABDESIGN
            : getState().exteriorPrehung.selections.slabDesign,
          slabDesigns,
          dispatch,
          setSlabDesignSelection,
          isEmpty(getState().exteriorPrehung.selections.slabDesign),
        );

        //Set glazingType
        const glazingType = get(
          getState().exteriorPrehung.selectionOptions,
          "doorSpecs.glazingTypes",
          [getState().exteriorPrehung.defaultSpecs.GLAZINGTYPE],
        ).find((glaze) => glaze.value === slabDesignValue.glazingType);

        if (glazingType) {
          dispatch(setDoorDetailSelection({ glazingType }));
        }

        //If the selected slabDesign is not the same as panelValue
        if (
          !isEqual(
            slabDesignValue,
            getState().exteriorPrehung.selections.slabDesign,
          )
        ) {
          // If hurricane rated is selected in the filter, set hurricaneRated to true
          // else set hurricaneRated to false
          if (
            slabDesignValue.rateable &&
            getState().exteriorPrehung.filterSettings.hurricaneRated
          ) {
            dispatch(
              setDoorDetailSelection({
                hurricaneRated:
                  getState().exteriorPrehung.selectionOptions.doorSpecs.hurricaneRatings.find(
                    (option) => option.value === true,
                  ),
              }),
            );
          } else {
            dispatch(
              setDoorDetailSelection({
                hurricaneRated:
                  getState().exteriorPrehung.selectionOptions.doorSpecs.hurricaneRatings.find(
                    (option) => option.value === false,
                  ),
              }),
            );
          }
        }
      }
    } catch (error) {
      dispatch(
        setSlabDesignError({
          ...error,
          type: "WARNING",
          message: error.message,
        }),
      );
    } finally {
      dispatch(startLoadingSlabDesigns(false));
    }
  };
}

export function fetchRoughOpenings() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingRoughOpening(true));

      const properties = getState().exteriorPrehung.selections;

      let options = [
        properties.slabLayout,
        properties.slabWidth,
        properties.slabHeight,
        properties.handing,
        properties.isDoorCovered,
        properties.hurricaneRated,
      ];
      if (!isEmpty(properties.cutDownDoorHeight)) {
        options.push(properties.cutDownDoorHeight);
      }

      if (get(properties, "slabLayout.hasSidelite")) {
        options.push(properties.sideLiteWidth);
      }
      const response = await builderFunctions.getRoughOpenings(options);
      dispatch(setRoughOpening(response));
      dispatch(setRoughOpeningError(null));
    } catch (error) {
      dispatch(setRoughOpening({}));
      dispatch(setRoughOpeningError(error.message));
    } finally {
      dispatch(startLoadingRoughOpening(false));
    }
  };
}

export function fetchGlassDesigns(properties, dimensions = []) {
  return async (dispatch, getState) => {
    try {
      const doorViewOptions = getState().exteriorPrehung.doorViewOptions;
      dispatch(startLoadingGlassDesigns(true));

      if (!properties) {
        if (
          doorViewOptions.mode.isInEditMode ||
          doorViewOptions.mode.isConsumerLead
        ) {
          const selections = getState().exteriorPrehung.selections;
          const defaults = getState().exteriorPrehung.defaultSpecs;
          properties = {
            SLABLAYOUT: selections.slabLayout,
            SLABDESIGN: selections.slabDesign,
            IMPACTRATED: defaults.IMPACTRATED,
          };
        } else {
          properties = getState().exteriorPrehung.defaultSpecs;
        }
      }

      let options = [
        properties.SLABLAYOUT,
        properties.SLABDESIGN,
        ...dimensions,
      ];

      if (properties.IMPACTRATED.value) {
        options.push(properties.IMPACTRATED);
      }

      const glassDesigns = await builderFunctions.getGlassDesigns(options);

      dispatch(setGlassDesigns(glassDesigns));
      dispatch(setGlassDesignError(null));

      if (isEmpty(glassDesigns)) {
        dispatch(setGlassDesignError({ type: "WARNING", message: null }));
      } else if (
        !doorViewOptions.mode.isInEditMode ||
        doorViewOptions.activeStep !== 2
      ) {
        const glassValue = chooseCompatible(
          isEmpty(getState().exteriorPrehung.selections.glassDesign)
            ? getState().exteriorPrehung.defaultSpecs.GLASSDESIGN
            : getState().exteriorPrehung.selections.glassDesign,
          glassDesigns,
          dispatch,
          setGlassDesignSelection,
          isEmpty(getState().exteriorPrehung.selections.glassDesign),
        );

        const hasSideliteGlass =
          getState().exteriorPrehung.selections.slabDesign.hasSideliteGlass;

        if (properties.SIDELITEGLASSDESIGN) {
          dispatch(
            setSideliteSelection(
              glassValue.sideLiteGlassDesign.find(
                (design) =>
                  design.resourceId ===
                  properties.SIDELITEGLASSDESIGN.resourceId,
              ),
            ),
          );
        } else if (hasSideliteGlass) {
          // Sets sidelite on initial fetch
          pickSideliteGlass(
            dispatch,
            glassValue,
            getState().exteriorPrehung.selections.sideliteGlassDesign,
          );
        }

        // Set impactRated option in Step 2 to true if the selected glassDesign is impact rated only,
        // else set option to false.
        if (glassValue.glassImpactRatedOnly) {
          dispatch(
            setDoorDetailSelection({
              impactRated:
                getState().exteriorPrehung.selectionOptions.doorSpecs.impactRatings.find(
                  (option) => option.value === true,
                ),
            }),
          );
        } else {
          //Set impact-rated option to false if new option is non-ratable
          if (
            getState().exteriorPrehung.selections.impactRated.value &&
            !getState().exteriorPrehung.selections.hurricaneRated.value &&
            !glassValue.rateable
          ) {
            dispatch(
              setDoorDetailSelection({
                impactRated:
                  getState().exteriorPrehung.selectionOptions.doorSpecs.impactRatings.find(
                    (option) => option.value === false,
                  ),
              }),
            );
          }
        }
      }
    } catch (error) {
      dispatch(setGlassDesigns([]));
      dispatch(
        setGlassDesignError({
          ...error,
          type: "WARNING",
          message: error.message,
        }),
      );
    } finally {
      dispatch(startLoadingGlassDesigns(false));
    }
  };
}

export function fetchFinishes() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingFinishes(true));
      const mode = getState().exteriorPrehung.doorViewOptions.mode;
      const finishes = await builderFunctions.getFinishes();
      dispatch(setFinishes(finishes));
      dispatch(setFinishError(null));

      if (!isEmpty(finishes) && !mode.isInEditMode) {
        const defaultSpecs = getState().exteriorPrehung.defaultSpecs;

        if (mode.isConsumerLead) {
          const selectedWeatherStripping = finishes["WEATHERSTRIP"].find(
            (finish) =>
              finish.resourceId === defaultSpecs.WEATHERSTRIPPING.resourceId,
          );

          dispatch(
            setFinishSelection({
              weatherStripping: selectedWeatherStripping,
            }),
          );
        } else {
          let selectedFinish;

          if (defaultSpecs.FINISH.finishType === "CUSTOM_PAINT") {
            selectedFinish = finishes.PAINT.find(
              (paint) => paint.description === "Nimbus",
            );
          } else {
            selectedFinish = finishes[defaultSpecs.FINISH.finishType].find(
              (finish) => finish.resourceId === defaultSpecs.FINISH.resourceId,
            );
          }

          const selectedWeatherStripping = finishes["WEATHERSTRIP"].find(
            (finish) =>
              finish.resourceId === defaultSpecs.WEATHERSTRIPPING.resourceId,
          );

          dispatch(
            setFinishSelection({
              doorExterior: selectedFinish,
              doorInterior: selectedFinish,
              frameExterior: selectedFinish,
              frameInterior: selectedFinish,
              weatherStripping: selectedWeatherStripping,
              casingColor: selectedFinish,
            }),
          );
        }
      }
    } catch (error) {
      dispatch(setFinishes([]));
      dispatch(
        setFinishError({
          ...error,
          type: "WARNING",
          message: error.message,
        }),
      );
    } finally {
      dispatch(startLoadingFinishes(false));
    }
  };
}

export function fetchHardwares() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingHardwares(true));

      const hardwares = await builderFunctions.getHardwares();
      const selections = getState().exteriorPrehung.selections;

      dispatch(setHardwares(hardwares));
      dispatch(setHardwareError(null));

      if (!isEmpty(hardwares)) {
        if (!getState().exteriorPrehung.doorViewOptions.mode.isInEditMode) {
          const selectedHardware = isEmpty(
            getState().exteriorPrehung.selections.hardware,
          )
            ? getState().exteriorPrehung.defaultSpecs.HARDWAREPART
            : getState().exteriorPrehung.selections.hardware;

          let targetHardware = hardwares.find(
            (hardware) =>
              hardware.part.resourceId === selectedHardware.resourceId,
          );

          if (!targetHardware) {
            targetHardware = hardwares[0];
          } else {
            if (targetHardware.part.description === "None") {
              await dispatch(fetchBoreDetails());
            } else {
              // Set the option with the correct finish from the selected hardware finish.
              targetHardware.part.finish = targetHardware.finishes.find(
                (finish) =>
                  finish.resourceId === selectedHardware.finish.resourceId,
              );
            }
          }

          dispatch(setHardwareSelection(targetHardware.part));
        } else {
          if (!selections.hardware.defaultHingeFinishResourceId) {
            const updatedSelectedHardware =
              hardwares.find(
                (hardware) =>
                  hardware.part.resourceId === selections.hardware.resourceId,
              ) || hardwares[0];

            dispatch(setHardwareSelection(updatedSelectedHardware.part));
          }

          if (selections.hardware.description === "None") {
            await dispatch(fetchBoreDetails());
          }
        }
      }
    } catch (error) {
      dispatch(setHardwares([]));
      dispatch(setHardwareError(error.message));
    } finally {
      dispatch(startLoadingHardwares(false));
    }
  };
}

export function fetchBrickMold() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingBrickMold(true));

      const brickMolds = await builderFunctions.getBrickMolds();
      dispatch(setBrickMolds(brickMolds));
      dispatch(setBrickMoldError(null));

      const selectedBrickMold = getState().exteriorPrehung.selections.brickMold;

      if (getState().exteriorPrehung.doorViewOptions.mode.isInEditMode) {
        dispatch(
          setBrickMoldSelection(
            isEmpty(selectedBrickMold) ? brickMolds[0] : selectedBrickMold,
          ),
        );
      } else {
        dispatch(
          setBrickMoldSelection(
            isEmpty(selectedBrickMold)
              ? brickMolds.find(
                  (option) =>
                    option.resourceId ===
                    getState().exteriorPrehung.defaultSpecs.BRICKMOLD
                      .resourceId,
                )
              : selectedBrickMold,
          ),
        );
      }
    } catch (error) {
      dispatch(setBrickMolds([]));
      dispatch(setBrickMoldError(error.message));
    } finally {
      dispatch(startLoadingBrickMold(false));
    }
  };
}

export function fetchCasings() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingCasings(true));

      const casings = await builderFunctions.getCasings();
      dispatch(setCasings(casings));
      dispatch(setCasingError(null));

      const selectedCasing = getState().exteriorPrehung.selections.casing;
      const isInEditMode =
        getState().exteriorPrehung.doorViewOptions.mode.isInEditMode;

      dispatch(
        setCasingSelection(
          isEmpty(selectedCasing) ? casings[0] : selectedCasing,
        ),
      );
      if (isInEditMode && isEmpty(selectedCasing)) {
        const interiorFinish =
          getState().exteriorPrehung.selections.finish.frameInterior;
        dispatch(setCasingColorSelection(interiorFinish));
      }
    } catch (error) {
      dispatch(setCasings([]));
      dispatch(setCasingError(error.message));
    } finally {
      dispatch(startLoadingCasings(false));
    }
  };
}

/**
 * This fetches the dimensions for the Door Details in step 2.
 */
export function fetchCompatibleDimensions() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingDimensions(true));

      const properties = getState().exteriorPrehung.selections;

      let param = [
        properties.slabLayout,
        properties.slabDesign,
        properties.glassDesign,
      ];

      // Get filter selections
      const filterSettings = getState().exteriorPrehung.filterSettings;
      const options = getState().exteriorPrehung.selectionOptions;

      const foundHeights = findValuesToArray(
        options.doorSpecs.dimensions.slabHeights,
        filterSettings,
      );
      const foundWidths = findValuesToArray(
        options.doorSpecs.dimensions.slabWidths,
        filterSettings,
      );

      if (foundHeights.length > 0) {
        param.push(...foundHeights);
      }

      if (foundWidths.length > 0) {
        param.push(...foundWidths);
      }

      if (properties.slabLayout.hasSidelite) {
        param.push(properties.sideliteGlassDesign);
      }

      if (properties.hurricaneRated.value) {
        param.push(properties.hurricaneRated);
      }

      if (properties.glassDesign.glassImpactRatedOnly) {
        param.push(
          options.doorSpecs.impactRatings.find(
            (option) => option.value === true,
          ),
        );
      } else if (properties.impactRated.value) {
        param.push(properties.impactRated);
      }

      let compatibleDimensions = await builderFunctions.getDimensions(param);
      let compatibleDimensionMatrix =
        await builderFunctions.getDimensionCompatibleMatrix(param);

      dispatch(
        setDimensions({
          ...compatibleDimensions,
          matrix: compatibleDimensionMatrix,
        }),
      );
      dispatch(setDimensionError(null));

      // Don't set dimension selections on initial edit load, but allow setting dimensions on change i.e. not in the last step.
      const doorViewOptions = getState().exteriorPrehung.doorViewOptions;
      if (
        !doorViewOptions.mode.isInEditMode ||
        doorViewOptions.activeStep !== 2
      ) {
        const selections = getState().exteriorPrehung.selections;

        let dimensions = {
          slabWidth: compatibleDimensions.slabWidths.find(
            (option) =>
              option.resourceId === selections.slabWidth.resourceId &&
              option.validHeights.includes(selections.slabHeight.resourceId),
          ),
          slabHeight: compatibleDimensions.slabHeights.find(
            (option) =>
              option.resourceId === selections.slabHeight.resourceId &&
              option.validWidths.includes(selections.slabWidth.resourceId),
          ),
        };

        if (!dimensions.slabHeight) {
          dimensions.slabHeight = compatibleDimensions.slabHeights[0];
        }

        if (!dimensions.slabWidth) {
          dimensions.slabWidth =
            compatibleDimensions.slabWidths.find((option) =>
              dimensions.slabHeight.validWidths.find(
                (id) => id === option.resourceId,
              ),
            ) || compatibleDimensions.slabWidths[0];
        }

        if (compatibleDimensions.sideLiteWidths) {
          dimensions.sideLiteWidth = isEmpty(selections.sideLiteWidth)
            ? compatibleDimensions.sideLiteWidths[0]
            : compatibleDimensions.sideLiteWidths.find(
                (option) =>
                  option.resourceId === selections.sideLiteWidth.resourceId,
              ) || compatibleDimensions.sideLiteWidths[0];
        }

        dispatch(setDoorDetailSelection(dimensions));
        await dispatch(fetchBoreDetails());
      }

      await dispatch(fetchRoughOpenings());
    } catch (error) {
      dispatch(setDimensions([]));
      dispatch(setDimensionError(error.message));
    } finally {
      dispatch(startLoadingDimensions(false));
    }
  };
}

export function fetchPeepSites() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingPeepSites(true));

      const properties = getState().exteriorPrehung.selections;
      const finish =
        properties.hardware.description === "None"
          ? properties.hinge
          : properties.hardware.finish;

      let peepSites = await builderFunctions.getPeepSites([
        properties.slabDesign,
        properties.hurricaneRated,
        finish,
        properties.slabHeight,
      ]);

      dispatch(setPeepSites(peepSites));
      dispatch(setPeepSiteError(null));

      if (!isEmpty(peepSites)) {
        const selectedPeepSite = getState().exteriorPrehung.selections.peepSite;
        dispatch(
          setPeepSiteSelection(
            isEmpty(selectedPeepSite) ||
              !peepSites.find(
                (peepSite) =>
                  peepSite.resourceId === selectedPeepSite.resourceId,
              )
              ? peepSites[0]
              : selectedPeepSite,
          ),
        );

        dispatch(
          setAdaSelection(
            isEmpty(selectedPeepSite) ||
              !peepSites.find(
                (peepSite) =>
                  peepSite.resourceId === selectedPeepSite.resourceId,
              )
              ? false
              : selectedPeepSite.ada,
          ),
        );
      } else if (
        !getState().exteriorPrehung.doorViewOptions.mode.isInEditMode
      ) {
        dispatch(
          setPeepSiteSelection(
            getState().exteriorPrehung.selectionOptions.peepSites.filter((p) =>
              p.description.toLowerCase().includes("none"),
            )[0],
          ),
        );

        dispatch(setAdaSelection(false));
      }
    } catch (error) {
      dispatch(setPeepSites([]));
      dispatch(setPeepSiteError(error.message));
    } finally {
      dispatch(startLoadingPeepSites(false));
    }
  };
}

export function fetchHandings() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingHandings(true));

      const handings = await builderFunctions.getHandings();
      dispatch(setDoorSpecOptions({ handings }));
      dispatch(setHandingError(null));

      if (
        !isEmpty(handings) &&
        !getState().exteriorPrehung.doorViewOptions.mode.isInEditMode
      ) {
        const selectedHanding = getState().exteriorPrehung.selections.handing;

        dispatch(
          setDoorDetailSelection({
            handing: isEmpty(selectedHanding)
              ? handings.find(
                  (option) =>
                    option.resourceId ===
                    getState().exteriorPrehung.defaultSpecs.HANDING.resourceId,
                )
              : selectedHanding,
          }),
        );
      }
    } catch (error) {
      dispatch(setDoorSpecOptions({ handings: [] }));
      dispatch(setHandingError(error.message));
    } finally {
      dispatch(startLoadingHandings(false));
    }
  };
}

export function fetchJambDepths() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingJambDepths(true));

      const jambDepths = await builderFunctions.getJambDepths();
      dispatch(setDoorSpecOptions({ jambDepths }));
      dispatch(setJambDepthError(null));

      if (!getState().exteriorPrehung.doorViewOptions.mode.isInEditMode) {
        const selectedJambDepth =
          getState().exteriorPrehung.selections.jambDepth;

        dispatch(
          setDoorDetailSelection({
            jambDepth: isEmpty(selectedJambDepth)
              ? jambDepths.find(
                  (option) =>
                    option.resourceId ===
                    getState().exteriorPrehung.defaultSpecs.JAMBDEPTH
                      .resourceId,
                )
              : selectedJambDepth,
          }),
        );
      }
    } catch (error) {
      dispatch(setDoorSpecOptions({ jambDepths: [] }));
      dispatch(setJambDepthError(error.message));
    } finally {
      dispatch(startLoadingJambDepths(false));
    }
  };
}

/**
 * This fetches the dimensions for the Door Settings.
 */
export function fetchDimensions(slabLayout) {
  return async (dispatch, getState) => {
    try {
      if (isEmpty(slabLayout)) {
        slabLayout = getState().exteriorPrehung.defaultSpecs.SLABLAYOUT;
      }
      return await builderFunctions.getDimensions([slabLayout]);
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };
}

export function fetchCustomDoorHeights() {
  return async (dispatch, getState) => {
    try {
      const response = await builderFunctions.getCutDoorHeights();
      dispatch(setCutDownDoorHeights(response));
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };
}

export function fetchGlazingTypes() {
  return async (dispatch, getState) => {
    try {
      return await builderFunctions.getGlazingTypes();
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };
}

export function fetchImpactRatings() {
  return async (dispatch, getState) => {
    try {
      return await builderFunctions.getImpactRatings();
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };
}

export function fetchHurricaneRatings() {
  return async (dispatch, getState) => {
    try {
      return await builderFunctions.getHurricaneRatings();
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };
}

export function fetchIsDoorCovered() {
  return async (dispatch, getState) => {
    try {
      const doorCovered = await builderFunctions.getDoorCovered();
      dispatch(setDoorSpecOptions({ doorCovered }));

      const selectedIsDoorCovered =
        getState().exteriorPrehung.selections.isDoorCovered;

      dispatch(
        setDoorDetailSelection({
          isDoorCovered: isEmpty(selectedIsDoorCovered)
            ? doorCovered.find((option) => option.value === false)
            : selectedIsDoorCovered,
        }),
      );
    } catch (error) {
      console.error("error", error);
    }
  };
}

export function fetchInactivePreps() {
  return async (dispatch, getState) => {
    try {
      const inactivePreps = await builderFunctions.getInactivePreps();
      dispatch(setInactivePreps(inactivePreps));

      const selectedInactivePrep =
        getState().exteriorPrehung.selections.inactivePrep;

      dispatch(
        setInactivePrepSelection(
          isEmpty(selectedInactivePrep)
            ? inactivePreps[0]
            : selectedInactivePrep,
        ),
      );
    } catch (error) {
      console.error("error", error);
    }
  };
}

export function fetchHinges() {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingHinges(true));

      const hinges = await builderFunctions.getHingeFinishes();

      dispatch(setHingeError(null));
      dispatch(setHinges(hinges));
      if (isEmpty(getState().exteriorPrehung.selections.hinge)) {
        const selectedHinge = hinges.find(
          (hinge) =>
            hinge.resourceId ===
            getState().exteriorPrehung.selections.hardware
              .defaultHingeFinishResourceId,
        );
        dispatch(
          setHingeSelection(isEmpty(selectedHinge) ? hinges[0] : selectedHinge),
        );
      }
    } catch (error) {
      dispatch(setHinges([]));
      dispatch(setHingeError(error.message));
    } finally {
      dispatch(startLoadingHinges(false));
    }
  };
}

export function fetchSill() {
  return async (dispatch, getState) => {
    const properties = getState().exteriorPrehung.selections;

    try {
      dispatch(startLoadingSill(true));

      const sillData = await builderFunctions.getSill([
        properties.hurricaneRated,
        properties.hinge,
        properties.handing,
        properties.isDoorCovered,
      ]);
      if (isEmpty(sillData)) {
        dispatch(setSillError({ type: "WARNING", message: null }));
      } else {
        dispatch(setSill(sillData));
      }
    } catch (error) {
      setSillError({ type: "WARNING", message: error.message });
    } finally {
      dispatch(startLoadingSill(false));
    }
  };
}

export function fetchBoreDetails() {
  return async (dispatch, getState) => {
    const selections = getState().exteriorPrehung.selections;

    try {
      dispatch(startLoadingBoreDetails(true));

      const boreDetails = await builderFunctions.getBoreMeasurement([
        selections.slabHeight,
      ]);
      dispatch(setBoreDetails(boreDetails));
    } catch (e) {
      dispatch(setBoreDetailsError(e.message));
    } finally {
      dispatch(startLoadingBoreDetails(false));
    }
  };
}

/**
 *
 * getProduct returns shape: {
 *   product: {
 *     resource: {...},
 *     signature: {...}
 *   },
 *   available: bool
 * }
 *
 * Note: product is used to update orderItem in the backend.
 * @returns {function(...[*]=)}
 */
export function fetchProduct() {
  return async (dispatch, getState) => {
    try {
      if (
        !getState().exteriorPrehung.doorViewOptions.hasAnySelectionChanged &&
        !isEmpty(getState().exteriorPrehung.doorSummary)
      ) {
        return;
      }

      const mode = getState().exteriorPrehung.doorViewOptions.mode;
      dispatch(startLoadingGetProduct(true));
      const response = await builderFunctions.getProduct(
        buildProduct(getState().exteriorPrehung.selections),
        mode.isInEditMode ? mode.companyResourceId : null,
      );
      dispatch(setDoorSummary(response.product));
      dispatch(setDoorAvailability(response.available));
      dispatch(setGetProductError(null));
      dispatch(
        setHasSelectionChanged({
          hasAnySelectionChanged: false,
          stepThreeComplete: true,
        }),
      );
    } catch (error) {
      dispatch(setDoorSummary({}));
      dispatch(setGetProductError(error.message));
    } finally {
      dispatch(startLoadingGetProduct(false));
    }
  };
}

/**
 * Add door to cart for consumer leads
 * @param resourceId
 * @returns {function(...[*]=)}
 */
export function addDoorToCart(resourceId) {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoadingGetProduct(true));

      const selections = getState().exteriorPrehung.selections;
      let properties = [
        selections.slabLayout,
        selections.slabDesign,
        selections.glassDesign,
        selections.finish.doorExterior,
        selections.glazingType,
        selections.slabWidth,
        selections.slabHeight,
      ];

      if (selections.slabLayout.hasSidelite) {
        properties.push(selections.sideLiteWidth);
        properties.push(selections.sideliteGlassDesign);
      }

      if (selections.hurricaneRated.value) {
        properties.push(selections.hurricaneRated);
      }

      if (
        (selections.glassDesign.description !== "No Glass" ||
          selections.slabDesign.hasSideliteGlass) &&
        selections.impactRated.value
      ) {
        properties.push(selections.impactRated);
      }

      await consumerLeadFunctions.createLeadItem(resourceId, {
        specifications: properties,
      });
    } catch (error) {
      dispatch(startLoadingGetProduct(false));
      throw new Error(error.message);
    } finally {
      dispatch(startLoadingGetProduct(false));
    }
  };
}
