import loadable from "@loadable/component";
import { Authority } from "../../AuthProvider";

const LazyPrintQuoteOrderPage = loadable(() => import("./PrintQuoteOrderPage"));
const LazyPrintOrderInvoice = loadable(() => import("./PrintOrderInvoice"));
const LazyPrintReorderPage = loadable(() => import("./PrintReorderPage"));

export default class Print {
  static routes(context) {
    let printRoutes = [
      {
        path: "/quotes/fs/:resourceId/print",
        fullscreen: true,
        component: LazyPrintQuoteOrderPage,
      },
      {
        path: "/orders/fs/:resourceId/print",
        fullscreen: true,
        component: LazyPrintQuoteOrderPage,
      },
      {
        path: "/orders/fs/:resourceId/print/invoice",
        fullscreen: true,
        component: LazyPrintOrderInvoice,
      },
    ];

    if (context.hasAuthority(Authority.FEATURE_REORDER)) {
      printRoutes.push(
        {
          path: "/reorders/fs/:reorderResourceId/print",
          fullscreen: true,
          component: LazyPrintReorderPage,
        },
        {
          path: "/reorders/fs/:reorderResourceId/print/invoice",
          fullscreen: true,
          component: LazyPrintReorderPage,
        }
      );
    }

    return printRoutes;
  }
}
