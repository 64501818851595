import React from "react";
import { NumericFormat } from "react-number-format";

export const PercentMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  const handleOnChange = (values) => {
    if (onChange) {
      onChange(values.value);
    }
  };

  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      decimalScale={1}
      getInputRef={ref}
      onValueChange={handleOnChange}
      isAllowed={({ floatValue }) => floatValue <= 999}
    />
  );
});

export const PriceMaskCustom = React.forwardRef((props, ref) => {
  const { inputRef, limit, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      prefix="$"
      placeholder="$0.00"
      thousandSeparator
      onValueChange={(values) => onChange(values)}
      isAllowed={(values) =>
        limit >= values.floatValue || isNaN(values.floatValue)
      }
    />
  );
});

export const SizeLimitMaskCustom = React.forwardRef((props, ref) => {
  const { inputRef, limit, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      allowNegative={false}
      onValueChange={(values) => onChange(values)}
      isAllowed={(values) =>
        limit >= values.floatValue || isNaN(values.floatValue)
      }
    />
  );
});
