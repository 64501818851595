import React from "react";
import PropTypes from "prop-types";
import {
  AlertDialog,
  MultilineTextField,
} from "@masonite-digital/common-components";

export default function AddConsumerLeadNoteDialog(props) {
  const { value, open, onPrimaryClick, onSecondaryClick, onReload } = props;

  const [shouldRefresh, setShouldRefresh] = React.useState(false);
  const [note, setNote] = React.useState(value);
  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => {
    setNote(value);
  }, [value]);

  const handleTextFieldChange = (event) => {
    setNote(event.target.value);
    setErrorMessage(null);
  };

  const handleOnExited = async () => {
    setNote(value);
    setErrorMessage(null);

    if (shouldRefresh) {
      await onReload();
    }
  };

  const handleOnPrimaryClick = async () => {
    try {
      await onPrimaryClick(note);
      setShouldRefresh(true);
      onSecondaryClick();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  return (
    <AlertDialog
      primaryLabel="Save"
      title={`${value ? "Edit" : "Add a"} Note?`}
      open={open}
      onPrimaryClick={handleOnPrimaryClick}
      onSecondaryClick={onSecondaryClick}
      TransitionProps={{
        onExited: handleOnExited,
      }}
      customContent={
        <MultilineTextField
          autoFocus
          error={Boolean(errorMessage)}
          value={note}
          onChange={handleTextFieldChange}
          label="Message"
          placeholder="Leave a comment about your door ..."
          helperText={errorMessage ? errorMessage : " "}
        />
      }
    />
  );
}

AddConsumerLeadNoteDialog.propTypes = {
  value: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  onReload: PropTypes.func,
};
