import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";

export default function EditNotSupportedDialog(props) {
  const { open, onSecondaryClick, contentText } = props;

  return (
    <AlertDialog
      open={open}
      dialogType="WARNING"
      hidePrimaryButton
      secondaryLabel="Cancel"
      onSecondaryClick={onSecondaryClick}
      title="Edit Line Item is Not Supported"
      contentText={contentText}
    />
  );
}

EditNotSupportedDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  contentText: PropTypes.string.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};
