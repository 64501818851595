import React from "react";
import loadable from "@loadable/component";
import SpeedIcon from "@mui/icons-material/Speed";

const LazySalesInsightPage = loadable(() => import("./SalesInsightPage"));

export default class SalesInsight {
  static routes(context) {
    // const type = context.whoAmI().type;

    return [
      {
        groupName: "Dashboards",
        items: [
          {
            label: "Sales Insight",
            // TODO: remove the true condition if Sales Insight Dashboard is added back to the application.
            // !(type === Type.CSR || type === Type.SALES_REP) &&
            // !context.hasAuthority(Authority.FEATURE_SALES_INSIGHT),
            hide: true,
            icon: <SpeedIcon />,
            path: "/sales-insight",
            component: <LazySalesInsightPage />,
          },
        ],
      },
    ];
  }
}
