import React from "react";
import CheckmarkIcon from "@mui/icons-material/Check";
import { Box, Grid, Tooltip } from "@mui/material";
import Blues from "./images/Blues.jpg";
import Greens from "./images/Greens.jpg";
import Neutrals from "./images/Neutrals.jpg";
import Oranges from "./images/Oranges.jpg";
import Purples from "./images/Purples.jpg";
import Reds from "./images/Reds.jpg";
import WhitesPastels from "./images/WhitesPastels.jpg";
import Yellows from "./images/Yellows.jpg";

const COLOR_FAMILIES = [
  {
    family: "Reds",
    image: Reds,
  },
  {
    family: "Oranges",
    image: Oranges,
  },
  {
    family: "Yellows",
    image: Yellows,
  },
  {
    family: "Greens",
    image: Greens,
  },
  {
    family: "Blues",
    image: Blues,
  },
  {
    family: "Purples",
    image: Purples,
  },
  {
    family: "Neutrals",
    image: Neutrals,
  },
  {
    family: "Whites/Pastels",
    image: WhitesPastels,
  },
];

export default function ColorExploreWorkflow({
  currentStep,
  toggleChip,
  selectedColors,
  colorSwatches,
  selectFamily,
}) {
  function isSelected(color) {
    return selectedColors.some(function ({ colorNumber }) {
      return colorNumber === color.colorNumber;
    });
  }

  function CurrentStep() {
    return currentStep === 2 &&
      colorSwatches &&
      Object.values(colorSwatches).length > 0 ? (
      <ColorList />
    ) : (
      <FamilyList />
    );
  }

  function FamilyList() {
    return (
      <Grid container spacing={3}>
        {COLOR_FAMILIES.map((color, i) => {
          return (
            <Grid
              key={i}
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                data-test={`color-family-${color.family.toLowerCase()}`}
                onClick={async () => selectFamily(color.family)}
                width="80px"
                height="80px"
                src={color.image}
                alt=""
              />
              {color.family}
            </Grid>
          );
        })}
      </Grid>
    );
  }

  //calculations and values from https://www.w3.org/TR/WCAG20-TECHS/G17.html
  function getContrastColorClass(color) {
    // Set threshold for changing between light/dark
    const threshold = 2.9;

    // Comparing contrast ratio against white
    const compareBase = [255, 255, 255];

    let compareColor = [color.redValue, color.greenValue, color.blueValue];

    // get luminance
    const luminance = (r, g, b) => {
      let adjustedValues = [r, g, b].map((colorValue) => {
        colorValue /= 255;
        return colorValue <= 0.03928
          ? colorValue / 12.92
          : Math.pow((colorValue + 0.055) / 1.055, 2.4);
      });
      return (
        adjustedValues[0] * 0.2126 +
        adjustedValues[1] * 0.7152 +
        adjustedValues[2] * 0.0722
      );
    };

    // get contrast from luminance
    const contrast = (comparedColor) => {
      return (
        (luminance(compareBase[0], compareBase[1], compareBase[2]) + 0.05) /
        (luminance(comparedColor[0], comparedColor[1], comparedColor[2]) + 0.05)
      );
    };

    return contrast(compareColor) < threshold ? "dark" : "light";
  }

  function ColorList() {
    return (
      <div key={colorSwatches} data-test="add-custom-color-content">
        {Object.values(colorSwatches).map((groups, i) => {
          return (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 0fr)",
                padding: "10px 0",
                alignContent: "center",
              }}
              key={i}
            >
              {groups.map((color) => {
                return (
                  <Tooltip title={color.colorName} key={color.colorNumber}>
                    <Box
                      sx={(theme) => ({
                        alignItems: "center",
                        background: "#" + color.finish.hexCode,
                        border: isSelected(color)
                          ? `4px solid ${theme.palette.action.selected}`
                          : "inherit",
                        borderRadius: "4px",
                        color: isSelected(color)
                          ? getContrastColorClass(color)
                          : "inherit",
                        cursor: "pointer",
                        display: "flex",
                        height: "40px",
                        justifyContent: "center",
                        m: "4px",
                        padding: isSelected(color) ? "2px" : "inherit",
                        width: "40px",
                      })}
                      data-test={`color-tile-${color.colorNumber}`}
                      onClick={() => toggleChip(color)}
                    >
                      {isSelected(color) && <CheckmarkIcon />}
                    </Box>
                  </Tooltip>
                );
              })}
            </Box>
          );
        })}
      </div>
    );
  }

  return <CurrentStep />;
}
