import React from "react";
import PropTypes from "prop-types";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

const AddToExistingQuote = ({
  quotes,
  onChange,
  quoteSelected,
  addToNewQuote,
  error,
  isCustomPart,
}) => {
  return (
    <div>
      <DialogContentText style={{ marginTop: "16px", marginBottom: "16px" }}>
        <strong>
          Add your {isCustomPart ? "item" : "door"} to one of the existing
          quotes:
        </strong>
      </DialogContentText>
      <FormControl variant="standard" style={{ width: "100%" }} error={!!error}>
        <InputLabel shrink>Select a Quote</InputLabel>
        <Select
          SelectDisplayProps={{ "data-test": "existing-quote-dropdown" }}
          input={<Input autoFocus inputProps={{ className: "autoFocus" }} />}
          value={quoteSelected ? quoteSelected : -1}
          onChange={onChange}
        >
          <MenuItem disabled value={-1}>
            Select an existing quote...
          </MenuItem>
          {quotes.length > 0 &&
            quotes.map((quote, i) => {
              return (
                <MenuItem key={i} value={quote}>
                  {quote.name}
                </MenuItem>
              );
            })}
        </Select>
        {error && <FormHelperText> {error.message}</FormHelperText>}
      </FormControl>
      <Link
        sx={{
          marginTop: "8px",
          "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
          },
        }}
        underline="hover"
        component="button"
        data-test="add-to-new-quote-link"
        color="secondary"
        variant="subtitle2"
        onClick={addToNewQuote}
      >
        Add to new quote instead?
      </Link>
    </div>
  );
};

AddToExistingQuote.propTypes = {
  quotes: PropTypes.array.isRequired,
  quoteSelected: PropTypes.shape({ name: PropTypes.string.isRequired }),
  onChange: PropTypes.func.isRequired,
  addToNewQuote: PropTypes.func.isRequired,
  isCustomPart: PropTypes.bool,
};

export default AddToExistingQuote;
