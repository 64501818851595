import React from "react";
import PropTypes from "prop-types";
import OverflowIcon from "@mui/icons-material/MoreVert";
import { Menu as MaterialMenu } from "@mui/material";
import IconButton from "@mui/material/IconButton";

/**
 * Menu provides `close` as a public facing API used by MenuItem children
 * to close the menu when they take over functionality.
 */
class OverflowMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  /**
   * This is a public facing method.
   */
  close = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { dataTest, disabled, children } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div onClick={(e) => e.stopPropagation()}>
        <IconButton
          data-test={dataTest ? dataTest : "overflow-btn"}
          disabled={disabled}
          onClick={this.handleClick}
          aria-owns={open}
          aria-haspopup="true"
        >
          <OverflowIcon />
        </IconButton>
        <MaterialMenu
          data-test="item-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.close}
        >
          {children}
        </MaterialMenu>
      </div>
    );
  }
}

OverflowMenu.propTypes = {
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
};

export default OverflowMenu;
