import React from "react";
import loadable from "@loadable/component";
import DoorIcon from "@mui/icons-material/SensorDoorOutlined";
import { Authority } from "../../AuthProvider";

const LazyDoorBuilder = loadable(() => import("./ExteriorPreHung/DoorBuilder"));

export default class DoorBuilder {
  static routes(context) {
    if (context.hasAnyAuthority([Authority.FEATURE_PRODUCT_V3])) {
      return [
        {
          groupName: "Products",
          items: [
            {
              label: "Exterior Doors",
              icon: <DoorIcon />,
              disablePadding: true,
              path: "/products/residential/exterior/pre-hung",
              component: <LazyDoorBuilder />,
              alternativeRoutes: [
                {
                  disablePadding: true,
                  path: "/products/residential/exterior/pre-hung/:orderResourceId/items/:orderItemResourceId",
                },
                {
                  disablePadding: true,
                  path: "/products/residential/exterior/pre-hung/:leadResourceId/lead-items/:leadItemResourceId",
                },
              ],
            },
          ],
        },
      ];
    } else {
      return [];
    }
  }
}
