import AsyncActivity from "./Activity";
import AsyncConsumer from "./Consumer";
import AsyncContact from "./Contact";
import AsyncCustomParts from "./CustomParts";
import AsyncInquiries from "./Inquiries";
import AsyncMetrics from "./Metrics";
import AsyncOrder from "./Order";
import AsyncPrint from "./Print";
import AsyncBuilder from "./ProductBuilders";
import AsyncReorders from "./Reorders";
import AsyncSales from "./Sales";
import AsyncSalesInsight from "./SalesInsight";
import AsyncHome from "./User/Home";

/**
 * The list of activities to attempt to render paths for.
 */
const Activities = [
  AsyncHome,
  AsyncBuilder,
  AsyncCustomParts,
  AsyncMetrics,
  AsyncActivity,
  AsyncConsumer,
  AsyncOrder,
  AsyncPrint,
  AsyncContact,
  AsyncSales,
  AsyncSalesInsight,
  AsyncInquiries,
  AsyncReorders,
];

/**
 * The list of routes that a user may go to when they're unauthenticated.
 * Either from an email or copy and pasting the email link. The user will be
 * redirected to the login page instead of a page not found screen.
 */
export const UnauthenticatedRoutes = [
  {
    path: "/",
  },
  {
    path: "/sales",
  },
  {
    path: "/financing",
  },
  {
    path: "/inquiries",
  },
  {
    path: "/metrics",
  },
  {
    path: "/activity",
  },
  {
    path: "/sales-insight",
  },
  {
    path: "/consumer-leads",
  },
  {
    path: "/my-quotes",
  },
  {
    path: "/companies",
  },
  {
    path: "/employees/contractor",
  },
  {
    path: "/employees/csr",
  },
  {
    path: "/employees/all",
  },
  {
    path: "/products/custom-parts",
  },
  {
    path: "/products/residential/exterior/pre-hung",
  },
  {
    path: "/products/residential/exterior/pre-hung/:resourceId/items/:itemId",
  },
  {
    path: "/consumer-leads/:resourceId",
  },
  {
    path: "/orders/:resourceId",
  },
  {
    path: "/quotes/:resourceId",
  },
  {
    path: "/orders/:orderResourceId/items/:orderItemResourceId/inquiry",
  },
  {
    path: "/orders/:orderResourceId/items/:orderItemResourceId/inquiry/:inquiryId",
  },
  {
    path: "/payment/:resourceId",
  },
  {
    path: "/reorders/in-progress/:reorderResourceId",
  },
  {
    path: "/reorders/completed/:reorderResourceId",
  },
];

export default Activities;
