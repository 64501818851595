import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";

export class TransferFailedDialog extends React.Component {
  render() {
    const { open, orderType, refresh, onPrimaryClick } = this.props;

    return (
      <AlertDialog
        open={open}
        dialogType="ERROR"
        title="Transfer Failed"
        contentText={`The ${orderType} you tried to transfer has delayed items in it. Any available items are now consuming inventory. Please resolve them and try again.`}
        onPrimaryClick={onPrimaryClick}
        hideSecondaryButton
        TransitionProps={{
          onExited: refresh,
        }}
      />
    );
  }
}

TransferFailedDialog.propTypes = {
  open: PropTypes.bool,
  orderType: PropTypes.string,
  refresh: PropTypes.func,
  onPrimaryClick: PropTypes.func.isRequired,
};

TransferFailedDialog.defaultProps = {
  open: false,
  orderType: "quote",
};

export default TransferFailedDialog;
