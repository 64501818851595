import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import customPartReducer from "./customPartReducer";
import exteriorPrehungReducer from "./exteriorPrehungReducer";
import paymentReducer from "./paymentReducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    exteriorPrehung: exteriorPrehungReducer,
    payments: paymentReducer,
    customPart: customPartReducer,
  });

export default createRootReducer;
