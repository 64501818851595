import React from "react";
import MetricDashIcon from "mdi-material-ui/GoogleAnalytics";
import loadable from "@loadable/component";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import { Authority, Type } from "../../AuthProvider";

const LazyCsrMetricsPage = loadable(() => import("./Csr/CsrMetricsPage"));
const LazyContractorMetricsPage = loadable(() =>
  import("./Contractor/ContractorMetricsPage")
);
const LazyProductMetricsPage = loadable(() =>
  import("./Csr/ProductMetricsPage")
);

export default class Quote {
  static routes(context) {
    if (context.hasAuthority(Authority.ANALYTICS_PRODUCT_READ)) {
      return [
        {
          groupName: "Dashboards",
          items: [
            {
              label: "Metrics",
              icon: <MetricDashIcon />,
              path: "/metrics",
              component: <LazyCsrMetricsPage />,
            },
            {
              label: "Analytics",
              icon: <BubbleChartIcon />,
              path: "/analytics",
              component: <LazyProductMetricsPage />,
            },
          ],
        },
      ];
    }

    const type = context.whoAmI().type;
    if (type === Type.CSR || type === Type.SALES_REP) {
      return [
        {
          groupName: "Dashboards",
          items: [
            {
              label: "Metrics",
              icon: <MetricDashIcon />,
              path: "/metrics",
              component: <LazyCsrMetricsPage />,
            },
          ],
        },
      ];
    }

    return [
      {
        groupName: "Dashboards",
        items: [
          {
            label: "Metrics",
            icon: <MetricDashIcon />,
            path: "/metrics",
            component: <LazyContractorMetricsPage />,
          },
        ],
      },
    ];
  }
}
