import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import DoorPreview from "./DoorPreview";
import RoughOpening from "./RoughOpening";
import ZoomComponent from "./ZoomComponent";
import { googleNavClickEvent } from "../../../analytics/googleAnalyticsUtility";
import ImageNotAvailable from "../../../components/Icons/image-not-available.svg";
import SlabLayoutList from "../step1/SlabLayoutList";

export default function PreviewSection({
  isInverted,
  doorViewOptions,
  loadingStates,
  errorStates,
  selections,
  roughOpening,
  slabLayouts,
  getSlabLayouts,
  getRoughOpenings,
  setDoorPreview,
  setSettingsDrawer,
  onSlabLayoutClick,
  resetClick,
}) {
  const [isFlipped, setIsFlipped] = React.useState(false);
  const isDoorPreviewLoading = Object.values(loadingStates).some(
    (value) => value
  );
  const slideDirection = isInverted ? "left" : "right";
  const statusLabel = doorViewOptions.mode.isInEditMode
    ? "Currently Editing Line Item"
    : "Currently Configuring Consumer Lead";
  const zoomComponentImg = !isFlipped
    ? doorViewOptions.preview.frontImage
    : doorViewOptions.preview.backImage;

  const handleFlipDoor = (value) => {
    googleNavClickEvent({
      activeStep: doorViewOptions.activeStep,
      tabStep: doorViewOptions.tabIndex,
      action: "click flip door",
      isOpen: value,
    });

    setIsFlipped(value);
  };

  return (
    <Grid
      container
      item
      xs={12}
      direction={isInverted ? "row-reverse" : "row"}
      height="100%"
      overflow="auto"
    >
      {errorStates.slabDesignError ? (
        <Grid item container justifyContent="center" alignContent="center">
          <Grid item>
            <Box
              component="img"
              alt="No result found"
              src={ImageNotAvailable}
              sx={(theme) => ({
                width: theme.spacing(25),
              })}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Box
            sx={(theme) => ({
              position: "relative",
              minHeight: "100px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              pt: 2,
              mb: 2,
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            })}
          >
            <Collapse
              in={
                doorViewOptions.activeStep === 1 &&
                doorViewOptions.startCollapse
              }
              mountOnEnter
              unmountOnExit
            >
              {doorViewOptions.activeStep > 0 && (
                <RoughOpening
                  isLoading={
                    loadingStates.dimensions ||
                    loadingStates.loadingRoughOpening ||
                    loadingStates.loadingDoorDetails
                  }
                  roughOpeningError={errorStates.roughOpeningError}
                  roughOpening={roughOpening}
                  handing={selections.handing}
                  retryCall={getRoughOpenings}
                />
              )}
            </Collapse>
          </Box>
          <Slide
            in={doorViewOptions.activeStep === 0 && doorViewOptions.startSlide}
            direction={slideDirection}
            mountOnEnter
            unmountOnExit
            style={{ position: "absolute" }}
          >
            <Box
              pr={2}
              // needed 1000 to overpower mobile text
              zIndex={1000}
            >
              <SlabLayoutList
                isLoading={
                  loadingStates.loadingSlabLayouts ||
                  loadingStates.loadingExistingStateCheck ||
                  loadingStates.loadingRecoveryOptions ||
                  loadingStates.loadingEditableDoor
                }
                slabLayoutError={errorStates.slabLayoutError}
                slabLayouts={slabLayouts}
                onChange={onSlabLayoutClick}
                retryCall={getSlabLayouts}
                selected={selections.slabLayout}
              />
            </Box>
          </Slide>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            xs={12}
            sx={(theme) => ({
              position: "relative",
              pb: "26px",
              height: "calc(100% - 100px - 16px)",
              [theme.breakpoints.down("sm")]: {
                pb: 0,
                height: "100%",
              },
            })}
          >
            <DoorPreview
              isLoading={isDoorPreviewLoading}
              activeStep={doorViewOptions.activeStep}
              activeTabIndex={doorViewOptions.tabIndex}
              onZoomClick={() => setDoorPreview(true)}
              onFlipDoorClick={handleFlipDoor}
              onFilterClick={setSettingsDrawer}
              isFlipped={isFlipped}
              onResetClick={resetClick}
              hasSidelite={selections.slabDesign.hasSideliteGlass}
              finish={selections.finish}
              slabDesign={selections.slabDesign}
              slabLayout={selections.slabLayout}
              sideliteGlassDesign={selections.sideliteGlassDesign}
              mode={doorViewOptions.mode}
            />
            <ZoomComponent
              open={doorViewOptions.openPreview}
              title="Door Preview"
              previewImg={zoomComponentImg}
              isFlipped={isFlipped}
              handleClose={() => setDoorPreview(false)}
              finish={selections.finish}
              slabLayout={selections.slabLayout}
              slabDesign={selections.slabDesign}
              sideliteGlassDesign={selections.sideliteGlassDesign}
            />
            {(doorViewOptions.mode.isInEditMode ||
              doorViewOptions.mode.isConsumerLead) && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="absolute"
                bottom="0"
                backgroundColor="#ec7d21"
                height="26px"
                textAlign="center"
                fontSize="12px"
                width="100%"
                color="#fff"
              >
                <Typography variant="body2" data-test="editing-status-label">
                  {statusLabel}
                </Typography>
              </Box>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}

PreviewSection.propTypes = {
  isInverted: PropTypes.bool,
  hasCustomColor: PropTypes.bool.isRequired,
  doorViewOptions: PropTypes.object.isRequired,
  loadingStates: PropTypes.object.isRequired,
  errorStates: PropTypes.object.isRequired,
  selections: PropTypes.object.isRequired,
  roughOpening: PropTypes.object,
  slabLayouts: PropTypes.array.isRequired,
  getSlabLayouts: PropTypes.func.isRequired,
  getRoughOpenings: PropTypes.func,
  setDoorPreview: PropTypes.func.isRequired,
  setSettingsDrawer: PropTypes.func.isRequired,
  onSlabLayoutClick: PropTypes.func.isRequired,
  resetClick: PropTypes.func.isRequired,
};
