import React from "react";
import PropTypes from "prop-types";
import AccountPlusIcon from "@mui/icons-material/PersonAdd";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CreateOrEditConsumerLeadDialog from "./CreateOrEditConsumerLeadDialog";

export default function CreateConsumerLeadAction(props) {
  const { createConsumerLead, refresh, disabled } = props;
  const [open, setOpen] = React.useState(false);

  const handleCreateConsumerLead = async (newAccount) => {
    await createConsumerLead(newAccount);
    setOpen(false);
    await refresh();
  };

  return (
    <>
      <Tooltip title="Create Consumer Lead">
        <div>
          <IconButton
            data-test="add-consumer-lead-btn"
            disabled={disabled}
            onClick={() => setOpen(true)}
          >
            <AccountPlusIcon />
          </IconButton>
        </div>
      </Tooltip>
      <CreateOrEditConsumerLeadDialog
        open={open}
        onPrimaryClick={handleCreateConsumerLead}
        onSecondaryClick={() => setOpen(false)}
      />
    </>
  );
}

CreateConsumerLeadAction.propTypes = {
  createConsumerLead: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
