import React from "react";
import PropTypes from "prop-types";
import {
  AlertDialog,
  EmailTextField,
  NameTextField,
  PhoneNumberTextField,
} from "@masonite-digital/common-components";
import Grid from "@mui/material/Grid";
import { isEmailValid, isNameValid, isPhoneNumberValid } from "../../utils";

export default function CreateOrEditConsumerLeadDialog(props) {
  const {
    open,
    isEdit,
    onPrimaryClick,
    onSecondaryClick,
    defaultFirstName,
    defaultLastName,
    defaultEmail,
    defaultPhoneNumber,
  } = props;

  const [firstName, setFirstName] = React.useState(defaultFirstName);
  const [lastName, setLastName] = React.useState(defaultLastName);
  const [email, setEmail] = React.useState(defaultEmail);
  const [confirmEmail, setConfirmEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState(defaultPhoneNumber);

  const [error, setError] = React.useState(null);
  const [firstNameError, setFirstNameError] = React.useState(null);
  const [lastNameError, setLastNameError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [confirmEmailError, setConfirmEmailError] = React.useState(null);
  const [phoneError, setPhoneError] = React.useState(null);

  const titleText = isEdit
    ? "Edit Customer Information?"
    : "Send Invite to Customer?";
  const contentText = isEdit
    ? "Edit the customer's information:"
    : "Add the customer's information to send an invite by email:";

  const handleOnExit = () => {
    setError(null);
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
    setConfirmEmailError(null);
    setPhoneError(null);
    setFirstName(defaultFirstName);
    setLastName(defaultLastName);
    setEmail(defaultEmail);
    setConfirmEmail("");
    setPhoneNumber(defaultPhoneNumber);
  };

  const onEnter = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await handlePrimaryClick();
    }
  };

  const isEmailAndConfirmEmailSame = () => {
    return email.toUpperCase() === confirmEmail.toUpperCase();
  };

  const handlePrimaryClick = async () => {
    if (!isNameValid(firstName, true)) {
      setFirstNameError(true);
    }
    if (!isNameValid(lastName, true)) {
      setLastNameError(true);
    }
    if (!isEmailValid(email)) {
      setEmailError(true);
    }
    if (!isEdit) {
      if (!isEmailAndConfirmEmailSame()) {
        setConfirmEmailError(true);
      }
    }
    if (!isPhoneNumberValid(phoneNumber)) {
      setPhoneError(true);
    }

    if (
      !isNameValid(firstName, true) ||
      !isNameValid(lastName, true) ||
      !isEmailValid(email) ||
      !isPhoneNumberValid(phoneNumber) ||
      (!isEdit && !isEmailAndConfirmEmailSame())
    ) {
      return;
    }

    try {
      await onPrimaryClick({
        firstName,
        lastName,
        email,
        phoneNumber,
      });
    } catch (e) {
      setError(e.message);
    }
  };

  const handleFirstNameChange = (event) => {
    setError(null);
    setFirstNameError(null);
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setError(null);
    setLastNameError(null);
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setError(null);
    setEmailError(null);
    setEmail(event.target.value);
  };

  const handleConfirmEmailChange = (event) => {
    setError(null);
    setConfirmEmailError(null);
    setConfirmEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setError(null);
    setPhoneError(null);
    setPhoneNumber(event.target.value);
  };

  return (
    <AlertDialog
      open={open}
      title={titleText}
      contentText={contentText}
      primaryLabel={isEdit ? "Save" : "Send"}
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      helperText={error}
      TransitionProps={{
        onExited: handleOnExit,
      }}
      customContent={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <NameTextField
                autoFocus
                label="Customer First Name"
                value={firstName}
                error={Boolean(firstNameError)}
                onChange={handleFirstNameChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NameTextField
                label="Customer Last Name"
                value={lastName}
                error={Boolean(lastNameError)}
                onChange={handleLastNameChange}
              />
            </Grid>
          </Grid>
          <EmailTextField
            label="Customer Email Address"
            value={email}
            error={Boolean(emailError)}
            onChange={handleEmailChange}
          />
          {!isEdit && (
            <EmailTextField
              label="Confirm Customer Email Address"
              value={confirmEmail}
              error={Boolean(confirmEmailError)}
              onChange={handleConfirmEmailChange}
              dataTest="confirm-email-input"
            />
          )}
          <PhoneNumberTextField
            label="Customer Phone Number"
            value={phoneNumber}
            error={Boolean(phoneError)}
            onChange={handlePhoneChange}
            onKeyPress={onEnter}
          />
        </>
      }
    />
  );
}

CreateOrEditConsumerLeadDialog.defaultProps = {
  defaultFirstName: "",
  defaultLastName: "",
  defaultEmail: "",
  defaultPhoneNumber: "",
};

CreateOrEditConsumerLeadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  defaultFirstName: PropTypes.string,
  defaultLastName: PropTypes.string,
  defaultEmail: PropTypes.string,
  defaultPhoneNumber: PropTypes.string,
};
