import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";

export default function RemoveBehalfPaymentDialog(props) {
  const { open, onPrimaryClick, onSecondaryClick, onRefresh } = props;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [shouldRefresh, setShouldRefresh] = React.useState(false);

  const handlePrimaryClick = async () => {
    try {
      await onPrimaryClick();
      setShouldRefresh(true);
      onSecondaryClick();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const handleOnExited = async () => {
    if (shouldRefresh) {
      await onRefresh();
    }
  };

  return (
    <AlertDialog
      open={open}
      dialogType="WARNING"
      primaryLabel="Remove"
      onPrimaryClick={handlePrimaryClick}
      onSecondaryClick={onSecondaryClick}
      title="Remove Behalf Payment?"
      contentText="Are you sure you want to remove the Behalf payment from this order? This action will revert the order back to an open quote. Behalf must be contacted directly to resolve the in-review payment."
      helperText={errorMessage}
      TransitionProps={{
        onExited: handleOnExited,
      }}
    />
  );
}

RemoveBehalfPaymentDialog.propTypes = {
  open: PropTypes.bool,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};
