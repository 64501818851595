import React from "react";
import { ResultMessage } from "@masonite-digital/common-components";
import { Grid, Paper } from "@mui/material";

export default function PlaceHolderPage({ match }) {
  const isExpired = match.path === "/expired";

  return (
    <Grid item container justifyContent="center">
      <Paper
        sx={(theme) => ({
          padding: theme.spacing(3),
          minWidth: "600px",
          [theme.breakpoints.down("sm")]: {
            minWidth: "300px",
          },
        })}
      >
        <ResultMessage
          type={isExpired ? "WARNING" : "SEARCHED"}
          title={isExpired ? "Sorry, this link has expired" : "Page not found"}
          message={
            isExpired
              ? "Please contact your sales representative to refresh your link"
              : "We can't find the page you're looking for."
          }
        />
      </Paper>
    </Grid>
  );
}
