import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export default function MobileRoughOpening(props) {
  const { handing, roughOpening } = props;

  const list = [
    {
      label: "Swing Type",
      value: handing.description,
    },
    {
      label: "Unit Size",
      value: `W: ${roughOpening.unitWidth}" | H: ${roughOpening.unitHeight}"`,
    },
    {
      label: "Rough Opening",
      value: `W: ${roughOpening.roughOpeningWidth}" | H: ${roughOpening.roughOpeningHeight}"`,
    },
    {
      label: "OS Brickmold",
      value: `W: ${roughOpening.outsideBrickmoldWidth}" | H: ${roughOpening.outsideBrickmoldHeight}"`,
    },
  ];

  return (
    <List>
      {list.map((item, index) => (
        <Fragment key={index}>
          <ListItem>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{
                className: "label",
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={item.value} />
          </ListItem>
          {list.length - 1 !== index && <Divider />}
        </Fragment>
      ))}
    </List>
  );
}

MobileRoughOpening.propTypes = {
  handing: PropTypes.object.isRequired,
  roughOpening: PropTypes.object.isRequired,
};
