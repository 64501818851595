import React from "react";
import anime from "animejs";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import PreviewOptions from "./PreviewOptions";
import { googleContextClickEvent } from "../../../analytics/googleAnalyticsUtility";
import DoorSVG from "../../../components/Common/DoorSVG";

const imgContainerStyle = (theme) => ({
  transformStyle: "preserve-3d",
  display: "flex",
  justifyContent: "center",
  position: "relative",
  cursor: "zoom-in",
  height: "100%",
  width: "100%",
  maxHeight: "55vh",
  [theme.breakpoints.down("xl")]: {
    maxHeight: "50vh",
  },
  [theme.breakpoints.down("lg")]: {
    maxHeight: "40vh",
  },
  [theme.breakpoints.down("md")]: {
    maxHeight: "30vh",
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "20vh",
  },
});

const imgContainerDoubleDoorSideliteStyle = (theme) => ({
  transformStyle: "preserve-3d",
  display: "flex",
  justifyContent: "center",
  position: "relative",
  cursor: "zoom-in",
  height: "100%",
  width: "auto",
  maxHeight: "43vh",
  [theme.breakpoints.down("xl")]: {
    maxHeight: "37vh",
  },
  [theme.breakpoints.down("lg")]: {
    maxHeight: "30vh",
  },
  [theme.breakpoints.down("md")]: {
    maxHeight: "18vh",
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "20vh",
  },
});

const imgContainerDoubleDoorStyle = (theme) => ({
  transformStyle: "preserve-3d",
  display: "flex",
  justifyContent: "center",
  position: "relative",
  cursor: "zoom-in",
  height: "100%",
  width: "100%",
  maxHeight: "60vh",
  [theme.breakpoints.down("xl")]: {
    maxHeight: "40vh",
  },
  [theme.breakpoints.down("lg")]: {
    maxHeight: "34vh",
  },
  [theme.breakpoints.down("md")]: {
    maxHeight: "30vh",
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "18vh",
  },
});

const doorImgSize = (theme) => ({
  height: "100%",
  width: "auto",
  maxHeight: "55vh",
  [theme.breakpoints.down("xl")]: {
    maxHeight: "50vh",
  },
  [theme.breakpoints.down("lg")]: {
    maxHeight: "40vh",
  },
  [theme.breakpoints.down("md")]: {
    maxHeight: "30vh",
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "20vh",
  },
});

const doubleDoorDoubleSideliteImgSize = (theme) => ({
  height: "100%",
  width: "auto",
  maxHeight: "40vh",
  [theme.breakpoints.down("xl")]: {
    maxHeight: "29vh",
  },
  [theme.breakpoints.down("lg")]: {
    maxHeight: "27vh",
  },
  [theme.breakpoints.down("md")]: {
    maxHeight: "17vh",
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "20vh",
  },
});

const doubleDoorSideliteImgSize = (theme) => ({
  height: "100%",
  width: "auto",
  maxHeight: "40vh",
  [theme.breakpoints.down("xl")]: {
    maxHeight: "34vh",
  },
  [theme.breakpoints.down("lg")]: {
    maxHeight: "30vh",
  },
  [theme.breakpoints.down("md")]: {
    maxHeight: "18vh",
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "20vh",
  },
});

const CONTAINER = "container";

export default class DoorPreview extends React.Component {
  state = {
    isPlaying: false,
    exteriorVisible: true,
    interiorVisible: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.activeStep === 1 &&
      this.props.activeStep === 0 &&
      this.state.interiorVisible
    ) {
      this.localSwitchImage();
    }

    if (
      prevProps.finish &&
      (prevProps.finish.doorExterior.finishType !== "CUSTOM_PAINT" ||
        prevProps.finish.doorInterior.finishType !== "CUSTOM_PAINT") &&
      prevState.svgLoaded === true
    ) {
      this.setState({ svgLoaded: false });
    }
  }

  onResetClickHandler = () => {
    const { onResetClick, activeStep, activeTabIndex } = this.props;
    googleContextClickEvent({
      activeStep: activeStep,
      tabStep: activeTabIndex,
      action: "click reset",
    });

    onResetClick();
  };

  localSwitchImage = () => {
    if (this.state.isPlaying) {
      return;
    }

    this.setState({ isPlaying: true });

    let exteriorClass = "exteriorVisible";
    let interiorClass = "interiorVisible";

    let classToSet = !!this.state.exteriorVisible
      ? exteriorClass
      : interiorClass;
    let secondaryClassToSet =
      classToSet === exteriorClass ? interiorClass : exteriorClass;

    anime({
      targets: `.${classToSet}`,
      rotateY: { value: "+=180", delay: 200 },
      easing: "easeInOutSine",
      duration: 700,
    });

    setTimeout(() => {
      this.setState({
        exteriorVisible: !this.state.exteriorVisible,
        interiorVisible: !this.state.interiorVisible,
      });
    }, 500);

    anime({
      targets: `.${secondaryClassToSet}`,
      rotateY: { value: "+=180", delay: 200 },
      easing: "easeInOutSine",
      duration: 700,
      complete: () => {
        this.setState({ isPlaying: false });
      },
    });

    this.props.onFlipDoorClick(!this.props.isFlipped);
  };

  render() {
    const {
      isLoading,
      onZoomClick,
      activeStep,
      isFlipped,
      finish,
      slabDesign,
      slabLayout,
      sideliteGlassDesign,
      onFilterClick,
      mode,
    } = this.props;

    const doubleDoorStyle = (theme) =>
      slabLayout.sidelite
        ? imgContainerDoubleDoorSideliteStyle(theme)
        : imgContainerDoubleDoorStyle(theme);
    const isDoubleDoor = slabLayout.slabType === "DOUBLE";
    const doubleDoorSideliteStyle = (theme) =>
      slabLayout.sidelite === "B"
        ? doubleDoorDoubleSideliteImgSize(theme)
        : doubleDoorSideliteImgSize(theme);

    return (
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          textAlign: "center",
          height: "100%",
          [theme.breakpoints.down("sm")]: {
            display: "flex",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            padding: 0,
            width: "100%",
          },
        })}
      >
        <Box
          data-test="preview-image"
          className={CONTAINER}
          sx={isDoubleDoor ? doubleDoorStyle : imgContainerStyle}
          onClick={onZoomClick}
        >
          <>
            <div
              className="exteriorVisible"
              style={{
                visibility: this.state.exteriorVisible ? "visible" : "hidden",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DoorSVG
                isLoading={isLoading}
                isDoubleDoor={isDoubleDoor}
                slabLayout={slabLayout}
                slabDesign={slabDesign}
                sideliteGlassDesign={sideliteGlassDesign}
                selectedFinish={finish.doorExterior}
                sx={isDoubleDoor ? doubleDoorSideliteStyle : doorImgSize}
              />
            </div>
            <div
              className="interiorVisible"
              style={{
                visibility: this.state.interiorVisible ? "visible" : "hidden",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
              }}
            >
              <DoorSVG
                isBackImage
                isLoading={isLoading}
                isDoubleDoor={isDoubleDoor}
                slabLayout={slabLayout}
                slabDesign={slabDesign}
                sideliteGlassDesign={sideliteGlassDesign}
                selectedFinish={finish.doorInterior}
                sx={isDoubleDoor ? doubleDoorSideliteStyle : doorImgSize}
              />
            </div>
          </>
        </Box>
        <PreviewOptions
          isLoading={isLoading}
          activeStep={activeStep}
          onResetClick={this.onResetClickHandler}
          onZoomClick={onZoomClick}
          isFlipped={isFlipped}
          onFlipClick={() => this.localSwitchImage(`.${CONTAINER}`)}
          onFilterClick={onFilterClick}
          mode={mode}
        />
      </Box>
    );
  }
}

DoorPreview.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  activeStep: PropTypes.number.isRequired,
  activeTabIndex: PropTypes.number.isRequired,
  onResetClick: PropTypes.func.isRequired,
  onZoomClick: PropTypes.func.isRequired,
  isFlipped: PropTypes.bool.isRequired,
  onFlipDoorClick: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  finish: PropTypes.object,
  slabDesign: PropTypes.object,
  slabLayout: PropTypes.object,
};
