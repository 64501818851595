import React from "react";
import PropTypes from "prop-types";
import {
  CardCvcTextField,
  CardExpiryTextField,
  CardNumberTextField,
  NameTextField,
} from "@masonite-digital/common-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { isFullNameValid } from "../../utils";

const CreditCardDetail = React.forwardRef((props, ref) => {
  const { initialValues, setBillingName, setFormComplete } = props;

  const [creditCardNumberComplete, setCreditCardNumberComplete] =
    React.useState(false);
  const [expirationDateComplete, setExpirationDateComplete] =
    React.useState(false);
  const [cvcComplete, setCvcComplete] = React.useState(false);
  const [cardNameError, setCardNameError] = React.useState(false);
  const [cardNumberError, setCardNumberError] = React.useState(false);
  const [expiredError, setExpiredError] = React.useState(false);
  const [cvcError, setCvcError] = React.useState(false);

  const stripe = useStripe();
  const elements = useElements();

  React.useImperativeHandle(ref, () => ({
    createStripeToken: async (cardDetails) => {
      const payload = await stripe.createToken(
        elements.getElement(CardNumberElement),
        cardDetails
      );

      if (!payload.error) {
        return payload;
      } else {
        throw Error(payload.error.message);
      }
    },
  }));

  const handleCardNumberChange = (event) => {
    setCreditCardNumberComplete(event.complete);
    setCardNumberError(event?.error?.message);
    setFormComplete(event.complete && expirationDateComplete && cvcComplete);
  };

  const handleCardExpiryChange = (event) => {
    setExpirationDateComplete(event.complete);
    setExpiredError(event?.error?.message);
    setFormComplete(creditCardNumberComplete && event.complete && cvcComplete);
  };

  const handleCvcChange = (event) => {
    setCvcComplete(event.complete);
    setCvcError(event?.error?.message);
    setFormComplete(
      creditCardNumberComplete && expirationDateComplete && event.complete
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" component="div">
          Order By Credit Card
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NameTextField
          label="Cardholder Name"
          value={initialValues.cardholderName}
          onChange={(e) => {
            setCardNameError(!isFullNameValid(e.target.value));
            setBillingName(e.target.value);
          }}
          error={cardNameError}
        />
      </Grid>
      <Grid item xs={12} id={"card-number"}>
        <CardNumberTextField
          error={Boolean(cardNumberError)}
          labelErrorMessage={cardNumberError}
          onChange={handleCardNumberChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} id={"card-expiry"}>
        <CardExpiryTextField
          error={Boolean(expiredError)}
          labelErrorMessage={expiredError}
          onChange={handleCardExpiryChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} id={"card-cvc"}>
        <CardCvcTextField
          error={Boolean(cvcError)}
          labelErrorMessage={cvcError}
          onChange={handleCvcChange}
        />
      </Grid>
    </Grid>
  );
});

CreditCardDetail.propTypes = {
  setBillingName: PropTypes.func.isRequired,
  setFormComplete: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    cardholderName: PropTypes.string.isRequired,
  }),
};

export default CreditCardDetail;
