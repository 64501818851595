import React from "react";
import UpdateIcon from "@mui/icons-material/Update";
import { useTheme } from "@mui/material/styles";

export default function DelayedIcon(props) {
  const theme = useTheme();
  return (
    <UpdateIcon
      data-test="delayed-icon"
      style={{ color: theme.palette.error.dark }}
    />
  );
}
