import React from "react";
import loadable from "@loadable/component";
import SendIcon from "@mui/icons-material/Send";
import { Authority, Type } from "../../AuthProvider";

const LazyConsumerLeadsTablePage = loadable(() =>
  import("./ConsumerLeadsTablePage")
);
const LazyConsumerLeadsDetailPage = loadable(() =>
  import("./ConsumerLeadsDetailPage")
);

export default class Consumer {
  static routes(context) {
    if (context.hasAuthority(Authority.FEATURE_CONSUMER_LEADS)) {
      return [
        {
          groupName:
            context.whoAmI().type === Type.CONTRACTOR ? "My Items" : "Search",
          items: [
            {
              label: "Leads",
              icon: <SendIcon />,
              path: "/consumer-leads",
              component: <LazyConsumerLeadsTablePage />,
              alternativeRoutes: [
                {
                  path: "/consumer-leads/:resourceId",
                  component: <LazyConsumerLeadsDetailPage />,
                },
              ],
            },
          ],
        },
      ];
    }
    return [];
  }
}
