import React from "react";
import capitalize from "lodash/capitalize";
import { AlertDialog } from "@masonite-digital/common-components";

const DeleteActiveCustomColorDialog = ({
  open,
  closeModal,
  onCustomDelete,
  finishKeysToUpdate,
  finishSelections,
  setFinishSelection,
  newFinishKey,
}) => {
  const selectedSections = (section) => {
    let words = section.split(/(?=[A-Z])/);
    let capitalizedWords = words.map((word) => capitalize(word));
    return capitalizedWords.reduce((result, word) =>
      result ? result + " " + word : word
    );
  };

  const targetedColorDescription = () => {
    if (finishKeysToUpdate) {
      const targetColorObject =
        finishSelections[finishKeysToUpdate[0]].description;
      return targetColorObject ? `SW${targetColorObject.match(/\d{4}/)}` : "";
    } else {
      return "";
    }
  };

  function onPrimaryClick() {
    let newFinish = finishSelections[newFinishKey];

    finishKeysToUpdate.forEach((paint) => {
      setFinishSelection(paint, newFinish);
    });
    onCustomDelete(finishSelections[finishKeysToUpdate[0]]);
    closeModal();
  }

  return (
    <AlertDialog
      open={open}
      title="Remove Custom Color?"
      primaryLabel="REMOVE"
      onPrimaryClick={() => onPrimaryClick()}
      onSecondaryClick={() => closeModal()}
      customContent={
        <div>
          <p>
            The custom color <b> {targetedColorDescription()} </b> that you’re
            removing is currently being used for the following:
          </p>
          <ul>
            {finishKeysToUpdate &&
              finishKeysToUpdate.map((section, index) => (
                <li key={index}>{selectedSections(section)}</li>
              ))}
          </ul>
          <p>
            If you would like to continue, the areas where this color is being
            used will be replaced with your {selectedSections(newFinishKey)}{" "}
            color. How would you like to continue?
          </p>
        </div>
      }
    />
  );
};

export default DeleteActiveCustomColorDialog;
