import React from "react";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { ResultMessage } from "@masonite-digital/common-components";
import NotesIcon from "@mui/icons-material/Assignment";
import CalendarIcon from "@mui/icons-material/DateRange";
import EmailIcon from "@mui/icons-material/Email";
import LabelIcon from "@mui/icons-material/LocalOffer";
import LeaveAtSiteIcon from "@mui/icons-material/Note";
import AccountIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MapMarkerIcon from "@mui/icons-material/Room";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Avatar, Box } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ResetBilling from "./ResetBilling";
import { PaymentType } from "./index";
import ReorderComponentList from "../../activities/Reorders/ReorderComponentList";
import DoorSVG from "../../components/Common/DoorSVG";
import { formatDate, usd_decimal } from "../../utils/format";
import { normalizeDoorPayload } from "../../utils/normalizeDoorPayloads";

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export function formatCardDigits(last4, type) {
  if (type === "AMERICAN EXPRESS") {
    return `**** ****** *${last4}`;
  } else {
    return `**** **** **** ${last4}`;
  }
}

function formatAddress(address) {
  return (
    <div>
      <div>{address.line1 || "N/A"}</div>
      {address.line2 && <div>{address.line2}</div>}
      <div>{`${address.city || "N/A"}, ${address.state || "N/A"} ${
        address.zipCode || "N/A"
      }`}</div>
    </div>
  );
}

export default function PaymentReview({
  quote,
  shipping,
  inquiry,
  warranty,
  stripe,
  paymentType,
  tax,
  expirationTime,
  setActiveStep,
  isReorder,
  onPurchaseClick,
  onCancelClick,
}) {
  const hasWarrantyInfo = !(
    isEmpty(warranty.name) &&
    isEmpty(warranty.email) &&
    isEmpty(warranty.phone) &&
    isEmpty(warranty.line1)
  );
  const history = useHistory();
  const subtotal =
    paymentType === PaymentType.WARRANTY
      ? "$0.00"
      : quote.price.cost.displayValue;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Card>
          <CardHeader
            avatar={
              <Avatar>
                <ShoppingCartIcon />
              </Avatar>
            }
            title={
              <Typography variant="h5">
                Review {isReorder ? "Reorder" : "Payment"}
              </Typography>
            }
          />
          <CardContent>
            {expirationTime}
            <List>
              <ListItem>
                <ListItemIcon>
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${quote.name} - ${quote.referenceNumber}`}
                />
              </ListItem>
            </List>
            <StyledDivider />
            <List
              subheader={
                <ListSubheader>
                  <Grid container justifyContent="space-between">
                    <Grid item>SHIPPING</Grid>
                    <Grid item>
                      <Button
                        data-test="edit-shipping-btn"
                        color="secondary"
                        variant="text"
                        onClick={() => setActiveStep("Shipping")}
                      >
                        EDIT
                      </Button>
                    </Grid>
                  </Grid>
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemIcon>
                  <MapMarkerIcon />
                </ListItemIcon>
                <ListItemText
                  data-test="shipping-address"
                  primary={formatAddress(shipping)}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText
                  data-test="shipping-phone-number"
                  primary={shipping.phone}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Delivery Date">
                    <CalendarIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  data-test="delivery-date"
                  primary={
                    shipping.date !== "N/A"
                      ? formatDate(shipping.deliveryDate)
                      : shipping.deliveryDate
                  }
                />
              </ListItem>
              {shipping.leaveAtSite && (
                <ListItem>
                  <ListItemIcon>
                    <LeaveAtSiteIcon />
                  </ListItemIcon>
                  <ListItemText
                    data-test="leave-at-site-label"
                    primary="Deliver the item(s) even if I’m not present."
                  />
                </ListItem>
              )}
              {shipping.notes && (
                <ListItem>
                  <ListItemIcon>
                    <NotesIcon />
                  </ListItemIcon>
                  <ListItemText
                    data-test="shipping-notes"
                    primary={shipping.notes}
                  />
                </ListItem>
              )}
            </List>
            <StyledDivider />
            <List
              subheader={
                <ListSubheader>
                  <Grid container justifyContent="space-between">
                    <Grid item>BILLING</Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="text"
                        onClick={() => setActiveStep("Billing")}
                      >
                        EDIT
                      </Button>
                    </Grid>
                  </Grid>
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <ResetBilling
                      type={paymentType}
                      readOnly
                      cardInfo={stripe.token.card}
                      address={stripe}
                    />
                  }
                />
              </ListItem>
            </List>
            <StyledDivider />
            {paymentType !== PaymentType.WARRANTY && !isReorder && (
              <>
                <List
                  subheader={
                    <ListSubheader>
                      <Grid container justifyContent="space-between">
                        <Grid item>WARRANTY</Grid>
                        <Grid item>
                          {hasWarrantyInfo && (
                            <Button
                              color="secondary"
                              variant="text"
                              onClick={() => setActiveStep("Warranty")}
                            >
                              EDIT
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </ListSubheader>
                  }
                >
                  {!hasWarrantyInfo && (
                    <ResultMessage
                      type="INFO"
                      title="No Warranty"
                      message="No warranty information provided"
                      onClick={() => setActiveStep("Warranty")}
                      buttonText="Add Warranty"
                    />
                  )}
                  {warranty.name && (
                    <ListItem>
                      <ListItemIcon>
                        <AccountIcon />
                      </ListItemIcon>
                      <ListItemText primary={warranty.name} />
                    </ListItem>
                  )}
                  {warranty.email && (
                    <ListItem>
                      <ListItemIcon>
                        <EmailIcon />
                      </ListItemIcon>
                      <ListItemText primary={warranty.email} />
                    </ListItem>
                  )}
                  {warranty.phone && (
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText primary={warranty.phone} />
                    </ListItem>
                  )}
                  {warranty.line1 && (
                    <ListItem>
                      <ListItemIcon>
                        <MapMarkerIcon />
                      </ListItemIcon>
                      <ListItemText primary={formatAddress(warranty)} />
                    </ListItem>
                  )}
                </List>
                <StyledDivider />
              </>
            )}
            <List
              subheader={
                <ListSubheader disableGutters>
                  {isReorder ? "COMPONENTS" : "ITEMS"}
                </ListSubheader>
              }
            >
              {isReorder ? (
                <ListItem disablePadding>
                  <ReorderComponentList reorder={quote} />
                </ListItem>
              ) : (
                quote.items.map((item, index) => {
                  const normalizedDoor = normalizeDoorPayload(item);

                  const slabFinish = normalizedDoor.finish;
                  const sideliteGlassDesign = normalizedDoor.sideliteGlass;
                  const slabDesign = normalizedDoor.slabDesign;

                  return (
                    <ListItem key={index}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Grid
                            sx={(theme) => ({
                              width: "82px",
                              height: "82px",
                              [theme.breakpoints.down("sm")]: {
                                width: "48px",
                                height: "48px",
                              },
                            })}
                            container
                            justifyContent="center"
                            alignItems="center"
                            item
                          >
                            <DoorSVG
                              sx={(theme) => ({
                                width: "auto",
                                height: "66px",
                                [theme.breakpoints.down("sm")]: {
                                  height: "34px",
                                },
                              })}
                              slabDesign={slabDesign}
                              sideliteGlassDesign={sideliteGlassDesign}
                              selectedFinish={slabFinish}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          container
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Grid item xs={12} sm={10}>
                            {item.label && (
                              <Box mb={1}>
                                <Chip
                                  variant="outlined"
                                  avatar={<LabelIcon />}
                                  label={item.label}
                                />
                              </Box>
                            )}
                            <Typography variant={"button"}>
                              {get(
                                item.product,
                                "components.description",
                                item.product.description
                              )}
                            </Typography>
                            <Typography>QTY: {item.quantity}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Typography
                              sx={(theme) => ({
                                textAlign: "right",
                                [theme.breakpoints.down("sm")]: {
                                  textAlign: "left",
                                },
                              })}
                            >
                              <strong data-test="unit-cost">
                                {item.price.price.displayValue}{" "}
                              </strong>
                              / unit
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })
              )}
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card sx={{ mb: 1 }}>
          <CardHeader
            title={
              <Typography variant="h5">
                {isReorder ? "Review" : "Subtotal"}
              </Typography>
            }
          />
          <CardContent>
            {isReorder ? (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <List
                    disablePadding
                    subheader={
                      <ListSubheader disableGutters>
                        <Typography variant="overline" color="textSecondary">
                          LINKED TO
                        </Typography>
                      </ListSubheader>
                    }
                  >
                    <ListItem
                      button
                      disableGutters
                      style={{ backgroundColor: "transparent" }}
                      onClick={() =>
                        history.push(`/orders/${quote.resourceId}`)
                      }
                    >
                      <ListItemIcon>
                        <ReceiptIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={quote.name}
                        primaryTypographyProps={{ "data-test": "order-name" }}
                        secondary={quote.referenceNumber}
                        secondaryTypographyProps={{
                          "data-test": "order-reference-number",
                        }}
                      />
                    </ListItem>
                  </List>
                </Grid>
                {inquiry && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <List
                        disablePadding
                        subheader={
                          <ListSubheader disableGutters>
                            <Typography
                              variant="overline"
                              color="textSecondary"
                            >
                              Inquiry
                            </Typography>
                          </ListSubheader>
                        }
                      >
                        <ListItem
                          button
                          disableGutters
                          style={{ backgroundColor: "transparent" }}
                          onClick={() =>
                            history.push(
                              `/inquiries/${inquiry.resourceId}/orders/${quote.originalOrderResourceId}/items/${quote.items[0].resourceId}`
                            )
                          }
                        >
                          <ListItemIcon>
                            <ReceiptIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={inquiry.type}
                            primaryTypographyProps={{
                              "data-test": "inquiry-type",
                            }}
                            secondary={`Sent: ${inquiry.audit.createdDate}`}
                            secondaryTypographyProps={{
                              "data-test": "inquiry-sent-date",
                            }}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={6} container justifyContent="flex-end">
                    Subtotal
                  </Grid>
                  <Grid
                    data-test="subtotal"
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                  >
                    {quote.price.cost.displayValue}
                  </Grid>
                  <Grid item xs={6} container justifyContent="flex-end">
                    Sales Tax
                  </Grid>
                  <Grid
                    data-test={"sales-tax"}
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                  >
                    {usd_decimal(tax.estimatedTax)}
                  </Grid>
                  {paymentType === PaymentType.WARRANTY && (
                    <Grid container spacing={1}>
                      <Grid item xs={6} container justifyContent="flex-end">
                        Discount
                      </Grid>
                      <Grid
                        data-test="discount"
                        item
                        xs={6}
                        color="red"
                        container
                        justifyContent="flex-end"
                      >
                        -{quote.price.cost.displayValue}
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    Total
                  </Grid>
                  <Grid item xs={6} container justifyContent="flex-end">
                    <Typography data-test="order-total" variant="h6">
                      {usd_decimal(tax.orderTotal)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1} mb={1}>
                  <Grid item xs={6} container justifyContent="flex-end">
                    Shipping
                  </Grid>
                  <Grid
                    data-test={"shipping"}
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                  >
                    $0.00
                  </Grid>
                  <Grid item xs={6} container justifyContent="flex-end">
                    Subtotal
                  </Grid>
                  <Grid
                    data-test={"subtotal"}
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                  >
                    {subtotal}
                  </Grid>
                  <Grid item xs={6} container justifyContent="flex-end">
                    Sales Tax
                  </Grid>
                  <Grid
                    data-test={"sales-tax"}
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                  >
                    {usd_decimal(tax.estimatedTax)}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    Total
                  </Grid>
                  <Grid item xs={6} container justifyContent="flex-end">
                    <Typography data-test="order-total" variant="h6">
                      {usd_decimal(tax.orderTotal)}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            <Button
              fullWidth
              sx={{ mb: 1 }}
              data-test="complete-order-btn"
              color="secondary"
              variant="contained"
              onClick={onPurchaseClick}
            >
              Complete Order
            </Button>
            <Button
              fullWidth
              data-test="cancel-btn"
              variant="outlined"
              onClick={onCancelClick}
            >
              Cancel
            </Button>
          </CardContent>
        </Card>
        {!isReorder && (
          <>
            <Typography align="center" variant="body2">
              <strong>Need help with your order?</strong>
            </Typography>
            <Typography align="center" variant="body2">
              <span>Give us a call at </span>
              <Link data-test="phone-number-link" href="tel:813-261-3580">
                813-261-3580
              </Link>
              <span>, or email us at </span>
              <Link
                data-test="email-link"
                href="mailto:CustomerCare@Advisar.com"
              >
                CustomerCare@Advisar.com
              </Link>
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
}

PaymentReview.propTypes = {
  quote: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  inquiry: PropTypes.object,
  isReorder: PropTypes.bool,
  paymentType: PropTypes.string.isRequired,
  shipping: PropTypes.shape({
    name: PropTypes.string.isRequired,
    line1: PropTypes.string.isRequired,
    line2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    notes: PropTypes.string,
    date: PropTypes.string,
    leaveAtSite: PropTypes.bool,
  }).isRequired,
  tax: PropTypes.shape({
    estimatedTax: PropTypes.number.isRequired,
    orderTotal: PropTypes.number.isRequired,
  }),
  warranty: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  stripe: PropTypes.shape({
    token: PropTypes.shape({
      card: PropTypes.shape({
        last4: PropTypes.string.isRequired,
        brand: PropTypes.string.isRequired,
      }),
    }).isRequired,
    zipCode: PropTypes.string.isRequired,
  }),
  setActiveStep: PropTypes.func.isRequired,
  onPurchaseClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  expirationTime: PropTypes.node.isRequired,
};
