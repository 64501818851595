import React from "react";
import PropTypes from "prop-types";
import NoteIcon from "@mui/icons-material/Note";
import { ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";

export default function ExpandNotes(props) {
  const { dataTest, notes, toolTipText = "Notes" } = props;
  const [expanded, setExpanded] = React.useState(false);

  const onExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <ListItem button onClick={onExpand}>
      <Tooltip title={toolTipText}>
        <ListItemIcon>
          <NoteIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText
        sx={{
          overflow: expanded ? "unset" : "hidden",
          textOverflow: expanded ? "unset" : "ellipsis",
          whiteSpace: expanded ? "unset" : "nowrap",
        }}
        primaryTypographyProps={{
          "data-test": dataTest,
        }}
        primary={notes}
      />
    </ListItem>
  );
}

ExpandNotes.propTypes = {
  notes: PropTypes.string,
  toolTipText: PropTypes.string,
};
