import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export const PEEP_SITE_LOCATION = '60" from bottom of door';
export const ADA_PEEP_SITE_LOCATION = '40" from bottom of door';

/**
 * Version 1.0.0 & 1.1.0: Uses payload
 * Version 1.2.0: Uses componentData and payload
 *
 * Does not have:
 *  roughOpening
 *  customHeight
 *  approvalCode
 */
export const getExteriorDoorVersion1 = (payload) => {
  const {
    addOnsDesc,
    configurations,
    glassFamilyDesc,
    hardwareDesc,
    panelDesc,
    packageDesc,
    sideliteFamilyDesc,
    slabUnitDesc,
    isDoorCovered,
  } = payload.payload;

  function getItemByPartType(type) {
    return get(payload, "component.componentData", []).find(
      (item) => item.partType === type
    );
  }

  const door = {
    description: panelDesc.type,
    slabDesignDescription: get(payload, "component.description", "N/A"),
    links: payload.component
      ? [
          {
            href:
              "https://images.advisar.com/products/exterior-door/preview/" +
              getItemByPartType("Door").frontImage.file,
          },
        ]
      : [],
  };
  const doorGlass = glassFamilyDesc
    ? {
        doorGlassName: glassFamilyDesc.description,
        doorGlassSize: panelDesc.glassSize,
        glazingType:
          glassFamilyDesc.glazingType === "lip-lite"
            ? "Lip Lite Glazing"
            : "Flushed Glazing",
      }
    : {};
  const sidelite = sideliteFamilyDesc ? { slSize: panelDesc.slSize } : {};
  const sideliteGlass = sideliteFamilyDesc
    ? {
        slGlassLayout: panelDesc.slType,
        slGlassName: sideliteFamilyDesc.description,
        slGlassSize: panelDesc.slGlassSize,
      }
    : {};
  const finish = {
    exteriorFinish: {
      description: configurations.exteriorFinish,
    },
    interiorFinish: {
      description: configurations.interiorFinish,
    },
    exteriorFrameFinish: {
      description: configurations.frameFinish,
    },
    interiorFrameFinish: {
      description: configurations.stopFrameFinish,
    },
    weatherStrip: {
      description: configurations.weatherStrip,
    },
  };

  // 1.2.0 has image links
  if (payload.component != null) {
    finish.exteriorFinish.links = [
      {
        href: get(
          getItemByPartType("Door"),
          "exteriorDoorColor.image.file",
          ""
        ),
      },
    ];
    finish.interiorFinish.links = [
      {
        href: get(
          getItemByPartType("Door"),
          "interiorDoorColor.image.file",
          ""
        ),
      },
    ];
    finish.exteriorFrameFinish.links = [
      {
        href: get(
          getItemByPartType("Frame"),
          "exteriorFrameColor.image.file",
          ""
        ),
      },
    ];
    finish.interiorFrameFinish.links = [
      {
        href: get(
          getItemByPartType("Frame"),
          "interiorFrameColor.image.file",
          ""
        ),
      },
    ];
    finish.weatherStrip.links = [
      { href: get(getItemByPartType("WeatherStrip"), "image", "") },
    ];
  }

  // 1.0.0 does not have casing
  const casing = {
    description: configurations.casingProfile || "None",
    finish: configurations.casingFinish || "N/A",
  };
  const frame = {
    jambDepth: configurations.frameType,
    handing: configurations.handing,
    isDoorCovered: isDoorCovered ? "Yes" : "No",
  };
  const hardware = {
    description: `${hardwareDesc.manufacturer} ${hardwareDesc.lockStyle}  ${hardwareDesc.lever}`,
    finish: hardwareDesc.finish,
  };
  const brickMold = {
    description: isEmpty(configurations.brickMold)
      ? "No"
      : configurations.brickMold,
  };
  const inactivePrep = {
    description: isEmpty(configurations.inactivePrep)
      ? "No"
      : configurations.inactivePrep,
  };
  const peepSite = {
    description: isEmpty(addOnsDesc.peepSite) ? "No" : addOnsDesc.peepSite,
    adaPeepSite: addOnsDesc.adaPeepSite,
  };
  const doorWidth = get(panelDesc, "width", "N/A");
  const doorHeight = get(panelDesc, "height", "N/A");
  const doorThickness = get(panelDesc, "thickness", "N/A");

  const isDoubleDoor = slabUnitDesc.type === "DOUBLE";
  const hasDoorGlass = !isEmpty(glassFamilyDesc);
  const hasSidelite = !isEmpty(sideliteFamilyDesc);
  const isHvhz = get(panelDesc, "hvhz");
  const isDoorGlassImpactRated = configurations.glassType.includes("Impact");
  const isSlGlassImpactRated = packageDesc.isImpactRated;
  const isOutSwing = configurations.handing.includes("Outswing");

  return {
    isDoubleDoor,
    hasDoorGlass,
    hasSidelite,
    isHvhz,
    isDoorGlassImpactRated,
    isSlGlassImpactRated,
    isOutSwing,
    door,
    doorGlass,
    sidelite,
    sideliteGlass,
    finish,
    casing,
    frame,
    hardware,
    brickMold,
    inactivePrep,
    peepSite,
    doorWidth,
    doorHeight,
    doorThickness,
  };
};

/**
 * Version 3.0.0 and above: Uses both componentData and specifications
 */
export const getExteriorDoorVersion2 = (payload) => {
  function getItemByPartType(type) {
    return get(payload, "components.componentData", []).find(
      (item) => item.partType === type
    );
  }

  function getItemBySpecification(type) {
    return get(payload, "specifications", []).find(
      (item) => item.type === type
    );
  }

  const door = {
    description: get(
      getItemBySpecification("SLAB_DESIGN"),
      "description",
      "N/A"
    ),
    layout: get(getItemBySpecification("SLAB_LAYOUT"), "description"),
    links: get(getItemBySpecification("SLAB_DESIGN"), "links", []),
  };
  const doorGlass = {
    doorGlassName: get(
      getItemBySpecification("GLASS_DESIGN"),
      "description",
      "N/A"
    ),
    doorGlassSize: get(
      getItemByPartType("DoorGlass"),
      "doorGlassSize.description",
      "N/A"
    ),
    glazingType: get(
      getItemBySpecification("SLAB_DESIGN"),
      "glazingType",
      "N/A"
    ),
  };
  const sidelite = {
    slSize: `${get(getItemByPartType("Sidelite"), "width", "N/A")} x ${get(
      getItemByPartType("Sidelite"),
      "height",
      "N/A"
    )}`,
  };
  const sideliteGlass = {
    slGlassLayout: get(
      getItemBySpecification("SLAB_DESIGN"),
      "sidelitePanelType",
      "N/A"
    ),
    slGlassSize: get(
      getItemByPartType("SideliteGlass"),
      "slGlassSize.description",
      "N/A"
    ),
    slGlassName: payload.components
      ? get(
          getItemByPartType("SideliteGlass"),
          "slGlassName.description",
          "N/A"
        )
      : get(
          getItemBySpecification("SIDE_LITE_GLASS_DESIGN"),
          "description",
          "N/A"
        ),
    resourceId: get(
      getItemBySpecification("SIDE_LITE_GLASS_DESIGN"),
      "resourceId",
      "N/A"
    ),
  };
  const finish = {
    exteriorFinish: get(
      getItemBySpecification("SLAB_FINISH"),
      "exterior",
      "N/A"
    ),
    interiorFinish: get(
      getItemBySpecification("SLAB_FINISH"),
      "interior",
      "N/A"
    ),
    exteriorFrameFinish: get(
      getItemBySpecification("SLAB_FINISH"),
      "exteriorFrame",
      "N/A"
    ),
    interiorFrameFinish: get(
      getItemBySpecification("SLAB_FINISH"),
      "interiorFrame",
      "N/A"
    ),
    weatherStrip: get(
      getItemBySpecification("SLAB_FINISH"),
      "weatherstrip",
      "N/A"
    ),
  };
  const casing = {
    description: get(getItemByPartType("Casing"), "description", "None"),
    finish: get(getItemBySpecification("CASING"), "finish"),
  };
  const frame = {
    jambDepth: get(getItemByPartType("Frame"), "frameType.description", "N/A"),
    handing: get(getItemByPartType("Frame"), "handing.description", "N/A"),
    isDoorCovered: get(getItemBySpecification("DOOR_COVERED"), "value")
      ? "Yes"
      : "No",
  };
  const roughOpening = get(getItemByPartType("Frame"), "roughOpening", "N/A");
  const customHeight = getItemBySpecification("CUT_DOWN_HEIGHT") || "N/A";

  const hardware = {
    description: get(
      getItemByPartType("Hardware"),
      "description",
      get(getItemBySpecification("HARDWARE_PART"), "description", "N/A")
    ),
    finish: get(getItemByPartType("Hardware"), "finish", "N/A"),
  };
  const brickMoldDescription = get(
    getItemByPartType("BrickMold"),
    "description",
    "N/A"
  );
  const brickMold = {
    description:
      brickMoldDescription === "No Brickmold"
        ? "None"
        : `${brickMoldDescription} | Shipped Loose`,
  };
  const inactivePrep = {
    description: get(
      getItemByPartType("Frame"),
      "bore.inactivePrepDescription",
      "No"
    ),
  };
  const peepSite = {
    description: get(getItemByPartType("PeepSite"), "description", "No"),
    adaPeepSite: get(getItemByPartType("PeepSite"), "adaPeepSite", false),
  };
  const approvalCode = get(payload, "components.approvalCode", "N/A");
  const doorWidth = get(payload, "components.doorWidth", "N/A");
  const doorHeight = get(payload, "components.doorHeight", "N/A");
  const doorThickness = get(payload, "components.doorThickness", "N/A");

  const isDoubleDoor =
    get(getItemBySpecification("SLAB_LAYOUT"), "slabType") === "DOUBLE";
  const hasDoorGlass =
    !isEmpty(getItemByPartType("DoorGlass")) ||
    !isEmpty(getItemBySpecification("GLASS_DESIGN"));
  const hasSidelite =
    !isEmpty(getItemByPartType("Sidelite")) ||
    !isEmpty(getItemBySpecification("SIDE_LITE_GLASS_DESIGN"));
  const isHvhz = Boolean(get(getItemByPartType("Door"), "hvhz"));
  const isDoorGlassImpactRated = Boolean(
    get(getItemByPartType("DoorGlass"), "impactRated")
  );
  const isSlGlassImpactRated = Boolean(
    get(getItemByPartType("SideliteGlass"), "impactRated")
  );
  const isOutSwing = get(
    getItemByPartType("Frame"),
    "handing.description",
    ""
  ).includes("Outswing");
  const doorSill = {
    description: get(getItemByPartType("Frame"), "sill.description", "N/A"),
    finishImage: get(getItemBySpecification("FINISH"), "links[0].href"),
  };
  const hinge = {
    description: get(getItemByPartType("Hinge"), "description", "N/A"),
    finishImage: get(getItemBySpecification("FINISH"), "links[0].href"),
  };
  const boreDetails = {
    diameter: get(getItemByPartType("Door"), "bore.diameter", "N/A"),
    backSet: get(getItemByPartType("Door"), "bore.backSet", "N/A"),
    centerLine: get(getItemByPartType("Door"), "bore.centerLine", "N/A"),
  };

  return {
    isDoubleDoor,
    hasDoorGlass,
    hasSidelite,
    isHvhz,
    isDoorGlassImpactRated,
    isSlGlassImpactRated,
    isOutSwing,
    door,
    doorGlass,
    sidelite,
    sideliteGlass,
    finish,
    casing,
    frame,
    hardware,
    brickMold,
    inactivePrep,
    peepSite,
    doorWidth,
    doorHeight,
    roughOpening,
    customHeight,
    doorThickness,
    approvalCode,
    doorSill,
    hinge,
    boreDetails,
  };
};
