import { ORDER_PARENT_STATUS, STATUS } from "../components/Status";

export function quoteIsSplittable(quote) {
  return anyItemReserved(quote.items) && quoteIsValid(quote);
}

export function quoteIsPurchasable(quote) {
  return allItemsReserved(quote.items) && quoteIsValid(quote);
}

export function quoteIsExpired(quote) {
  return anyItemsExpired(quote.items) && quoteIsValid(quote);
}

function quoteIsValid(quote) {
  return (
    quote.items &&
    quote.items.length > 0 &&
    (quote.state.parentStatus === ORDER_PARENT_STATUS.OPEN ||
      quote.state.parentStatus === ORDER_PARENT_STATUS.PAYMENT)
  );
}

function allItemsReserved(orderItems) {
  return orderItems.every((item) => item.state === STATUS.RESERVED);
}

function anyItemReserved(orderItems) {
  return orderItems.some((item) => item.state === STATUS.RESERVED);
}

function anyItemsExpired(orderItems) {
  return orderItems.some((item) => item.state === STATUS.EXPIRED);
}
