import loadable from "@loadable/component";

const LazyForgotPasswordPage = loadable(() => import("./ForgotPassword"));
const LazyResetPasswordPage = loadable(() => import("./ResetPassword"));
const LazySignInPage = loadable(() => import("./SignIn"));

export default class Login {
  static routes(context) {
    return [
      {
        path: "/login",
        component: LazySignInPage,
      },
      {
        path: "/forgot-password",
        component: LazyForgotPasswordPage,
      },
      {
        path: "/reset-password/:token",
        component: LazyResetPasswordPage,
      },
    ];
  }
}
