import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@masonite-digital/common-components";
import { AuthContext } from "../../AuthContext";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledLink = styled(Link)({
  textDecoration: "none",
});

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: "14px",
  backgroundColor: "#F2F9E8",
  padding: "8px 16px",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  border: `1px solid ${theme.palette.border}`,
  borderCollapse: "separate",
  borderRadius: "4px",
  margin: "24px 0",
}));

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "childComponentStyle",
})(({ childComponentStyle = {} }) => ({
  cursor: "auto",
  "& > *": {
    border: "none",
    ...childComponentStyle,
  },
}));

export default function ExpiredPriceDialog(props) {
  const { error, open, onSecondaryClick } = props;
  const context = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleSecondaryClick = async () => {
    try {
      await onSecondaryClick();
    } catch (e) {
      if (e.status === 503) {
        context.openErrorSnackbar(e);
      } else {
        setErrorMessage(e.message);
      }
    }
  };

  return (
    <AlertDialog
      open={open}
      title="Quoted prices have expired"
      dialogType="WARNING"
      onSecondaryClick={handleSecondaryClick}
      helperText={errorMessage}
      hidePrimaryButton
      secondaryLabel="Close"
      contentText={error?.message}
      customContent={
        <Grid>
          <StyledTable>
            <TableBody>
              {error?.detailed_messages?.sort().map((message, key) => (
                <StyledTableRow key={key}>
                  <StyledTableCell>
                    <Typography variant="body2">{message}</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>

          <Grid container item direction="column">
            <Typography variant="body2">
              Any questions or need assistance please contact Customer Service.
            </Typography>
            <Typography variant="body2">
              <StyledLink
                data-test="email-link"
                href="mailto:CustomerCare@Advisar.com"
              >
                CustomerCare@Advisar.com
              </StyledLink>
              <span>, or </span>
              <StyledLink data-test="phone-number-link" href="tel:813-261-3580">
                813-261-3580
              </StyledLink>
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
}

ExpiredPriceDialog.propTypes = {
  error: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};
