import React from "react";
import PropTypes from "prop-types";
import CloseCircleIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ButtonBase, SvgIcon, Tooltip, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CompatibilityIconSkeleton } from "../Common/OptionSkeleton";

export default function SelectionBox({
  children,
  isLoadingCompatibility,
  onClick,
  component,
  selected,
  compatible,
  disableIcon,
  forceExtraSmallIcons,
  circleShape,
  toolTipText = "",
  dataTest,
  onDelete,
  showDelete,
  addMargin,
}) {
  const theme = useTheme();

  const isMediaQueryExtraSmall = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMediaQuerySmall = useMediaQuery(theme.breakpoints.only("sm"), {
    noSsr: true,
  });
  const isMediaQueryMedium = useMediaQuery(theme.breakpoints.up("md"), {
    noSsr: true,
  });

  const isExtraSmall = forceExtraSmallIcons ? true : isMediaQueryExtraSmall;
  const isSmall = forceExtraSmallIcons ? false : isMediaQuerySmall;
  const isMedium = forceExtraSmallIcons ? false : isMediaQueryMedium;
  const getFontSize = isSmall ? "small" : isMedium ? "medium" : "inherit";

  let icon;

  if (isExtraSmall) {
    if (selected || !compatible) {
      icon = (
        <SvgIcon
          fontSize="inherit"
          viewBox="0 0 12 12"
          sx={{
            position: "absolute",
            right: "-5px",
            bottom: "-5px",
            zIndex: 1,
          }}
          data-test={"active"}
        >
          <circle
            cx="6"
            cy="6"
            r="5"
            fill={
              selected ? theme.palette.secondary.main : theme.palette.error.dark
            }
            fillRule="evenodd"
            stroke="#FFF"
            strokeWidth="2"
          />
        </SvgIcon>
      );
    } else {
      icon = <div data-test={"incompatible-icon"} />;
    }
  } else {
    if (selected) {
      icon = (
        <CheckCircleIcon
          sx={{
            position: "absolute",
            right: "-10px",
            bottom: "-10px",
            backgroundColor: "white",
            borderRadius: "30px",
            zIndex: 1,
          }}
          fontSize={getFontSize}
          data-test={"active"}
        />
      );
    } else if (!compatible) {
      icon = (
        <SvgIcon
          fontSize={getFontSize}
          sx={{
            position: "absolute",
            right: "-10px",
            bottom: "-10px",
            backgroundColor: "white",
            borderRadius: "30px",
            zIndex: 1,
          }}
          data-test={"incompatible-icon"}
        >
          <path
            fill="crimson"
            d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z"
          />
        </SvgIcon>
      );
    } else {
      icon = <div />;
    }
  }

  if (isLoadingCompatibility) {
    icon = <CompatibilityIconSkeleton />;
  }

  return (
    <div
      style={{
        position: "relative",
        marginBottom: addMargin ? "4px" : 0,
      }}
    >
      {!selected && showDelete && (
        <CloseCircleIcon
          data-test={dataTest + "-delete-icon"}
          sx={{
            position: "absolute",
            zIndex: "2",
            top: "-4px",
            right: "-4px",
            background: "white",
            borderRadius: "50%",
            color: "rgba(0,0,0,.24)",
            cursor: "pointer",
            ...(forceExtraSmallIcons && {
              top: "-2px",
              right: "-2px",
              height: ".7em",
              width: ".7em",
            }),
          }}
          onClick={onDelete}
        />
      )}
      <Tooltip title={toolTipText}>
        <ButtonBase
          data-test={dataTest}
          focusRipple
          component={component}
          onClick={onClick}
          TouchRippleProps={{
            style: {
              color: !compatible ? "crimson" : theme.palette.secondary.main,
            },
          }}
          sx={{
            position: "relative",
            WebkitTouchCallout: "none",
            border: `${isExtraSmall ? 2 : 4}px solid ${
              selected ? theme.palette.secondary.main : "transparent"
            }`,
            color: theme.palette.secondary.main,
            padding: "2px",
            margin: "0 2px",
            borderRadius: `${circleShape ? "50%" : "4px"}`,
          }}
        >
          {children}
          {!disableIcon && <>{icon}</>}
        </ButtonBase>
      </Tooltip>
    </div>
  );
}

SelectionBox.defaultProps = {
  onClick: () => {
    /*Empty function is intentional*/
  },
  component: "button",
  forceExtraSmallIcons: false,
  circleShape: false,
};

SelectionBox.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoadingCompatibility: PropTypes.bool,
  component: PropTypes.string,
  selected: PropTypes.bool,
  compatible: PropTypes.bool,
  disableIcon: PropTypes.bool,
  forceSmall: PropTypes.bool,
  circleShape: PropTypes.bool,
  toolTipText: PropTypes.string,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool,
};
